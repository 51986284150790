import { Grid, TableCell, TableHead, TableRow } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Done } from "@material-ui/icons";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import React, { useCallback, useEffect, useState } from "react";
import {
    Datagrid, Edit, EditButton, Error, Loading, required, SaveButton, SimpleForm,
    TextInput, Toolbar, useDataProvider, useNotify, useRecordContext, useRefresh
} from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { rowEditPopupContent } from "./components/page-path-edit-popup";
import { PagePathInput } from "./components/page-path-input";
var moment = require("moment-timezone");

const PageTitle = ({ record }) => {
  return <span>Page {record ? `${record.name}` : ""}</span>;
};

const PageEditToolbar = (props) => {
  const data = useRecordContext();
  const form = useForm();

  const onClick = useCallback(()=>{
    const p = form.getState().values.path;
    console.log('onClick : path = ' + p);
    path2 = p;
  });

  return (
    <Toolbar alwaysEnableSaveButton={true} {...props}>
      <SaveButton onClick={onClick} />
    </Toolbar>
  );
};

const SortableList = ({ ...props }) => {
  const data = useRecordContext();
  const form = useForm();

  const dataProvider = useDataProvider();
  const [rows, setRows] = useState();
  const [templates, setTemplates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [dummy2, setDummy2] = useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd = " + oldIndex + " " + newIndex);
    var oldItem = rows[oldIndex];
    var newItem = rows[newIndex];

    setRows((rows) => {
      var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row
      var updatedRowIds = updatedRows.map((r) => r.id);
      form.change("rows", updatedRowIds); //Also change form::rows
      return updatedRows;
    });
  };

  const onDelete = (rowId) => {
    setRows((rows) => {
      var updatedRows = rows.filter((row) => row.id !== rowId);
      var updatedRowIds = updatedRows.map((r) => r.id);
      form.change("rows", updatedRowIds); //Also change form::rows
      return updatedRows;
    });
  };

  useEffect(() => {
    console.log("GET mpm-row-templates");
    dataProvider
      .getList("mpm-row-templates", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setTemplates(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    console.log("get mpm-rows " + data.rows);

    var rowIds = data.rows;
    dataProvider
      .getMany("mpm-rows", { ids: rowIds })
      .then(({ data }) => {
        //Result is not in correct order. Reorder result according to data.rows order
        var arr = [];
        for (var rowId of rowIds) {
          var x = data.find((row) => row.id == rowId);
          if (x != null) {
            //Maybe row does not exist anymore
            arr.push(x);
          }
        }

        setRows(arr);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [data.rows.length, dummy2]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!rows) return null;
  if (!templates) return null;

  return (
    <>
      <PopupboxContainer />
      {/* <TopToolbar>
        <Link to={`/mpm-rows/create?page_id=${data.id}`}>Create</Link>
      </TopToolbar> */}
      <SortableListContainer {...props} isHomepage={data.is_homepage} page_id={data.id} templates={templates} items={rows} onSortEnd={onSortEnd} onDelete={onDelete} useDragHandle={true} lockAxis="y" setDummy2={setDummy2} />
    </>
  );
};

const SortableItem2 = SortableElement(({ item, layoutName, onDelete, setDummy2 }) => {
  return <MyDatagridRow item={item} layoutName={layoutName} showDeleteButton={true} showReorderButton={true} onDelete={onDelete} setDummy2={setDummy2} />;
});

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{}}>ID</TableCell>
      <TableCell style={{}}>Row Name</TableCell>
      <TableCell style={{}}>Title in TC</TableCell>
      <TableCell style={{}}>Content</TableCell>
      <TableCell style={{}}>Layout</TableCell>
      <TableCell style={{}}>RowPath</TableCell>
      <TableCell style={{}}>Onshelf Datetime</TableCell>
      <TableCell style={{}}>Offshelf Datetime</TableCell>
      <TableCell style={{}}></TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridBody = ({ templates, items, onDelete, setDummy2 }) => {
  return items.map((item, index) => {
    var layoutName = "";
    for (const template of templates) {
      if (template.id == item.template) {
        layoutName = template.name;
      }
    }

    return <SortableItem2 key={item.id} index={index} item={item} layoutName={layoutName} onDelete={() => onDelete(item.id)} setDummy2={setDummy2} />;
  });
};

const MyDatagridRow = ({ item, layoutName, showDeleteButton, showReorderButton, onDelete, setDummy2 }) => {
  var content = "";
  if (item.type == "editorial_pick") {
    content = "Editorial";
  } else if (item.type == "event") {
    content = "Event";
  } else if (item.type == "programme_list") {
    content = "ProgrammeList";
  } else if (item.type == "history") {
    content = "WatchingHistory";
  } else if (item.type == "recom_personalised") {
    content = "Recommendation (Personalised)";
  } else if (item.type == "recom_trendy") {
    content = "Recommendation (Trending)";
  } else if (item.type == "recom_universal") {
    content = "Recommendation (Universal list)";
  } else if (item.type == "recom_free_preview") {
    content = "Recommendation (Free Episode)";
  } else if (item.type == "latest") {
    content = "Latest";
  } else if (item.type == "top_sv") {
    content = "Top SV";
  } else if (item.type == "episode_list") {
    content = "Episode";
  } else if (item.type == "clip_list") {
    content = "Clips";
  } else if (item.type == "category_list") {
    content = "Category List";
  }

  var onshelfTime = "";
  var offshelfTime = "";
  if (item.start_time) {
    onshelfTime = moment.tz(item.start_time, "Asia/Hong_Kong").format("YYYY-MM-DD HH:mm");
  }
  if (item.end_time) {
    offshelfTime = moment.tz(item.end_time, "Asia/Hong_Kong").format("YYYY-MM-DD HH:mm");
  }

  // Edit popup
  const rowEditOnClick = useCallback((item)=>{
    PopupboxManager.open({ 
        content: rowEditPopupContent({
            item: item,
            setDummy2: setDummy2,
        }), 
        config: {
            overlayClose: false, 
            escClose: false,
        } 
    });
    console.log('rowEditOnClick');
    console.log(item);
  });


  return (
    <TableRow>      
      <TableCell style={{'overflowWrap': 'break-word'}}>{item.id}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{item.name}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{item.title_tc}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{content}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{layoutName}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{item.path}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{onshelfTime}</TableCell>
      <TableCell style={{'overflowWrap': 'break-word'}}>{offshelfTime}</TableCell>
      <TableCell>

        {
          item.type == "programme_list" ? (
            <EditButton onClick={()=>{rowEditOnClick(item)}} label="Edit"  />
          ) : <></>
        }
        
      </TableCell>
    </TableRow>
  );
};

const SortableListContainer = SortableContainer(({ ...props }) => {
  const { page_id, isHomepage, templates, items, onDelete } = props;

  return <Datagrid style={{ "table-layout": "fixed" }} 
    header={<DatagridHeader />} 
    body={
        <MyDatagridBody
        isHomepage={isHomepage}
        templates={templates}
        items={items}
        onDelete={onDelete}
        setDummy2={props.setDummy2}
        />
    }></Datagrid>;
});

const CategoryGrid = (props) => {
  const record = useRecordContext();

  const dataProvider = useDataProvider();
  // const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!record.category_id) {
      return;
    }

    dataProvider
      .getMenuTreeCategoryById(record.category_id)
      .then(({ data }) => {
        setCategory(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (!category) {
    return <></>;
  }

  var parentCategory;
  var thisCategory;

  //Max 2 level
  if (category.menu_tree) {
    for (var firstLevel of category.menu_tree) {
      if (firstLevel.category_id == record.category_id) {
        thisCategory = firstLevel;
        break;
      }

      if (firstLevel.categories) {
        for (var secondLevel of firstLevel.categories) {
          if (secondLevel.category_id == record.category_id) {
            thisCategory = secondLevel;
            parentCategory = firstLevel;
            break;
          }
        }
      }
    }
  }

  var status, menuId, parentTc, parentEn, categoryTc, categoryEn;

  var datetime = moment(category.start_time).format("YYYY-MM-DD HH:mm");
  if (category.status == "inactive") {
    status = `This page is inside an inactive menu set [${category.description}] which will be effective on [${datetime}]`;
  } else if (category.status == "active") {
    status = `This page is inside a currently active menu set [${category.description}]`;
  } else if (category.status == "expired") {
    status = `This page is inside an inactive menu set [${category.description}] which is expired on [${datetime}]`;
  }

  menuId = `Menu ID: {record.category_id}`;
  if (parentCategory) {
    parentTc = `Parent in TC: ${parentCategory.name_tc}`;
    parentEn = `Parent in EN: ${parentCategory.name_en}`;
  }
  if (thisCategory) {
    categoryTc = `Category Name in TC: ${thisCategory.name_tc}`;
    categoryEn = `Category Name in EN: ${thisCategory.name_en}`;
  }

  if (parentCategory && thisCategory) {
    return (
      <>
        {status}
        <br />
        <br />
        Menu ID: {record.category_id}
        <br />
        <br />
        {parentTc}
        <br />
        {parentEn}
        <br />
        <br />
        {categoryTc}
        <br />
        {categoryEn}
      </>
    );
  } else if (thisCategory) {
    return (
      <>
        {status}
        <br />
        <br />
        Menu ID: {record.category_id}
        <br />
        <br />
        {categoryTc}
        <br />
        {categoryEn}
      </>
    );
  }

  return (
    <>
      {status}
      <br />
      <br />
      Menu ID: {record.category_id}
    </>
  );
};

var path1 = '';
var path2 = '';

const EditX = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  const onSuccess = useCallback(() => {
    notify('Page path update success : ' + path1 + ' -> ' + path2, 'success');
    refresh();
    // window.location.reload(false);
  });
  // const from = useForm();
  // const getPagePath = ()=>{
  //   return form.getStates.values.page_path;
  // };

  const EditFormContent = (props) => {
    
    const record = useRecordContext();

    useEffect(() => {
      console.log('EditFormContent . useEffect : record.path');
      console.log(record.path);
      path1 = record.path;
      // props.setPath1(record.path);
    }, []);

    return <></>;
  }

  return (
    <Edit onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" {...props} title={<PageTitle />}>
      <SimpleForm toolbar={<PageEditToolbar />}>
        <EditFormContent />
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <CategoryGrid />
          </Grid>

          <Grid item sm={12} md={6}>
            <TextInput source="name_tc" fullWidth disabled />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput source="name_en" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <PagePathInput />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput onKeyDown={listenerTC} label="SEO Description in TC (within 85 char)" source="seo_desc_tc" fullWidth defaultValue={""} multiline disabled />
            <DefaultValuetc />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput onKeyDown={listenerEN} label="SEO Description in EN (within 170 char)" source="seo_desc_en" fullWidth defaultValue={""} multiline disabled />
            <DefaultValueEn />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <SortableList {...props} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const PageRowPathEdit = (props) => {
  return <EditX {...props} />;
};

const listenerEN = (event) => {
  var maxlength = 170;

  document.getElementById("start-en").innerHTML = document.getElementById("seo_desc_en").value.length + 1;
};
const listenerTC = (event) => {
  var maxlength = 85;
  document.getElementById("start-tc").innerHTML = document.getElementById("seo_desc_tc").value.length + 1;
};

const DefaultValueEn = (props) => {
  const page = useRecordContext();
  let count = 0;
  if (page.seo_desc_en && page.seo_desc_en.length) {
    count = page.seo_desc_en.length;
  }
  return (
    <div id="count-en">
      <span id="start-en">{count}</span>/170
    </div>
  );
};
const DefaultValuetc = (props) => {
  const page = useRecordContext();
  let count = 0;
  if (page.seo_desc_tc && page.seo_desc_tc.length) {
    count = page.seo_desc_tc.length;
  }
  return (
    <div id="count-tc">
      <span id="start-tc">{count}</span>/85
    </div>
  );
};
