import { TableCell, TableHead, TableRow } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import React, { useState } from "react";
import { BooleanInput, CreateButton, Datagrid, Button, DeleteWithConfirmButton, FormDataConsumer, Labeled, RecordContextProvider, useDataProvider, useRecordContext } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { AddIconDialog } from "./icon-dialog";
var moment = require("moment-timezone");

const addIconButtonOnClick = async (form, dataProvider) => {
  const { data } = await dataProvider.getAllIMIcons();

  var selectedIcons = form.getState().values.icons ?? [];

  PopupboxManager.open({
    content: <AddIconDialog key={moment().unix()} form={form} icons={data} selectedIcons={selectedIcons} />,
    config: {
      style: { width: "80%", height: "80%" },
      overlayClose: false,
      escClose: false,
      content: {
        style: {
          overflowY: "scroll",
        },
      },
    },
  });
};

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell> {/* Drag */}
      <TableCell style={{}}>ID</TableCell>
      <TableCell style={{}}>Icon</TableCell>
      <TableCell style={{}}></TableCell> {/* Enabled */}
      <TableCell style={{}}></TableCell> {/* Remove */}
    </TableRow>
  </TableHead>
);

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const SortableItem2 = SortableElement(({ roomStatus, item, pos, ...props }) => {
  return <MyDatagridRow roomStatus={roomStatus} item={item} pos={pos} {...props} />;
});

const MyDatagridRow = ({ roomStatus, item, pos, ...props }) => {
  const form = useForm();

  return (
    <RecordContextProvider value={item}>
      <TableRow>
        {roomStatus == "end" ? <TableCell /> : <DragHandle />}

        <TableCell style={{ overflowWrap: "break-word" }}>{item.id}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <img src={item.image_url} style={{ height: "100px", width: "100px" }} />
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          {roomStatus != "end" && (
            <BooleanInput
              label="Enabled"
              source={`enabled_${item.id}`}
              defaultValue={item.enabled}
              onChange={(checked) => {
                var oldData = form.getState().values.icons ?? [];
                var newData = [...oldData]; //Clone

                newData[pos]["enabled"] = checked;

                form.change("icons", newData);
              }}
            />
          )}
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          {!["live", "end"].includes(roomStatus) && (
            <Button
              label="Remove"
              style={{ color: "red" }}
              onClick={(e) => {
                var oldData = form.getState().values.icons ?? [];
                var newData = [...oldData]; //Clone

                newData.splice(pos, 1);

                form.change("icons", newData);
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = ({ roomStatus, items, ...props }) => {
  return items.map((item, index) => {
    return <SortableItem2 roomStatus={roomStatus} item={item} index={index} pos={index} {...props} />;
    // return <MyDatagridRow item={item} index={index} {...props} />;
  });
};

const SortableListContainer = SortableContainer(({ roomStatus, rows, ...props }) => {
  return (
    <Datagrid
      style={{ tableLayout: "fixed" }}
      header={<DatagridHeader />}
      body={
        <MyDatagridBody
          items={rows ?? []}
          roomStatus={roomStatus}
          // refreshRows={refreshRows}
        />
      }
    />
  );
  // return <Datagrid style={{ "table-layout": "fixed" }} header={<DatagridHeader />} body={<MyDatagridBody isHomepage={isHomepage} items={items} onDelete={onDelete} />}></Datagrid>;
});

const SortableList = ({ rows, roomStatus, ...props }) => {
  const data = useRecordContext();
  const form = useForm();

  const dataProvider = useDataProvider();
  // const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd = " + oldIndex + " " + newIndex);
    var oldItem = rows[oldIndex];
    var newItem = rows[newIndex];

    var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder
    form.change("icons", [...updatedRows]); //Clone

    // setRows((rows) => {
    //   var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row
    //   var updatedRowIds = updatedRows.map((r) => r.id);
    //   form.change("rows", updatedRowIds); //Also change form::rows
    //   return updatedRows;
    // });
  };

  const onDelete = (rowId) => {
    // setRows((rows) => {
    //   var updatedRows = rows.filter((row) => row.id !== rowId);
    //   var updatedRowIds = updatedRows.map((r) => r.id);
    //   form.change("rows", updatedRowIds); //Also change form::rows
    //   return updatedRows;
    // });
  };

  return <SortableListContainer {...props} roomStatus={roomStatus} rows={rows} onSortEnd={onSortEnd} onDelete={onDelete} useDragHandle={true} lockAxis="y" />;
};

export const IconList = ({ roomStatus, ...props }) => {
  const form = useForm();

  const dataProvider = useDataProvider();

  return (
    <>
      <Labeled label="Icon">
        {roomStatus != "end" && (
          <CreateButton
            label="Add"
            onClick={(e) => {
              e.preventDefault();
              addIconButtonOnClick(form, dataProvider);
            }}
          />
        )}
      </Labeled>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          var rows = formData.icons;
          console.log(rows);

          return <SortableList {...props} rows={rows} roomStatus={formData.status} />;
        }}
      </FormDataConsumer>
    </>
  );
};

export const IconListShow = (props) => {
  const { record } = props;

  const MyDatagridRow = ({ item, ...props }) => {
    return (
      <TableRow>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <img src={item.image_url} style={{ height: "100px", width: "100px" }} />
        </TableCell>
      </TableRow>
    );
  };

  const MyDatagridBody = ({ items, ...props }) => {
    if (items) {
      return items.map((item, index) => {
        return <MyDatagridRow item={item} {...props} />;
      });
    }
    return <></>;
  };

  return (
    <Labeled label="Icon">
      <Datagrid style={{ tableLayout: "fixed" }} header={<></>} body={<MyDatagridBody items={record.icons} />} />
    </Labeled>
  );
};
