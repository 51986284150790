import {
  List, //
  ListContextProvider,
  Button,
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  SimpleShowLayout,
  Labeled,
  ListButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useParams, useCallback } from "react";
import { useDataProvider, Loading, useRecordContext } from "react-admin";
import { MenuTreeCreatePopup } from "./menu-tree-create-popup";
import { ReorderButton } from "./../components/reorder-button";

// popup
import "reactjs-popup/dist/index.css";
import { Add, Remove } from "@material-ui/icons";

var moment = require("moment-timezone");

const customButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid grey",
  margin: "10px",
};

export const MenuItemTable = (props) => {
  console.log("MenuItemTable : props = ");
  console.log(props);

  // constructor
  const [rowExpands, setRowExpands] = useState(props.rows);

  useEffect(() => {
    setRowExpands(props.rows);
  });

  // edit button
  const editButtonOnClick = useCallback((row, parentIndex, selfIndex) => {
    console.log("MenuItemTable . editButtonOnClick : parentIndex = " + parentIndex + " ,  selfIndex = " + selfIndex + " , row = ");
    console.log(row);
    row.parentIndex = parseInt(parentIndex);
    row.selfIndex = parseInt(selfIndex);

    props.editMenuItemCallback(row);
  });

  // expand toggler
  const rowExpandsCallback = useCallback((rowIndex, flag) => {
    console.log("MenuItemTable . rowExpandsCallback : rowIndex = " + rowIndex + " ,flag = " + flag);
    rowExpands[rowIndex].isExpanded = flag;
    setRowExpands(rowExpands.map((item) => item));
  });

  // displayRows
  const displayRows = [];
  for (const rowIndex in props.rows) {
    const row = props.rows[rowIndex];

    displayRows.push(
      <tr key={row.id}>
        <td className="edit-td">
          {props.isTogglingReorder ? (
            <ReorderButton index={rowIndex} total={props.rows.length} parentIndex={-1} reorderCallback={props.reorderCallback} />
          ) : (
            <EditButton
              onClick={() => {
                editButtonOnClick(row, -1, rowIndex);
              }}
            />
          )}
        </td>
        <td className="expand-td">{row.categories && row.categories.length > 0 ? <ExpandButton row={row} rowIndex={rowIndex} rowExpands={rowExpands} rowExpandsCallback={rowExpandsCallback} /> : <></>}</td>
        <td className="normal-td">{row.name_tc}</td>
        <td className="normal-td">{row.name_en}</td>
        {/* <td className="normal-td">{row.path}</td> */}
        <td className="normal-td">{row.category_id}</td>
        <td className="normal-td">{showDate(row.start_time)}</td>
        <td className="normal-td">{showDate(row.end_time)}</td>
      </tr>
    );

    displayRows.push(
      <tr key={row.id + "_2"}>
        <td colSpan={7} style={{ paddingLeft: "0px", width: "100%" }}>
          <table className="menu-expand-category border-collapse">
            <tbody>
              {row.categories && row.categories.length > 0 && rowExpands[rowIndex].isExpanded == true ? (
                row.categories.map((row2, rowIndex2) => (
                  <tr key={row2.id + "_3"}>
                    <td className="edit-td">
                      {props.isTogglingReorder ? (
                        <ReorderButton index={rowIndex2} total={row.categories.length} parentIndex={rowIndex} reorderCallback={props.reorderCallback} />
                      ) : (
                        <EditButton
                          onClick={() => {
                            editButtonOnClick(row2, rowIndex, rowIndex2);
                          }}
                        />
                      )}
                    </td>
                    <td className="expand-td">&nbsp;</td>
                    <td className="normal-td">{row2.name_tc}</td>
                    <td className="normal-td">{row2.name_en}</td>
                    {/* <td className="normal-td">{row2.path}</td> */}
                    <td className="normal-td">{row2.category_id}</td>
                    <td className="normal-td">{showDate(row2.start_time)}</td>
                    <td className="normal-td">{showDate(row2.end_time)}</td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  return (
    <table className="menu-tree-edit">
      <thead>
        <tr key="table_1_head">
          <td className="menu-child-category_name">&nbsp;</td>
          <td className="expand-td">&nbsp;</td>
          <td className="menu-child-category_name">Page title in TC (SEO)</td>
          <td className="menu-child-category_name">Page title in EN (SEO)</td>
          {/* <td className="menu-child-category_name">Path</td> */}
          <td className="menu-child-category_name">Menu ID</td>
          <td className="menu-child-category_name">Start Time</td>
          <td className="menu-child-category_name">End Time</td>
        </tr>
      </thead>
      <tbody>{displayRows}</tbody>
    </table>
  );
};

const ExpandButton = (props) => {
  const onExpandClick = () => {
    console.log("ExpandButton . onExpandClick");
    props.rowExpandsCallback(props.rowIndex, true);
  };
  const onCollaspeClick = () => {
    console.log("ExpandButton . onCollaspeClick");
    props.rowExpandsCallback(props.rowIndex, false);
  };

  return <>{props.rowExpands[props.rowIndex].isExpanded ? <EditButton label="" icon={<Remove />} onClick={onCollaspeClick} /> : <EditButton label="" icon={<Add />} onClick={onExpandClick} />}</>;
};

const showDate = (obj)=>{
  if(obj)  return moment(obj).format('YYYY-MM-DD HH:mm');
  return '';
}