import { TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { Button, Confirm, CreateButton, useNotify, Datagrid, DeleteButton, DeleteWithConfirmButton, EditButton, FormDataConsumer, Labeled, RecordContextProvider, ShowButton, useRecordContext } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { MyDateField } from "../../../MyDateField";
import { AddCannedMessageDialog, ShowCannedMessageDialog } from "./canned-message-dialog";
import dataProvider from "../../../dataProvider";
var moment = require("moment-timezone");

export const CannedMessageListShow = (props) => {
  const { record } = props;
  console.log(`record = ${JSON.stringify(record)}`);

  const DatagridHeader = ({ children }) => (
    <TableHead>
      <TableRow>
        <TableCell style={{}}>Set Name</TableCell>
        <TableCell style={{}}>Voting Question</TableCell>
        <TableCell style={{}}>Voting</TableCell>
        <TableCell style={{}}>Start Time</TableCell>
        <TableCell style={{}}>End Time</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const MyDatagridRow = ({ item, ...props }) => {
    const rowShowOnClick = (item) => {
      PopupboxManager.open({
        content: <ShowCannedMessageDialog item={item} />,
        config: {
          style: { width: "80%", height: "80%" },
          overlayClose: false,
          escClose: false,
          content: {
            style: {
              overflowY: "scroll",
            },
          },
        },
      });
    };

    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.name}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.question}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.voting_enabled ? "Y" : "N"}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <MyDateField source="start_time" showTime />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <MyDateField source="end_time" showTime />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <ShowButton
              onClick={() => {
                rowShowOnClick(item);
              }}
            />
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  };

  const MyDatagridBody = ({ items, ...props }) => {
    if (items) {
      return items.map((item, index) => {
        console.log("index =" + index);
        return <MyDatagridRow item={item} {...props} />;
      });
    }
    return <></>;
  };

  return (
    <Labeled label="Canned message">
      <Datagrid style={{ tableLayout: "fixed" }} header={<DatagridHeader />} body={<MyDatagridBody items={record.canned_message_list} />} />
    </Labeled>
  );
};

export const CannedMessageList = ({ chatroomId, roomStatus, ...props }) => {
  const form = useForm();

  const addCannedMessageButtonOnClick = () => {
    PopupboxManager.open({
      content: <AddCannedMessageDialog key={moment().unix()} form={form} item={null} roomStatus={roomStatus} />,
      config: {
        style: { width: "80%", height: "80%" },
        overlayClose: false,
        escClose: false,
        content: {
          style: {
            overflowY: "scroll",
          },
        },
      },
    });
  };

  const DatagridHeader = ({ children }) => (
    <TableHead>
      <TableRow>
        <TableCell style={{}}>Set Name</TableCell>
        <TableCell style={{}}>Voting Question</TableCell>
        <TableCell style={{}}>Voting</TableCell>
        <TableCell style={{}}>Start Time</TableCell>
        <TableCell style={{}}>End Time</TableCell>
        <TableCell style={{}}></TableCell> {/* Start Now*/}
        <TableCell style={{}}></TableCell> {/* End Now*/}
        <TableCell style={{}}></TableCell> {/* Show */}
        <TableCell style={{}}></TableCell> {/* Edit */}
        <TableCell style={{}}></TableCell> {/* Remove */}
      </TableRow>
    </TableHead>
  );

  const StartNowButton = (props) => {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const record = useRecordContext();
    const notify = useNotify();

    return (
      <React.Fragment>
        <Button
          label="Start Now"
          disabled={!record.id || ["live", "end"].includes(record.status)}
          onClick={() => {
            setShowConfirm(true);
          }}
        />
        <Confirm
          isOpen={showConfirm}
          title="Are you sure you want to start this Canned Message Set now?"
          onConfirm={async () => {
            try {
              await dataProvider.emergencyStartEndMessageList(chatroomId, record.id);
              window.location.reload(true);
            } catch (error) {
              notify(error.message.toString(), "warning");
            }

            setShowConfirm(false);
          }}
          onClose={() => {
            setShowConfirm(false);
          }}
        />
      </React.Fragment>
    );
  };

  const EndNowButton = (props) => {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const record = useRecordContext();
    const notify = useNotify();

    return (
      <React.Fragment>
        <Button
          label="End Now"
          disabled={!record.id || ["preview", "end"].includes(record.status)}
          onClick={() => {
            setShowConfirm(true);
          }}
        />
        <Confirm
          isOpen={showConfirm}
          title="Are you sure you want to end this Canned Message Set now?"
          onConfirm={async () => {
            try {
              await dataProvider.emergencyEndMessageList(chatroomId, record.id);
              window.location.reload(true);
            } catch (error) {
              notify(error.message.toString(), "warning");
            }

            setShowConfirm(false);
          }}
          onClose={() => {
            setShowConfirm(false);
          }}
        />
      </React.Fragment>
    );
  };

  const MyDatagridRow = ({ roomStatus, item, index, ...props }) => {
    const form = useForm();

    // Edit popup
    const rowEditOnClick = (item, index) => {
      PopupboxManager.open({
        content: <AddCannedMessageDialog key={moment().unix()} form={form} item={item} index={index} {...props} roomStatus={roomStatus} />,
        config: {
          style: { width: "80%", height: "80%" },
          overlayClose: false,
          escClose: false,
          content: {
            style: {
              overflowY: "scroll",
            },
          },
        },
      });
      console.log("rowEditOnClick");
      console.log(item);
    };

    const rowShowOnClick = (item) => {
      PopupboxManager.open({
        content: <ShowCannedMessageDialog item={item} />,
        config: {
          style: { width: "80%", height: "80%" },
          overlayClose: false,
          escClose: false,
          content: {
            style: {
              overflowY: "scroll",
            },
          },
        },
      });
    };

    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.name}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.question}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.voting_enabled ? "Y" : "N"}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <MyDateField source="start_time" showTime />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <MyDateField source="end_time" showTime />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <StartNowButton />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <EndNowButton />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <ShowButton
              onClick={(e) => {
                e.preventDefault();

                rowShowOnClick(item);
              }}
            />
          </TableCell>
          <TableCell>
            {roomStatus != "end" && (
              <EditButton
                onClick={(e) => {
                  e.preventDefault();

                  rowEditOnClick(item, index, false);
                }}
                label="Edit"
              />
            )}
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            {!["live", "end"].includes(roomStatus) && (
              <Button
                label="Remove"
                style={{ color: "red" }}
                onClick={(e) => {
                  e.preventDefault();

                  var oldData = form.getState().values.canned_message_list ?? [];
                  var newData = [...oldData]; //Clone

                  newData.splice(index, 1);

                  form.change("canned_message_list", newData);
                }}
              />
            )}
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  };

  const MyDatagridBody = ({ items, roomStatus, ...props }) => {
    return items.map((item, index) => {
      console.log("index =" + index);
      return <MyDatagridRow roomStatus={roomStatus} item={item} index={index} {...props} />;
    });
  };

  return (
    <>
      <Labeled label="Canned message">
        {roomStatus != "end" && (
          <CreateButton
            label="Add"
            onClick={(e) => {
              console.log(e);
              e.preventDefault();
              addCannedMessageButtonOnClick();
            }}
          />
        )}
      </Labeled>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          var rows = formData.canned_message_list;
          console.log(rows);

          return (
            <Datagrid
              style={{ tableLayout: "fixed" }}
              header={<DatagridHeader />}
              body={
                <MyDatagridBody
                  items={rows ?? []}
                  roomStatus={roomStatus}
                  // refreshRows={refreshRows}
                />
              }
            />
          );
        }}
      </FormDataConsumer>
    </>
  );
};
