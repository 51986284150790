import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput, number, required, TextInput, useDataProvider } from "react-admin";
import { useForm } from "react-final-form";
import { mytvsuperUrl } from "../../../constant";
import { showFormContent } from "../../mpm-rows/components/show-form-content";

// var programmePath = "";

export const EpisodeSearch = (props) => {
  const formData = props.formData;
  const rest = props.rest;

  // reset the form into initial values
  const form = useForm();
  const dataProvider = useDataProvider();

  console.log("EpisodeSearch : props = ");
  console.log(props);
  console.log("EpisodeSearch : form = ");
  console.log(showFormContent(form));

  // check change of programme_id , fetch programme name , fill the field
  useEffect(async () => {
    console.log("EpisodeSearch . useEffect (programme_id) :");

    if (formData.programme_id) {
      const result = await dataProvider.getProgrammeByProgrammeId(parseInt(formData.programme_id));

      console.log("ProgrammeSearch . useEffect : fetchProgramme result");
      console.log(result);

      if (result && result.data && result.data.name_tc) {
        form.change("title_tc", result.data.name_tc);
        form.change("title_en", result.data.name_en);

        // Auto complete "destination_url"
        // programmePath = result.data.path;
        // form.change("destination_url", mytvsuperUrl + "/programme/" + programmePath + "/");
      }
    }
  }, [formData.programme_id]);

  // check change of programme_id , fetch programme name , fill the field
  // useEffect(async () => {
  //   console.log("EpisodeSearch . useEffect (episode_no) :");

  //   if (formData.episode_no) {
  //     const result = await dataProvider.getEpisodeByEpisodeNo(parseInt(formData.programme_id), parseInt(formData.episode_no));

  //     console.log("EpisodeSearch . useEffect : fetchEpisode result");
  //     console.log(result);

  //     if (result && result.data) {
  //       const arr = [];
  //       if (result.data.name_tc) {
  //         arr.push(result.data.name_tc);
  //       }
  //       arr.push(result.data.episode_no);

  //       form.change("episode_text", arr.join(" - "));
  //     }
  //   }
  // }, [formData.episode_no]);

  const fetchProgramme = useCallback(async (filterType) => {
    // const result = await dataProvider.getProgrammeByProgrammeId(filterType);
    // console.log("ProgrammeSearch . fetchProgramme : result");
    // console.log(result);
    // form.change("title_tc", result.data.name_tc);
    // form.change("title_en", result.data.name_en);
  });

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={6}>
        <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth formData={formData} {...rest} allowEmpty={true} fetchProgramme={fetchProgramme} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Content ID" source="programme_id" disabled fullWidth />
      </Grid>
      {/* <Grid item sm={12} md={6}>
        <TextInput label="Episode ID" source="episode_id_for_url" disabled fullWidth />
      </Grid> */}
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Caption in TC *" source="title_tc" disabled fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Caption in EN *" source="title_en" disabled fullWidth />
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Episode No." source="episode_no" validate={number()} fullWidth />
      </Grid>

      {/* <Grid item sm={12} md={6}>
        <AutoCompleteEpisode clearAlwaysVisible={true} fullWidth formData={formData} {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Episode" source="episode_text" disabled fullWidth />
      </Grid> */}
    </Grid>
  );
};

const AutoCompleteProgramme = (props) => {
  console.log("AutoCompleteProgramme : props = ");
  console.log(props);

  const form = useForm();

  useEffect(() => {});

  return (
    <ReferenceInput
      label="Search Programme Name"
      source="programme_autocomplete"
      reference="programmes-autocomplete"
      onChange={(val) => {
        console.log("AutoCompleteProgramme . onChange : val = ");
        console.log(val);

        props.fetchProgramme(val);

        form.change("programme_id", val);
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="programme_id" fullWidth />
    </ReferenceInput>
  );
};

// const AutoCompleteEpisode = (props) => {
//   const dataProvider = useDataProvider();

//   console.log("AutoCompleteEpisode : props = ");
//   console.log(props);

//   const form = useForm();
//   console.log(form.getState().values);

//   useEffect(() => {});

//   return (
//     <ReferenceInput
//       label="Search Episode"
//       source="episode_autocomplete"
//       reference="episodes-autocomplete"
//       onChange={async (val) => {
//         console.log("AutoCompleteEpisode . onChange : val = ");
//         console.log(val);

//         const programmeId = form.getState().values.programme_id;

//         const res = await dataProvider.getEpisodeByEpisodeId(programmeId, val);

//         if (res.data) {
//           const episode = res.data;

//           form.change("episode_no", episode.episode_no);
//           form.change("video_id", episode.video_id);
//         }
//       }}
//       filterToQuery={(searchText) => {
//         if (searchText) {
//           return {
//             programme_id: form.getState().values.programme_id,
//             episode_no: searchText,
//           };
//         }
//         return {};
//       }}
//     >
//       <AutocompleteInput optionText="name_tc" optionValue="id" translateChoice={false} fullWidth />
//     </ReferenceInput>
//   );
// };
