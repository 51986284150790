import { FileUpload, FilterAltOff } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { Datagrid, EditButton, Filter, FunctionField, List, ShowButton, SimpleForm, TextField, TextInput, Toolbar, useAuthenticated, useAuthProvider, useAuthState, useLogin, useNotify, usePermissions, useRecordContext } from "react-admin";
import dataProvider from "../../dataProvider";
import { MyDateField } from "../../MyDateField";

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
    <TextInput source="sports_topic_code" alwaysOn />
    <TextInput source="sports_event_code" alwaysOn />
  </Filter>
);

// List page
export const VodLivesList = (props) => {
  const auth = useAuthProvider();
  const [canEdit, setCanEdit] = useState(false);

  useEffect(async () => {}, []);

  return (
    <List {...props} bulkActionButtons={false} exporter={false} filters={<CustomFilter />}>
      <Datagrid>
        <TextField label="Name in TC" source="name_tc" />
        <TextField label="Name in EN" source="name_en" />
        <TextField source="sports_topic_code" />
        <TextField source="sports_event_code" />

        <MyDateField source="start_time" showTime />
        <MyDateField source="end_time" showTime />

        {/* Status column, 4 = deleted  */}
        {/* <FunctionField
          label="Status"
          render={(record) => {
            switch (record.status) {
              case "4":
                return "Deleted";
              default:
                return record.status;
            }
          }}
        /> */}

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
