import {
  List, //
  ListContextProvider,
  Button,
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  Toolbar,
  TopToolbar,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ListButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useParams, useCallback, Form } from "react";
import { useForm } from "react-final-form";
import { MenuTreeCreatePopup } from "./../components/menu-tree-create-popup";
import { MyDateTimeInput } from "./../../../MyDateTimeInput";
import { OnChange } from "react-final-form-listeners";

// popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Add, Menu } from "@material-ui/icons";

var moment = require("moment-timezone");

const customButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid grey",
  margin: "10px",
};

export const CustomEditAction = (props) => {
  console.log("CustomEditAction : props = ");
  console.log(props);

  return (
    <>
      <TopToolbar>
        <SimpleForm toolbar={false} style={{ flexGrow: "1" }}>
          <TopInputs start_time={props.start_time} description={props.description} startTimeChangeCallback={props.startTimeChangeCallback} descriptionChangeCallback={props.descriptionChangeCallback} />
        </SimpleForm>
        <SimpleForm toolbar={false}>
          <Popup
            open={props.popupOpen}
            arrow={false}
            closeOnDocumentClick={false}
            onClose={() => {
              console.log("Popup : onClose");
              props.setPopupOpen(false);
            }}
            trigger={
              <>
                <EditButton
                  label="Create menu item"
                  variant="outlined"
                  icon={<Add />}
                  onClick={() => {
                    console.log("Popup . trigger : setPopupOpen(true)");
                    props.setEditRow(false);
                    props.setPopupOpen(true);
                  }}
                />
              </>
            }
          >
            <MenuTreeCreatePopup //
              addMenuItemCallback={props.addMenuItemCallback}
              updateMenuItemCallback={props.updateMenuItemCallback}
              removeMenuItemCallback={props.removeMenuItemCallback}
              closeMenuItemCallback={props.closeMenuItemCallback}
              getMenuItemParent={props.getMenuItemParent}
              editRow={props.editRow}
            />
          </Popup>

          <>
            <EditButton
              label="Change order"
              variant="outlined"
              icon={<Menu />}
              onClick={() => {
                props.changeOrderCallback();
              }}
            />
          </>
        </SimpleForm>
      </TopToolbar>
    </>
  );
};

const TopInputs = (props) => {
  const form = useForm();

  const [initialStartTime, setInitialStartTime] = useState(props.start_time);
  const [initialDescription, setInitialDescription] = useState(props.description);

  useEffect(() => {
    form.change("start_time", initialStartTime);
    form.change("description", initialDescription);
  }, []);

  return (
    <Grid item sm={12} md={12} style={{ flexGrow: "1", width: "100%" }}>
      <Grid item sm={12} md={12} style={{ flexGrow: "1" }}>
        <MyDateTimeInput source="start_time" label="Effective Time" showTime />
        <OnChange name="start_time">
          {(value, previous) => {
            props.startTimeChangeCallback(value);
            setInitialStartTime(value);
          }}
        </OnChange>
      </Grid>
      <Grid item sm={12} md={12} style={{ flexGrow: "1" }}>
        <TextInput source="description" label="Description" style={{ flexGrow: "1", width: "100%" }} />
        <OnChange name="description">
          {(value, previous) => {
            props.descriptionChangeCallback(value);
            setInitialDescription(value);
          }}
        </OnChange>
      </Grid>
    </Grid>
  );
};
