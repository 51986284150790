import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { TextInput, required, useDataProvider } from "react-admin";
import { useForm } from "react-final-form";
import { ShelfDateTimeInput } from "./shelf-date-time-input";
import { showFormContent } from "./show-form-content";
import { TemplateSelectInput } from "./template-select-input";
import { RegionCheckboxGroupInput } from "../../component/region-components";
import { PlatformCheckboxGroupInput } from "../../component/platform-components";

export const ScoopPlusForm = (props) => {
  const formData = props.formData;
  const rest = props.rest;

  // reset the form into initial values
  const form = useForm();
  const dataProvider = useDataProvider();

  console.log("ScoopPlusForm : props = ");
  console.log(props);
  console.log("ScoopPlusForm : form = ");
  console.log(showFormContent(form));

  // useEffect(() => {
  //   console.log("ScoopPlusForm . useEffect :");
  // });

  return (
    <Grid container spacing={1} fullWidth>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <TemplateSelectInput layouts={["scoopplus"]} defaultValue={"scoopplus"} isRequired={true} />
      </Grid>

      <Grid item sm={12} md={12}>
        <PlatformCheckboxGroupInput fullWidth {...rest} />
      </Grid>

      <Grid item sm={12} md={12}>
        <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
      </Grid>

      <ShelfDateTimeInput />
    </Grid>
  );
};
