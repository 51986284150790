import { FindInPage, KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Datagrid, DatagridBody, EditButton, FunctionField, List, ListButton, RecordContextProvider, SaveButton, TextField, Toolbar, TopToolbar, useDataProvider, useListContext } from "react-admin";
import { RegionTextField } from "../component/region-components";
import "./custom.css";
var moment = require("moment-timezone");
var _ = require("lodash");

const ExpandedDataGrid = ({ profileClass, record, selectedCategoryIds, setSelectedCategoryIds }) => {
  const ids = record.categories.map((t) => t.category_id);
  const data = record.categories.reduce((obj, item) => {
    obj[item.category_id] = item;
    return obj;
  }, {});

  return <Datagrid data={data} ids={ids} body={<MyDataGridBody parent={record} profileClass={profileClass} selectedCategoryIds={selectedCategoryIds} setSelectedCategoryIds={setSelectedCategoryIds} />} />;
};

const isCategorySelected = (selectedCategoryIds, categoryId) => {
  return selectedCategoryIds.includes(categoryId);
  // for (const t of menuTree) {
  //   if (t.category_id == categoryId) {
  //     return true;
  //   }

  //   for (const c of t.categories) {
  //     if (c.category_id == categoryId) {
  //       return true;
  //     }
  //   }
  // }
  // return false;
};

const MyDatagridRow = ({ record, id, onToggleItem, children, selected, selectable, profileClass, parent, expandedIds, setExpandedIds, selectedCategoryIds, setSelectedCategoryIds }) => {
  const showCheckbox = profileClass == "kids";
  const isExpanded = expandedIds.includes(id);
  const isSelected = isCategorySelected(selectedCategoryIds, id);

  return (
    <RecordContextProvider value={record}>
      <>
        <TableRow>
          <TableCell padding="none">
            {showCheckbox && (
              <Checkbox
                checked={isSelected}
                onClick={(event) => {
                  event.preventDefault();

                  if (isSelected) {
                    if (parent) {
                      //This is children, check if parent still have other child checked
                      const cs = parent.categories.map((c) => c.category_id);
                      const arr = selectedCategoryIds.filter((el) => cs.includes(el) && el != id);

                      if (arr.length > 0) {
                        //There is some other exist, only remove self
                        setSelectedCategoryIds(selectedCategoryIds.filter((el) => el != id));
                      } else {
                        //No other exist, remove self & parent
                        setSelectedCategoryIds(selectedCategoryIds.filter((el) => el != id && el != parent.category_id));
                      }
                    } else {
                      //This is parent, also uncheck all other children have checked
                      const cs = [id, ...record.categories.map((c) => c.category_id)];
                      const arr = selectedCategoryIds.filter((el) => !cs.includes(el));

                      setSelectedCategoryIds(arr);
                    }
                  } else {
                    if (parent) {
                      //This is children, also check Parent if it hasn't
                      if (!selectedCategoryIds.includes(parent.category_id)) {
                        setSelectedCategoryIds([...selectedCategoryIds, id, parent.category_id]);
                      } else {
                        setSelectedCategoryIds([...selectedCategoryIds, id]);
                      }
                    } else {
                      //This is parent, also check all other children
                      const cs = [id, ...record.categories.map((c) => c.category_id)];
                      setSelectedCategoryIds([...selectedCategoryIds, ...cs]);

                      //   //Have children, select all
                      //   if (record.categories.length > 0) {
                      //     const cs = [id, ...record.categories.map((c) => c.category_id)];
                      //     setSelectedCategoryIds([...selectedCategoryIds, ...cs]);
                      //   } else {
                      //     setSelectedCategoryIds([...selectedCategoryIds, id]);
                      //   }
                    }
                  }
                }}
              />
            )}
          </TableCell>
          <TableCell>
            <RegionTextField label="Region" record={record} />
          </TableCell>
          <TableCell>
            {record.categories && record.categories.length > 0 ? (
              <Button
                onClick={() => {
                  isExpanded ? setExpandedIds(expandedIds.filter((x) => x != id)) : setExpandedIds([...expandedIds, id]);
                }}
              >
                {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </Button>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>
            <CategoryTopLevelEditButton record={record} />
          </TableCell>

          <TableCell>
            <TextField label="Page title in TC (SEO)" source="name_tc" sortable={false} />
          </TableCell>
          <TableCell>
            <TextField label="Page title in EN (SEO)" source="name_en" sortable={false} />
          </TableCell>
          <TableCell>
            <TextField label="Menu ID" source="category_id" sortable={false} />
          </TableCell>
          <TableCell>
            <FunctionField
              source="start_time"
              className="textNoWrap"
              sortable={false}
              render={(record) => {
                var text = "";
                if (record) {
                  var val = _.get(record, "start_time");
                  if (val) text = moment(val).format("YYYY-MM-DD HH:mm");
                }
                return text;
              }}
            />
          </TableCell>
          <TableCell>
            <FunctionField
              source="end_time"
              className="textNoWrap"
              sortable={false}
              render={(record) => {
                var text = "";
                if (record) {
                  var val = _.get(record, "end_time");
                  if (val) text = moment(val).format("YYYY-MM-DD HH:mm");
                }
                return text;
              }}
            />
          </TableCell>
        </TableRow>

        {isExpanded ? (
          <TableRow>
            <TableCell colSpan={99999}>
              <ExpandedDataGrid record={record} profileClass={profileClass} selectedCategoryIds={selectedCategoryIds} setSelectedCategoryIds={setSelectedCategoryIds} />
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </>
    </RecordContextProvider>
  );
};

const MyDataGridBody = ({ profileClass, parent, selectedCategoryIds, setSelectedCategoryIds, ...props }) => {
  const [expandedIds, setExpandedIds] = useState([]);
  // const [selectedIds, setSelectedIds] = useState(initialSelectedIds);
  return <DatagridBody {...props} row={<MyDatagridRow profileClass={profileClass} parent={parent} expandedIds={expandedIds} setExpandedIds={setExpandedIds} selectedCategoryIds={selectedCategoryIds} setSelectedCategoryIds={setSelectedCategoryIds} />} />;
};

const MyDataGridHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell></TableCell> {/* Checkbox */}
      <TableCell style={{}}>Region</TableCell>
      <TableCell></TableCell> {/* Arrow button*/}
      <TableCell></TableCell> {/* Page button */}
      <TableCell style={{}}>Page title in TC (SEO)</TableCell>
      <TableCell style={{}}>Page title in EN (SEO)</TableCell>
      <TableCell style={{}}>Menu ID</TableCell>
      <TableCell style={{}}>Start time</TableCell>
      <TableCell style={{}}>End time</TableCell>
    </TableRow>
  </TableHead>
);

const onSave = async (dataProvider, profileClass, menuTrees, selectedCategoryIds) => {
  //Get General menu_tree
  const general = Object.values(menuTrees).find((x) => x.profile_class == "general");
  const kids = Object.values(menuTrees).find((x) => x.profile_class == "kids");

  var menuTree = [];
  for (const parent of general.menu_tree) {
    if (selectedCategoryIds.includes(parent.category_id)) {
      var val = { ...parent };
      val.categories = []; //Clear

      for (const child of parent.categories) {
        if (selectedCategoryIds.includes(child.category_id)) {
          val.categories.push(child);
        }
      }

      menuTree.push(val);
    }
  }

  await dataProvider.update("kids-menu-tree", {
    id: kids.id,
    data: {
      id: kids.id,
      profileClass: profileClass,
      description: kids.description,
      startTime: kids.start_time,
      menuTree: menuTree,
    },
  });
  window.location.reload(true);
};

const MenuTreePreviewGrid = ({ profileClass }) => {
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [ids, setIds] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    if (Object.keys(listContext.data) == 0) {
      return <></>;
    }

    //Get General
    var general;
    for (const [key, value] of Object.entries(listContext.data)) {
      if (value.profile_class.includes("general")) {
        general = value;
      }
    }

    var initialSelectedIds = [];
    if (profileClass == "kids") {
      //Get Kids
      var kids;
      for (const [key, value] of Object.entries(listContext.data)) {
        if (value.profile_class.includes("kids")) {
          kids = value;
        }
      }

      //Update general.menu_tree::selected
      for (const mt of kids.menu_tree) {
        const parent = general.menu_tree.find((x) => x.category_id == mt.category_id);
        if (parent) {
          initialSelectedIds.push(parent.category_id);

          for (const c of mt.categories) {
            const child = parent.categories.find((x) => x.category_id == c.category_id);
            if (child) {
              initialSelectedIds.push(child.category_id);
            }
          }
        }
      }
    }

    const ids = general.menu_tree.map((t) => t.category_id);
    const data = general.menu_tree.reduce((obj, item) => {
      obj[item.category_id] = item;

      return obj;
    }, {});

    setSelectedCategoryIds(initialSelectedIds);
    setIds(ids);
    setData(data);
  }, [listContext.data]);

  return (
    <>
      <Datagrid data={data} ids={ids} className="menu-tree-preview" header={<MyDataGridHeader />} body={<MyDataGridBody profileClass={profileClass} selectedCategoryIds={selectedCategoryIds} setSelectedCategoryIds={setSelectedCategoryIds} />} />
      <Toolbar>
        <SaveButton
          label="Save"
          handleSubmitWithRedirect={async () => {
            await onSave(dataProvider, profileClass, listContext.data, selectedCategoryIds);
          }}
        />
      </Toolbar>
    </>
  );

  // const isRowExpandable = (row) => {
  //   console.log("row");
  //   console.log(row);
  //   return row.categories && row.categories.length > 0;
  // };

  // return (
  //   <Datagrid data={data} ids={ids} className="menu-tree-preview" isRowExpandable={isRowExpandable} expand={<PostPanel groupContracted="tick" />}>
  //     <Field name="pc" componCategoryTopLevelEditButton ent="input" type="checkbox" />
  //     <CategoryTopLevelEditButton />
  //     <TextField label="Page title in TC (SEO)" source="name_tc" sortable={false} />
  //     <TextField label="Page title in EN (SEO)" source="name_en" sortable={false} />
  //     <TextField label="Menu ID" source="category_id" sortable={false} />

  //     <FunctionField
  //       source="start_time"
  //       className="textNoWrap"
  //       sortable={false}
  //       render={(record) => {
  //         var text = "";
  //         if (record) {
  //           var val = _.get(record, "start_time");
  //           if (val) text = moment(val).format("YYYY-MM-DD HH:mm");
  //         }
  //         return text;
  //       }}
  //     />

  //     <FunctionField
  //       source="end_time"
  //       className="textNoWrap"
  //       sortable={false}
  //       render={(record) => {
  //         var text = "";
  //         if (record) {
  //           var val = _.get(record, "end_time");
  //           if (val) text = moment(val).format("YYYY-MM-DD HH:mm");
  //         }
  //         return text;
  //       }}
  //     />
  //   </Datagrid>
  // );

  // return (
  //   <div style={{ margin: "1em" }}>
  //     {ids.map((id) => (
  //       <Card key={id} style={cardStyle}>
  //         <CardHeader title={<TextField record={data[id]} source="author.name" />} subheader={<DateField record={data[id]} source="created_at" />} avatar={<Avatar icon={<PersonIcon />} />} />
  //         <CardContent>
  //           <TextField record={data[id]} source="body" />
  //         </CardContent>
  //         <CardContent>
  //           about&nbsp;
  //           <ReferenceField label="Post" resource="comments" record={data[id]} source="post_id" reference="posts" basePath={basePath}>
  //             <TextField source="title" />
  //           </ReferenceField>
  //         </CardContent>
  //         <CardActions style={{ textAlign: "right" }}>
  //           <EditButton resource="posts" basePath={basePath} record={data[id]} />
  //         </CardActions>
  //       </Card>
  //     ))}
  //   </div>
  // );
};

const MenuTreePreview = ({ profileClass, permissions, ...props }) => {
  return (
    <List filter={{ profile_class: profileClass }} {...props} actions={<ListActions profileClass={profileClass} />} sort={{ field: "order", order: "ASC" }} exporter={false} pagination={false} bulkActionButtons={false}>
      <MenuTreePreviewGrid profileClass={profileClass} />
    </List>
  );
};

const CategoryTopLevelEditButton = ({ record }) => {
  if (!record.categories || record.categories.length == 0) {
    return <EditButton label="Page" basePath="/categories" record={{ id: record.category_id }} icon={<FindInPage />} />;
  }

  return <></>;
};

const PostPanel = ({ id, record, resource }) => {
  if (record.categories) {
    return (
      <table className="menu-expand-category MuiTable-root ">
        <tbody className="MuiTableBody-root">
          {record.categories.map((category, categoryIndex) => (
            <tr key={categoryIndex} className="MuiTableRow-root">
              <td className="MuiTableCell-root MuiTableCell-paddingNone" style={{ width: "48px" }}>
                &nbsp;
              </td>
              <td className="MuiTableCell-root MuiTableCell-sizeSmall">
                <EditButton label="Page" basePath="/categories" record={{ id: category.category_id }} icon={<FindInPage />} />
              </td>
              <td className="MuiTableCell-root MuiTableCell-sizeSmall">{category.name_tc}</td>
              <td className="MuiTableCell-root MuiTableCell-sizeSmall">{category.name_en}</td>
              <td className="MuiTableCell-root MuiTableCell-sizeSmall">{category.category_id}</td>
              <td className="MuiTableCell-root textNoWrap">{showDate(category.start_time)}</td>
              <td className="MuiTableCell-root textNoWrap">{showDate(category.end_time)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
};

const ListActions = ({ profileClass, ...props }) => {
  if (profileClass == "general") {
    return (
      <TopToolbar>
        <ListButton label="Update menu tree info" basePath="/menu-tree" icon={<EditIcon />} />
      </TopToolbar>
    );
  } else if (profileClass == "kids") {
    return <TopToolbar></TopToolbar>;
  }
};

const showDate = (text) => {
  return moment(text).format("YYYY-MM-DD HH:mm");
};

export const GeneralMenuTreePreview = (props) => <MenuTreePreview profileClass="general" {...props} />;
export const KidsMenuTreePreview = (props) => <MenuTreePreview profileClass="kids" {...props} />;
