import {
  List, //
  ListContextProvider,
  Button,
  Create,
  Edit,
  EditButton,
  ShowButton,
  SaveButton,
  Show,
  Datagrid,
  TextField,
  Toolbar,
  TopToolbar,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ListButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useParams, useCallback } from "react";
import { useDataProvider, Loading, useRecordContext } from "react-admin";
import { MenuItemTable } from "./../components/menu-tree-table";
import { CustomEditAction } from "./../components/custom-edit-action";

// popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

// save confirm popup
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import { Close, Done, Save } from "@material-ui/icons";

// const customButtonStyle = {
//   backgroundColor: "transparent",
//   border: "1px solid grey",
//   margin: "10px",
// };

export const MenuTreeEdit2 = React.memo(
  (props) => {
    // console.log("MenuTreeEdit2 : props = ");
    // console.log(props);

    // constructor
    const [popupOpen, setPopupOpen] = useState(false);
    const [rows, setRows] = useState(props.rows);
    const [editRow, setEditRow] = useState(false);
    const [startTime, setStartTime] = useState(props.start_time);
    const [description, setDescription] = useState(props.description);
    const [isTogglingReorder, setIsTogglingReorder] = useState(false);

    // on start time change
    const startTimeChangeCallback = useCallback((value) => {
      // console.log("MenuTreeEdit2 . startTimeChangeCallback : value = " + value);
      setStartTime(value);
    });

    // on description change
    const descriptionChangeCallback = useCallback((value) => {
      // console.log("MenuTreeEdit2 . descriptionChangeCallback : value = " + value);
      setDescription(value);
    });

    // info for popup
    const getMenuItemParent = useCallback(() => {
      const menuItemParentTemp = [{ id: -1, name: "[Under Root]" }];
      // console.log("MenuTreeEdit2 . getMenuItemParent : rows = ");
      // console.log(rows);

      for (const i in rows) {
        const row = rows[i];
        // console.log('MenuTreeEdit2 . getMenuItemParent : i = '+i+' , row = ');
        // console.log(row);
        menuItemParentTemp.push({
          id: i,
          name: row.name_tc,
        });
      }

      return menuItemParentTemp;
    });

    // change order toggler
    const changeOrderCallback = () => {
      setIsTogglingReorder(!isTogglingReorder);
    };

    // reorder (dir -1 = up , 1 = down , parentIndex -1 = root)
    const reorderCallback = (dir, index, parentIndex) => {
      // console.log("MenuTreeEdit2 . reorderCallback : dir = " + dir + " , index = " + index + " , parentIndex = " + parentIndex);

      dir = parseInt(dir);
      index = parseInt(index);
      parentIndex = parseInt(parentIndex);

      if (parentIndex == -1) {
        const temp = rows[index];
        rows[index] = rows[index + dir];
        rows[index + dir] = temp;
      } else {
        const temp = rows[parentIndex].categories[index];
        rows[parentIndex].categories[index] = rows[parentIndex].categories[index + dir];
        rows[parentIndex].categories[index + dir] = temp;
      }

      setRows(rows.map((item) => item));
    };

    // popup for edit menu item
    const editMenuItemCallback = useCallback((row) => {
      // console.log("MenuTreeEdit2 . editMenuItemCallback : row = ");
      // console.log(row);

      setEditRow(row);
      setPopupOpen(true);
    });

    // close popup and add menu item
    const addMenuItemCallback = useCallback((params) => {
      // console.log("MenuTreeEdit2 . addMenuItemCallback : params = ");
      // console.log(params);

      // console.log("rows = ");
      // console.log(rows);

      if (params.parent == -1) {
        // add to root
        rows.push(params);
      } else {
        // add to sub dir
        rows[params.parent].categories.push(params);
      }

      setRows(rows.map((item) => item));

      setPopupOpen(false);
    });

    // close popup and update menu item
    const updateMenuItemCallback = useCallback((params) => {
      // console.log("MenuTreeEdit2 . updateMenuItemCallback : params = ");
      // console.log(params);

      var r;
      if (params.parent == -1) {
        // change in root level
        r = rows[params.selfIndex];
      } else {
        // change in 2 level
        r = rows[params.parent].categories[params.selfIndex];
      }
      r.category_id = params.category_id;
      r.name_tc = params.name_tc;
      r.name_en = params.name_en;
      r.path = params.path;
      r.start_time = params.start_time;
      r.end_time = params.end_time;
      r.tags = params.tags;
      r.region = params.region;
      // r.categories do not need to change

      if (params.parent == -1) {
        // change in root level
        rows[params.selfIndex] = r;
      } else {
        // change in 2 level
        rows[params.parent].categories[params.selfIndex] = r;
      }

      // console.log('to update rows : ');
      // console.log(rows);

      setRows(rows.map((item) => item));
      setPopupOpen(false);
    });

    // close popup and Remove menu item
    const removeMenuItemCallback = useCallback((params) => {
      // console.log("MenuTreeEdit2 . removeMenuItemCallback : params = ");
      // console.log(params);

      if (params.parent == -1) {
        // remove in root level
        rows.splice(params.selfIndex, 1);
      } else {
        // remove in 2 level
        rows[params.parent].categories.splice(params.selfIndex, 1);
      }

      setRows(rows.map((item) => item));
      setPopupOpen(false);
    });

    // close popup
    const closeMenuItemCallback = useCallback((params) => {
      // console.log("MenuTreeEdit2 . closeMenuItemCallback : params = ");
      // console.log(params);
      setPopupOpen(false);
    });

    // Save Button
    const saveMenuTreeCallbackPopup = useCallback(() => {
      const content = (
        <div>
          <Grid item sm={12} md={12}>
            <h2>Save Menu Tree Set</h2>
          </Grid>
          <Grid item sm={12} md={12}>
            <p>{"Are you sure to save it ?"}</p>
            <br />
            <br />
          </Grid>
          <Grid item sm={12} md={12}>
            <SimpleForm toolbar={false}>
              <Grid sm={12} md={12} container justifyContent="space-around">
                <EditButton
                  label="Save"
                  icon={<Done />}
                  onClick={() => {
                    console.log("saveMenuTreeCallbackPopup : rows = ");
                    console.log(rows);

                    // real save
                    props.saveMenuTreeCallback({
                      rows: rows,
                      startTime: startTime,
                      description: description,
                    });
                  }}
                />

                <EditButton
                  label="Cancel"
                  icon={<Close />}
                  onClick={() => {
                    PopupboxManager.close();
                  }}
                />
              </Grid>
            </SimpleForm>
          </Grid>
        </div>
      );
      PopupboxManager.open({ content });
    });

    useEffect(() => {
      // console.log("MenuTreeEdit2 . useEffect");
    }, [rows, setRows]);

    return (
      <>
        {props.mode == "edit" ? (
          <Edit //
            {...props}
            mutationMode="pessimistic"
            actions={
              <CustomEditAction //
                start_time={props.start_time}
                description={props.description}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
                editRow={editRow}
                setEditRow={setEditRow}
                getMenuItemParent={getMenuItemParent}
                startTimeChangeCallback={startTimeChangeCallback}
                descriptionChangeCallback={descriptionChangeCallback}
                changeOrderCallback={changeOrderCallback}
                addMenuItemCallback={addMenuItemCallback}
                updateMenuItemCallback={updateMenuItemCallback}
                closeMenuItemCallback={closeMenuItemCallback}
                removeMenuItemCallback={removeMenuItemCallback}
              />
            }
          >
            {rows ? (
              <MenuItemTable //
                rows={rows}
                isTogglingReorder={isTogglingReorder}
                editMenuItemCallback={editMenuItemCallback}
                reorderCallback={reorderCallback}
              />
            ) : (
              <></>
            )}
          </Edit>
        ) : (
          <Create {...props} actions={<CustomEditAction start_time={""} description={""} popupOpen={popupOpen} setPopupOpen={setPopupOpen} editRow={editRow} setEditRow={setEditRow} getMenuItemParent={getMenuItemParent} startTimeChangeCallback={startTimeChangeCallback} descriptionChangeCallback={descriptionChangeCallback} changeOrderCallback={changeOrderCallback} addMenuItemCallback={addMenuItemCallback} updateMenuItemCallback={updateMenuItemCallback} closeMenuItemCallback={closeMenuItemCallback} removeMenuItemCallback={removeMenuItemCallback} />}>
            {rows ? <MenuItemTable rows={rows} isTogglingReorder={isTogglingReorder} editMenuItemCallback={editMenuItemCallback} reorderCallback={reorderCallback} /> : <></>}
          </Create>
        )}
        <Toolbar>
          <SimpleForm toolbar={false}>
            <SaveButton label="Save menu tree" variant="outlined" icon={<Save />} handleSubmitWithRedirect={saveMenuTreeCallbackPopup} />
            <PopupboxContainer />
          </SimpleForm>
        </Toolbar>
      </>
    );
  },
  () => true
);
