import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  ShowButton,
  EditButton,
  TextField,
  SimpleForm,
  TextInput,
  SimpleList,
  SimpleShowLayout,
  ArrayField,
  ArrayInput,
  SelectInput,
  NumberInput,
  SimpleFormIterator,
  useNotify,
  required,
  BooleanInput,
  BooleanField,
  useInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import { AssetBulkActionButtons } from "./components";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const BitrateProfileList = (props) => {
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;
  return (
    <List {...props} bulkActionButtons={<AssetBulkActionButtons />}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField source="name" />
          <ArrayField source="settings">
            <Datagrid>
              <TextField source="platform" />
              <ArrayField source="bitrate">
                <Datagrid>
                  <TextField source="quality" />
                  <TextField source="bitrate_upper_limit" />
                  <TextField source="bitrate_lower_limit" />
                  <BooleanField source="is_auto" />
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </ArrayField>
          <BooleanField source="is_default_video_setting" />

          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

const BitrateProfileTitle = ({ record }) => {
  return <span>BitrateProfile {record ? `${record.name}` : ""}</span>;
};

export const BitrateProfileShow = (props) => (
  <Show {...props} title={<BitrateProfileTitle />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ArrayField source="settings">
        <Datagrid>
          <TextField source="platform" />
          <ArrayField source="bitrate">
            <Datagrid>
              <TextField source="quality" />
              <TextField source="bitrate_upper_limit" />
              <TextField source="bitrate_lower_limit" />
             <BooleanField source="is_auto" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
       <BooleanField source="is_default_video_setting" />
    </SimpleShowLayout>
  </Show>
);

export const BitrateProfileEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<BitrateProfileTitle />}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ArrayInput source="settings">
          <SimpleFormIterator>
          <ReferenceInput label="Platform" source="platform" reference="key-value-by-platform">
                <SelectInput
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
            
            <ArrayInput source="bitrate" label="Bitrates" validate={required()}>
              <SimpleFormIterator>
              <ReferenceInput label="Quality" source="quality" reference="key-value-by-quality">
                <SelectInput 
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
                
                <NumberInput label="Bitrate upper limit" source="bitrate_upper_limit" validate={required()} />
                <NumberInput label="Bitrate lower limit" source="bitrate_lower_limit" validate={required()} />
                <BooleanInput label="Is Auto" source="is_auto" />
              </SimpleFormIterator>
            </ArrayInput>
            
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Default Video Settings" source="is_default_video_setting" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = () => "/bitrate-profiles";
export const BitrateProfileCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" validate={required()} />
        <ArrayInput source="settings">
          <SimpleFormIterator>
             <ReferenceInput label="Platform" source="platform" reference="key-value-by-platform">
                <SelectInput
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
            <ArrayInput source="bitrate" label="Bitrates" validate={required()}>
              <SimpleFormIterator>
               <ReferenceInput label="Quality" source="quality" reference="key-value-by-quality">
                <SelectInput 
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
                <NumberInput label="Bitrate upper limit" source="bitrate_upper_limit" validate={required()} />
                <NumberInput label="Bitrate lower limit" source="bitrate_lower_limit" validate={required()} />
                <BooleanInput label="Is Auto" source="is_auto" />
              </SimpleFormIterator>
            </ArrayInput>
         
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Default Video Settings" source="is_default_video_setting" />
      </SimpleForm>
    </Create>
  );
};

const SelectPlatform = (props) => {
  let df;
  if(props.record.platform){
    df = props.record.platform;
  }
  const dataProvider = useDataProvider();
  const [platforms, setPlatforms] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getList("key-values", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "key", order: "ASC" },
        filter: {key:"platform"},
      })
      .then(({ data }) => {
        setPlatforms(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (!platforms) return null;

  const keys = platforms[0].values.map(value=> ({
    id: value.toLowerCase(),
    name: value,
  }));
  return <SelectInput source="platform" defaultValue={df} choices={keys} allowEmpty={true} />;
};


const SexInput = props => {
    const {
        input,
        meta: { touched, error }
    } = useInput(props);

    return (
        <Select
            label="Quality"
            {...input}
        >
            <MenuItem value="high">high</MenuItem>
            <MenuItem value="medium">medium</MenuItem>
        </Select>
    );
};
