import {
  List, //
  Create,
  Edit,
  EditButton,
  Datagrid,
  ListContextProvider,
  Pagination,
  TextField,
  Toolbar,
  BooleanInput,
  ArrayInput,
  useRecordContext,
  SaveButton,
  SimpleForm,
  ExportButton,
  DateInput,
  TextInput,
  useNotify,
  SimpleFormIterator,
  Labeled,
  BooleanField,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  required,
  TopToolbar,
  Button,
  Link,
  downloadCSV,
  useListContext,
  useCreate,
  useUpdate,
  useRedirect,
  useUnselectAll,
} from "react-admin";

import { useDataProvider } from "react-admin";
import { useState, useEffect, Fragment, useCallback } from "react";
import { Grid, Typography, TableHead, TableRow, TableCell } from "@material-ui/core";
import jsonExport from "jsonexport/dist";

const CustomPagination = (props) => {
  return <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;
};

const AutoCompleteProgramme = (props) => {
  //   const form = useForm();
  return (
    <ReferenceInput
      label="Search Programme Name or ID"
      source="programme_id"
      reference="programmes-autocomplete"
      onChange={(val) => {}}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};
export const SpecialProgramList = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const [newProgrammes, setNewProgrammes] = useState([]);
  const [removedProgrammes, setRemovedProgrammes] = useState([]);

  const CustomToolbar = (props) => {
    const redirectTo = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const [create] = useCreate("special-program-programs");

    const handleSave = useCallback(
      async (values, redirect) => {
        console.log("onSave");

        var addProgrammeIds = newProgrammes.map((p) => p.programme_id);
        var removeProgrammeIds = removedProgrammes.map((p) => p.programme_id);

        var successA = false;
        var successB = false;

        if (addProgrammeIds.length > 0) {
          create(
            {
              payload: {
                data: {
                  is_sprog: true,
                  programme_ids: addProgrammeIds,
                },
              },
            },
            {
              onSuccess: ({ data: newRecord }) => {
                console.log("onSuccess");
                successA = true;

                if (successA & successB) {
                  notify("Success", "success");
                  window.location.reload(true);
                  //   redirectTo("/special-program");
                }
              },
              onFailure: (error) => {
                console.log("onFailure");
                notify(error.message.toString(), "warning");
              },
            }
          );
        } else {
          successA = true;

          if (successA && successB) {
            notify("Success", "success");
            window.location.reload(true);
            // redirectTo("/special-program");
          }
        }

        if (removeProgrammeIds.length > 0) {
          create(
            {
              payload: {
                data: {
                  is_sprog: false,
                  programme_ids: removeProgrammeIds,
                },
              },
            },
            {
              onSuccess: ({ data: newRecord }) => {
                console.log("onSuccess");
                successB = true;

                if (successA & successB) {
                  notify("Success", "success");
                  window.location.reload(true);
                  //   redirectTo("/special-program");
                }
              },
              onFailure: (error) => {
                console.log("onFailure");
                notify(error.message.toString(), "warning");
              },
            }
          );
        } else {
          successB = true;

          if (successA && successB) {
            notify("Success", "success");
            window.location.reload(true);
            // redirectTo("/special-program");
          }
        }
      },
      [create, notify, redirectTo]
    );

    return (
      <Toolbar {...props}>
        <SaveButton onSave={handleSave} />
      </Toolbar>
    );
  };

  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <AutoCompleteProgramme />
          </Grid>
          <Grid item sm={12} md={12}>
            <TagInputs />
          </Grid>
          <Grid item sm={12} md={12}>
            <ProgrammeTable newProgrammes={newProgrammes} setNewProgrammes={setNewProgrammes} removedProgrammes={removedProgrammes} setRemovedProgrammes={setRemovedProgrammes} {...props} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const ChangedProgrammeTable = ({ newProgrammes, setNewProgrammes, removedProgrammes, setRemovedProgrammes }) => {
  const dataProvider = useDataProvider();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  var newProgrammes = newProgrammes ?? [];
  var removedProgrammes = removedProgrammes ?? [];

  const ids = [...newProgrammes, ...removedProgrammes].map((p) => p.id);

  var data = [];
  for (var programme of newProgrammes) {
    data[programme.id] = { action: "add", programme: programme };
  }
  for (var programme of removedProgrammes) {
    data[programme.id] = { action: "remove", programme: programme };
  }

  var total = newProgrammes.length + removedProgrammes.length;

  const UndoButton = (props) => {
    return (
      <Button
        label="UNDO"
        onClick={() => {
          console.log("undo");
          console.log(props.record);

          var action = props.record.action;
          var programmeId = props.record.programme.programme_id;

          if (action == "add") {
            setNewProgrammes((programmes) => programmes.filter((p) => p.programme_id != programmeId));
          } else if (action == "remove") {
            setRemovedProgrammes((programmes) => programmes.filter((p) => p.programme_id != programmeId));
          }
        }}
      />
    );
  };

  return (
    <>
      <hr />
      <Typography variant="h6">Changed List</Typography>

      <ListContextProvider
        value={{
          data: data,
          ids: ids,
          total,
          page,
          perPage,
          setPage,
          setPerPage,
          currentSort: { field: "id", order: "ASC" },
          basePath: "/posts",
          resource: "posts",
          selectedIds: [],
        }}
      >
        <Datagrid>
          <UndoButton />
          <TextField source="action" label="Action" />
          <TextField source="programme.name_tc" label="Programme Name" />
          <TextField source="programme.programme_id" label="Programme ID" />
          <BooleanField source="programme.is_sprog" label="Special prog (Y/N)" />
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    </>
  );
};

const ExistingProgrammeTable = ({ removedProgrammes, setRemovedProgrammes, ...props }) => {
  const ExistingProgrammeTableBulkActionButtons = (props) => {
    const unselectAll = useUnselectAll();
    const { data } = useListContext(); //Get records in List

    return (
      <Fragment>
        <Button
          label="remove"
          onClick={() => {
            console.log("remove");

            var arr = [];
            for (var id of props.selectedIds) {
              //Don't remove again if already removed
              const isRemoved = removedProgrammes.find((p) => p.id == id);
              if (!isRemoved) {
                if (id in data) {
                  arr.push(data[id]);
                }
              }
            }

            setRemovedProgrammes((programmes) => [...programmes, ...arr]);
            unselectAll(props.resource);
          }}
        />
      </Fragment>
    );
  };

  const exporter = (programmes) => {
    console.log(programmes);
    const BOM = "\uFEFF";

    const forExport = programmes.map((programme) => {
      var p = {};
      p.name_tc = programme.name_tc;
      p.programme_id = programme.programme_id;
      return p;
    });
    jsonExport(
      forExport,
      {
        headers: ["name_tc", "programme_id"], // order fields in the export
        rename: ["Programme Name", "Programme ID"],
      },
      (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, "special_program_list"); // download as 'posts.csv` file
      }
    );
  };

  return (
    <>
      <hr />
      <Typography variant="h6">Special Program List</Typography>
      <List {...props} title={<></>} resource="special-program-programs" pagination={<CustomPagination />} perPage={100} empty={false} actions={<ExportButton />} exporter={exporter} bulkActionButtons={<ExistingProgrammeTableBulkActionButtons />}>
        <Datagrid>
          <TextField source="name_tc" label="Programme Name" />
          <TextField source="programme_id" label="Programme ID" />
        </Datagrid>
      </List>
    </>
  );
};

const ProgrammeTable = ({ newProgrammes, setNewProgrammes, removedProgrammes, setRemovedProgrammes, ...props }) => {
  const ProgrammeTableBulkActionButtons = (props) => {
    const unselectAll = useUnselectAll();
    const { data } = useListContext(); //Get records in List

    return (
      <Fragment>
        <Button
          label="add"
          onClick={() => {
            console.log("add");

            var arr = [];
            for (var id of props.selectedIds) {
              //Don't add again if already added
              const isAdded = newProgrammes.find((p) => p.id == id);
              if (!isAdded) {
                if (id in data) {
                  arr.push(data[id]);
                }
              }
            }

            setNewProgrammes((programmes) => [...programmes, ...arr]);
            unselectAll(props.resource);
          }}
        />
      </Fragment>
    );
  };

  const exporter = (programmes) => {
    console.log(programmes);
    const BOM = "\uFEFF";

    const forExport = programmes.map((programme) => {
      var p = {};
      p.name_tc = programme.name_tc;
      p.programme_id = programme.programme_id;
      return p;
    });
    jsonExport(
      forExport,
      {
        headers: ["name_tc", "programme_id"], // order fields in the export
        rename: ["Programme Name", "Programme ID"],
      },
      (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, "program_list"); // download as 'posts.csv` file
      }
    );
  };

  return (
    <>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          console.log(formData);

          return (
            <>
              <Typography variant="h6">Program List</Typography>
              <List {...props} title={<></>} syncWithLocation={false} resource="ads-adgroups-programs-search" pagination={<CustomPagination />} perPage={100} filter={{ tags: formData.tags, programme_id: formData.programme_id }} empty={false} actions={<ExportButton />} exporter={exporter} bulkActionButtons={<ProgrammeTableBulkActionButtons />}>
                <Datagrid>
                  <TextField source="name_tc" label="Programme Name" />
                  <TextField source="programme_id" label="Programme ID" />
                  <BooleanField source="is_sprog" label="Special prog (Y/N)" />
                </Datagrid>
              </List>
            </>
          );
        }}
      </FormDataConsumer>
      <ExistingProgrammeTable setRemovedProgrammes={setRemovedProgrammes} removedProgrammes={removedProgrammes} {...props} />
      <ChangedProgrammeTable newProgrammes={newProgrammes} setNewProgrammes={setNewProgrammes} removedProgrammes={removedProgrammes} setRemovedProgrammes={setRemovedProgrammes} />
    </>
  );
};

const TagInputs = ({ formData, rest }) => {
  const dataProvider = useDataProvider();

  const [tagTypes, setTagTypes] = useState([]);

  useEffect(() => {
    dataProvider.getTagTypes().then((types) => {
      setTagTypes(types.data);
    });
  }, []);

  return (
    <ArrayInput source="tags" label="Program Search">
      {/* Add TransitionProps because of bug: https://github.com/marmelab/react-admin/issues/6900 */}
      <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
        {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
        <SelectInput fullWidth source="tag_type" choices={tagTypes} label="Type" />
        <FormDataConsumer label="Value" fullWidth>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            if (!scopedFormData) {
              return <></>;
            }

            return (
              <ReferenceInput
                fullWidth
                label="Value"
                source={getSource("tag_value")}
                reference="mpm-row-tag-autocomplete"
                filter={{ tag_type: scopedFormData.tag_type }}
                filterToQuery={(searchText) => {
                  if (searchText) {
                    return {
                      name_tc: searchText,
                    };
                  }
                  return {};
                }}
              >
                <AutocompleteInput
                  fullWidth
                  optionText={(record) => {
                    if (record == null) {
                      return null;
                    }
                    return record.name_tc + " " + record.name_en;
                  }}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
