import React, { useEffect, useState } from "react";
import { AutocompleteInput, Button, usePermissions, Confirm, Create, Datagrid, Filter, Link, List, RecordContextProvider, ReferenceInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, TopToolbar, useDataProvider, useNotify, useRecordContext } from "react-admin";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { Grid } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import { Field } from "react-final-form";

const ProfileFilter = ({ setF, ...props }) => {
  useEffect(() => {
    setF(props.filterValues);
  }, [props.filterValues]);

  return (
    <Filter {...props}>
      <TextInput label="Name in TC" source="name_tc" alwaysOn />
      <TextInput label="Name in EN" source="name_en" alwaysOn />
      <TextInput label="Tag ID" source="tag_id" alwaysOn />
    </Filter>
  );
};

const SortableItem2 = SortableElement(({ allItems, setAllItems, item, pos, ...props }) => {
  return <MyDatagridRow allItems={allItems} setAllItems={setAllItems} item={item} pos={pos} {...props} />;
});

const CustomDeleteButton = ({ allItems, setAllItems }, props) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const record = useRecordContext();

  return (
    <React.Fragment>
      <Button
        label="Delete"
        style={{ color: "red" }}
        onClick={() => {
          setShowConfirm(true);
        }}
      >
        <Delete />
      </Button>
      <Confirm
        isOpen={showConfirm}
        title={"Delete " + record.name_tc}
        content="Are you sure you want to delete this item?"
        onConfirm={async () => {
          setAllItems(allItems.filter((i) => i.tag_id != record.tag_id));
          setShowConfirm(false);
        }}
        onClose={() => {
          setShowConfirm(false);
        }}
      />
    </React.Fragment>
  );
};

const MyDatagridRow = ({ allItems, setAllItems, item, pos, ...props }) => {
  return (
    <RecordContextProvider value={item}>
      <TableRow>
        <DragHandle />

        <TableCell style={{ overflowWrap: "anywhere" }}>{item.tag_id}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.type}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.name_tc}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.name_en}</TableCell>

        <TableCell>
          <CustomDeleteButton allItems={allItems} setAllItems={setAllItems} record={item} />
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const ListActions = ({ profileClass, ...props }) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: (profileClass == "general" ? "/profiles-preferences" : "/kids-profiles-preferences") + "/create",
      }}
      label="+ CREATE"
    />
  </TopToolbar>
);

const SortableListContainer = SortableContainer(({ profileClass, f, setF, allItems, setAllItems, items, setItems, ...props }) => {
  return (
    <List filter={{ profile_class: profileClass, allItems: allItems }} {...props} pagination={false} actions={<ListActions profileClass={profileClass} />} filters={<ProfileFilter setF={setF} />}>
      <Datagrid header={<DatagridHeader />} body={<MyDatagridBody f={f} allItems={allItems} setAllItems={setAllItems} items={items} setItems={setItems} {...props} />} empty={<DataGridEmptyView />} />
    </List>
  );
});

const DataGridEmptyView = () => {
  return (
    <div style={{ margin: "16px" }}>
      <p class="MuiTypography-root MuiTypography-body2">No results found</p>
    </div>
  );
};

const MyDatagridBody = ({ f, allItems, setAllItems, items, setItems, data, ...props }) => {
  useEffect(() => {
    var tags = [];
    if (allItems.length == 0) {
      //If don't have data from API yet
      for (const id of props.ids) {
        tags.push(data[id]);
      }
      setAllItems(tags);
    } else {
      tags = allItems; //Use local data if already fetched from API
    }

    //Do filter locally
    if (f.tag_id) {
      tags = tags.filter((t) => t.tag_id.toString().includes(f.tag_id.toString()));
    }
    if (f.name_tc) {
      tags = tags.filter((t) => t.name_tc.includes(f.name_tc));
    }
    if (f.name_en) {
      tags = tags.filter((t) => t.name_en.includes(f.name_en));
    }

    //Set items after filter
    setItems(tags);
  }, [data, allItems, f]);

  return items.map((item, index) => {
    return <SortableItem2 allItems={allItems} setAllItems={setAllItems} item={item} index={index} pos={index} {...props} />;
  });
};

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell> {/* Drag */}
      <TableCell style={{}}>Tag ID</TableCell>
      <TableCell style={{}}>Type</TableCell>
      <TableCell style={{}}>Sub-cat group</TableCell>
      <TableCell style={{}}>Name in TC</TableCell>
      <TableCell style={{}}>Name in EN</TableCell>
      <TableCell style={{}}></TableCell> {/* Remove */}
    </TableRow>
  </TableHead>
);

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const onSave = async (dataProvider, profileClass, allItems) => {
  if (!allItems) {
    return;
  }
  //Get pp id from items
  const profilePreferenceId = allItems[0].pp_id;

  await dataProvider.updateProfilesPreference(allItems, profileClass, profilePreferenceId);

  window.location.reload(true);
};

const ProfilesPreferenceList = ({ profileClass, ...props }) => {
  const dataProvider = useDataProvider();
  const [items, setItems] = useState([]); //All items from api
  const [allItems, setAllItems] = useState([]); //Items after filter
  const [f, setF] = useState({}); //filter
  const { permissions } = usePermissions();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // var oldItem = allItems[oldIndex];
    // var newItem = allItems[newIndex];
    setAllItems((allItems) => arrayMove(allItems, oldIndex, newIndex));
    // setItems((items) => arrayMove(items, oldIndex, newIndex));
  };

  return (
    <>
      <SortableListContainer {...props} profileClass={profileClass} f={f} setF={setF} allItems={allItems} setAllItems={setAllItems} items={items} setItems={setItems} onSortEnd={onSortEnd} useDragHandle={true} lockAxis="y" />

      <Toolbar>
        <SaveButton
          label="Save"
          handleSubmitWithRedirect={async () => {
            await onSave(dataProvider, profileClass, allItems);
          }}
        />
      </Toolbar>
    </>
  );
};

const ProfilesPreferenceCreate = ({ profileClass, ...props }) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const onSuccess = (result) => {
    if (profileClass == "general") {
      window.location.href = "/admin/#/profiles-preferences";
    } else if (profileClass == "kids") {
      window.location.href = "/admin/#/kids-profiles-preferences";
    }
    window.location.reload(true);
  };
  return (
    <Create {...props} onFailure={onFailure} onSuccess={onSuccess} mutationMode="pessimistic">
      <SimpleForm
        redirect={false}
        // redirect={() => {
        //   if (profileClass == "general") {
        //     return "/profiles-preferences";
        //   } else if (profileClass == "kids") {
        //     return "/kids-profiles-preferences";
        //   }
        // }}
      >
        <Field name="pc" component="input" type="hidden" defaultValue={profileClass} />
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <SelectInput label="Type" source="tag_type" defaultValue="main_cat" choices={[{ id: "main_cat", name: "main_cat" }]} emptyValue={0} disabled={true} fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <ReferenceInput
              label="Value"
              source="tag_id"
              reference="tags-autocomplete"
              onChange={(val) => {
                console.log("ONCHANGE" + val);
              }}
              filter={{ tag_type: "main_cat" }}
              filterToQuery={(searchText) => {
                if (searchText) {
                  return { q: searchText };
                }
                return {};
              }}
            >
              <AutocompleteInput optionText="name_tc" fullWidth matchSuggestion={() => true} />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const GeneralProfilesPreferenceList = (props) => <ProfilesPreferenceList profileClass="general" {...props} />;
export const KidsProfilesPreferenceList = (props) => <ProfilesPreferenceList profileClass="kids" {...props} />;

export const GeneralProfilesPreferenceCreate = (props) => <ProfilesPreferenceCreate profileClass="general" {...props} />;
export const KidsProfilesPreferenceCreate = (props) => <ProfilesPreferenceCreate profileClass="kids" {...props} />;
