import { Grid } from "@material-ui/core";
import { Button, FormDataConsumer, SaveButton, SimpleForm, Toolbar, useDataProvider, useNotify, useRedirect } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";

const PopupContent = ({ form, icons, ...props }) => {
  const popupForm = useForm();

  return (
    <Grid container spacing={1} fullWidth>
      {icons.map((icon) => (
        <Grid>
          <Button
            onClick={() => {
              console.log(icon);

              console.log(popupForm.getState().values);

              var oldData = popupForm.getState().values.selected_icons ?? [];
              var newData = [...oldData];

              var shouldAdd = true;
              //Delete the icon if already added before
              for (var i = 0; i < newData.length; i++) {
                const d = newData[i];
                if (d.id == icon.id) {
                  shouldAdd = false;
                  newData.splice(i, 1);
                  break;
                }
              }
              //Add the icon if not added before
              if (shouldAdd) {
                newData.push({
                  id: icon.id,
                  image_url: icon.image_url,
                });
              }

              popupForm.change("selected_icons", newData);
            }}
          >
            <FormDataConsumer label="Value" fullWidth>
              {({ formData, scopedFormData, getSource, ...rest }) => {
                var isSelected = false;
                if (formData.selected_icons) {
                  for (const i of formData.selected_icons) {
                    if (i.id == icon.id) {
                      isSelected = true;
                      break;
                    }
                  }
                }

                return (
                  <div class="chatroom-icon" style={{ backgroundColor: isSelected ? "red" : "transparent", width: "100%", aspectRatio: 1 }}>
                    <img src={icon.image_url} />
                  </div>
                );
              }}
            </FormDataConsumer>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

const CustomToolbar = ({ form, selectedIcons, ...props }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const popupForm = useForm();

  const handleSave = async (values, redirect) => {
    console.log("onSave");
    console.log(values);

    //Check if any icon has already added before
    var alreadySelected = false;
    for (const selectedIcon of selectedIcons) {
      for (const i of values.selected_icons) {
        if (selectedIcon.id == i.id) {
          alreadySelected = true;
          break;
        }
      }
    }
    // const alreadySelected = selectedIcons.some((selectedIcon) => selectedIcon.id == values.id);

    if (alreadySelected) {
      notify("Already Selected this Icon", "error");
      return;
    }

    var oldData = form.getState().values.icons ?? [];

    var newData = [...oldData]; //Clone

    for (const i of values.selected_icons) {
      newData.push({ id: i.id, image_url: i.image_url, enabled: true });
    }
    // newData.push({ id: values.id, image_url: values.image_url, enabled: true });

    form.change("icons", newData);

    cleanPopupForm(popupForm);
    PopupboxManager.close();
  };

  return (
    <Toolbar {...props}>
      <Button
        onClick={() => {
          cleanPopupForm(popupForm);
          PopupboxManager.close();
        }}
        label="Close"
      ></Button>
      <SaveButton onSave={handleSave} label="Save" />
    </Toolbar>
  );
};

const cleanPopupForm = (popupForm) => {
  //   popupForm.change("set_name", undefined);
  //   popupForm.change("voting_question", undefined);
  //   popupForm.change("canned_message_start_time", undefined);
  //   popupForm.change("canned_message_end_time", undefined);
  //   popupForm.change("canned_messages", undefined);
  //   popupForm.change("voting_enabled", undefined);
};

export const AddIconDialog = ({ form, icons, selectedIcons, ...props }) => {
  console.log("AddIconDialog constructor");
  return (
    <SimpleForm toolbar={<CustomToolbar form={form} selectedIcons={selectedIcons} />}>
      <PopupContent form={form} icons={icons} />
    </SimpleForm>
  );
};
