import * as React from "react";
import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  TopToolbar,
  Link,
  ExportButton,
  ShowButton,
  CreateButton,
  Labeled,
  EditButton,
  TextField,
  NumberField,
  BooleanField,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  DeleteButton,
  SaveButton,
  SimpleList,
  SimpleShowLayout,
  useNotify,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  RadioButtonGroupInput,
  SelectField,
  FormDataConsumer,
  useRedirect,
  useCreate,
  useRecordContext,
  useReferenceArrayFieldController,
  FunctionField,
} from "react-admin";
import { Grid, useMediaQuery, Button } from "@material-ui/core";
import { AssetBulkActionButtons } from "../components";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { useDataProvider, Loading } from "react-admin";
import { useState, useEffect, useCallback } from "react";
import { useForm, Form, Field } from "react-final-form";
import { MyDateField } from "../../MyDateField";

// popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { render } from "@testing-library/react";
import { MCDNAppName } from "./components/mcdn-app-name";
import { PopupboxContainer } from "react-popupbox";
import { BooleanInputWithConfirm } from "../component/boolean-input-with-confirm";
import { RegionCheckboxGroupInput, RegionChipField } from "../component/region-components";
import { PlatformCheckboxGroupInput, PlatformChipField } from "../component/platform-components";

const ChannelFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Ch No" source="channel_no" alwaysOn disabled />
    </Filter>
  );
};

export const ChannelList = (props) => {
  const ListActions = (props) => {
    const redirectTo = useRedirect();

    return (
      <TopToolbar>
        <CreateButton
          onClick={(e) => {
            e.preventDefault();

            //Note: Document about how to clone
            //https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#prefilling-a-create-record

            var data = {
              channel_no: props.filterValues.channel_no,
            };
            redirectTo(`/channels/create?source=${JSON.stringify(data)}`);
          }}
        />
        {/* <ExportButton /> */}
      </TopToolbar>
    );
  };

  const MyCopyButton = ({ record }) => {
    const customButtonStyle = {
      backgroundColor: "transparent",
      border: "1px solid grey",
      margin: "10px",
    };

    const ChannelCopyToPopup = ({ close, ...props }) => {
      const CustomToolbar = (props) => {
        const redirectTo = useRedirect();

        const handleSave = useCallback(
          async (values, redirect) => {
            console.log("onSave");
            console.log(values);

            //Note: Document about how to clone
            //https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#prefilling-a-create-record

            var data = values;
            delete data.created_at;
            delete data.modified_at;
            delete data.__v;
            delete data.id;

            // delete data.landscape_poster; //TODO: Remove this after API fixed
            // delete data.disclaimer_image; //TODO: Remove this after API fixed
            // delete data.portrait_poster; //TODO: Remove this after API fixed
            redirectTo(`/channels/create?source=${JSON.stringify(data)}`);
          },
          [redirectTo]
        );

        return (
          <Toolbar {...props}>
            <Button onClick={close}>Cancel</Button>
            <SaveButton onSave={handleSave} />
          </Toolbar>
        );
      };

      const dataProvider = useDataProvider();

      const [channelNos, setChannelNos] = useState([]);

      useEffect(() => {
        dataProvider.getChannelNoList().then((data) => {
          setChannelNos(data.data);
        });
      }, []);

      return (
        <div>
          <SimpleForm toolbar={<CustomToolbar />}>
            <SelectInput
              source="channel_no"
              label="Channel No."
              choices={channelNos.map((channelNo) => ({
                id: channelNo.channel_no,
                name: channelNo.channel_no.toString(),
              }))}
              fullWidth
            />
          </SimpleForm>
        </div>
      );
    };

    return (
      <Popup trigger={(open) => <Button color="primary">Copy To</Button>} modal closeOnDocumentClick={false}>
        {(close) => <ChannelCopyToPopup close={close} />}
      </Popup>
    );
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} sort={{ field: "start_time", order: "DESC" }} actions={<ListActions />} bulkActionButtons={<AssetBulkActionButtons />} filters={<ChannelFilter />}>
      {isSmall ? (
        <Datagrid rowClick="edit">
          <NumberField label="Ch No" source="channel_no" />
          <TextField label="Channel Name in TC" source="name_tc" />
          <BooleanField source="enabled" />
        </Datagrid>
      ) : (
        <Datagrid>
          <TextField label="OTT Ch Code" source="network_code" sortable={false} />
          <TextField label="Channel Name in TC" source="name_tc" sortable={false} />
          <TextField label="Channel Name in EN" source="name_en" sortable={false} />
          <MyDateField source="start_time" showTime sortable={false} />
          <MyDateField source="end_time" showTime sortable={false} />
          <EditButton />
          <MyCopyButton />
        </Datagrid>
      )}
    </List>
  );
};

const ChannelTitle = ({ record }) => {
  return <span>Channel {record ? `${record.name_tc}` : ""}</span>;
};

export const ChannelShow = (props) => (
  <Show {...props} title={<ChannelTitle />}>
    <SimpleShowLayout>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Start Time">
            <MyDateField source="start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="End Time">
            <MyDateField source="end_time" showTime />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Ch No">
            <NumberField source="channel_no" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Ch Display No (for Smart TV)">
            <NumberField source="channel_display_no" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="OTT Ch Code">
            <TextField source="network_code" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Channel Name in EN">
            <TextField source="name_en" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Channel Name in TC">
            <TextField source="name_tc" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Timeshift">
            {/* <NumberField source="timeshift" /> */}
            <SelectField
              fullWidth
              source="timeshift"
              choices={[
                { id: 0, name: "Off" },
                { id: 180, name: "On" },
              ]}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Catch Up">
            <BooleanField source="catchup" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Ch# Display">
            <SelectField
              fullWidth
              label="Ch# Display"
              source="show_num"
              choices={[
                { id: true, name: "Always On" },
                { id: false, name: "Briefly" },
              ]}
            />
          </Labeled>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="MCDN App Name">
            <TextField source="mcdn_appname" />
          </Labeled>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Bitrate Profile">
            <ReferenceField label="Bitrate Profile" source="bitrate_profile" reference="bitrate-profiles">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="DRM Profile">
            <ReferenceField label="Drm Profile" source="drm_profile" reference="drm-profiles">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Coding Profile">
            <ReferenceField label="Coding Profile" source="coding_profile" reference="coding-profiles">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Aspect Ratio">
            <TextField source="aspect_ratio" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Audio 1">
            <TextField source="audio_1" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Audio 2">
            <TextField source="audio_2" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Audio 3">
            <TextField source="audio_3" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Remarks">
            <TextField source="remark" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Path">
            <TextField source="path" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Enabled">
            <BooleanField source="enabled" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Parental lock">
            <BooleanField source="locked" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Is Default">
            <BooleanField source="is_default" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Is DTT">
            <BooleanField source="is_dtt" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Is Interactive">
            <BooleanField source="is_interactive" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="is hdr (for Smart TV)">
            <BooleanField source="is_hdr" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Is 4K">
            <BooleanField source="is_4k" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Show with Subscription">
            <BooleanField source="show_with_subscription" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Call Ad">
            <BooleanField source="call_ad" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Show In Listing">
            <BooleanField source="show_in_listing" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Flash sync u wall">
            <BooleanField source="has_flash_sync_u_wall" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="IP whitelist for oversea">
            <BooleanField source="is_whitelist" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Made for kid">
            <BooleanField source="made_for_kid" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Labeled label="Channel Preview (for Smart TV)">
            <BooleanField source="channel_preview" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Tags">
            <ReferenceArrayField label="Tags" reference="tags-for-show" source="tags">
              <SingleFieldList>
                <CustomTagChipField source="name_tc" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Platform">
            <PlatformChipField />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Region">
            <RegionChipField />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled label="Landscape (image)">
            <ImageField source="landscape_poster" />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Labeled label="Portrait (image)">
            <ImageField source="portrait_poster" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={12}>
          <Labeled label="Disclaimer (image)">
            <ImageField source="disclaimer_image" />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

const CustomTagChipField = (props) => {
  const redirectTo = useRedirect();
  const render = (record) => {
    console.log("CustomTagChipField . record");
    console.log(record);

    if (record.name_tc == "##DELETED##") {
      return <></>;
    } else {
      return (
        <ChipField
          source="name_tc"
          onClick={(e) => {
            e.preventDefault();
            redirectTo("/tags/" + record._id + "/show");
          }}
        />
      );
    }
  };

  return <FunctionField {...props} render={render} />;
};

const MyActions = (props) => (
  <Toolbar
    {...props}
    style={{
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <DeleteButton undoable={false} confirmTitle={"Delete channel Ch# " + props.record.channel_no} />
  </Toolbar>
);

export const ChannelEdit = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  const onSuccess = ({ data }) => {
    redirectTo(`/channels?filter=${JSON.stringify({ channel_no: data.channel_no })}`);
  };

  return (
    <Edit onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" {...props} title={<ChannelTitle />}>
      <SimpleForm toolbar={<MyActions />}>
        <PopupboxContainer />

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <MyDateTimeInput source="start_time" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MyDateTimeInput source="end_time" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <NumberInput label="Ch No" source="channel_no" validate={required()} disabled fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <NumberInput label="Ch Display No (for Smart TV)" source="channel_display_no" validate={required()} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="OTT Ch Code" source="network_code" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Channel Name in EN" source="name_en" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Channel Name in TC" source="name_tc" validate={required()} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <RadioButtonGroupInput
              fullWidth
              source="timeshift"
              choices={[
                { id: 0, name: "Off" },
                { id: 180, name: "On" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput label="Catch Up" source="catchup" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Overwrite network code" source="overwrite_network_code" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <SelectInput
              fullWidth
              label="Ch# Display"
              source="show_num"
              choices={[
                { id: true, name: "Always On" },
                { id: false, name: "Briefly" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <ReferenceInput label="HD/SD" source="hd_sd" reference="profiles-mapping">
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MCDNAppName />
          </Grid>
          <FormDataConsumer>{({ formData, ...rest }) => formData.hd_sd != "" && <ProfileData formData={formData} rest={rest} />}</FormDataConsumer>

          <Grid item xs={12} sm={12} md={12}>
            <ReferenceInput source="bitrate_profile" reference="bitrate-profiles">
              <SelectInput disabled optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceInput source="drm_profile" reference="drm-profiles">
              <SelectInput disabled optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceInput source="coding_profile" reference="coding-profiles">
              <SelectInput disabled optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Aspect Ratio" source="aspect_ratio" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Audio 1" source="audio_1" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Audio 2" source="audio_2" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Audio 3" source="audio_3" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput label="Remarks" multiline={true} source="remark" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput source="path" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInputWithConfirm source="enabled" popupTitle="Disable channel?" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="locked" defaultValue={false} label="Parental lock" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_default" defaultValue={false} label="Is Default" />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_dtt" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_interactive" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_hdr" label="is hdr (for Smart TV)" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_4k" defaultValue={false} />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="show_with_subscription" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="call_ad" defaultValue={false} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="show_in_listing" defaultValue={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput label="Flash sync u wall" source="has_flash_sync_u_wall" defaultValue={false} disabled />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput label="IP whitelist for oversea" source="is_whitelist" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="made_for_kid" label="Made for kid" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="channel_preview" label="Channel Preview (for Smart TV)" defaultValue={false} />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceArrayInput source="tags" reference="tags-checkbox">
              <CheckboxGroupInput optionText="name_tc" optionValue="tag_id" />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <PlatformCheckboxGroupInput useDefaultValue={true} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <RegionCheckboxGroupInput />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={6}>
            <ImageField id="landscape_poster" source="landscape_poster" fullWidth />
            <ImageInput source="landscape_poster_base64" label="Landscape (image)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ImageField id="portrait_poster" source="portrait_poster" fullWidth />
            <ImageInput source="portrait_poster_base64" label="Portrait (image)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <ImageField id="disclaimer_image" source="disclaimer_image" fullWidth />
            <ImageInput source="disclaimer_image_base64" label="Disclaimer (image)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

// const redirect = () => "/channels";
export const ChannelCreate = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const onSuccess = ({ data }) => {
    redirectTo(`/channels?filter=${JSON.stringify({ channel_no: data.channel_no })}`);
  };

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure}>
      <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton={true} />}>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <MyDateTimeInput source="start_time" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MyDateTimeInput source="end_time" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <NumberInput label="Ch No" source="channel_no" validate={required()} disabled fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="OTT Ch Code" source="network_code" validate={required()} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Channel Name in EN" source="name_en" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Channel Name in TC" source="name_tc" validate={required()} fullWidth />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <RadioButtonGroupInput
              fullWidth
              source="timeshift"
              choices={[
                { id: 0, name: "Off" },
                { id: 180, name: "On" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput label="Catch Up" source="catchup" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Overwrite network code" source="overwrite_network_code" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <SelectInput
              fullWidth
              label="Ch# Display"
              source="show_num"
              choices={[
                { id: true, name: "Always On" },
                { id: false, name: "Briefly" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <ReferenceInput label="HD/SD" source="hd_sd" reference="profiles-mapping">
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MCDNAppName />
          </Grid>
          <FormDataConsumer>{({ formData, ...rest }) => formData.hd_sd != "" && <ProfileData formData={formData} rest={rest} />}</FormDataConsumer>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceInput source="bitrate_profile" reference="bitrate-profiles">
              <SelectInput disabled optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceInput source="drm_profile" reference="drm-profiles">
              <SelectInput disabled optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceInput source="coding_profile" reference="coding-profiles">
              <SelectInput disabled optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Aspect Ratio" source="aspect_ratio" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Audio 1" source="audio_1" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Audio 2" source="audio_2" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput label="Audio 3" source="audio_3" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <TextInput label="Remarks" multiline={true} source="remark" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          {/* <Grid item xs={12} sm={12} md={3}>
            <TextInput disabled source="path" fullWidth />
          </Grid> */}
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="enabled" defaultValue={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="locked" defaultValue={false} label="Parental lock" />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_default" defaultValue={false} label="Is Default" />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_dtt" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_interactive" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="is_4k" defaultValue={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="show_with_subscription" defaultValue={false} />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="call_ad" defaultValue={false} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="show_in_listing" defaultValue={true} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput label="Flash sync u wall" source="has_flash_sync_u_wall" defaultValue={false} disabled />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput label="IP whitelist for oversea" source="is_whitelist" defaultValue={false} />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={3}>
            <BooleanInput source="made_for_kid" label="Made for kid" />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <ReferenceArrayInput source="tags" reference="tags-checkbox">
              <CheckboxGroupInput optionText="name_tc" optionValue="tag_id" />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <PlatformCheckboxGroupInput useDefaultValue={true} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <RegionCheckboxGroupInput />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={6}>
            <ImageField id="landscape_poster" source="landscape_poster" fullWidth />
            <ImageInput source="landscape_poster_base64" label="Landscape (image)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ImageField id="portrait_poster" source="portrait_poster" fullWidth />
            <ImageInput source="portrait_poster_base64" label="Portrait (image)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={12}>
            <ImageField id="disclaimer_image" source="disclaimer_image" fullWidth />
            <ImageInput source="disclaimer_image_base64" label="Disclaimer (image)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const ProfileData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();
  console.log(props);
  useEffect(async () => {
    if (!props.formData.hd_sd) {
      return;
    }

    console.log(props.formData.hd_sd);

    const res = await dataProvider.getOne("profiles-mapping", { id: props.formData.hd_sd }, {});

    if (res.data.length == 0) {
      return;
    }

    const profile = res.data;

    form.change("coding_profile", profile.coding_profile_id);

    form.change("bitrate_profile", profile.bitrate_profile_id);
    form.change("drm_profile", profile.drm_profile_id);

    //Use caption_tc/en if exists, use programme name otherwise
    // if (!props.caption_tc) {
    //   form.change("coding_profile", vodlive.name_tc);
    // }
    // if (!props.caption_en) {
    //   form.change("caption_en", vodlive.name_en);
    // }
  }, [props.formData.hd_sd]);

  return null;
};
