import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  SelectInput,
  ImageField,
  DateTimeInput,
  Button,
  Link,
  SimpleList,
  useListContext,
  TopToolbar,
  useDataProvider,
  useRedirect,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import { Grid } from "@material-ui/core";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { useState, useEffect, useCallback } from "react";
import { useForm, Form, Field } from "react-final-form";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";

var moment = require("moment-timezone");

const queryString = require("query-string");

const MPMGrid = SortableContainer(({ type, name, slots, max_slot_id, displaySlots, ...props }) => {
  if (!slots) {
    return <></>;
  }

  var hasAddButton = max_slot_id < 999999;

  return (
    <Grid container spacing={1}>
      {displaySlots}

      {hasAddButton ? (
        <Grid id="action-link" item xs={6} md={3}>
          <Link class="link-to-slot-new" id="link-to-new" to={`slots?filter=${JSON.stringify({ slot_id: max_slot_id + 1, type: type, name: name })}`}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAA50lEQVR4nO3ZQQrCUBAE0dZziOdRvP8F8nMPXWggceMuL0gXDGQ39QuymqQUxSPJnGQkuWMXwkjy/MykJE5qcd4PX0NczmLpkWgALaBpAC2gaQAtoGkALaBpAC2gaQAtoGkALaBpAC2gaQAtoGkALaBpAC2gaQAtoFkHWG51z53mm732bm6R63PUSHL53ewvGEmuSX+BDfdsL7b/OlOS2/LoXofF0iPRAFpA0wBaQNMAWkDTAFpA0wBaQNMAWkDTAFpA0wBaQNMAWkDTAFpA0wBaQNMAWkAjA8yr78EsIMstcnOrK2VfXmMffu4mXwiGAAAAAElFTkSuQmCC" />
          </Link>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
});

const MPMGridItem = SortableElement(({ record, slot_id, type, name, index }) => {
  if (!record) {
    //No this slot
    return (
      <Grid item xs={6} md={3} style={{ zIndex: 9999 }}>
        {/* the Link to is not working because SortableElement digested it  */}
        <Link class="mpm-list" style={{ textAlign: "center", color: "black", textDecoration: "none" }} to={`slots?filter=${JSON.stringify({ slot_id: slot_id.toString(), type: type, name: name })}`}>
          <span> Currently there is no active content in this slot</span>
        </Link>
      </Grid>
    );
  }

  return (
    <Grid item xs={6} md={3} style={{ zIndex: 9999 }}>
      {/* the Link to is not working because SortableElement digested it  */}
      <Link class="mpm-list" to={`slots?filter=${JSON.stringify({ slot_id: record.slot_id.toString(), type: type, name: name })}`}>
        <img src={record.landscape_poster} style={{ width: "100%" }} />
      </Link>
    </Grid>
  );
});

var type;
var name;
export const MPMList = (props) => {
  const parsed = queryString.parse(props.location.search);

  if (parsed.type) {
    type = parsed.type;
  }
  if (parsed.name) {
    name = parsed.name;
  }

  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  // const [previewDate, setPreviewDate] = useState(moment.utc()); //Store moment object, with correct timezone
  const [previewDate, setPreviewDate] = useState(moment.tz("Asia/Hong_Kong")); //ALWAYS Store in UTC+8 timezone
  const [response, setResponse] = useState();
  const [slots, setSlots] = useState([]);
  const [displaySlots, setDisplaySlots] = useState([]);

  const refreshSlot = useCallback(() => {
    dataProvider
      .getPreviewSlot(type, previewDate)
      .then((res) => {
        res.data.data.sort((a, b) => a.slot_id - b.slot_id);
        setResponse(res.data);
        setSlots(res.data.data);
        updateDisplaySlots(res.data.data, res.data.max_slot_id);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const updateDisplaySlots = useCallback((slots, max_slot_id) => {
    const result = [];

    // if(slots.length == 0)return result;

    // loop till max slot id
    for (var index = 0; index < max_slot_id; index++) {
      // lookup if slot has record
      const filteredSlots = slots.filter((slot) => {
        return slot.slot_id == index + 1;
      });

      if (filteredSlots.length > 0) {
        // show record slot
        result.push(<MPMGridItem record={filteredSlots[0]} slot_id={index + 1} type={type} name={name} index={index} key={index} />);
      } else {
        // show blank slot
        result.push(<MPMGridItem record={null} slot_id={index + 1} type={type} name={name} index={index} key={index} />);
      }
    }

    setDisplaySlots(result);
    return result;
  });

  useEffect(() => {
    refreshSlot();
  }, [previewDate]);

  if (!response) {
    return <></>;
  }

  const onChange = (e, val) => {
    //Note: This is HK time, create moment object in HK time
    var x = moment.tz(e.target.value, "Asia/Hong_Kong");
    setPreviewDate(x);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    console.log(`MPMList . onSortEnd : ${oldIndex} -> ${newIndex}`);

    // sim the onClick on the Link because the SortableElement digested it
    if (oldIndex == newIndex) {
      redirect(`slots?filter=${JSON.stringify({ slot_id: (oldIndex + 1).toString(), type: type, name: name })}`);

      return;
    }

    // local change
    var updatedSlots = arrayMove(displaySlots, oldIndex, newIndex); //Reorder Row
    setDisplaySlots(updatedSlots);

    // call api
    await dataProvider.updateSlotOrder({
      type: type,
      slot_id: oldIndex + 1,
      new_slot_id: newIndex + 1,
    });

    refreshSlot();
  };

  return (
    <Create {...props} title={name}>
      <SimpleForm toolbar={<></>}>
        <div style={{ display: "table", width: "100%" }}>
          <MyDateTimeInput label="Preview Date" source="previewDate" initialValue={previewDate.format("YYYY-MM-DDTHH:mm", false)} onChange={onChange} style={{ textAlign: "left", display: "table-cell" }} />
          <div id="editorial_name" class="editorial_name" style={{ textAlign: "right", display: "table-cell", verticalAlign: "middle" }}>
            <label>Editorial Name: </label>
            <span>
              <b>{name}</b>
            </span>
          </div>
        </div>

        <MPMGrid type={type} name={name} slots={slots} displaySlots={displaySlots} max_slot_id={response.max_slot_id} onSortEnd={onSortEnd} axis="xy" hideSortableGhost="true" />
      </SimpleForm>
    </Create>
  );

  //Note:
  //React admin supports query string filter={} to set default filter
  //However, it cannot use with filterDefaultValues(Maybe bug)
  //Therefore, we cannot use filter={} here, and we need to handle filter manually
  // return (
  //   <List {...props} pagination={false} filter={{ type: type }} filters={<MPMFilter />} filterDefaultValues={{ previewDate: datestring }} perPage={20} actions={<ListActions name={name} />}>
  //     <MPMGrid type={type} name={name} />
  //   </List>
  // );

  // <List {...props} pagination={false} perPage={20} filters={<MPMFilter />} filterDefaultValues={{ previewDate: datestring }}>
  //   <SimpleList class="mpm-list" primaryText={(record) => ""} linkType={(record) => `slots?filter=${JSON.stringify({ slot_id: record.slot_id.toString() })}`} rowStyle={postRowStyle} />
  // </List>;
};
