import { Close, Delete, Done } from "@material-ui/icons";
import { Grid } from "@mui/material";
import { useCallback, useState } from "react";
import {
  Button, Datagrid, DeleteWithConfirmButton, EditButton, ExportButton, Filter, FormDataConsumer, ImageField, Link, List, ShowButton, SimpleList, TextField, TextInput, TopToolbar, useDataProvider, useListContext, useNotify, useRecordContext, useRefresh
} from "react-admin";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { MyDateField } from "../../MyDateField";
import { MyDateTimeInput } from "../../MyDateTimeInput";


const PosterFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Programme ID" source="programme_id" alwaysOn />
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/programme-overwrite-poster-module/create`,
      }}
      label="+ CREATE"
    />
  </TopToolbar>
);

export const OverwritePosterModuleList = (props) => {
    
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;

  return (<>    
    <PopupboxContainer />    
    <List {...props} sort={{ field: "order", order: "ASC" }} filters={<PosterFilter />} actions={<ListActions />} bulkActionButtons={false} >
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField source="programme_id" />
          <TextField label="Name in TC" source="name_tc" />
          <TextField label="Name in EN" source="name_en" />
          <MyDateField source="start_time" showTime />
          <MyDateField source="end_time" showTime />
          <ShowButton basePath="/programme-overwrite-posters" />
          <EditButton basePath="/programme-overwrite-posters" />
          <CustomDeleteButton />        
        </Datagrid>
      )}
    </List>
  </>);
};

const CustomDeleteButton = (props)=>{

  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  // console.log('CustomDeleteButton : record = ');
  // console.log(record);

  const [disabled, setDisabled] = useState(false);


  const onDeleteButtonClick = useCallback(()=>{

    const content = (
      <div style={{textAlign:"center"}}>
      <Grid item sm={12} md={12}>
          <h2>Delete overwrite poster</h2>
      </Grid>
      <Grid item sm={12} md={12}>
          <br/>
          <p>{"Are you sure?"}</p>
          <br/>
      </Grid>
      <Grid item sm={12} md={12}>
  
          <Grid sm={12} md={12} container justifyContent="space-around">
              
              <EditButton 
              label="Confirm" 
              icon={<Done />} 
              disabled={disabled}
              onClick={async() => {

                setDisabled(true);

                try{
                  await dataProvider.delete('programme-overwrite-posters', {id: record.id});

                  notify("Delete programme overwrite poster successfully", "success");                  
                  refresh();

                }catch(error){
                  console.log(error);
                  notify(error.message.toString(), "warning");

                }finally{
                  PopupboxManager.close();
                  setDisabled(false);  
                };
                
              }}
              />
              
              <EditButton
              label="Cancel"
              icon={<Close />}
              disabled={disabled}
              onClick={() => {   
                PopupboxManager.close();
              }}
              />
  
          </Grid>
          
      </Grid>
      </div>
    );

    PopupboxManager.open({ content, config: {overlayClose: false, escClose: false} });
  });

  return (<EditButton basePath="/" onClick={onDeleteButtonClick} icon={<Delete />} label="Delete" style={{color:"red"}} />);
}