import { Grid, TableCell, TableHead, TableRow } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Label } from "@material-ui/icons";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import React, { useEffect, useRef, useState } from "react";
import { AutocompleteInput, ArrayField, Labeled, BooleanInput, BooleanField, Button, CreateButton, Datagrid, DeleteWithConfirmButton, FormDataConsumer, RecordContextProvider, ReferenceField, ReferenceInput, required, SaveButton, SimpleForm, TextField, TextInput, Toolbar, useDataProvider, useNotify, useRecordContext, useRedirect } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { MyDateTimeInput } from "../../../MyDateTimeInput";
import { MyDateField } from "../../../MyDateField";

var moment = require("moment-timezone");

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell> {/* Drag */}
      <TableCell style={{}}></TableCell> {/* Text */}
      <TableCell style={{}}></TableCell> {/* Enabled */}
      <TableCell style={{}}></TableCell> {/* Remove */}
    </TableRow>
  </TableHead>
);

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const CustomDeleteButton = ({ pos, ...props }) => {
  const popupForm = useForm();
  return (
    <Button
      onClick={(e) => {
        var oldData = popupForm.getState().values.canned_messages ?? [];
        var newData = [...oldData]; //Clone
        newData.splice(pos, 1);

        popupForm.change("canned_messages", newData);
      }}
      label="Remove"
    />
  );
};

const MyDatagridRow = ({ popupForm, totalItems, item, pos, roomStatus, ...props }) => {
  const autoCompleteInputRef = useRef(null);
  const dataProvider = useDataProvider();
  console.log(`totalItems = ${totalItems}`);
  console.log(item);

  var idSource = `canned_message_id_${item.internalId ?? item.id}`;
  var enabledSource = `enabled_${item.internalId ?? item.id}`;

  if (item.isNew) {
    return (
      <RecordContextProvider value={null}>
        <TableRow>
          <DragHandle />
          <TableCell style={{ overflowWrap: "break-word" }}>
            <ReferenceInput
              label="Canned Message"
              source={idSource}
              reference="im-canned-messages-autocomplete"
              validate={required()}
              onChange={async (val) => {
                console.log(`onChange: ${val}`);

                if (val == "@@ra-create") {
                  return;
                }

                const res = await dataProvider.getOne("im-canned-messages", { id: val });
                if (res.data) {
                  var oldData = popupForm.getState().values.canned_messages ?? [];
                  var newData = [...oldData]; //Clone

                  newData[pos]["isNew"] = false;
                  newData[pos]["id"] = val;
                  newData[pos]["text"] = res.data.text;
                  //DO NOT CANGE canned_message_enabled

                  popupForm.change("canned_messages", newData);
                }
              }}
              disabled={false}
              filterToQuery={(searchText) => {
                if (searchText) {
                  return { text: searchText };
                }
                return {};
              }}
            >
              <AutocompleteInput
                optionText="text"
                optionValue="id"
                fullWidth
                disabled={false}
                validate={required()}
                shouldRenderSuggestions={(val) => {
                  return val && val.length > 0;
                }}
                onCreate={(filter) => {
                  var oldData = popupForm.getState().values.canned_messages ?? [];
                  var newData = [...oldData]; //Clone

                  newData[pos]["isNew"] = false;
                  newData[pos]["id"] = null;
                  newData[pos]["text"] = filter;

                  popupForm.change("canned_messages", newData);
                  return false;
                }}
              />
            </ReferenceInput>
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <BooleanInput
              label="Enabled"
              key={enabledSource}
              source={enabledSource}
              defaultValue={item.enabled}
              onChange={(checked) => {
                var oldData = popupForm.getState().values.canned_messages ?? [];
                var newData = [...oldData]; //Clone

                newData[pos]["enabled"] = checked;

                popupForm.change("canned_messages", newData);
              }}
            />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <CustomDeleteButton popupForm={popupForm} pos={pos} />
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  } else if (item.id) {
    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <DragHandle />

          <TableCell style={{ overflowWrap: "break-word" }}>
            <TextField source="text" disabled={true} />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <BooleanInput
              label="Enabled"
              key={enabledSource}
              source={enabledSource}
              defaultValue={item.enabled}
              onChange={(checked) => {
                var oldData = popupForm.getState().values.canned_messages ?? [];
                var newData = [...oldData]; //Clone

                newData[pos]["enabled"] = checked;

                popupForm.change("canned_messages", newData);
              }}
            />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{!["live", "end"].includes(roomStatus) && <CustomDeleteButton popupForm={popupForm} pos={pos} />}</TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  } else if (!item.id) {
    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <DragHandle />
          <TableCell style={{ overflowWrap: "break-word" }}>
            <TextField source="text" disabled={true} />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <BooleanInput
              label="Enabled"
              key={enabledSource}
              source={enabledSource}
              defaultValue={item.enabled}
              onChange={(checked) => {
                var oldData = popupForm.getState().values.canned_messages ?? [];
                var newData = [...oldData]; //Clone

                newData[pos]["enabled"] = checked;

                popupForm.change("canned_messages", newData);
              }}
            />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <CustomDeleteButton popupForm={popupForm} pos={pos} />
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  }
};

const SortableItem2 = SortableElement(({ roomStatus, totalItems, popupForm, item, pos, ...props }) => {
  return <MyDatagridRow roomStatus={roomStatus} totalItems={totalItems} popupForm={popupForm} item={item} pos={pos} {...props} />;
});

const MyDatagridBody = ({ roomStatus, items, popupForm, ...props }) => {
  // return (
  //   <div>
  //     {items.map((item, index) => {
  //       return (
  //         <div>
  //           <SortableItem2 totalItems={items.length} form={form} item={item} pos={index} {...props} />
  //         </div>
  //       );
  //     })}
  //   </div>
  // );

  return items.map((item, index) => {
    return (
      // <div id={"item_" + index} key={`item-${index}`}>
      <SortableItem2 key={index} roomStatus={roomStatus} totalItems={items.length} popupForm={popupForm} item={item} index={index} pos={index} {...props} />
      // </div>
    );
  });
  // return items.map((item, index) => {
  //   return <SortableItem2 totalItems={items.length} form={form} item={item} pos={index} {...props} />;
  // });
};

const SortableListContainer = SortableContainer(({ roomStatus, rows, popupForm, ...props }) => {
  return (
    <Datagrid
      style={{ tableLayout: "fixed" }}
      header={<DatagridHeader />}
      body={
        <MyDatagridBody
          roomStatus={roomStatus}
          popupForm={popupForm}
          items={rows ?? []}
          // refreshRows={refreshRows}
        />
      }
    />
  );
  // return <Datagrid style={{ "table-layout": "fixed" }} header={<DatagridHeader />} body={<MyDatagridBody isHomepage={isHomepage} items={items} onDelete={onDelete} />}></Datagrid>;
});

const SortableList = ({ roomStatus, rows, popupForm, ...props }) => {
  const data = useRecordContext();
  //   const form = useForm();

  const dataProvider = useDataProvider();
  // const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd = " + oldIndex + " " + newIndex);
    var oldItem = rows[oldIndex];
    var newItem = rows[newIndex];

    popupForm.change(`enabled_${oldIndex}`, newItem.enabled);
    popupForm.change(`enabled_${newIndex}`, newItem.enabled);
    var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder
    popupForm.change("canned_messages", [...updatedRows]); //Clone

    // setRows((rows) => {
    //   var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row
    //   var updatedRowIds = updatedRows.map((r) => r.id);
    //   form.change("rows", updatedRowIds); //Also change form::rows
    //   return updatedRows;
    // });
  };

  const onDelete = (rowId) => {
    // setRows((rows) => {
    //   var updatedRows = rows.filter((row) => row.id !== rowId);
    //   var updatedRowIds = updatedRows.map((r) => r.id);
    //   form.change("rows", updatedRowIds); //Also change form::rows
    //   return updatedRows;
    // });
  };

  return <SortableListContainer {...props} roomStatus={roomStatus} popupForm={popupForm} rows={rows} onSortEnd={onSortEnd} onDelete={onDelete} useDragHandle={true} lockAxis="y" />;
};

const PopupContent = ({ form, item, roomStatus, ...props }) => {
  const endRef = useRef(null);
  const popupForm = useForm();

  useEffect(() => {
    popupForm.change("name", item?.name ?? "");
    popupForm.change("question", item?.question);
    popupForm.change("tnc", item?.tnc);
    popupForm.change("start_time", item?.start_time);
    popupForm.change("end_time", item?.end_time);
    popupForm.change("voting_enabled", item?.voting_enabled);
    popupForm.change("canned_messages", item?.canned_messages);
  }, []);

  const validateVotingQuestion = (values, allValues) => {
    if (allValues.voting_enabled && !values) {
      return "Required";
    }

    if (!allValues.voting_enabled && values) {
      return "Voting is disabled";
    }

    return undefined;
  };

  return (
    <>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <TextInput source="name" label="Set name" validate={required()} fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={2}>
          <BooleanInput label="Voting" source="voting_enabled" initialValue={false} disabled={item && item.status != "preview"} />
        </Grid>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <Grid item xs={12} sm={12} md={10}>
                <TextInput source="question" label="Voting Question" validate={validateVotingQuestion} disabled={!formData.voting_enabled} fullWidth />
                <TextInput source="tnc" label="Voting T&C" disabled={!formData.voting_enabled} fullWidth />
              </Grid>
            );
          }}
        </FormDataConsumer>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <MyDateTimeInput label="Start Time" source="start_time" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <MyDateTimeInput label="End Time" source="end_time" fullWidth validate={required()} />
        </Grid>
      </Grid>

      <CreateButton
        label="Add"
        disabled={false}
        onClick={(e) => {
          e.preventDefault();

          var oldData = popupForm.getState().values.canned_messages ?? [];
          var newData = [...oldData]; //Clone
          //This internal id is important, to prevent re-ordering BooleanInput's source reset
          newData.push({ isNew: true, enabled: true, internalId: Math.random().toString(36).slice(2) });

          popupForm.change("canned_messages", newData);

          //Scroll to bottom
          if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      />

      <FormDataConsumer label="Value" fullWidth>
        {({ formData, scopedFormData, getSource, ...rest }) => {
          return (
            // <Grid container spacing={1} fullWidth>
            <SortableList helperClass="sortableHelper" {...props} roomStatus={roomStatus} popupForm={popupForm} rows={formData.canned_messages ?? []} />
            // </Grid>
          );
        }}
      </FormDataConsumer>

      <div ref={endRef} />
    </>
  );
};

const CustomToolbar = ({ form, item, index, ...props }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const popupForm = useForm();

  const handleSave = async (values, redirect) => {
    console.log("onSave");
    console.log(values);

    var oldData = form.getState().values.canned_message_list ?? [];

    var newData = [...oldData]; //Clone

    if (item) {
      //Update
      newData[index] = {
        id: item.id,
        name: values.name,
        question: values.question,
        tnc: values.tnc,
        start_time: values.start_time,
        end_time: values.end_time,
        voting_enabled: values.voting_enabled,
        canned_messages: values.canned_messages,
        status: item.status,
      };
    } else {
      //Add
      newData.push({
        name: values.name,
        question: values.question,
        tnc: values.tnc,
        start_time: values.start_time,
        end_time: values.end_time,
        voting_enabled: values.voting_enabled,
        canned_messages: values.canned_messages,
      });
    }

    form.change("canned_message_list", newData);

    cleanPopupForm(popupForm);
    PopupboxManager.close();
  };

  return (
    <Toolbar {...props}>
      <Button
        onClick={() => {
          cleanPopupForm(popupForm);
          PopupboxManager.close();
        }}
        label="Close"
      ></Button>
      <SaveButton onSave={handleSave} label="Save" />
    </Toolbar>
  );
};

const cleanPopupForm = (popupForm) => {
  //   popupForm.change("set_name", undefined);
  //   popupForm.change("voting_question", undefined);
  //   popupForm.change("canned_message_start_time", undefined);
  //   popupForm.change("canned_message_end_time", undefined);
  //   popupForm.change("canned_messages", undefined);
  //   popupForm.change("voting_enabled", undefined);
};

export const AddCannedMessageDialog = ({ form, item, index, roomStatus, ...props }) => {
  return (
    <SimpleForm {...props} toolbar={<CustomToolbar item={item} index={index} form={form} />}>
      <PopupContent form={form} item={item} roomStatus={roomStatus} />
    </SimpleForm>
  );
};

export const ShowCannedMessageDialog = ({ item, ...props }) => {
  return (
    <>
      <RecordContextProvider value={item}>
        <>
          <Grid container spacing={1} fullWidth>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Set name">
                <TextField source="name" />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Voting Question">
                <TextField source="question" />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Labeled label="Voting TNC">
                <TextField source="tnc" />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Labeled label="Voting">
                <BooleanField source="voting_enabled" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container spacing={1} fullWidth>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="Start Time">
                <MyDateField source="start_time" showTime />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Labeled label="End Time">
                <MyDateField source="end_time" showTime />
              </Labeled>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Labeled label="Canned message">
                <ArrayField source="canned_messages">
                  <Datagrid header={<></>}>
                    <TextField source="text" />
                  </Datagrid>
                </ArrayField>
              </Labeled>
            </Grid>
          </Grid>
        </>
        <Button
          onClick={() => {
            PopupboxManager.close();
          }}
          label="Close"
        ></Button>
      </RecordContextProvider>
    </>
  );
};
