import {
  SimpleForm,
  TextInput,
  useNotify,
  Create,
} from "react-admin";

const validatePassword = async (values) => {
    const errors = {};
    if(values.new_password !== values.new_repeat_password){
     errors.new_repeat_password = 'Password confirm does not match.'
    }
    return errors
};

const redirect = () => "/password/create";

export const PasswordCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect} validate={validatePassword}>
         <TextInput label="Current Password" source="old_password" type="password" />
         <TextInput label="New Password" source="new_password" type="password" />
         <TextInput label="New Password Confirm" source="new_repeat_password" type="password" />
      </SimpleForm>
    </Create>
  );
};
