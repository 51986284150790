import {
  ArrayInput,
  AutocompleteInput,
  Button,
  Create,
  DeleteButton,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  required,
  FormDataConsumer,
  Filter,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  TopToolbar,
  Toolbar,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  SelectInput,
  TextInput,
  useNotify,
  useCreate,
  useUpdate,
  useRedirect,
  SimpleShowLayout,
  Labeled,
  ListButton,
  List, //
  ListContextProvider,
  ReferenceInput,
  useFormContext,
  RecordContextProvider,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useParams, useCallback } from "react";
import { useDataProvider, Loading, useRecordContext } from "react-admin";
import { OnChange } from "react-final-form-listeners";
// import { IconButton } from "@mui/material";
// import Remove from '@material-ui/svg-icons/Content/remove';
import MenuIcon from "@material-ui/icons/Menu";
import SaveIcon from "@material-ui/icons/Save";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

// delete confirm popup
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import { useForm, useFormState } from "react-final-form";
import { MyDateTimeInput } from "../../../MyDateTimeInput";
import { Restore } from "@material-ui/icons";

import { RegionCheckboxGroupInput, convertRegionToJson, regionDefaultValue } from "../../component/region-components";

// popup
// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';

const customButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid grey",
  margin: "10px",
};

const customPopupStyle = {
  backgroundColor: "white",
  position: "fixed",
  top: "10vh",
  left: "10vw",
  width: "80vw",
  maxHeight: "85vh",
  overflow: "scroll",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
};

export const MenuTreeCreatePopup = (props) => {
  // props.addMenuItemCallback from parent
  console.log("MenuTreeCreatePopup : props");
  console.log(props);

  const form = useForm();

  const [categoryId, setCategoryId] = useState(-1);
  // const [nameTC, setNameTC] = useState("");
  // const [nameEN, setNameEN] = useState("");
  // const [path, setPath] = useState("");
  const [tags, setTags] = useState([]);
  const [parent, setParent] = useState(-1);
  const [selfIndex, setSelfIndex] = useState(-1);
  const [region, setRegion] = useState(regionDefaultValue);

  // default value for form
  const getDefaultNameTC = () => {
    return props.editRow ? props.editRow.name_tc : "";
  };

  const getDefaultNameEN = () => {
    return props.editRow ? props.editRow.name_en : "";
  };

  const getDefaultPath = () => {
    return props.editRow ? props.editRow.path : "";
  };

  const getDefaultStartTime = () => {
    // return null;
    const v = form.getState().values;
    if (v.item_start_time !== undefined) {
      console.log("getDefaultStartTime : use form value : " + v.item_start_time);
      return v.item_start_time;
    }
    console.log("getDefaultStartTime : use editRow value");
    return props.editRow ? props.editRow.start_time : "";
  };

  const getDefaultEndTime = () => {
    const v = form.getState().values;
    if (v.item_end_time !== undefined) {
      console.log("getDefaultStartTime : use form value : " + v.item_end_time);
      return v.item_end_time;
    }
    console.log("getDefaultStartTime : use editRow value");
    return props.editRow ? props.editRow.end_time : "";
  };

  const getDefaultParent = () => {
    const i = props.editRow ? props.editRow.parentIndex : -1;
    console.log("getDefaultParent : parentIndex = " + i);

    return i;
  };

  const getDefaultTags = () => {
    console.log("getDefaultTags : tags = ");
    const t = props.editRow ? props.editRow.tags : [];
    console.log(t);
    return t;
  };

  // tag change
  const onTagChange = useCallback((arrayInput) => {
    console.log("onTagChange : arrayInput = ");
    console.log(arrayInput);
    console.log("onTagChange : result = ");

    const result = [];
    for (const item of arrayInput) {
      if (item && item.tag_value) {
        // check tag_value already exist in result
        if (!result.includes(item.tag_value)) result.push(item.tag_value);
      }
    }
    console.log(result);
    setTags(result);
  });

  /* ***************************
        popup actions
  *************************** */

  // Save
  const onSaveButtonClick = useCallback((formData) => {
    console.log("onSaveButtonClick : formData = ");
    console.log(formData);

    const data = {
      category_id: categoryId,
      name_tc: formData.name_tc,
      name_en: formData.name_en,
      path: formData.path,
      start_time: formData.item_start_time,
      end_time: formData.item_end_time,
      parent: parent,
      tags: formData.tags ? formData.tags.map((t) => t.tag_value) : [],
      selfIndex: selfIndex,
      categories: [],
      region: formData.region,
    };
    console.log("onSaveButtonClick : submit data = ");
    console.log(data);

    if (props.editRow) {
      // if has editRow, it is edit item
      props.updateMenuItemCallback(data);
    } else {
      // if no editRow, it is add item
      props.addMenuItemCallback(data);
    }
  });

  // Cancel
  const onCancelButtonClick = useCallback(() => {
    console.log("onCancelButtonClick");

    props.closeMenuItemCallback();
  });

  // Delete
  const onDeleteButtonClick = useCallback(() => {
    console.log("onDeleteButtonClick : data = ");
    const data = {
      category_id: categoryId,
      name_tc: form.getState().values.name_tc,
      name_en: form.getState().values.name_en,
      path: form.getState().values.path,
      start_time: form.getState().values.start_time,
      end_time: form.getState().values.end_time,
      parent: parent,
      tags: tags,
      selfIndex: selfIndex,
      categories: [],
    };

    console.log(data);

    props.removeMenuItemCallback(data);
  });

  const NameAndPath = () => {
    console.log("NameAndPath : categoryId = " + categoryId);

    const form = useForm();

    const nameEnOnChange = useCallback(() => {
      const n = form.getState().values.name_en;
      const n2 = n.toLowerCase().replace(/[^a-z0-9]/g, "");
      form.change("path", n2);
    });

    if (categoryId == -1) {
      return (
        <>
          <TextInput label="Page title in EN (SEO)" source="name_en" defaultValue={getDefaultNameEN()} validate={required()} onChange={nameEnOnChange} fullWidth />
          <br />
          <TextInput label="Page Path" source="path" defaultValue={getDefaultPath()} validate={required()} fullWidth />
        </>
      );
    } else {
      return (
        <>
          <TextInput label="Page title in EN (SEO)" source="name_en" defaultValue={getDefaultNameEN()} validate={required()} fullWidth />
        </>
      );
    }
  };

  const menuItemPopupFormValidation = (formData) => {
    const errors = {};
    console.log("menuItemPopupFormValidation : form = ");
    console.log(formData);

    if ((formData.item_start_time && !formData.item_end_time) || (!formData.item_start_time && formData.item_end_time)) {
      errors.item_start_time = "Please input both start time and end time";
      errors.item_end_time = "Please input both start time and end time";
    }

    return errors;
  };

  const MenuItemPopupFormContent = () => {
    const form = useForm();

    // landing page
    // if editRow exist, set them to the form
    useEffect(() => {
      console.log("MenuTreeCreatePopup . useEffect : ");

      if (props.editRow) {
        console.log("MenuTreeCreatePopup . useEffect : editRow = ");

        const r = props.editRow;
        console.log(r);

        setCategoryId(r.category_id);
        // setNameTC(r.name_tc);
        // setNameEN(r.name_en);
        // setPath(r.path);

        // replace values
        const v = form.getState().values;

        if (!v.name_tc !== undefined && r.name_tc) form.change("name_tc", r.name_tc);
        if (!v.name_en !== undefined && r.name_en) form.change("name_en", r.name_en);
        if (!v.path !== undefined && r.path) form.change("path", r.path);
        if (!v.item_start_time !== undefined && r.start_time) form.change("item_start_time", r.start_time);
        if (!v.item_end_time !== undefined && r.end_time) form.change("item_end_time", r.end_time);
        // if (!v.region !== undefined && r.region) form.change("region", r.region);

        setTags(r.tags);
        setParent(r.parentIndex);
        setSelfIndex(r.selfIndex);
        setRegion(r.region);

        console.log("MenuTreeCreatePopup . useEffect : form = ");
        console.log(form.getState().values);
      }
    }, [props.editRow]);

    return (
      <>
        <>
          <h3>Create menu item</h3>
        </>
        <TextInput label="Page title in TC (SEO)" source="name_tc" defaultValue={getDefaultNameTC()} validate={required()} fullWidth />
        <NameAndPath />

        <Grid container spacing={1} fullWidth>
          <Grid item xs={12} sm={12} md={6}>
            <MyDateTimeInput source="item_start_time" label="Start Time" initialValue={getDefaultStartTime()} showTime fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MyDateTimeInput source="item_end_time" label="End Time" initialValue={getDefaultEndTime()} showTime fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <EditButton
            label="Reset Start / End Time"
            icon={<Restore />}
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              console.log("Reset Start / End Time : form = ");
              var n = form.getState().values;
              console.log(n);
              form.change("item_start_time", "");
              form.change("item_end_time", "");

              n = form.getState().values;
              console.log("Reset Start / End Time : form2 = ");
              console.log(n);
            }}
          />
        </Grid>
      </>
    );
  };

  return (
    <div style={customPopupStyle}>
      <SimpleForm toolbar={<CustomToolbar editRow={props.editRow} onSaveButtonClick={onSaveButtonClick} onCancelButtonClick={onCancelButtonClick} onDeleteButtonClick={onDeleteButtonClick} />} validate={menuItemPopupFormValidation}>
        <MenuItemPopupFormContent />

        <>
          <h4>Select Parent</h4>
        </>
        <Grid item xs={12} sm={12} md={12}>
          <SelectInput label="Content" source="parent" choices={props.getMenuItemParent()} defaultValue={getDefaultParent()} fullWidth disabled={props.editRow ? true : false} translateChoice={false} />
        </Grid>

        {/* tags  */}
        <>
          <h4>Tag of this item (optional)</h4>
        </>
        <Grid item sm={12} md={12}>
          <TagInputsWithOldData defaultValue={getDefaultTags()} />
        </Grid>

        <Grid item sm={12} md={12}>
          <RecordContextProvider value={{ region: region }}>
            <RegionCheckboxGroupInput {...props} />
          </RecordContextProvider>
        </Grid>

        {/* form on change */}
        {/* <OnChange name="name_tc">
          {(value, previous) => {
            setNameTC(value);
          }}
        </OnChange>
        <OnChange name="name_en">
          {(value, previous) => {
            setNameEN(value);
          }}
        </OnChange>
        <OnChange name="path">
          {(value, previous) => {
            setPath(value);
          }}
        </OnChange> */}
        <OnChange name="parent">
          {(value, previous) => {
            setParent(value);
          }}
        </OnChange>
        <OnChange name="tags">
          {(value, previous) => {
            onTagChange(value);
          }}
        </OnChange>
      </SimpleForm>
    </div>
  );
};

const CustomToolbar = (props) => {
  const form = useForm();
  const notify = useNotify();
  // console.log("CustomToolbar : form.getState() = ");
  // console.log(form.getState());

  const onDeleteButtonClickPopup = useCallback(() => {
    console.log("onDeleteButtonClickPopup");

    const content = (
      <Grid container>
        <Grid item sm={12} md={12}>
          <h2>Warning !</h2>
        </Grid>
        <Grid item sm={12} md={12}>
          <p>{"Are you sure to delete " + form.getState().values.name_tc + " ? All of its child items will also be deleted !!!"}</p>
          <br />
          <br />
        </Grid>
        <Grid item sm={12} md={12} container style={{ justifyContent: "space-around" }}>
          <EditButton
            label="Delete Item"
            icon={<DoneIcon />}
            onClick={() => {
              PopupboxManager.close();
              props.onDeleteButtonClick();
            }}
          />{" "}
          <EditButton
            label="Cancel"
            icon={<CloseIcon />}
            onClick={() => {
              PopupboxManager.close();
            }}
          />
        </Grid>
      </Grid>
    );
    PopupboxManager.open({ content });
  });

  return (
    <Toolbar {...props}>
      <Grid container spacing={1}>
        <SaveButton
          label="Save"
          icon={<DoneIcon />}
          handleSubmitWithRedirect={() => {
            console.log("SaveButton . handleSubmitWithRedirect : form = ");
            console.log(form);
            console.log(form.getState());
            if (form.getState().valid) {
              props.onSaveButtonClick(form.getState().values);
            } else {
              notify("Form is not completed", "warning");
            }
          }}
        />

        <EditButton
          label="Cancel"
          variant="outlined"
          icon={<CloseIcon />}
          onClick={() => {
            props.onCancelButtonClick();
          }}
        />

        {props.editRow ? (
          <>
            <EditButton label="Delete Item" variant="outlined" icon={<DeleteIcon />} style={{ color: "red" }} onClick={onDeleteButtonClickPopup} />
            <PopupboxContainer />
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Toolbar>
  );
};

const TagInputsWithOldData = (props) => {
  console.log("TagInputs : props = ");
  console.log(props);

  // constructor
  // const formData = props.formData;
  // const rest = props.rest;
  const defaultValue = props.defaultValue;

  const dataProvider = useDataProvider();

  const [tagTypes, setTagTypes] = useState([]);
  const [tags, setTags] = useState([]);
  // const [oldTagAddCount, setOldTagAddCount] = useState(0);
  // const [apiCallCount, setApiCallCount] = useState(0);

  useEffect(() => {
    dataProvider.getTagTypes().then((types) => {
      setTagTypes(types.data);
    });

    // load tags from defaultValue
    if (defaultValue && defaultValue.length > 0) {
      const r = dataProvider.getTags(defaultValue).then((result) => {
        console.log("TagInputs . useEffect : defaultValue = ");
        console.log(defaultValue);
        console.log("TagInputs . useEffect : tagObjects = ");
        console.log(result);

        const tagObjects = result.data;
        console.log(tagObjects);
        const tagObjects2 = tagObjects.map((obj) => ({
          tag_type: obj.type,
          tag_value: obj.tag_id,
          // name_tc: obj.name_tc,
          // name_en: obj.name_en,
        }));

        setTags(tagObjects2);

        // setTimeout(() => {
        //   setApiCallCount(apiCallCount + 1);
        // }, 1);
      });
    }
  }, []);

  const render = () => <TagsContent {...props} tagTypes={tagTypes} tags={tags} />;

  // force re-render
  return render();
};

const TagsContent = (props) => {
  return (
    <ArrayInput source="tags" label="Tag Search" defaultValue={props.tags}>
      {/* Add TransitionProps because of bug: https://github.com/marmelab/react-admin/issues/6900 */}
      <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
        {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
        <SelectInput source="tag_type" choices={props.tagTypes} label="Type" translateChoice={false} />
        <FormDataConsumer label="Value">
          {(props) => {
            const formData = props.formData;
            const scopedFormData = props.scopedFormData;
            const getSource = props.getSource;

            console.log("FormDataConsumer 2 : props=");
            // props.formData.tags = tags;
            console.log(props);

            if (!scopedFormData) {
              return <></>;
            }

            return (
              <ReferenceInput
                label="Value"
                source={getSource("tag_value")}
                reference="mpm-row-tag-autocomplete"
                filter={{ tag_type: scopedFormData.tag_type }}
                filterToQuery={(searchText) => {
                  if (searchText) {
                    return {
                      name_tc: searchText,
                    };
                  }
                  return {};
                }}
              >
                <AutocompleteInput
                  optionText={(record) => {
                    if (record == null) {
                      return null;
                    }
                    return record.name_tc + " " + record.name_en;
                  }}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
