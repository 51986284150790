import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  DeleteWithConfirmButton,
  useRecordContext
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import {
  Grid,
} from "@material-ui/core";

const VipFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
  </Filter>
);

const CustomDelete = () => {

  /*
  Source Code:
  https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/button/DeleteWithConfirmButton.tsx
  */ 
  const record = useRecordContext();
  return(<DeleteWithConfirmButton record={record} confirmTitle={'Delete Vip Ad Free ' + record.user_id}/>);
};

export const VipsAdFreeList = (props) => (
    <List {...props} sort={{ field: "user_id", order: "ASC" }}
      bulkActionButtons={<AssetBulkActionButtons />} filters={<VipFilter />}>
        <Datagrid>
          <TextField source="user_id" />
          {/* <ShowButton />
          <EditButton /> */}
          <CustomDelete />
        </Datagrid>
    </List>
);

export const VipsAdFreeEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit
      {...props}
      onFailure={onFailure}
      mutationMode="pessimistic"
      title={<VipTitle />}
    >
      <SimpleForm>
            <TextInput source="user_id" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = () => "/vips-ad-free";

const VipTitle = ({ record }) => {
  return <span>VIP {record ? `${record.user_id}` : ""}</span>;
};

export const VipsAdFreeCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="user_id" />
      </SimpleForm>
    </Create>
  );
};
export const VipsAdFreeShow = (props) => (
  <Show {...props} title={<VipTitle />}>
    <SimpleShowLayout>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Labeled label="User ID">
              <TextField source="user_id" />
            </Labeled>
          </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);