import { useMediaQuery } from "@material-ui/core";
import { ChangeCircle } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { ArrayField, ChipField, Create, Datagrid, Edit, EditButton, Filter, FunctionField, List, ReferenceArrayField, ReferenceArrayInput, SaveButton, SelectInput, Show, ShowButton, SimpleForm, SimpleList, SimpleShowLayout, SingleFieldList, TextField, TextInput, Toolbar, email, useNotify } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { MyDateField } from "../../MyDateField";
import { AssetBulkActionButtons } from "../components";
import { ChangePasswordPopupContent } from "./components/change-password-popup";

const validateEmail = email();

export const UserList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} sort={{ field: "username", order: "ASC" }} bulkActionButtons={<AssetBulkActionButtons />} filters={<CustomFilter />}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.username} />
      ) : (
        <Datagrid>
          <TextField source="username" />
          <TextField source="email" />
          <ArrayField source="roles" sortBy="roles.name">
            <SingleFieldList>
              <FunctionField
                render={(record) => (
                  <ChipField
                    record={{ id: record._id, name: record.name }}
                    source="name"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/admin/#/roles/" + record._id;
                    }}
                  />
                )}
              />
            </SingleFieldList>
          </ArrayField>
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

const UserTitle = ({ record }) => {
  return <span>User {record ? `${record.username}` : ""}</span>;
};

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
  </Filter>
);

export const UserShow = (props) => (
  <Show {...props} title={<UserTitle />}>
    <SimpleShowLayout>
      <TextField source="username" />
      <TextField source="email" />
      <ReferenceArrayField label="Roles" source="roles" reference="roles">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <MyDateField source="created_at" showTime />
      <MyDateField source="modified_at" showTime />
    </SimpleShowLayout>
  </Show>
);

export const UserEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<UserTitle />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="username" />
        <TextInput source="email" validate={validateEmail} />
        <ReferenceArrayInput source="roles" reference="roles">
          <SelectInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const EditToolbar = (props) => {
  const form = useForm();

  const [dummy2, setDummy2] = useState(1);

  const handleSubmitWithRedirect = useCallback((formData) => {
    console.log("EditToolbar . handleSubmitWithRedirect : form = ");
    const formState = form.getState();

    console.log("EditToolbar . handleSubmitWithRedirect : formState = ");
    console.log(formState);

    // check form valid
    if (formState.valid) {
      // form submit
      form.submit();
    }
  }, []);

  return (
    <Toolbar {...props}>
      <PopupboxContainer />
      <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} />
      <p>&emsp;</p>
      <EditButton
        label="Change Password"
        icon={<ChangeCircle />}
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          setDummy2(dummy2 + 1);
          PopupboxManager.open({
            content: ChangePasswordPopupContent(props, dummy2),
            config: { overlayClose: false, escClose: false },
          });
        }}
      />
    </Toolbar>
  );
};

const redirect = () => "/users";
export const UserCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="username" />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="password" />
        <ReferenceArrayInput source="roles" reference="roles">
          <SelectInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
