import { TableCell, TableHead, TableRow } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import React, { useState } from "react";
import { BooleanInput, Button, CreateButton, Datagrid, EditButton, FormDataConsumer, Labeled, RecordContextProvider, RichTextField, ShowButton, useDataProvider, useRecordContext } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { AddProductDialog, ShowProductDialog } from "./product-dialog";
var moment = require("moment-timezone");

export const ProductListShow = (props) => {
  const { record } = props;
  console.log(`record = ${JSON.stringify(record)}`);

  const DatagridHeader = ({ children }) => (
    <TableHead>
      <TableRow>
        <TableCell style={{}}>Title</TableCell>
        <TableCell style={{}}>Description</TableCell>
        <TableCell style={{}}>Live Promote Text</TableCell>
        <TableCell style={{}}>Purchase Button Text</TableCell>
        <TableCell style={{}}>Product Picture</TableCell>
        <TableCell style={{}}>URL</TableCell>
        <TableCell style={{}}>Open URL</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const MyDatagridRow = ({ item, ...props }) => {
    const dataProvider = useDataProvider();

    const rowShowOnClick = async (item) => {
      // GET /im-room-products/:id to get data
      var newItem;
      if (!item.isNew) {
        const response = await dataProvider.getOneIMRoomProducts(item.id);
        var data = response.data;
        newItem = data;
      } else {
        newItem = item;
      }

      PopupboxManager.open({
        content: <ShowProductDialog item={newItem} />,
        config: {
          style: { width: "80%", height: "80%" },
          overlayClose: false,
          escClose: false,
          content: {
            style: {
              overflowY: "scroll",
            },
          },
        },
      });
    };

    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.title}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <RichTextField source="description" />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.live_promote_text}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.purchase_button_text}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <img src={item.product_image} style={{ width: "100px" }} />
          </TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.product_url}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.open_url}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <ShowButton
              onClick={() => {
                rowShowOnClick(item);
              }}
            />
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  };

  const MyDatagridBody = ({ items, ...props }) => {
    if (items) {
      return items.map((item, index) => {
        console.log("index =" + index);
        return <MyDatagridRow item={item} {...props} />;
      });
    }
    return <></>;
  };

  return (
    <Labeled label="Product List">
      <Datagrid style={{ tableLayout: "fixed" }} header={<DatagridHeader />} body={<MyDatagridBody items={record.products} />} />
    </Labeled>
  );
};

const SortableListContainer = SortableContainer(({ roomStatus, rows, ...props }) => {
  return (
    <Datagrid
      // style={{ tableLayout: "fixed" }}
      header={<DatagridHeader />}
      body={
        <MyDatagridBody
          items={rows ?? []}
          roomStatus={roomStatus}

          // refreshRows={refreshRows}
        />
      }
    />
  );
  // return <Datagrid style={{ "table-layout": "fixed" }} header={<DatagridHeader />} body={<MyDatagridBody isHomepage={isHomepage} items={items} onDelete={onDelete} />}></Datagrid>;
});

const SortableList = ({ rows, roomStatus, ...props }) => {
  const data = useRecordContext();
  const form = useForm();

  const dataProvider = useDataProvider();
  // const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd = " + oldIndex + " " + newIndex);
    var oldItem = rows[oldIndex];
    var newItem = rows[newIndex];

    var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder
    form.change("products", [...updatedRows]); //Clone

    // setRows((rows) => {
    //   var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row
    //   var updatedRowIds = updatedRows.map((r) => r.id);
    //   form.change("rows", updatedRowIds); //Also change form::rows
    //   return updatedRows;
    // });
  };

  const onDelete = (rowId) => {
    // setRows((rows) => {
    //   var updatedRows = rows.filter((row) => row.id !== rowId);
    //   var updatedRowIds = updatedRows.map((r) => r.id);
    //   form.change("rows", updatedRowIds); //Also change form::rows
    //   return updatedRows;
    // });
  };

  return <SortableListContainer {...props} roomStatus={roomStatus} rows={rows} onSortEnd={onSortEnd} onDelete={onDelete} useDragHandle={true} lockAxis="y" />;
};

const addProductButtonOnClick = (form) => {
  PopupboxManager.open({
    content: <AddProductDialog key={moment().unix()} form={form} item={null} />,
    config: {
      style: { width: "80%", height: "80%" },
      overlayClose: false,
      escClose: false,
      content: {
        style: {
          overflowY: "scroll",
        },
      },
    },
  });
};

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell> {/* Drag */}
      <TableCell style={{}}>Title</TableCell>
      <TableCell style={{}}>Description</TableCell>
      <TableCell style={{}}>Live Promote Text</TableCell>
      <TableCell style={{}}>Purchase Button Text</TableCell>
      <TableCell style={{}}>Product Picture</TableCell>
      <TableCell style={{}}>URL</TableCell>
      <TableCell style={{}}>Open URL</TableCell>
      <TableCell style={{}}>Onshelf</TableCell>
      <TableCell style={{}}>Live Promote</TableCell>
      <TableCell style={{}}></TableCell> {/* Show */}
      <TableCell style={{}}></TableCell> {/* Edit */}
      <TableCell style={{}}></TableCell> {/* Remove */}
    </TableRow>
  </TableHead>
);

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const MyDatagridRow = ({ roomStatus, item, pos, ...props }) => {
  const form = useForm();
  const dataProvider = useDataProvider();

  // Edit popup
  const rowEditOnClick = (item, index) => {
    PopupboxManager.open({
      content: <AddProductDialog key={moment().unix()} form={form} item={item} index={index} {...props} roomStatus={roomStatus} />,
      config: {
        style: { width: "80%", height: "80%" },
        overlayClose: false,
        escClose: false,
        content: {
          style: {
            overflowY: "scroll",
          },
        },
      },
    });
    console.log("rowEditOnClick");
    console.log(item);
  };

  const rowShowOnClick = async (item) => {
    // GET /im-room-products/:id to get data
    var newItem;
    if (!item.isNew) {
      const response = await dataProvider.getOneIMRoomProducts(item.id);
      var data = response.data;
      newItem = data;
    } else {
      newItem = item;
    }

    PopupboxManager.open({
      content: <ShowProductDialog item={newItem} />,
      config: {
        style: { width: "80%", height: "80%" },
        overlayClose: false,
        escClose: false,
        content: {
          style: {
            overflowY: "scroll",
          },
        },
      },
    });
  };

  return (
    <RecordContextProvider value={item}>
      <TableRow>
        {roomStatus == "end" ? <TableCell /> : <DragHandle />}

        <TableCell style={{ overflowWrap: "break-word" }}>{item.title}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <RichTextField source="description" />
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.live_promote_text}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.purchase_button_text}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <img src={item.product_image} style={{ width: "100px" }} />
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.product_url}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.open_url}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <BooleanInput
            label="Onshelf"
            source={`is_onshelf_${item.id}`}
            defaultValue={item.is_onshelf}
            onChange={(checked) => {
              var oldData = form.getState().values.products ?? [];
              var newData = [...oldData]; //Clone

              newData[pos]["is_onshelf"] = checked;

              form.change("products", newData);
            }}
          />
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <BooleanInput
            label="Live Promote"
            source={`is_live_promote_${item.id}`}
            defaultValue={item.is_live_promote}
            validate={(values, allValues) => {
              console.log("validateLivePromote");

              var hasLivePromote = false;
              for (const product of allValues.products) {
                if (product.is_live_promote) {
                  if (hasLivePromote) {
                    return "Maximum 1 Live Promote";
                  } else {
                    hasLivePromote = true;
                  }
                }
              }

              return undefined;
            }}
            onChange={(checked) => {
              var oldData = form.getState().values.products ?? [];
              var newData = [...oldData]; //Clone

              newData[pos]["is_live_promote"] = checked;

              form.change("products", newData);
            }}
          />
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          <ShowButton
            onClick={(e) => {
              e.preventDefault();

              rowShowOnClick(item);
            }}
          />
        </TableCell>
        <TableCell>
          {roomStatus != "end" && (
            <EditButton
              onClick={(e) => {
                e.preventDefault();

                rowEditOnClick(item, pos, false);
              }}
              label="Edit"
            />
          )}
        </TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>
          {!["live", "end"].includes(roomStatus) && (
            <Button
              label="Remove"
              style={{ color: "red" }}
              onClick={(e) => {
                e.preventDefault();

                var oldData = form.getState().values.products ?? [];
                var newData = [...oldData]; //Clone

                newData.splice(pos, 1);

                form.change("products", newData);
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const SortableItem2 = SortableElement(({ roomStatus, item, pos, ...props }) => {
  return <MyDatagridRow roomStatus={roomStatus} item={item} pos={pos} {...props} />;
});

const MyDatagridBody = ({ items, roomStatus, ...props }) => {
  return items.map((item, index) => {
    console.log("index =" + index);
    return <SortableItem2 roomStatus={roomStatus} item={item} index={index} pos={index} {...props} />;
    // return <MyDatagridRow roomStatus={roomStatus} item={item} index={index} {...props} />;
  });
};

export const ProductList = ({ chatroomId, roomStatus, ...props }) => {
  const form = useForm();

  return (
    <>
      <Labeled label="Product List">
        {roomStatus != "end" && (
          <CreateButton
            label="Add"
            onClick={(e) => {
              console.log(e);
              e.preventDefault();
              addProductButtonOnClick(form);
            }}
          />
        )}
      </Labeled>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          var rows = formData.products;
          console.log(rows);

          return <SortableList {...props} rows={rows} roomStatus={formData.status} />;
        }}
      </FormDataConsumer>
    </>
  );
};
