import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  useRecordContext,
  required,
  DeleteWithConfirmButton,
  ReferenceArrayInput,
  SimpleFormIterator,
  ArrayInput
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";

export const EventTypeList = (props) => {
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getListEventType()
      .then(({ data }) => {
 
        window.location.href = "/admin/#/event-type/" + data[0].id;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <></>;
  
};
const Values = (props) => {
  const key_value = useRecordContext();
  if (key_value.values instanceof Array) {
    return <div>{key_value.values.join(", ")}</div>;
  } else {
    return <div>{key_value.values}</div>;
  }
};

export const EventTypeEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic">
      <SimpleForm>
        <ArrayInput source="values">
          <SimpleFormIterator>
            <TextInput label="Value" source="name" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
