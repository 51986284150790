import { useRecordContext, Labeled, TextField, FunctionField } from "react-admin";
var moment = require("moment-timezone");
var _ = require("lodash");

export const MyDateField = ({ source, ...props }) => {

  return (
    <FunctionField
      render={(record) => {
        var text = "";
        if (record) {
          var val = _.get(record, source);
          if (val) {
            var d = moment.tz(val, "Asia/Hong_Kong");

            if (props.showTime) {
              text = d.format("YYYY-MM-DD HH:mm");
            } else {
              text = d.format("YYYY-MM-DD");
            }
          }
        }
        return text;
      }}
    />
  );

};

MyDateField.defaultProps = {
  addLabel: true,
};
