import { Grid } from "@mui/material";
import { useCallback } from "react";
import { Show, SimpleShowLayout, TextField, useNotify } from "react-admin";
import { MyDateField } from "../../MyDateField";

export const VodLivesShow = (props) => {
  const notify = useNotify();

  
  const onFailure = useCallback((error) => {
    console.log("VodLivesShow . onFailure");
    notify(error.message.toString(), "warning");
  });

  return (<>
    <Show {...props} >
        <>
        <Grid container spacing={1} fullWidth>
            <Grid item sm={12} md={4}>
                <SimpleShowLayout>
                    <TextField label="ID" source="event_id" />
                </SimpleShowLayout>
            </Grid>
            <Grid item sm={12} md={4}>
                <SimpleShowLayout>
                    <TextField label="Name in TC" source="name_tc" />
                </SimpleShowLayout>
            </Grid>
            <Grid item sm={12} md={4}>
                <SimpleShowLayout>
                    <TextField label="Name in EN" source="name_en" />
                </SimpleShowLayout>
            </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
            <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                <TextField label="Channel Number" source="channel_no" />
                </SimpleShowLayout>
            </Grid>
            <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                    <TextField source="network_code" />
                </SimpleShowLayout>
            </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
            <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                    <TextField source="sports_topic_code" />
                </SimpleShowLayout>
            </Grid>
            <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                    <TextField source="sports_event_code" />
                </SimpleShowLayout>
            </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
            <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                    <MyDateField source="start_time" showTime />
                </SimpleShowLayout>
            </Grid>
            <Grid item sm={12} md={6}>
                <SimpleShowLayout>
                    <MyDateField source="end_time" showTime />
                </SimpleShowLayout>
            </Grid>
        </Grid>
        </>
        
    </Show>
  </>);
};
