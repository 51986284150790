import { TableCell, TableHead, TableRow } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import * as React from "react";
import { useEffect, useState } from "react";
import { Datagrid, List, RecordContextProvider, SaveButton, Toolbar, useDataProvider } from "react-admin";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { RegionTextField } from "../component/region-components";

const onSave = async (dataProvider, allItems) => {
  if (!allItems) {
    return;
  }

  const allChannelIds = allItems.map((i) => i.id);

  await dataProvider.updateChannelOrder(allChannelIds);

  window.location.reload(true);
};

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const DataGridEmptyView = () => {
  return (
    <div style={{ margin: "16px" }}>
      <p class="MuiTypography-root MuiTypography-body2">No results found</p>
    </div>
  );
};

const MyDatagridRow = ({ allItems, setAllItems, item, pos, ...props }) => {
  return (
    <RecordContextProvider value={item}>
      <TableRow>
        <DragHandle />
        <TableCell style={{ overflowWrap: "break-word" }}>
          <RegionTextField record={item} />
        </TableCell>
        <TableCell style={{ overflowWrap: "anywhere" }}>{item.channel_no}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.channel_display_no}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.network_code}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.name_tc}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.name_en}</TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const SortableItem2 = SortableElement(({ allItems, setAllItems, item, pos, ...props }) => {
  return <MyDatagridRow allItems={allItems} setAllItems={setAllItems} item={item} pos={pos} {...props} />;
});

const MyDatagridBody = ({ allItems, setAllItems, data, ...props }) => {
  useEffect(() => {
    var tags = [];
    if (allItems.length == 0) {
      //If don't have data from API yet
      for (const id of props.ids) {
        tags.push(data[id]);
      }
      setAllItems(tags);
    }
  }, [data, allItems]);

  return allItems.map((item, index) => {
    return <SortableItem2 allItems={allItems} setAllItems={setAllItems} item={item} index={index} pos={index} {...props} />;
  });
};

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell> {/* Drag */}
      <TableCell style={{}}>Region</TableCell>
      <TableCell style={{}}>Ch No</TableCell>
      <TableCell style={{}}>Ch Display No (for Smart TV)</TableCell>
      <TableCell style={{}}>OTT Ch Code</TableCell>
      <TableCell style={{}}>Channel Name in TC</TableCell>
      <TableCell style={{}}>Channel Name in EN</TableCell>
    </TableRow>
  </TableHead>
);

const SortableListContainer = SortableContainer(({ allItems, setAllItems, ...props }) => {
  return (
    <List {...props} pagination={false}>
      <Datagrid header={<DatagridHeader />} body={<MyDatagridBody allItems={allItems} setAllItems={setAllItems} {...props} />} empty={<DataGridEmptyView />} />
    </List>
  );
});

export const ChannelOrderingList = (props) => {
  const dataProvider = useDataProvider();
  const [allItems, setAllItems] = useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllItems((allItems) => arrayMove(allItems, oldIndex, newIndex));
  };

  return (
    <>
      <SortableListContainer {...props} allItems={allItems} setAllItems={setAllItems} onSortEnd={onSortEnd} useDragHandle={true} lockAxis="y" useWindowAsScrollContainer={true} />

      <Toolbar>
        <SaveButton
          label="Save"
          handleSubmitWithRedirect={async () => {
            await onSave(dataProvider, allItems);
          }}
        />
      </Toolbar>
    </>
  );
};
