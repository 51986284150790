import { Add } from "@material-ui/icons";
import { 
    useCallback, useEffect, useState,
} from "react";

import { 
    Edit, 
    SimpleForm,
    SaveButton,
    useRecordContext,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
    useDataProvider,
    SimpleFormIterator,
    EditButton,
    Create,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SelectInput,
    FormDataConsumer,
} from "react-admin";
import { useForm } from "react-final-form";

export const SubCategoryTagSelect = (props) => {
    console.log('SubCategoryTagSelect : props = ');
    console.log(props);

    const form = useForm();
    const dataProvider = useDataProvider();
    useEffect(() => {
        // dataProvider.getTagTypes().then((types) => {
        //   setTagTypes(types.data);
        // });
      }, []);
    
    // const [tagTypes, setTagTypes] = useState([]);

    const onChange = useCallback((event) => {
        console.log('SubCategoryTagSelect . onChange : form = ');
        console.log(form.getState().values);
    });
    
    const onClick = useCallback((event) => {
        console.log('SubCategoryTagSelect . onClick : form = ');
        console.log(form.getState().values);
    });
    
    return (<>
            {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
            {/* <SelectInput fullWidth source="tag_type" choices={tagTypes} label="Type" /> */}
            <TextInput fullWidth source="tag_type" defaultValue="sub_category" label="Type" disabled />
            <FormDataConsumer label="Value" fullWidth>
              {({ formData }) => {

                return (
                  <ReferenceInput
                    fullWidth
                    label="Value"
                    source={"sub_category_tag_id"}
                    reference="mpm-row-tag-autocomplete"
                    filter={{ tag_type: 'sub_category' }}
                    filterToQuery={(searchText) => {
                      if (searchText) {
                        return {
                          name_tc: searchText,
                        };
                      }
                      return {};
                    }}
                  >
                    <AutocompleteInput
                      // source="sub_category_tag_id"
                      
                      fullWidth
                      optionText={(record) => {
                        if (record == null) {
                          return null;
                        }
                        return record.name_tc + " " + record.name_en;
                      }}
                      onChange={onChange}
                      onClick={onClick}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
      
    </>);
}



