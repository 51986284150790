import {
  List, //
  Show,
  Datagrid,
  ShowButton,
  TextField,
  SimpleShowLayout,
  email,
  Filter,
  TextInput,
  downloadCSV,
  SelectInput,
  useListFilterContext,
  useListSortContext,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import jsonExport from "jsonexport/dist";
import dataProvider from "../dataProvider";
import { useState } from "react";
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import { Grid } from "@mui/material";


const ArtistFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
    <SelectInput
      label="Gender"
      source="gender"
      choices={[
        { id: "M", name: "M" },
        { id: "F", name: "F" },
      ]}
      alwaysOn
    />
    <TextInput label="Other Name in TC" source="othername_tc" alwaysOn />
    <TextInput label="Other Name in EN" source="othername_en" alwaysOn />
  </Filter>
);


export const ArtistList = (props) => {
  
  const customParam = {filter: null, sort: null};

  const exporter = async(data) => {

    // popup content
    const content = (
      <Grid container style={{ justifyContent: "center" }} >
        <Grid item sm={12} md={12} >
          <h3 style={{ textAlign: "center" }} >Downloading ... </h3>
        </Grid>
      </Grid>
    );

    // open popup for loading
    PopupboxManager.open({ content, config: {overlayClose: false, escClose: false} });

    // call api
    const BOM = "\uFEFF";
    const {data: data2} = await dataProvider.getAllArtistsList({
      filter: customParam.filter,
      sort: customParam.sort,
    });
    console.log('exporter data.length = ' + data2.length);

    // close popup for loading
    PopupboxManager.close();

    // make file  
    jsonExport(data2, (err, csv) => {
      downloadCSV(`${BOM} ${csv}`, "artists");
      if (err) {
        console.log("Error trying to export list");
      }
    });

  };

  return (
    <List {...props} bulkActionButtons={<AssetBulkActionButtons />} filters={<ArtistFilter />} exporter={exporter}>
      <CustomListDatagrid {...props} customParam={customParam} />
    </List>
  );
};

const CustomListDatagrid = (props)=>{

  const filter = useListFilterContext();
  const sort = useListSortContext();

  props.customParam.filter = filter.filterValues;
  props.customParam.sort = sort.currentSort;

  return (<>
    <PopupboxContainer />
    <Datagrid>
      <TextField label="Name in TC" source="name_tc" />
      <TextField label="Name in EN" source="name_en" />
      <TextField source="gender" />
      <TextField source="birth_year" />
      <TextField source="ref_id" />
      <TextField source="ref_type" />
      <TextField label="Other Name in TC" source="othername_tc" />
      <TextField label="Other Name in EN" source="othername_en" />
      <ShowButton />
    </Datagrid>
  </>);
};

const ArtistTitle = ({ record }) => {
  return <span>Artist {record ? `${record.name_tc}` : ""}</span>;
};

export const ArtistShow = (props) => (
  <Show {...props} title={<ArtistTitle />}>
    <SimpleShowLayout>
      <TextField label="Name in TC" source="name_tc" />
      <TextField label="Name in EN" source="name_en" />
      <TextField source="gender" />
      <TextField source="birth_year" />
      <TextField source="ref_id" />
      <TextField source="ref_type" />
      <TextField label="Other Name in TC" source="othername_tc" />
      <TextField label="Other Name in EN" source="othername_en" />
    </SimpleShowLayout>
  </Show>
);

// export const ArtistEdit = (props) => {
//   const notify = useNotify();
//   const onFailure = (error) => {
//     notify(error.message.toString(), "warning");
//   };
//   return (
//     <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<ArtistTitle />}>
//       <SimpleForm>
//         <TextInput source="name_tc" validate={required()} />
//         <TextInput source="name_en" validate={required()} />
//         <SelectInput
//           label="Gender"
//           source="gender"
//           validate={required()}
//           choices={[
//             { id: "M", name: "M" },
//             { id: "F", name: "F" },
//           ]}
//         />
//         <NumberInput source="birth_year" validate={required()} />
//         <NumberInput source="ref_id" validate={required()} />
//         <TextInput source="ref_type" validate={required()} />
//         <TextInput source="othername_tc" validate={required()} />
//         <TextInput source="othername_en" validate={required()} />
//       </SimpleForm>
//     </Edit>
//   );
// };

// const redirect = () => "/artists";
// export const ArtistCreate = (props) => {
//   const notify = useNotify();
//   const onFailure = (error) => {
//     notify(error.message.toString(), "warning");
//   };
//   return (
//     <Create {...props} onFailure={onFailure}>
//       <SimpleForm redirect={redirect}>
//         <TextInput source="name_tc" validate={required()} />
//         <TextInput source="name_en" validate={required()} />
//         <SelectInput
//           label="Gender"
//           source="gender"
//           validate={required()}
//           choices={[
//             { id: "M", name: "M" },
//             { id: "F", name: "F" },
//           ]}
//         />
//         <NumberInput source="birth_year" validate={required()} />
//         <NumberInput source="ref_id" validate={required()} />
//         <TextInput source="ref_type" validate={required()} />
//       </SimpleForm>
//     </Create>
//   );
// };
