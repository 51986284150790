import { fetchUtils } from "react-admin";
import { apiUrl } from "./constant";
import { setTokens, ereaseTokens, getAccessToken, getAuthHeaders } from "./authUtils";
import { SettingsInputSvideoRounded, SettingsSuggestRounded } from "@mui/icons-material";
import decodeJwt from "jwt-decode";

const httpClient = fetchUtils.fetchJson;

const authProvider = {
  // authentication
  login: async ({ username, password }) => {
    console.log("auth: login");
    const url = `${apiUrl}/auth/login`;
    const { status, json } = await httpClient(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
    });

    if (status < 200 || status >= 300) {
      throw new Error(json.message);
    }
    console.log(json);
    setTokens(json.access_token, json.access_token_expires_in, json.refresh_token);
  },
  checkError: (error) => {
    console.log("auth: checkError", JSON.stringify(error));
    const status = error.status;
    if (status === 401) {
      ereaseTokens();
      return Promise.reject({ message: "session.expired" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async (params) => {
    console.log("auth: checkAuth", JSON.stringify(params));
    const accessToken = await getAccessToken().catch((err) => {
      console.log("checkAuth . getAccessToken : err = ");
      console.log(err.toString());
      window.location = "/#/login";
    });

    return accessToken ? Promise.resolve() : Promise.reject({ message: "login.required" });
  },

  logout: () => {
    console.log("auth: logout");
    ereaseTokens();
    //localStorage.removeItem('access_token');
    //localStorage.removeItem('refresh_token');
    return Promise.resolve();
  },
  getIdentity: () => {
    console.log("auth: getIdentity");
    return Promise.resolve();
  },
  // authorization
  // getPermissions: async (params) => {
  //   console.log("auth: getPermissions", JSON.stringify(params));

  //   const accessToken = await getAccessToken();
  //   const decodedToken = decodeJwt(accessToken);

  //   const url = `${apiUrl}/users/${decodedToken.sub}`;

  //   const { status, json } = await httpClient(url, {
  //     method: "GET",
  //     headers: await getAuthHeaders(),
  //   });

  //   if (status < 200 || status >= 300) {
  //     throw new Error(json.message);
  //   }

  //   if (!json) {
  //     throw new Error("no user is found");
  //   }

  //   //Store current user info to local storage
  //   localStorage.setItem("user", JSON.stringify(json));

  //   // get roles permissions
  //   const roles = json.roles;

  //   const rolePromises = roles.map((r) => {
  //     return _getPermissionsByRole(r);
  //   });

  //   const roleResults = await Promise.all(rolePromises);

  //   var permissions = [];

  //   for (const r of roleResults) {
  //     permissions = permissions.concat(r);
  //   }

  //   return permissions;
  // },
  getPermissions: async (params) => {
    console.log("auth: getPermissions: params = ", JSON.stringify(params));

    const accessToken = await getAccessToken();
    const decodedToken = decodeJwt(accessToken);

    const url = `${apiUrl}/users/info`;

    const { status, json } = await httpClient(url, {
      method: "GET",
      headers: await getAuthHeaders(),
    });

    console.log("auth: getPermissions: response = ")
    console.log({
      'status': status,
      'json': json,
    });
    
    if (status < 200 || status >= 300) {
      throw new Error(json.message);
    }

    if (!json) {
      throw new Error("no user is found");
    }

    //Store current user info to local storage
    // localStorage.setItem("user", JSON.stringify(json));

    // // get roles permissions
    // const roles = json.roles;

    // const rolePromises = roles.map((r) => {
    //   return _getPermissionsByRole(r);
    // });

    // const roleResults = await Promise.all(rolePromises);

    var permissions = json.roles_info.permissions;

    // for (const r of roleResults) {
    //   permissions = permissions.concat(r);
    // }

    return permissions;
  },
  changePassword: async ({ old_password, new_password }) => {
    console.log("auth: login");
    const url = `${apiUrl}/users/change-password`;
    const { status, json } = await httpClient(url, {
      method: "POST",
      body: JSON.stringify({ old_password, new_password }),
    });

    if (status < 200 || status >= 300) {
      throw new Error(json.message);
    }
  },
};

async function _getPermissionsByRole(id) {
  const url = `${apiUrl}/roles/${id}`;

  const { status, json } = await httpClient(url, {
    method: "GET",
    headers: await getAuthHeaders(),
  });

  if (status < 200 || status >= 300) {
    throw new Error(json.message);
  }

  if (!json || !json.permissions) {
    throw new Error("no role is found");
  }

  if (!json.permissions) {
    throw new Error("no permission is found");
  }

  return json.permissions;
}

export default authProvider;
