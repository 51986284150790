import {
  List, //
  ListContextProvider,
  Button,
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  Toolbar,
  TopToolbar,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ListButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useParams, useCallback } from "react";
import { useDataProvider, Loading, useRecordContext } from "react-admin";
import { MenuTreeCreatePopup } from "./menu-tree-create-popup";

// fa icon
import ReactDOM from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas, faTwitter, faFontAwesome);

const customButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid grey",
  margin: "10px",
};

export const ReorderButton = (props) => {
  // console.log('ReorderButton : props = ');
  // console.log(props);

  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td>
            {" "}
            {props.index == 0 ? (
              <Button label="" disabled>
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              </Button>
            ) : (
              <Button
                label=""
                onClick={() => {
                  props.reorderCallback(-1, props.index, props.parentIndex);
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              </Button>
            )}{" "}
          </td>
          <td>
            {" "}
            {props.index == props.total - 1 ? (
              <Button label="" disabled>
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              </Button>
            ) : (
              <Button
                label=""
                onClick={() => {
                  props.reorderCallback(1, props.index, props.parentIndex);
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              </Button>
            )}{" "}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
