import { useEffect, useState } from "react";
import { regex, TextInput } from "react-admin";
import { useForm } from "react-final-form";

export const PagePathInput = (props) => {
    const [pagePath, setPagePath] = useState();
    const form = useForm();
    const getPagePath = ()=>{
      console.log('getPagePath');
      console.log(form.getState().values);
      setPagePath(form.getState().values.path);
    };
    
    useEffect(()=>{
      getPagePath();
    }, [props.dummy]);

    const validate = regex(/^[a-z0-9]*$/, 'Must be lowcase letters and / or numerics.');

    return (<div style={{position: 'relative'}}>
      <TextInput {...props} label="Page Path" source="path" fullWidth onChange={getPagePath} validate={validate} />
      <p style={{position: 'absolute', top: 0, right: '10px', color: 'gray', 'fontStyle': 'italic' }}>Row Path = {pagePath} + "Row title in EN"</p>  
    </div>);

};