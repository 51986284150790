import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  useRecordContext,
  required,
  DeleteWithConfirmButton
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import {
  Grid,
} from "@material-ui/core";

const KeyValueFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Key" source="key" alwaysOn />
  </Filter>
);

export const KeyValueList = (props) => (
    <List {...props} sort={{ field: "created", order: "DESC" }}
      bulkActionButtons={<AssetBulkActionButtons />} filters={<KeyValueFilter />}>
        <Datagrid>
          <TextField source="key" />
          <Values label="Values"/>
          <EditButton />
          <DeleteWithConfirmButton />
        </Datagrid>
    </List>
);

const Values = (props) => {

  const key_value = useRecordContext();
  if(key_value.values instanceof Array){
    return (
        <div>
        {key_value.values.join(', ')}
        </div>
      );
  }else{
    return (
        <div>
        {key_value.values}
        </div>
      );
  }
  
  
}

export const KeyValueEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit
      {...props}
      onFailure={onFailure}
      mutationMode="pessimistic"
      title={<KeyValueTitle />}
    >
      <SimpleForm>
            <TextInput source="values" fullWidth/>
      </SimpleForm>
    </Edit>
  );
};


const KeyValueTitle = ({ record }) => {
  return <span>Key {record ? `${record.key}` : ""}</span>;
};

export const KeyValueCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <TextInput source="key" validate={required()}/>
        <TextInput label='Values (separate by comma ",")' source="values" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
export const KeyValueShow = (props) => (
  <Show {...props} title={<KeyValueTitle />}>
    <SimpleShowLayout>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Labeled label="Key">
              <TextField source="key" />
            </Labeled>
          </Grid>
          <Grid item sm={12} md={6}>
            <Labeled label="Values">
              <TextField source="values" />
            </Labeled>
          </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);