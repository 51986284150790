import { Grid } from "@material-ui/core";
import { Close, Done } from "@material-ui/icons";
import * as React from "react";
import {
    BooleanInput, EditButton
} from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";
import "reactjs-popup/dist/index.css";


export const BooleanInputWithConfirm = (props) => {

    const form = useForm();
  
    return (<BooleanInput onClick={() => {
      
        const formContent = form.getState().values;
        if(formContent.enabled == false) return;

        const content = (
            <div style={{textAlign:"center"}}>
            <Grid item sm={12} md={12}>
                <h2>{props.popupTitle}</h2>
            </Grid>
            <Grid item sm={12} md={12}>
                <br/>
                <p>{"Are you sure?"}</p>
                <br/>
            </Grid>
            <Grid item sm={12} md={12}>
        
                <Grid sm={12} md={12} container justifyContent="space-around">
                    
                    <EditButton 
                    label="Confirm" 
                    icon={<Done />}                          
                    onClick={() => {
                        form.change(props.source, false);
                        PopupboxManager.close();
                    }}
                    />
                    
                    <EditButton
                    label="Cancel"
                    icon={<Close />}
                    onClick={() => {
                        form.change(props.source, true);
                        PopupboxManager.close();
                    }}
                    />
        
                </Grid>
                
            </Grid>
            </div>
        );
    
      PopupboxManager.open({ content, config: {overlayClose: false, escClose: false} });
  
    }} source={props.source} defaultValue={true} />);
  }
  