import { Grid } from "@mui/material";
import { 
    useCallback, useState,
} from "react";
import { 
    Edit, 
    SimpleForm,
    SaveButton,
    useRecordContext,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
    useDataProvider,
    SimpleFormIterator,
    EditButton,
    Create,
} from "react-admin";
import { useForm } from "react-final-form";
import dataProvider from "../../dataProvider";
import { SubCategoryTagSelect } from "./components/subcat-tag-select";

export const FilteringOverwriteEdit = (props) => {    
    console.log('FilteringOverwriteEdit : props = ');
    console.log(props);

    return (<Edit {...props}>
        <Edit2 {...props} />
    </Edit>);
}

const Edit2 = (props) => {

    const record = useRecordContext();
    console.log('FilteringOverwriteEdit2 : record = ');
    console.log(record);


    return (<>
    <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container>
            <Grid item sm={12} md={12}>
                <SubCategoryTagSelect />
            </Grid>
        </Grid>
    </SimpleForm>
    </>);
}

const CustomToolbar = () => {

    const record = useRecordContext();
    const form = useForm();
    const redirect = useRedirect();
    const notify = useNotify();

    const handleSubmitWithRedirect = useCallback(async() => {

        const values = form.getState().values;
        console.log('CustomToolbar . handleSubmitWithRedirect : form = ');
        console.log(values);

        try{
            const result = await dataProvider.updateSubcatFilterOverwrite({
                id: record.id,
                data: values,
            });
            console.log('CustomToolbar . handleSubmitWithRedirect : result = ');
            console.log(result);
            redirect('edit', '/subcat-filter-overwrite-list', record.category_tag_id);
    
        }catch(error){
            console.log('CustomToolbar . handleSubmitWithRedirect : error = ');
            console.log(error.message.toString());

            notify(error.message.toString(), "warning");
        }

        

    });

    return (<Toolbar>
        <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} />
    </Toolbar>);
}