import {
  List, //
  ListContextProvider,
  Button,
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  Toolbar,
  TopToolbar,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ListButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useParams, useCallback } from "react";
import { useDataProvider, Loading, useRecordContext } from "react-admin";
import { MenuTreeCreatePopup } from "./components/menu-tree-create-popup";
import { MenuItemTable } from "./components/menu-tree-table";
import { MenuTreeMassage } from "./components/menu-tree-massage";
import { MenuTreeEdit2 } from "./components/menu-tree-edit-2";
import "./custom.css";

// popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import dataProvider from "../../dataProvider";

const customButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid grey",
  margin: "10px",
};

export const MenuTreeEdit = (props) => {
  console.log("MenuTreeEdit : props = ");
  console.log(props);
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const notify = useNotify();

  // constructor
  const [resp, setResp] = useState({ start_time: "", description: "" });

  // Fetch API
  useEffect(() => {
    dataProvider
      .getOneMenuTree(props.id)
      .then(({ data }) => {
        console.log("MenuTreeEdit : data=");
        console.log(data);
        setResp(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // call PUT API
  const saveMenuTreeCallback = useCallback((params) => {
    console.log("MenuTreeEdit . saveMenuTreeCallback : param1 = ");
    console.log(params);
    
    const rows = params.rows;
    const startTime = params.startTime;
    const description = params.description;

    // massage rows
    const menuTree = MenuTreeMassage(rows);

    const param = {
      menu_tree: menuTree,
      start_time: startTime,
      description: description,
    };
    console.log("param");
    console.log(param);

    dataProvider
      .putMenuTree(props.id, param)
      .then((result) => {
        console.log("MenuTreeEdit . saveMenuTreeCallback . putMenuTree : result");
        console.log(result);
        notify("Update Menu tree is Successful", "success");
        redirectTo("/menu-tree");
      })
      .catch((e) => {
        console.log("MenuTreeEdit . saveMenuTreeCallback . putMenuTree : error");
        console.log(e);
        notify(e.message.toString(), "warning");
      });
  });

  return resp.menu_tree ? ( //
    <MenuTreeEdit2 //
      {...props}
      mode="edit"
      rows={JSON.parse(JSON.stringify(resp.menu_tree))}
      start_time={resp.start_time}
      description={resp.description}
      saveMenuTreeCallback={saveMenuTreeCallback}
    />
  ) : (
    <></>
  );
};
