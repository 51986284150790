import { Close, Done,  } from "@material-ui/icons";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { EditButton, SimpleForm, TextInput, useDataProvider, useNotify } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";

const PopupEditRowFormContent = (props) => {
  const [loading, isLoading] = useState();
  const [dummy, setDummy] = useState(1);
  const form = useForm();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  console.log('PopupEditRowFormContent: props = ');
  console.log(props);

  useEffect(()=>{
    form.change('password', null);
    setDummy(dummy + 1);
  }, [props.dummy2]);

  const onPopupSubmit = async() => {
    isLoading(true);
    try{
      await dataProvider.updateUserPassword({
        id: props.record.id,
        data: {
            password: form.getState().values.password,    
        },        
      });  
      PopupboxManager.close();
      notify("Update user password success", "success");
    }catch(error){
      console.log(error);
      notify(error.message.toString(), "warning");
    }finally{
      isLoading(false);      
    }
  }

  const onChange = ()=>{
    setDummy(dummy + 1);
  }

  return (<>
    <Grid sm={12} md={12} container>
      <Grid item sm={12} md={12}>
        <TextInput label="New Password" source="password" onChange={onChange} />
      </Grid>
    </Grid>
    <Grid item sm={12} md={12}>
      <Grid container justifyContent="space-around" sm={12} md={12}>
      <EditButton
          label="Submit"
          icon={<Done />}
          onClick={onPopupSubmit}
          disabled={(loading || !form.getState().values.password)}
        />
        <EditButton
          label="Cancel"
          icon={<Close />}
          onClick={()=>{
            PopupboxManager.close();
          }}
          disabled={loading}
        />
      </Grid>
    </Grid>
  </>);
}          

export const ChangePasswordPopupContent = (props, dummy2)=>{
    
    return(
        <div style={{textAlign:"center"}}>
            <Grid item sm={12} md={12}>
                <h2>New Password</h2>
            </Grid>
            <Grid item sm={12} md={12}>
                <SimpleForm toolbar={false}>
                    <PopupEditRowFormContent {...props} dummy2={dummy2} />
                </SimpleForm>
            </Grid>
        </div>
    );
}