import Chip from "@material-ui/core/Chip";
import { BulkDeleteButton} from "react-admin";
import { Fragment } from "react";

export const AssetBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} undoable={false} />
  </Fragment>
);

export const StringArrayChipField = ({ record }) => {
  return <Chip linkType={false} label={record} />;
};
