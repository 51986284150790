import { List, Show, Edit, Create, Datagrid, ShowButton, EditButton, TextField, SimpleForm, TextInput, SimpleList, SimpleShowLayout, CheckboxGroupInput, useNotify, required, useGetList, Loading } from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { MyDateField } from "../../MyDateField";
import './custom.css';
const RoleTitle = ({ record }) => {
  return <span>User {record ? `${record.name}` : ""}</span>;
};

export const RoleList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} sort={{ field: "name", order: "ASC" }}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField source="name" />
          <ShowButton />
          <EditButton />
          {/* <DeleteButton /> */}
        </Datagrid>
      )}
    </List>
  );
};

export const RoleShow = (props) => (
  <Show {...props} title={<RoleTitle />}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <PermissionCheckbox disabled={true} />
      </SimpleForm>
  </Show>
);

export const RoleEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<RoleTitle />}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <PermissionCheckbox />
      </SimpleForm>
    </Edit>
  );
};

const redirect = () => "/roles";
export const RoleCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" validate={required()} />
        <PermissionCheckbox />
      </SimpleForm>
    </Create>
  );
};

const PermissionCheckbox = (props) => {
  const { data, loaded } = useGetList("permissions");
  if (!loaded) {
    return <Loading />;
  }

  var choices = Object.values(data);
  return <CheckboxGroupInput validate={required()} source="permissions" choices={choices} className="role-container" disabled={props.disabled} />;
};
