import DragHandle from "@material-ui/icons/DragHandle";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { SortableContainer, SortableElement } from "react-sortable-hoc";



export const SortableComponent = (props) => {
    // console.log('SortableComponent : props');
    // console.log(props);

    return (<>
        <SortableList items={props.rows} onSortEnd={props.onSortEnd} />
    </>);  
}


const SortableList = SortableContainer((props) => {
    // console.log('SortableContainer : props');
    // console.log(props);

    return (
    <Table>
        <DatagridHeader />
        {
            props.items.map((value, index) => (
                <SortableItem key={index} index={index} value={value} />
            ))
        }
    </Table>);
});

const DatagridHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: "50px" }}></TableCell>
        <TableCell style={{ width: "200px" }}>Tag ID</TableCell>
        <TableCell style={{ width: "200px" }}>Type</TableCell>
        <TableCell style={{ width: "200px" }}>Subcat group</TableCell>
        <TableCell style={{ width: "200px" }}>Name in TC</TableCell>
        <TableCell style={{ width: "200px" }}>Name in EN</TableCell>
        <TableCell style={{ width: "50px" }}></TableCell>
      </TableRow>
    </TableHead>
);

const SortableItem = SortableElement((props) => {
    // console.log('SortableElement : props');
    // console.log(props);
    
    const row = props.value;

    return (<TableRow style={{zIndex: 9999, cursor: "pointer"}} >
        <TableCell><DragHandle /></TableCell>
        <TableCell>{row.sub_category_tag_id}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.group}</TableCell>
        <TableCell>{row.name_tc}</TableCell>
        <TableCell>{row.name_en}</TableCell>

    </TableRow>);
});
