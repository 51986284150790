import { FileUpload, FilterAltOff } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import {
  Datagrid, EditButton, Filter, List, SimpleForm, TextField, TextInput, Toolbar, useAuthenticated, useAuthProvider, useAuthState, useLogin, useNotify, usePermissions, useRecordContext
} from "react-admin";
import dataProvider from "../../dataProvider";





const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
  </Filter>
);


// List page
export const FilteringList = (props) => {

  const auth =  useAuthProvider();
  const [canEdit, setCanEdit] = useState(false);
  
  useEffect(async() => {
    const p = await auth.getPermissions();
    
    console.log('FilteringList . useEffect : auth.getPermissions = ');
    console.log(p);
  
    if(p.includes('edit_subcat_filter')){
      setCanEdit(true);
      console.log('FilteringList . useEffect : setCanEdit = true');
    }

  }, []);

  const OverwriteButton = () => {
    const record = useRecordContext();
    console.log('OverwriteButton : record = ');
    console.log(record);

    const onClick = useCallback(() => {
    });
  
    return (
      <EditButton basePath="/subcat-filter-overwrite-list" record={record} label="Overwrite filter" icon={<FilterAltOff />} onClick={(event)=>{
        // event.preventDefault();
        onClick();
      }}/>
    );
  };


  return (
    <List {...props} resource="subcat-filters" bulkActionButtons={false} filters={<CustomFilter />} actions={<CustomListActions canEdit={canEdit} />} >
      <Datagrid>
        {/* <TextField label="Tag ID" source="category_tag_id" /> */}
        <TextField label="Tag ID" source="tag_id" />
        <TextField label="Type" source="type" />
        <TextField label="Name in TC" source="name_tc" />
        <TextField label="Name in EN" source="name_en" />
        <OverwriteButton />
        <EditButton />
      </Datagrid>
    </List>
  );
  
};

const CustomListActions = function (props) {

  const notify = useNotify();
  const [disableButton, setDisableButton] = useState(false);

  console.log("CustomListActions : props = ");
  console.log(props);

  // click Update Filtering
  const onClick = useCallback(async(event)=>{
    
    event.preventDefault();
    console.log('CustomListActions . onClick ');
    setDisableButton(true);
    
    // check import-reqeusts
    const checkFunction = async()=>{
      // console.log('checkFunction');
      const result = await dataProvider.getImportRequestForSubcatFilters();
      console.log('checkFunction : result = ');
      console.log(result);

      if(result && result.data && result.data.status == 'DONE'){
        console.log('checkFunction : DONE');
        notify("Update Filtering success", "success");
        setDisableButton(false);
      }else if(result && result.data && ['NEW', 'IN_PROGRESS'].includes(result.data.status)){
        setTimeout(checkFunction, 1000);
      }else{
        const message = (result && result.data && result.data.message) ? result.data.message : 'Update Filtering ERROR';
        console.log('checkFunction ERROR : result = ');
        console.log(result);
        notify(message, "warning");
        setDisableButton(false);
      }
      
    }

    // POST import-requests
    const result = await dataProvider.postImportRequestForSubcatFilters();

    setTimeout(checkFunction, 1000);

    console.log('CustomListActions . onClick : result = ');
    console.log(result);

  });

  
  return (
    <Toolbar {...props} style={{padding: 0, margin: 0, backgroundColor:"transparent" }} >
      {/* button link to Import Request List page  */}
      <SimpleForm toolbar={false} basePath="" record={{ id: "" }} >
        <EditButton label="Update filtering" icon={<FileUpload />} style={{ width: "unset" }} variant="outlined" onClick={onClick} disabled={!props.canEdit || disableButton}/>
      </SimpleForm>

      {/* default Export Button  */}
      {/* <ExportButton /> */}
    </Toolbar>
  );
};
