import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { SimpleShowLayout, TextField, useRecordContext, useShowContext, useShowController, DeleteButton, DeleteWithConfirmButton, Show, useRedirect } from "react-admin";
import { useForm } from "react-final-form";

export const CustomDeleteHotSearchButton = (props) => {
  // console.log('CustomDeleteHotSearchButton : props = ');
  // console.log(props);

  // Define your custom title of confirm dialog
  const DeleteConfirmTitle = "Delete hot search " + props.record.search + " ?";

  // Define your custom contents of confirm dialog
  const DeleteConfirmContent = (props2) => {
    // console.log('DeleteConfirmContent : props2 = ');
    // console.log(props2);

    return <></>;
  };

  return (
    <DeleteWithCustomConfirmButton
      title={DeleteConfirmTitle} // your custom title of delete confirm dialog
      content={DeleteConfirmContent} // your custom contents of delete confirm dialog
      label="Delete" // label of delete button (default: 'Delete')
      confirmColor="warning" // color of delete button ('warning' or 'primary', default: 'warning')
      ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
      cancel="Cancel" // label of cancel button (default: 'Cancel')
      CancelIcon={ErrorOutline} // icon of cancel button (default: 'ErrorOutline')
      undoable={false} // undoable (default: true)
      resource="hot-searchs"
      record={props.record}
      redirect={() => {
        window.location.reload(true);
      }}
    />
  );
};
