import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput, required, TextInput, useDataProvider } from "react-admin";
import { useForm } from "react-final-form";
import { ShelfDateTimeInput } from "./shelf-date-time-input";
import { TemplateSelectInput } from "./template-select-input";
import { showFormContent } from "./show-form-content";
import { PlatformCheckboxGroupInput } from "../../component/platform-components";
import { RegionCheckboxGroupInput } from "../../component/region-components";

export const EpisodeForm = (props) => {
  const formData = props.formData;
  const rest = props.rest;

  // reset the form into initial values
  const form = useForm();
  const dataProvider = useDataProvider();

  console.log("EpisodeForm : props = ");
  console.log(props);
  console.log("EpisodeForm : form = ");
  console.log(showFormContent(form));

  // check change of programme_id , fetch programme name , fill the field
  useEffect(async () => {
    console.log("EpisodeForm . useEffect :");

    if (formData.programme_id) {
      const result = await dataProvider.getProgrammeByProgrammeId(parseInt(formData.programme_id));

      console.log("EpisodeForm . useEffect : fetchProgramme result");
      console.log(result);

      if (result && result.data && result.data.name_tc) {
        form.change("programme_name", result.data.name_tc);
      }
    }
  }, [formData.programme_id]);

  const fetchProgramme = useCallback(async (filterType) => {
    const result = await dataProvider.getProgrammeByProgrammeId(filterType);

    console.log("EpisodeForm . fetchProgramme : result");
    console.log(result);

    form.change("programme_name", result.data.name_tc);
    form.change("title_tc", result.data.name_tc);
    form.change("title_en", result.data.name_en);
  });

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <AutoCompleteEpisode clearAlwaysVisible={true} fullWidth formData={formData} {...rest} allowEmpty={true} fetchProgramme={fetchProgramme} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Programme Name" source="programme_name" disabled fullWidth />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Programme ID" source="programme_id" disabled validate={required()} fullWidth />
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <TemplateSelectInput layouts={["episode"]} defaultValue={"episode"} isRequired={true} />
      </Grid>

      <Grid item sm={12} md={12}>
        <PlatformCheckboxGroupInput fullWidth {...rest} />
      </Grid>

      <Grid item sm={12} md={12}>
        <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
      </Grid>

      <ShelfDateTimeInput />
    </Grid>
  );
};

const AutoCompleteEpisode = (props) => {
  console.log("AutoCompleteEpisode : props = ");
  console.log(props);

  const form = useForm();

  // useEffect(() => {});

  return (
    <ReferenceInput
      label="Search Programme Name"
      source="episode_autocomplete"
      reference="programmes-autocomplete"
      onChange={(val) => {
        console.log("AutoCompleteEpisode . onChange : val = ");
        console.log(val);

        props.fetchProgramme(val);

        form.change("programme_id", val);
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="programme_id" fullWidth />
    </ReferenceInput>
  );
};
