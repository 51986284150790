import {
  Create, //
  SimpleForm,
  useNotify,
  SelectInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  Toolbar,
  Labeled,
} from "react-admin";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import { Button } from "@material-ui/core";
import { useForm } from "react-final-form";

export const ImagesList = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure} mutationMode="pessimistic">
      <SimpleForm toolbar={<UserEditToolbar />}>
        <ImageName />
        <ImagePlatform />

        <FormDataConsumer>{({ formData, ...rest }) => formData.type && formData.platform && <ImageWidget formData={formData} rest={rest} />}</FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const ImageWidget = ({ formData }) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    setLoading(true);

    dataProvider
      .getImageByNameAndPlatform(formData.type, formData.platform)
      .then(({ data }) => {
        console.log(data);
        setImage(data);

        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [formData.type, formData.platform]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return "";
  }

  if (image) {
    return (
      <>
        <img src={image.image_url} style={{ width: "50%" }} />
        <ImageInput label="Image" source="image_base64">
          <ImageField source="src" />
        </ImageInput>
        <Button
          style={{ backgroundColor: "red", color: "white" }}
          onClick={() => {
            if (formData.image_base64) {
              dataProvider
                .updateImage(image._id, formData)
                .then(({ data }) => {
                  window.location.reload(true);
                })
                .catch((error) => {
                  notify(error.message.toString(), "warning");
                });
            }
          }}
        >
          Save
        </Button>
      </>
    );
  }

  return (
    <>
      <h3>There is no image yet</h3>
      <ImageInput label="Image" source="image_base64">
        <ImageField source="src" />
      </ImageInput>
      <Button
        style={{ backgroundColor: "red", color: "white" }}
        onClick={() => {
          if (formData.image_base64) {
            dataProvider
              .createImage(formData)
              .then(({ data }) => {
                window.location.reload(true);
              })
              .catch((error) => {
                notify(error.message.toString(), "warning");
              });
          }
        }}
      >
        Save
      </Button>
    </>
  );
};

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <></>
  </Toolbar>
);

const ImageName = () => {
  const dataProvider = useDataProvider();
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getKeyValueByKey("image_key")
      .then(({ data }) => {
        setValues(data.values);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return "";
  }
  if (error) {
    return "";
  }

  const choices = values.map((value) => ({
    id: value,
    name: value,
  }));

  return <SelectInput allowEmpty={true} label="Name" source="type" choices={choices} alwaysOn />;
};

const ImagePlatform = () => {
  const dataProvider = useDataProvider();
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getKeyValueByKey("image_platform")
      .then(({ data }) => {
        setValues(data.values);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return "";
  }
  if (error) {
    return "";
  }

  const choices = values.map((value) => ({
    id: value,
    name: value,
  }));

  return <SelectInput allowEmpty={true} source="platform" choices={choices} alwaysOn />;
};
