import { toDate } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { required, SelectInput, TextInput, useDataProvider, useRecordContext } from "react-admin";
import { useForm } from "react-final-form";
import dataProvider from "../../../dataProvider";

export const MCDNAppName = ()=>{

    const [type, setType] = useState([]);
    const [value, setValue] = useState('');
    const [defaultValue, setDefaultValue] = useState(0);
    const dataProvider = useDataProvider();
    const form = useForm();
    const record = useRecordContext();

    useEffect(async()=>{
        console.log('MCDNAppName . useEffect (init) : ');
        const t = await dataProvider.getListMCDNAppNames();
        console.log('MCDNAppName . useEffect (init) : type = ');
        
        const t2 = [];
        t.data.map((item)=>{
            item.values.map((item2)=>{
                t2.push(item2);
            });
        });
        const t3 = t2.map((item,key)=>({
            id: key,
            name: item,
        }));
        console.log(t3);
        console.log(setType(t3));
        if((!record.mcdn_appname) && (t3.length > 0)) form.change('mcdn_appname', t3[0].name);
    },[]);

    useEffect(()=>{
        console.log('MCDNAppName . useEffect (type) : record = ');
        console.log(record);
        console.log('MCDNAppName . useEffect (type) : type = ');
        console.log(type);
    
        if(record && record.mcdn_appname){

            // find key in type
            type.map((item)=>{
                
                if(item.name == record.mcdn_appname){
                    setDefaultValue(item.id);
                    form.change('mcdn_appname', item.name);
    
                }
            });
            
        }
    },[type]);

    useEffect(()=>{

        console.log('useEffect . type : ');

        const values = form.getState().values;

        console.log('useEffect . type : type = ');
        console.log(type);

        console.log('useEffect . type : values = ');
        console.log(values);

        // find key in type
        const item = type.filter((item)=>(item.name == values.mcdn_appname));
        if(item.length > 0){
            // setValue(item.name);
            // tem.name == values.mcdn_appname_select
            form.change('mcdn_appname_select', item[0].id);
        }
        
    
    },[type]);

    const onChange = ()=>{
        console.log('MCDN on change');
        const values = form.getState().values;

        // find key in type
        const item = type.filter((item)=>(item.id == values.mcdn_appname_select));
        console.log('MCDN on change : item = ');
        console.log(item);

        if(item && item[0]){
            // setValue(item.name);
            form.change('mcdn_appname', item[0].name);
            console.log(item[0].name);
        }
    };

return (<>
        <SelectInput label="MCDN App Name" source="mcdn_appname_select" choices={type} trnaslateChoice={false} defaultValue={defaultValue} allowEmpty={false} fullWidth onChange={onChange} validate={required()} />
        {/* <TextInput source="mcdn_appname" disabled  /> */}
    </>);
}
