import { useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  Datagrid, EditButton, Filter, List, TextField, TextInput
} from "react-admin";

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="_id" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Menu ID" source="category_id" alwaysOn />
  </Filter>
);

export const PageRowPathList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} sort={{ field: "_id", order: "DESC" }} bulkActionButtons={false} filters={<PageFilter />} actions={null}>
      <Datagrid>
        <TextField source="id" />
        <TextField label="Name in TC" source="name_tc" />
        <TextField label="Name in EN" source="name_en" />
        <TextField label="Menu ID" source="category_id" />
        <TextField label="Page Path" source="path" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
