import {defaultTheme} from "react-admin";
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';
import { unstable_extendSxProp } from "@mui/system";
import { StayPrimaryLandscape } from "@material-ui/icons";


const theme = {
    ...defaultTheme,
  
    // palette: {
    //   primary: indigo,
    //   secondary: pink,
    //   error: red,
    //   contrastThreshold: 3,
    //   tonalOffset: 0.2,
    // },
    // typography: {
    //     // Use the system font instead of the default Roboto font.
    //     fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    // },
    overrides: {
        RaCreateButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                // backgroundColor: 'red', // Some CSS
            },
            floating: {
                // backgroundColor: 'red', // Some CSS
                backgroundColor: '#2196f3',
                left: '20px',
                right: 'unset',
            },
        },
        MuiInputBase: {
            // root:{
            //     color: 'yellow',
            // },
            input:{
                // color: 'red',
                'text-align': 'left',
            },
        },
        MuiFilledInput: {
            // root:{
            //     color: 'green',
            // },
            input:{
                // color: 'blue',
                'text-align': 'left',
            },
        },
        MuiCard: {
            root: {
                'overflow': 'visible',
            },
        },

    },

  };
  
  export const Customtheme = theme;
