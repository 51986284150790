import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  DeleteWithConfirmButton,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import { MyDateField } from "../MyDateField";

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name tc" source="name_tc" alwaysOn />
  </Filter>
);

const CategoryTopLevelEditButton = (props) => {
  if (!props.record.categories || props.record.categories.length == 0) {
    return <EditButton basePath="/categories" record={{ id: props.record.id }} />;
  }

  return <></>;
};

export const CategoriesList = (props) => (
  <List {...props} sort={{ field: "order", order: "ASC" }} exporter={false} pagination={false} bulkActionButtons={false}>
    <Datagrid isRowExpandable={(row) => row.categories} expand={<PostPanel />}>
      <TextField label="Genre/Category Name in TC" source="name_tc" sortable={false} />
      <TextField label="Genre/Category Name in EN" source="name_en" sortable={false} />
      <MyDateField label="onshelf DateTime" source="start_time" showTime sortable={false} />
      <MyDateField label="offshelf DateTime" source="end_time" showTime sortable={false} />
      <CategoryTopLevelEditButton />
    </Datagrid>
  </List>
);

export const CategoriesEdit = (props) => {
  console.log(props);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getMpmPageByCategoryId(props.id)
      .then(({ data }) => {
        console.log(data);
        window.location.href = "/admin/#/mpm-pages/" + data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <></>;
};

const redirect = () => "/categories";

const CategoriesTitle = ({ record }) => {
  return <span>VIP {record ? `${record.name_tc}` : ""}</span>;
};

export const CategoriesCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name_tc" />
        <TextInput source="name_en" />
      </SimpleForm>
    </Create>
  );
};

export const CategoriesShow = (props) => {
  return <></>;
};

const PostPanel = ({ id, record, resource }) => {
  if (record.categories) {
    return (
      <table class="menu-expand-category">
        {record.categories.map((category) => (
          <tr>
            <td class="menu-child-empty">&nbsp;</td>

            <td class="menu-child-category_name">{category.name_tc}</td>
            <td class="menu-child-category_name_en">{category.name_en}</td>
            <td class="menu-child-category_start">
              <MyDateField source="start_time" showTime />
            </td>
            <td class="menu-child-category_end">
              <MyDateField source="end_time" showTime />
            </td>
            <td class="menu-child-category_edit">
              <EditButton basePath="/categories" record={{ id: category.category_id }} />
            </td>
            <td class="menu-child-category">&nbsp;</td>
          </tr>
        ))}
      </table>
    );
  } else {
    return <></>;
  }
};
