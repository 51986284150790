import {
  List, //
  Create,
  Edit,
  EditButton,
  Datagrid,
  ListContextProvider,
  Pagination,
  TextField,
  Toolbar,
  BooleanInput,
  ArrayInput,
  useRecordContext,
  SaveButton,
  SimpleForm,
  ExportButton,
  DateInput,
  TextInput,
  useNotify,
  SimpleFormIterator,
  Labeled,
  BooleanField,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  required,
  TopToolbar,
  Button,
  Link,
  downloadCSV,
  useListContext,
  useCreate,
  useUpdate,
  useRedirect,
  useUnselectAll,
} from "react-admin";

import { useDataProvider } from "react-admin";
import { useState, useEffect, Fragment, useCallback } from "react";
import { Grid, Typography, TableHead, TableRow, TableCell } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { MyDateField } from "../MyDateField";

const CustomPagination = (props) => {
  return <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;
};

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/ads-adgroups/create`,
        // search: `programme_id=${props.filterValues.programme_id}`,
      }}
      label="+ CREATE"
    />
  </TopToolbar>
);

const StatusButton = ({ record, text, status }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();

  const [update] = useUpdate("ads-adgroups", record.id);

  const handleSave = useCallback(
    async (values, redirect) => {
      console.log("onSave");

      update(
        {
          payload: {
            data: {
              start_date: record.start_date,
              end_date: record.end_date,
              enabled: status,
              add_programme_ids: [],
              remove_programme_ids: [],
            },
          },
        },
        {
          onSuccess: ({ data: newRecord }) => {
            console.log("onSuccess");
            window.location.reload(true);
            // redirectTo("/ads-adgroups");
          },
          onFailure: (error) => {
            console.log("onFailure");
            notify(error.message.toString(), "warning");
          },
        }
      );
    },
    [update, notify, redirectTo]
  );
  return (
    <Button
      onClick={() => {
        handleSave();
      }}
      label={text}
    />
  );
};

export const AdGroupingList = (props) => (
  <List {...props} sort={{ field: "created_at", order: "DESC" }} bulkActionButtons={false} actions={<ListActions />}>
    <Datagrid>
      <TextField label="Ad Grouping" source="name" />
      <TextField label="No. of Program" source="num_of_programmes" />
      <MyDateField label="Start Date" source="start_date" />
      <MyDateField label="End Date" source="end_date" />
      <TextField label="Status" source="status" />
      <EditButton />
      <StatusButton text="Active" status={true} />
      <StatusButton text="Inactive" status={false} />
    </Datagrid>
  </List>
);

export const AdGroupingEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const [newProgrammes, setNewProgrammes] = useState([]);
  const [removedProgrammes, setRemovedProgrammes] = useState([]);

  const CustomToolbar = (props) => {
    const redirectTo = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const [update] = useUpdate("ads-adgroups", props.record.id);

    const handleSave = useCallback(
      async (values, redirect) => {
        console.log("onSave");

        var addProgrammeIds = newProgrammes.map((p) => p.programme_id);
        var removeProgrammeIds = removedProgrammes.map((p) => p.programme_id);

        update(
          {
            payload: {
              data: {
                start_date: values.start_date,
                end_date: values.end_date,
                enabled: values.enabled,
                add_programme_ids: addProgrammeIds,
                remove_programme_ids: removeProgrammeIds,
              },
            },
          },
          {
            onSuccess: ({ data: newRecord }) => {
              console.log("onSuccess");
              redirectTo("/ads-adgroups");
            },
            onFailure: (error) => {
              console.log("onFailure");
              notify(error.message.toString(), "warning");
            },
          }
        );
      },
      [update, notify, redirectTo]
    );

    return (
      <Toolbar {...props}>
        <SaveButton onSave={handleSave} />
      </Toolbar>
    );
  };

  const AdGroupingTitle = ({ record }) => {
    return <span>Ad Grouping {record ? `${record.name}` : ""}</span>;
  };

  return (
    <Edit {...props} onFailure={onFailure}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput label="Group Name (Small letter and number, no space)" source="name" validate={required()} disabled fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <BooleanInput source="enabled" validate={required()} defaultValue={false} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateInput source="start_date" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateInput source="end_date" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={12}>
            <AutoCompleteProgramme />
          </Grid>
          <Grid item sm={12} md={12}>
            <TagInputs />
          </Grid>
          <Grid item sm={12} md={12}>
            <ProgrammeTable newProgrammes={newProgrammes} setNewProgrammes={setNewProgrammes} removedProgrammes={removedProgrammes} setRemovedProgrammes={setRemovedProgrammes} {...props} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const AutoCompleteProgramme = (props) => {
  //   const form = useForm();
  return (
    <ReferenceInput
      label="Search Programme Name or ID"
      source="programme_id"
      reference="programmes-autocomplete"
      onChange={(val) => {}}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

export const AdGroupingCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const [newProgrammes, setNewProgrammes] = useState([]);
  const [removedProgrammes, setRemovedProgrammes] = useState([]);

  const CustomToolbar = (props) => {
    const redirectTo = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [create] = useCreate("ads-adgroups");

    const handleSave = useCallback(
      async (values, redirect) => {
        console.log("onSave");
        console.log(values);
        console.log(newProgrammes);

        var programmeIds = newProgrammes.map((p) => p.programme_id);

        create(
          {
            payload: {
              data: {
                name: values.name,
                start_date: values.start_date,
                end_date: values.end_date,
                enabled: values.enabled,
                programme_ids: programmeIds,
              },
            },
          },
          {
            onSuccess: ({ data: newRecord }) => {
              console.log("onSuccess");
              redirectTo("/ads-adgroups");
            },
            onFailure: (error) => {
              console.log("onFailure");
              notify(error.message.toString(), "warning");
            },
          }
        );
      },
      [create, notify, redirectTo]
    );

    return (
      <Toolbar {...props}>
        <SaveButton onSave={handleSave} />
      </Toolbar>
    );
  };

  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput label="Group Name (Small letter and number, no space)" source="name" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <BooleanInput source="enabled" validate={required()} defaultValue={false} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateInput source="start_date" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateInput source="end_date" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={12}>
            <AutoCompleteProgramme />
          </Grid>
          <Grid item sm={12} md={12}>
            <TagInputs />
          </Grid>
          <Grid item sm={12} md={12}>
            <ProgrammeTable newProgrammes={newProgrammes} setNewProgrammes={setNewProgrammes} removedProgrammes={removedProgrammes} setRemovedProgrammes={setRemovedProgrammes} {...props} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const ChangedProgrammeTable = ({ newProgrammes, setNewProgrammes, removedProgrammes, setRemovedProgrammes }) => {
  const dataProvider = useDataProvider();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  var newProgrammes = newProgrammes ?? [];
  var removedProgrammes = removedProgrammes ?? [];

  const ids = [...newProgrammes, ...removedProgrammes].map((p) => p.id);

  // paginate ids
  const startIndex = (page - 1) * perPage;
  const endIndex = page * perPage;
  const paginatedIds = ids.slice(startIndex, endIndex);

  var data = [];
  for (var programme of newProgrammes) {
    data[programme.id] = { action: "add", programme: programme };
  }
  for (var programme of removedProgrammes) {
    data[programme.id] = { action: "remove", programme: programme };
  }

  var total = newProgrammes.length + removedProgrammes.length;

  const UndoButton = (props) => {
    return (
      <Button
        label="UNDO"
        onClick={() => {
          console.log("undo");
          console.log(props.record);

          var action = props.record.action;
          var programmeId = props.record.programme.programme_id;

          if (action == "add") {
            setNewProgrammes((programmes) => programmes.filter((p) => p.programme_id != programmeId));
          } else if (action == "remove") {
            setRemovedProgrammes((programmes) => programmes.filter((p) => p.programme_id != programmeId));
          }
        }}
      />
    );
  };

  return (
    <>
      <hr />
      <Typography variant="h6">Changed List</Typography>

      <ListContextProvider
        value={{
          data: data,
          ids: paginatedIds,
          total,
          page,
          perPage,
          setPage,
          setPerPage,
          currentSort: { field: "id", order: "ASC" },
          basePath: "/posts",
          resource: "posts",
          selectedIds: [],
        }}
      >
        <Datagrid>
          <UndoButton />
          <TextField source="action" label="Action" />
          <TextField source="programme.name_tc" label="Programme Name" />
          <TextField source="programme.programme_id" label="Programme ID" />
          <BooleanField source="programme.is_sprog" label="Special prog (Y/N)" />
          <TextField source="programme.adgroup_name" label="Other grouping" />
          <MyDateField source="programme.adgroup_start_date" label="Other grouping start date" />
          <MyDateField source="programme.adgroup_end_date" label="Other grouping end date" />
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    </>
  );
};

const ExistingProgrammeTable = ({ removedProgrammes, setRemovedProgrammes, ...props }) => {
  const data = useRecordContext();

  //No Exisiting in Create Mode
  if (!data.id) {
    return <></>;
  }

  const ExistingProgrammeTableBulkActionButtons = (props) => {
    const unselectAll = useUnselectAll();
    const { data } = useListContext(); //Get records in List

    return (
      <Fragment>
        <Button
          label="remove"
          onClick={() => {
            console.log("remove");

            var arr = [];
            for (var id of props.selectedIds) {
              //Don't remove again if already removed
              const isRemoved = removedProgrammes.find((p) => p.id == id);
              if (!isRemoved) {
                if (id in data) {
                  arr.push(data[id]);
                }
              }
            }

            setRemovedProgrammes((programmes) => [...programmes, ...arr]);
            unselectAll(props.resource);
          }}
        />
      </Fragment>
    );
  };

  const exporter = (programmes) => {
    console.log(programmes);
    const BOM = "\uFEFF";

    const forExport = programmes.map((programme) => {
      var p = {};
      p.name_tc = programme.name_tc;
      p.programme_id = programme.programme_id;
      p.is_sprog = programme.is_sprog;
      p.added_date = programme.added_date;
      return p;
    });
    jsonExport(
      forExport,
      {
        headers: ["name_tc", "programme_id", "is_sprog", "added_date"], // order fields in the export
        rename: ["Programme Name", "Programme ID", "Special prog (Y/N)", "Added Date"],
      },
      (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, "program_grouping_list"); // download as 'posts.csv` file
      }
    );
  };

  return (
    <>
      <hr />
      <Typography variant="h6">Program Grouping List</Typography>
      <List {...props} title={<></>} resource="ads-adgroups-programs" pagination={<CustomPagination />} perPage={100} filter={{ id: data.id }} empty={false} actions={<ExportButton />} exporter={exporter} bulkActionButtons={<ExistingProgrammeTableBulkActionButtons />}>
        <Datagrid>
          <TextField source="name_tc" label="Programme Name" />
          <TextField source="programme_id" label="Programme ID" />
          <BooleanField source="is_sprog" label="Special prog (Y/N)" />
          <MyDateField source="added_date" label="Added Date" />
        </Datagrid>
      </List>
    </>
  );
};

const ProgrammeTable = ({ newProgrammes, setNewProgrammes, removedProgrammes, setRemovedProgrammes, ...props }) => {
  const ProgrammeTableBulkActionButtons = (props) => {
    const unselectAll = useUnselectAll();
    const { data } = useListContext(); //Get records in List

    return (
      <Fragment>
        <Button
          label="add"
          onClick={() => {
            console.log("add");

            var arr = [];
            for (var id of props.selectedIds) {
              //Don't add again if already added
              const isAdded = newProgrammes.find((p) => p.id == id);
              if (!isAdded) {
                if (id in data) {
                  arr.push(data[id]);
                }
              }
            }

            setNewProgrammes((programmes) => [...programmes, ...arr]);
            unselectAll(props.resource);
          }}
        />
      </Fragment>
    );
  };

  const exporter = (programmes) => {
    console.log(programmes);
    const BOM = "\uFEFF";

    const forExport = programmes.map((programme) => {
      var p = {};
      p.name_tc = programme.name_tc;
      p.programme_id = programme.programme_id;
      p.is_sprog = programme.is_sprog;
      p.adgroup_name = programme.adgroup_name;
      p.adgroup_start_date = programme.adgroup_start_date;
      p.adgroup_end_date = programme.adgroup_end_date;
      return p;
    });
    jsonExport(
      forExport,
      {
        headers: ["name_tc", "programme_id", "is_sprog", "adgroup_name", "adgroup_start_date", "adgroup_end_date"], // order fields in the export
        rename: ["Programme Name", "Programme ID", "Special prog (Y/N)", "Other Grouping", "Other Grouping Start Date", "Other Grouping End Date"],
      },
      (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, "program_list"); // download as 'posts.csv` file
      }
    );
  };

  return (
    <>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          console.log(formData);

          return (
            <>
              <Typography variant="h6">Program List</Typography>
              <List {...props} title={<></>} syncWithLocation={false} resource="ads-adgroups-programs-search" pagination={<CustomPagination />} perPage={100} filter={{ tags: formData.tags, programme_id: formData.programme_id }} empty={false} actions={<ExportButton />} exporter={exporter} bulkActionButtons={<ProgrammeTableBulkActionButtons />}>
                <Datagrid>
                  <TextField source="name_tc" label="Programme Name" />
                  <TextField source="programme_id" label="Programme ID" />
                  <BooleanField source="is_sprog" label="Special prog (Y/N)" />
                  <TextField source="adgroup_name" label="Other grouping" />
                  <MyDateField source="adgroup_start_date" label="Other grouping start date" />
                  <MyDateField source="adgroup_end_date" label="Other grouping end date" />
                </Datagrid>
              </List>
            </>
          );
        }}
      </FormDataConsumer>
      <ExistingProgrammeTable setRemovedProgrammes={setRemovedProgrammes} removedProgrammes={removedProgrammes} {...props} />
      <ChangedProgrammeTable newProgrammes={newProgrammes} setNewProgrammes={setNewProgrammes} removedProgrammes={removedProgrammes} setRemovedProgrammes={setRemovedProgrammes} />
    </>
  );
};

const TagInputs = ({ formData, rest }) => {
  const dataProvider = useDataProvider();

  const [tagTypes, setTagTypes] = useState([]);

  useEffect(() => {
    dataProvider.getTagTypes().then((types) => {
      setTagTypes(types.data);
    });
  }, []);

  return (
    <ArrayInput source="tags" label="Program Search">
      {/* Add TransitionProps because of bug: https://github.com/marmelab/react-admin/issues/6900 */}
      <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
        {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
        <SelectInput fullWidth source="tag_type" choices={tagTypes} label="Type" />
        <FormDataConsumer label="Value" fullWidth>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            if (!scopedFormData) {
              return <></>;
            }

            return (
              <ReferenceInput
                fullWidth
                label="Value"
                source={getSource("tag_value")}
                reference="mpm-row-tag-autocomplete"
                filter={{ tag_type: scopedFormData.tag_type }}
                filterToQuery={(searchText) => {
                  if (searchText) {
                    return {
                      name_tc: searchText,
                    };
                  }
                  return {};
                }}
              >
                <AutocompleteInput
                  fullWidth
                  optionText={(record) => {
                    if (record == null) {
                      return null;
                    }
                    return record.name_tc + " " + record.name_en;
                  }}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
