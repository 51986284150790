import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  FormDataConsumer,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  DeleteWithConfirmButton,
  SelectInput,
  required,
  useRecordContext,
  TopToolbar,
  Button,
  Link,
  ExportButton,
  CreateButton,
  SaveButton,
  Toolbar,
  FunctionField,
  refreshSaga,
  useRefresh,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import dataProvider from "../dataProvider";

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
    {/* <ExportButton /> */}
  </TopToolbar>
);
const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const EditorialFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID (Type)" source="type" alwaysOn />
    <TextInput label="name" source="name" alwaysOn />
    <TextInput label="page" source="page" alwaysOn />
  </Filter>
);

const LinkToMpm = (props) => {
  return <Link to={`mpm?type=${props.record.type}&name=${props.record.name}`}>Edit</Link>;
};

const LinkToEdit = (props) => {
  return <EditButton label="Rename" {...props} />;
};

export const EditorialList = (props) => {
  const dataProvider = useDataProvider();
  const [pageRows, setPageRows] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getListRowPage("mpm-row-page", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setPageRows(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <List {...props} sort={{ field: "created_at", order: "DESC" }} actions={<ListActions />} bulkActionButtons={false} filters={<EditorialFilter />}>
      <Datagrid>
        <TextField label="ID (Type)" source="type" />
        <TextField source="name" />
        <UseInField label="use_in (page name)" pageRows={pageRows} />
        <LinkToMpm />
        <LinkToEdit />
        <IsPin />
      </Datagrid>
    </List>
  );
};

const IsPin = (props) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  // console.log("IsPined : record = ");
  // console.log(record);

  const onClickToUnPin = async () => {
    await onClickBase(false);
  };

  const onClickToPin = async () => {
    await onClickBase(true);
  };

  const onClickBase = async (b) => {
    // console.log('onClickBase : ' + b);
    const result = await dataProvider.updateEditorialType("", {
      id: record.id,
      data: {
        is_pinned: b,
      },
    });
    // console.log('onClickBase : result = ');
    // console.log(result);

    // update end
    refresh();
  };

  if (record.is_pinned && record.is_pinned == true)
    return (
      <Button label="" onClick={onClickToUnPin}>
        <img src={`/images/pin.png`} title="pinned" style={{ maxHeight: "22.75px" }} />
      </Button>
    );

  return (
    <Button label="" onClick={onClickToPin}>
      <img src={`/images/unpin.png`} title="unpinned" style={{ maxHeight: "22.75px" }} />
    </Button>
  );
};

const UseInField = (props) => {
  var pageNames = [];
  for (const pageRow of props.pageRows) {
    var arr = pageRow.ref.split("/");
    if (arr.length != 2) {
      continue;
    }

    const pageRowEditorialType = arr[1];
    //Match editorial type
    if (pageRowEditorialType == props.record.type) {
      //Get all the pages name
      for (const page of pageRow.pages) {
        pageNames.push(page.name_tc);
      }
    }
  }
  return <div>{pageNames.join(", ")}</div>;
};

export const EditorialEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<EditorialTitle />}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="name" />
        {/* <SelectInput source="type" choices={[
              {id: "editorial_okbuy", name: "editorial_okbuy"},
              {id: "editorial_pick", name: "editorial_pick"},
              {id: "editorial_sponsor", name: "editorial_sponsor"},
              {id: "horserace_1", name: "horserace_1"},
              {id: "horserace_2", name: "horserace_2"},
              {id: "olympics_hk_team", name: "olympics_hk_team"},
              {id: "roger", name: "roger"},
              {id: "roger_pick", name: "roger_pick"},
              {id: "roger_programmeonshelf", name: "roger_programmeonshelf"},
              {id: "editorial_1001", name: "editorial_1001"},
            ]} /> */}
      </SimpleForm>
    </Edit>
  );
};

const EditorialTitle = ({ record }) => {
  return <span>Editorial {record ? `${record.name}` : ""}</span>;
};

export const EditorialCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

export const EditorialShow = (props) => (
  console.log(props),
  (
    <Show {...props} title={<EditorialTitle />}>
      <SimpleShowLayout>
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  )
);
const redirect = (basePath, id, data) => {
  // const datestring = moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]", false);
  return `/mpm?type=${data.type}&name=${data.name}`;
  // return `/mpm?filter={"slot_id":1,"name":"${data.name}","type":"${data.type}"}`;
  // return `/mpm?filter={"slot_id":1,"name":"${data.name}","type":"${data.type}", "previewDate": "${datestring}"}`;
};
