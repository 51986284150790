import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { regex, required, TextInput } from "react-admin";
import { useForm } from "react-final-form";

export const RowPathInput = (props) => {
    const [suffix, setSuffix] = useState();
    const [dummy, setDummy] = useState(1);
    const form = useForm();
    console.log('RowPathInput props = ');
    console.log(props);

    const getSuffix = ()=>{
        console.log('RowPathInput . getSuffix : props = ');
        console.log(props);
        console.log('RowPathInput . getSuffix : form = ');
        console.log(form.getState().values);
        const path = form.getState().values.path;
        if(path && props.page){
          const re = new RegExp('^(' + props.page.path + ')');
          const suffix = path.replace(re,"");
          setSuffix(suffix);

          console.log('RowPathInput . getSuffix : RegExp = ');
          console.log(re);
          console.log('RowPathInput . getSuffix : suffix = ');
          console.log(suffix);
          form.change('path', suffix);

        }else{
          console.log('RowPathInput . getSuffix : No Page No Path');
          setSuffix(path);
        }

    };
      
    useEffect(()=>{
      if(props.page){
        form.change('page_path', props.page.path);
      }
      getSuffix();

    }, []);

    const validate = regex(/^[a-z0-9]*$/, 'Must be lowcase letters and / or numerics.');


    const nameEnOnChange = () => {
      
      const n = form.getState().values.title_en;
      const n2 = n.toLowerCase().replace(/[^a-z0-9]/g,'');
      form.change('path', n2);
      setDummy(dummy + 1);

      setSuffix(n2);

    }

    if(props.disabled == true){
      return (<>

        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>

        <Grid item sm={12} md={12}>
          <TextInput {...props} label="Row Path" source="path" fullWidth />
        </Grid>
      
      </>);
    }

    return (<>

        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth onChange={nameEnOnChange} />
        </Grid>

        <Grid item sm={12} md={12}>

          <div style={{position: 'relative'}}>
            <TextInput {...props} label="Row Path Suffix" source="path" fullWidth onChange={getSuffix} validate={validate} />
            <p style={{position: 'absolute', top: 0, right: '10px', color: 'gray', 'fontStyle': 'italic' }}>Row Path = {props.page ? props.page.path : '"Page Path"'} + {suffix}</p>  
          </div>

        </Grid>

    </>);

}
