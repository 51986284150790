import { Close, Done,  } from "@material-ui/icons";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { EditButton, SimpleForm, TextInput, useDataProvider, useNotify, required } from "react-admin";
import { useForm } from "react-final-form";
import { PopupboxManager } from "react-popupbox";

const PopupEditRowFormContent = (props) => {
  const [loading, isLoading] = useState();
  const [isFieldEmpty, setIsFieldEmpty] = useState();
  
  const form = useForm();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  console.log('PopupEditRowFormContent');
  console.log(props);

  useEffect(()=>{
    console.log('PopupEditRowFormContent: useEffect');
    if(props.record.path){
        form.change("page_path", props.record.path);
    }else{
        form.change("page_path", "");
    }

    if(props.item.path){
        var suffix = props.item.path;
        if(props.record.path){
            const re = new RegExp('^(' + props.record.path + ')');
            suffix = suffix.replace(re,"");
        }
        form.change("suffix", suffix);
        setIsFieldEmpty(suffix == '');
    }else{
        form.change("suffix", "");
        setIsFieldEmpty(true);
    }
  }, [props.dummy]);

  const onChange = useCallback(()=>{
    const suffix = form.getState().values.suffix;
    console.log('PopupEditRowFormContent . onChange : suffix = ' + suffix);
    setIsFieldEmpty(!suffix);
  });

  const onPopupSubmit = async() => {
    isLoading(true);
    try{
      await dataProvider.updatePageRowPath({
        page_id: props.item.id,
        path: form.getState().values.page_path + form.getState().values.suffix,
      });  
      PopupboxManager.close();
      notify("Update row path success", "success");
      props.setDummy2(Date.now());
    }catch(error){
      console.log(error);
      notify(error.message.toString(), "warning");
    }finally{
      isLoading(false);      
    }
  }

  return (<>
    <Grid sm={12} md={12} container>
      <Grid item sm={6} md={6}>
        <TextInput source="page_path" disabled/>
      </Grid>
      <Grid item sm={6} md={6}>
        <TextInput source="suffix" onChange={onChange} />
      </Grid>
    </Grid>
    <Grid item sm={12} md={12}>
      <Grid container justifyContent="space-around" sm={12} md={12}>
      <EditButton
          label="Confirm"
          icon={<Done />}
          onClick={onPopupSubmit}
          disabled={loading || isFieldEmpty}
        />
        <EditButton
          label="Cancel"
          icon={<Close />}
          onClick={()=>{
            PopupboxManager.close();
          }}
          disabled={loading}
        />
      </Grid>
    </Grid>
  </>);
}          

export const rowEditPopupContent = ({item, setDummy2})=>{

    console.log('rowEditPopupContent');

    const formWrap = {
        form: null,
    };

    const dummy = Date.now();

    return (
        <div style={{textAlign:"center"}}>
        <Grid item sm={12} md={12}>
            <h2>Row Path</h2>
        </Grid>
        <Grid item sm={12} md={12}>
            <SimpleForm toolbar={false}>
                <PopupEditRowFormContent item={item} formWrap={formWrap} dummy={dummy} setDummy2={setDummy2}/>
            </SimpleForm>
        </Grid>
        </div>
    )
};