import { DateTimeInput, TextInput } from "react-admin";
import { useForm } from "react-final-form";
var moment = require("moment-timezone");

export const MyDateTimeInput = (props) => {
  const format = (dataDate) => {
    if (!dataDate) {
      return null;
    }

    //Create moment object in UTC+8 timezone
    const d = moment.tz(dataDate, "Asia/Hong_Kong");
    const r = d.format("YYYY-MM-DDTHH:mm");
    // console.log("dataDate = " + dataDate);
    // console.log("format : r=");
    // console.log(r);
    return r;

    // let value = dataDate;
    // if (props.defaultValue) {
    //   value = props.defaultValue;
    // }
    // // console.log("MyDateTimeInput::format " + typeof value + " " + value);

    // if (typeof value == "object") {
    //   //Set by DateTimeInput

    //   const d = moment.tz(value, "Asia/Hong_Kong");
    //   return d.format("YYYY-MM-DDTHH:mm");
    // } else if (typeof value == "string") {
    //   //Set by API
    //   const d = moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).tz("Asia/Hong_Kong");
    //   if (d.isValid()) {
    //     return d.format("YYYY-MM-DDTHH:mm");
    //   }
    // }

    // //Sometimes format is string in YYYY-MM-DDTHH:mm, just ignore this value
    // return value;
  };

  const parse = (dataDate) => {
    if (!dataDate) {
      return null;
    }

    let value = dataDate;
    if (props.defaultValue) {
      value = props.defaultValue;
    }
    // console.log("MyDateTimeInput::parse " + value);
    const d = moment.tz(value, "Asia/Hong_Kong");
    return d.toDate();
  };

  // parse and format for input text "no value"
  const noValueText = "no value";
  const formatText = (data) => {
    if(!data) return noValueText;
    return data;
  };
  const parseText = (text) => {
    if(text == noValueText) return null;
    return text;
  }; 

  // get its value from the form
  const form = useForm();
  const formValues = form.getState().values;

  if(
    props.hasOwnProperty('disabled') && props.disabled != false && 
    (!formValues[props.source])
  ){
    console.log('MyDatetimeInput : render text ');
    return <TextInput {...props}  format={formatText} parse={parseText} />;
  }else{
    return <DateTimeInput {...props} inputProps={{max: "2999-12-31T00:00"}}  parse={(val) => parse(val)} format={(val) => format(val)} />;
  }
};
