import { Grid, TableCell, TableHead, TableRow, useMediaQuery } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Close, Delete, Done } from "@material-ui/icons";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import React, { useCallback, useEffect, useState } from "react";
import { BooleanInput, CheckboxGroupInput, Create, CreateButton, Datagrid, Edit, EditButton, Error, Filter, FormDataConsumer, Link, List, Loading, SaveButton, SimpleForm, TextField, TextInput, Toolbar, TopToolbar, required, useDataProvider, useNotify, useRecordContext } from "react-admin";
import { Field, useForm } from "react-final-form";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { PagePathInput } from "../page-row-paths/components/page-path-input";
import { ImageDropzone } from "./components/image-dropzone";
var moment = require("moment-timezone");

const PageTitle = ({ record }) => {
  return <span>Page {record ? `${record.name_tc}` : ""}</span>;
};

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="_id" alwaysOn />
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
    <TextInput label="Menu ID" source="category_id" alwaysOn />
  </Filter>
);

const PageEditToolbar = (props) => {
  const data = useRecordContext();

  return (
    <Toolbar alwaysEnableSaveButton={true} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const HomepageList = ({ profileClass, ...props }) => {
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getMpmHomepage(profileClass)
      .then(({ data }) => {
        window.location.href = "/admin/#/" + (profileClass == "kids" ? "kids-mpm-home-page" : "mpm-home-page") + "/" + data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <></>;
};

export const HomepageEdit = (props) => {
  return <EditX {...props} />;
};

const ListAction = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  );
};

export const PageList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} sort={{ field: "_id", order: "DESC" }} bulkActionButtons={false} filters={<PageFilter />} actions={<ListAction />}>
      <Datagrid>
        <TextField source="id" />
        <TextField label="Name in TC" source="name_tc" />
        <TextField label="Name in EN" source="name_en" />
        <TextField label="Menu ID" source="category_id" />
        <TextField label="Page Path" source="path" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const SortableList = ({ profileClass, ...props }) => {
  const data = useRecordContext();
  const form = useForm();

  const dataProvider = useDataProvider();
  const [rows, setRows] = useState();
  const [templates, setTemplates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd = " + oldIndex + " " + newIndex);
    var oldItem = rows[oldIndex];
    var newItem = rows[newIndex];

    setRows((rows) => {
      var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row
      var updatedRowIds = updatedRows.map((r) => r.id);
      form.change("rows", updatedRowIds); //Also change form::rows
      return updatedRows;
    });
  };

  const onDelete = (rowId) => {
    setRows((rows) => {
      var updatedRows = rows.filter((row) => row.id !== rowId);
      var updatedRowIds = updatedRows.map((r) => r.id);
      form.change("rows", updatedRowIds); //Also change form::rows
      return updatedRows;
    });
  };

  useEffect(() => {
    console.log("GET mpm-row-templates");
    dataProvider
      .getList("mpm-row-templates", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setTemplates(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    console.log("get mpm-rows " + data.rows);

    var rowIds = data.rows;
    dataProvider
      .getMany("mpm-rows", { ids: rowIds })
      .then(({ data }) => {
        //Result is not in correct order. Reorder result according to data.rows order
        var arr = [];
        for (var rowId of rowIds) {
          var x = data.find((row) => row.id == rowId);
          if (x != null) {
            //Maybe row does not exist anymore
            arr.push(x);
          }
        }

        setRows(arr);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [data.rows.length]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!rows) return null;
  if (!templates) return null;

  return (
    <>
      <TopToolbar>
        <Link to={`/mpm-rows/create?page_id=${data.id}&profile_class=${profileClass}&is_homepage=${data.is_homepage}`}>Create</Link>
      </TopToolbar>
      <SortableListContainer {...props} isHomepage={data.is_homepage} page_id={data.id} templates={templates} items={rows} onSortEnd={onSortEnd} onDelete={onDelete} useDragHandle={true} lockAxis="y" />
    </>
  );
};

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const SortableItem2 = SortableElement(({ isHomepage, item, layoutName, onDelete }) => {
  return <MyDatagridRow isHomepage={isHomepage} item={item} layoutName={layoutName} showDeleteButton={true} showReorderButton={true} onDelete={onDelete} />;
});

const DatagridHeader = ({ children, isHomepage }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell>
      <TableCell style={{ width: "200px" }}>ID</TableCell>
      <TableCell style={{ width: "200px" }}>Row Name</TableCell>
      <TableCell style={{ width: "200px" }}>Title in TC</TableCell>
      <TableCell style={{ width: "200px" }}>Content</TableCell>
      <TableCell style={{ width: "200px" }}>Layout</TableCell>
      <TableCell style={{ width: "200px" }}>RowPath</TableCell>
      <TableCell style={{ width: "200px" }}>Onshelf Datetime</TableCell>
      <TableCell style={{ width: "200px" }}>Offshelf Datetime</TableCell>
      <TableCell style={{ width: "50px" }}></TableCell>
      <TableCell style={{ width: "50px" }}></TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridBody = ({ isHomepage, templates, items, onDelete }) => {
  return items.map((item, index) => {
    var layoutName = "";
    for (const template of templates) {
      if (template.id == item.template) {
        layoutName = template.name;
      }
    }

    //Disallow reordering & delete if is_homepage & first row
    if (isHomepage && index == 0) {
      return <MyDatagridRow isHomepage={isHomepage} item={item} layoutName={layoutName} showDeleteButton={false} showReorderButton={false} />;
    }

    return <SortableItem2 isHomepage={isHomepage} key={item.id} index={index} item={item} layoutName={layoutName} onDelete={() => onDelete(item.id)} />;
  });
};

const MyDatagridRow = ({ isHomepage, item, layoutName, showDeleteButton, showReorderButton, onDelete }) => {
  var content = "";
  if (item.type == "editorial_pick") {
    content = "Editorial";
  } else if (item.type == "event") {
    content = "Event";
  } else if (item.type == "programme_list") {
    content = "ProgrammeList";
  } else if (item.type == "history") {
    content = "WatchingHistory";
  } else if (item.type == "recom_personalised") {
    content = "Recommendation (Personalised)";
  } else if (item.type == "recom_trendy") {
    content = "Recommendation (Trending)";
  } else if (item.type == "recom_universal") {
    content = "Recommendation (Universal list)";
  } else if (item.type == "latest") {
    content = "Latest";
  } else if (item.type == "top_sv") {
    content = "Top SV";
  } else if (item.type == "episode_list") {
    content = "Episode";
  } else if (item.type == "clip_list") {
    content = "Clips";
  } else if (item.type == "category_list") {
    content = "Category List";
  } else if (item.type == "recom_free_preview") {
    content = "Free Episode";
  } else {
    content = item.type;
  }

  var onshelfTime = "";
  var offshelfTime = "";
  if (item.start_time) {
    onshelfTime = moment.tz(item.start_time, "Asia/Hong_Kong").format("YYYY-MM-DD HH:mm");
  }
  if (item.end_time) {
    offshelfTime = moment.tz(item.end_time, "Asia/Hong_Kong").format("YYYY-MM-DD HH:mm");
  }

  // Delete popup
  const rowDeleteOnClick = useCallback(() => {
    PopupboxManager.open({ content: rowDeletePopupContent });
  });
  const rowDeletePopupContent = (
    <div style={{ textAlign: "center" }}>
      <Grid item sm={12} md={12}>
        <h2>Delete Row</h2>
      </Grid>
      <Grid item sm={12} md={12}>
        <br />
        <p>{"Are you sure?"}</p>
        <br />
      </Grid>
      <Grid item sm={12} md={12}>
        <Grid sm={12} md={12} container justifyContent="space-around">
          <EditButton
            label="Confirm"
            icon={<Done />}
            onClick={() => {
              onDelete();
              PopupboxManager.close();
            }}
          />

          <EditButton
            label="Cancel"
            icon={<Close />}
            onClick={() => {
              PopupboxManager.close();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <TableRow>
      {showReorderButton ? <DragHandle /> : <TableCell />}
      <TableCell>{item.id}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.title_tc}</TableCell>
      <TableCell>{content}</TableCell>
      <TableCell>{layoutName}</TableCell>
      <TableCell>{item.path}</TableCell>
      <TableCell>{onshelfTime}</TableCell>
      <TableCell>{offshelfTime}</TableCell>
      <TableCell>
        <EditButton basePath="/mpm-rows" label="Edit" record={{ id: item.id }} />
      </TableCell>
      {showDeleteButton ? (
        <TableCell>
          <EditButton label="DELETE" icon={<Delete />} onClick={rowDeleteOnClick} style={{ color: "red" }} />
          <PopupboxContainer />
        </TableCell>
      ) : (
        <TableCell />
      )}
    </TableRow>
  );
};

const SortableListContainer = SortableContainer(({ ...props }) => {
  const { page_id, isHomepage, templates, items, onDelete } = props;

  return <Datagrid style={{ "table-layout": "fixed" }} header={<DatagridHeader isHomepage={isHomepage} />} body={<MyDatagridBody isHomepage={isHomepage} templates={templates} items={items} onDelete={onDelete} />}></Datagrid>;
});

const CategoryGrid = (props) => {
  const record = useRecordContext();

  const dataProvider = useDataProvider();
  // const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!record.category_id) {
      return;
    }

    dataProvider
      .getMenuTreeCategoryById(record.category_id)
      .then(({ data }) => {
        setCategory(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (!category) {
    return <></>;
  }

  var parentCategory;
  var thisCategory;

  //Max 2 level
  if (category.menu_tree) {
    for (var firstLevel of category.menu_tree) {
      if (firstLevel.category_id == record.category_id) {
        thisCategory = firstLevel;
        break;
      }

      if (firstLevel.categories) {
        for (var secondLevel of firstLevel.categories) {
          if (secondLevel.category_id == record.category_id) {
            thisCategory = secondLevel;
            parentCategory = firstLevel;
            break;
          }
        }
      }
    }
  }

  var status, menuId, parentTc, parentEn, categoryTc, categoryEn;

  var datetime = moment(category.start_time).format("YYYY-MM-DD HH:mm");
  if (category.status == "inactive") {
    status = `This page is inside an inactive menu set [${category.description}] which will be effective on [${datetime}]`;
  } else if (category.status == "active") {
    status = `This page is inside a currently active menu set [${category.description}]`;
  } else if (category.status == "expired") {
    status = `This page is inside an inactive menu set [${category.description}] which is expired on [${datetime}]`;
  }

  menuId = `Menu ID: {record.category_id}`;
  if (parentCategory) {
    parentTc = `Parent in TC: ${parentCategory.name_tc}`;
    parentEn = `Parent in EN: ${parentCategory.name_en}`;
  }
  if (thisCategory) {
    categoryTc = `Category Name in TC: ${thisCategory.name_tc}`;
    categoryEn = `Category Name in EN: ${thisCategory.name_en}`;
  }

  if (parentCategory && thisCategory) {
    return (
      <>
        {status}
        <br />
        <br />
        Menu ID: {record.category_id}
        <br />
        <br />
        {parentTc}
        <br />
        {parentEn}
        <br />
        <br />
        {categoryTc}
        <br />
        {categoryEn}
      </>
    );
  } else if (thisCategory) {
    return (
      <>
        {status}
        <br />
        <br />
        Menu ID: {record.category_id}
        <br />
        <br />
        {categoryTc}
        <br />
        {categoryEn}
      </>
    );
  }

  return (
    <>
      {status}
      <br />
      <br />
      Menu ID: {record.category_id}
    </>
  );
};

const EditX = ({ profileClass, permissions, ...props }) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  const onSuccess = () => {
    window.location.reload(false);
  };

  return (
    <Edit onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" {...props} title={<PageTitle />}>
      <SimpleForm toolbar={<PageEditToolbar />}>
        <Field name="pc" component="input" type="hidden" defaultValue={profileClass} />
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <CategoryGrid />
          </Grid>

          {/* <Grid item sm={12} md={12}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid> */}
          <Grid item sm={12} md={12}>
            <TextInput label="Name in TC" source="name_tc" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput label="Name in EN" source="name_en" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <PagePathInput disabled />
          </Grid>

          <Grid item sm={12} md={12}>
            <TextInput onKeyDown={listenerTC} label="SEO Description in TC (within 85 char)" source="seo_desc_tc" fullWidth defaultValue={""} multiline />
            <DefaultValuetc />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput onKeyDown={listenerEN} label="SEO Description in EN (within 170 char)" source="seo_desc_en" fullWidth defaultValue={""} multiline />
            <DefaultValueEn />
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.is_homepage == false && (
              <>
                <Grid container spacing={1} fullWidth>
                  <Grid item sm={12} md={12}>
                    <TextInput label="Page title in TC (SEO)" source="title_tc" fullWidth />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <TextInput label="Page title in EN (SEO)" source="title_en" fullWidth />
                  </Grid>
                </Grid>

                <Grid container spacing={1} fullWidth>
                  <Grid item sm={12} md={6}>
                    <BooleanInput source="call_ad" label="Call ad" />
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <BooleanInput source="made_for_kid" label="Made for kid" />
                  </Grid>
                </Grid>

                <Grid container spacing={1} fullWidth>
                  <Grid item sm={12} md={12}>
                    <CheckboxGroupInput source="template" label="Template" choices={[{ id: "is_olympic", name: "Olympic" }]} />
                  </Grid>
                </Grid>

                <Grid container spacing={1} fullWidth>
                  <Grid item xs={6} sm={6} md={6}>
                    <ImageDropzone source="zoneimage_app_tc" sourceBase="zoneimage_app_tc_base64" label="ZoneImage App in TC" />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <ImageDropzone source="zoneimage_app_en" sourceBase="zoneimage_app_en_base64" label="ZoneImage App in EN" />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <ImageDropzone source="zoneimage_stb_tc" sourceBase="zoneimage_stb_tc_base64" label="ZoneImage Stb in TC" />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <ImageDropzone source="zoneimage_stb_en" sourceBase="zoneimage_stb_en_base64" label="ZoneImage Stb in EN" />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <ImageDropzone source="zoneimage_web_tc" sourceBase="zoneimage_web_tc_base64" label="ZoneImage Web in TC" />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <ImageDropzone source="zoneimage_web_en" sourceBase="zoneimage_web_en_base64" label="ZoneImage Web in EN" />
                  </Grid>
                </Grid>
              </>
            )
          }
        </FormDataConsumer>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <SortableList profileClass={profileClass} {...props} />
          </Grid>
        </Grid>
        <Field name="is_homepage" component="input" type="hidden" />
      </SimpleForm>
    </Edit>
  );
};

const PageEdit = ({ profileClass, ...props }) => {
  return <EditX profileClass={profileClass} {...props} />;
};

export const PageCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const NameAndPath = () => {
    const form = useForm();
    const [dummy, setDummy] = useState(1);

    const nameEnOnChange = () => {
      console.log("nameEnOnChange");
      console.log();

      const n = form.getState().values.name_en;
      const n2 = n.replace(/[^A-Za-z0-9]/g, "").toLowerCase();
      form.change("path", n2);
      setDummy(dummy + 1);
    };

    return (
      <>
        <Grid item sm={12} md={12}>
          <TextInput label="Name in EN" source="name_en" validate={required()} fullWidth onChange={nameEnOnChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PagePathInput dummy={dummy} />
        </Grid>
      </>
    );
  };

  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect="edit">
        <Grid container spacing={1} fullWidth>
          {/* <Grid item sm={12} md={12}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid> */}
          <Grid item sm={12} md={12}>
            <TextInput label="Name in TC" source="name_tc" validate={required()} fullWidth />
          </Grid>

          <NameAndPath />

          <Grid item sm={12} md={12}>
            <TextInput onKeyDown={listenerTC} label="SEO Description in TC (within 85 char)" source="seo_desc_tc" fullWidth defaultValue={""} multiline />
            <div id="count-tc">
              <span id="start-tc">0</span>/85
            </div>
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput onKeyDown={listenerEN} label="SEO Description in EN (within 170 char)" source="seo_desc_en" fullWidth defaultValue={""} multiline />
            <div id="count-en">
              <span id="start-en">0</span>/170
            </div>
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput label="Page title in TC (SEO)" source="title_tc" fullWidth />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput label="Page title in EN (SEO)" source="title_en" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <BooleanInput source="call_ad" label="Call ad" />
          </Grid>

          <Grid item sm={12} md={6}>
            <BooleanInput source="made_for_kid" label="Made for kid" />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <CheckboxGroupInput source="template" label="Template" choices={[{ id: "is_olympic", name: "Olympic" }]} />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item xs={6} sm={6} md={6}>
            <ImageDropzone source="zoneimage_app_tc" sourceBase="zoneimage_app_tc_base64" label="ZoneImage App in TC" />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ImageDropzone source="zoneimage_app_en" sourceBase="zoneimage_app_en_base64" label="ZoneImage App in EN" />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ImageDropzone source="zoneimage_stb_tc" sourceBase="zoneimage_stb_tc_base64" label="ZoneImage Stb in TC" />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ImageDropzone source="zoneimage_stb_en" sourceBase="zoneimage_stb_en_base64" label="ZoneImage Stb in EN" />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ImageDropzone source="zoneimage_web_tc" sourceBase="zoneimage_web_tc_base64" label="ZoneImage Web in TC" />
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <ImageDropzone source="zoneimage_web_en" sourceBase="zoneimage_web_en_base64" label="ZoneImage Web in EN" />
          </Grid>
        </Grid>

        <Field name="is_homepage" component="input" type="hidden" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};

const listenerEN = (event) => {
  var maxlength = 170;

  document.getElementById("start-en").innerHTML = document.getElementById("seo_desc_en").value.length + 1;
};
const listenerTC = (event) => {
  var maxlength = 85;
  document.getElementById("start-tc").innerHTML = document.getElementById("seo_desc_tc").value.length + 1;
};

const DefaultValueEn = (props) => {
  const page = useRecordContext();
  let count = 0;
  if (page.seo_desc_en && page.seo_desc_en.length) {
    count = page.seo_desc_en.length;
  }
  return (
    <div id="count-en">
      <span id="start-en">{count}</span>/170
    </div>
  );
};
const DefaultValuetc = (props) => {
  const page = useRecordContext();
  let count = 0;
  if (page.seo_desc_tc && page.seo_desc_tc.length) {
    count = page.seo_desc_tc.length;
  }
  return (
    <div id="count-tc">
      <span id="start-tc">{count}</span>/85
    </div>
  );
};

export const GeneralHomepageList = (props) => <HomepageList profileClass="general" {...props} />;
export const KidsHomepageList = (props) => <HomepageList profileClass="kids" {...props} />;

export const GeneralHomepageEdit = (props) => <PageEdit profileClass="general" {...props} />;
export const KidsHomepageEdit = (props) => <PageEdit profileClass="kids" {...props} />;
// export const KidsPageEdit = (props) => <PageEdit profileClass="kids" {...props} />;
export const GeneralPageEdit = (props) => <PageEdit profileClass="general" {...props} />;
