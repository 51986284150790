import {
  List, //
  Show,
  Edit,
  Datagrid,
  ShowButton,
  EditButton,
  TextField,
  SimpleForm,
  TextInput,
  DateTimeInput,
  SimpleList,
  SimpleShowLayout,
  BooleanInput,
  BooleanField,
  ImageField,
  Labeled,
  useNotify,
  required,
  Filter,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

import { Grid } from "@material-ui/core";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { MyDateField } from "../MyDateField";
import { BooleanInputWithConfirm } from "./component/boolean-input-with-confirm";
import { PopupboxContainer } from "react-popupbox";

const EpisodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Programme ID" source="programme_id" alwaysOn />
  </Filter>
);

export const EpisodeList = (props) => {
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;
  return (
    <List {...props} sort={{ field: "episode_id", order: "DESC" }} filters={<EpisodeFilter />}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField label="Episode ID" source="episode_id" />
          <TextField source="episode_no" />
          <TextField label="Name in TC" source="name_tc" />
          <TextField label="Name in EN" source="name_en" />
          <TextField source="is_preview" />
          <BooleanField source="enabled" />
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

const EpisodeTitle = ({ record }) => {
  return <span>Episode {record ? `${record.name_tc}` : ""}</span>;
};

export const EpisodeShow = (props) => (
  <Show {...props} title={<EpisodeTitle />}>
    <SimpleShowLayout>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Episode ID">
            <TextField source="episode_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Name in TC">
            <TextField source="name_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Name in EN">
            <TextField source="name_en" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Desc in TC">
            <TextField source="desc_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Desc in EN">
            <TextField source="desc_en" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Free Start Time">
            <MyDateField source="free_start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Free End Time">
            <MyDateField source="free_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Pay Start Time">
            <MyDateField source="pay_start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Pay End Time">
            <MyDateField source="pay_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Gold Start Time">
            <MyDateField source="gold_start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Gold End Time">
            <MyDateField source="gold_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="APP/Web free start time">
            <MyDateField source="app_web_free_start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="APP/Web free end time">
            <MyDateField source="app_web_free_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="APP/Web first start time">
            <MyDateField source="app_web_first_start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="APP/Web first end time">
            <MyDateField source="app_web_first_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Small">
            <ImageField source="image.small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Medium">
            <ImageField source="image.medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Large">
            <ImageField source="image.large" title="" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Programme ID">
            <TextField source="programme_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Episode No">
            <TextField source="episode_no" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Is Preview">
            <TextField source="is_preview" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Enabled">
            <BooleanField source="enabled" />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export const EpisodeEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<EpisodeTitle />}>
      <SimpleForm toolbar={<PostEditToolbar />}>
        <PopupboxContainer />

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="free_start_time" fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="free_end_time" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="pay_start_time" fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="pay_end_time" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="gold_start_time" fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="gold_end_time" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="app_web_free_start_time" label="APP/Web free start time" fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="app_web_free_end_time" label="APP/Web free end time" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="app_web_first_start_time" label="APP/Web first start time" fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="app_web_first_end_time" label="APP/Web first end time" fullWidth />
          </Grid>
        </Grid>

        <BooleanInputWithConfirm source="enabled" popupTitle="Disable episode?" />
        <TextField source="is_preview" />
      </SimpleForm>
    </Edit>
  );
};

// This Toolbar leave a SaveButton the same as default Toolbar but remove Delete
const PostEditToolbar = function (props) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );
};
