import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  ShowButton,
  EditButton,
  TextField,
  SimpleForm,
  TextInput,
  SimpleList,
  SimpleShowLayout,
  ArrayField,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  useNotify,
  required,
  BooleanField,
  BooleanInput,
  ReferenceInput,

} from "react-admin";
import { AssetBulkActionButtons } from "./components";

export const CodingProfileList = (props) => {
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;
  return (
    <List {...props} bulkActionButtons={<AssetBulkActionButtons />}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField source="name" />
          <ArrayField source="settings">
            <Datagrid>
              <TextField source="platform" />
              <TextField source="coding" />
            </Datagrid>
          </ArrayField>
          <BooleanField source="is_default_video_setting" />
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

const CodingProfileTitle = ({ record }) => {
  return <span>CodingProfile {record ? `${record.name}` : ""}</span>;
};

export const CodingProfileShow = (props) => (
  <Show {...props} title={<CodingProfileTitle />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ArrayField source="settings">
        <Datagrid>
          <TextField source="platform" />
          <TextField source="coding" />
        </Datagrid>
      </ArrayField>
      <BooleanField source="is_default_video_setting" />
    </SimpleShowLayout>
  </Show>
);

export const CodingProfileEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<CodingProfileTitle />}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ArrayInput source="settings">
          <SimpleFormIterator>
             <ReferenceInput label="Platform" source="platform" reference="key-value-by-platform">
                <SelectInput
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
               <ReferenceInput label="Coding" source="coding" reference="key-value-by-coding">
                <SelectInput
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
            
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Default Video Settings" source="is_default_video_setting" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = () => "/coding-profiles";
export const CodingProfileCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" validate={required()} />
        <ArrayInput source="settings">
          <SimpleFormIterator>
             <ReferenceInput label="Platform" source="platform" reference="key-value-by-platform">
                <SelectInput
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
             <ReferenceInput label="Coding" source="coding" reference="key-value-by-coding">
                <SelectInput
                  optionText="name" optionValue="id"
                />
              </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Default Video Settings" source="is_default_video_setting" />
      </SimpleForm>
    </Create>
  );
};
