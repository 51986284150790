import { Add, Close, Delete, Done } from "@material-ui/icons";
import { Grid, ListItemIcon, TableCell, TableHead, TableRow } from "@mui/material";
import { 
    useCallback, useEffect, useState,
} from "react";

import { 
    Edit, 
    SimpleForm,
    SaveButton,
    useRecordContext,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
    useDataProvider,
    SimpleFormIterator,
    EditButton,
    ArrayInput,
    List,
    Datagrid,
    TextField,
    FormDataConsumer,
    DeleteButton,
    DeleteWithConfirmButton,
} from "react-admin";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { useForm } from "react-final-form";
import arrayMove from "array-move";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import dataProvider from "../../dataProvider";

export const FilteringOverwriteList = (props) => {    
    return (<Edit {...props}>
        <Edit2 {...props} />        
    </Edit>);
}

const Edit2 = (props) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [rows, setRows] = useState([]);

    console.log('FilteringOverwriteEdit2 : record = ');
    console.log(record);

    const onSortEnd = async({ oldIndex, newIndex }) => {
      console.log("FilteringOverwriteEdit2 . onSortEnd : " + oldIndex + " -> " + newIndex);

      var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row

      setRows(updatedRows);
      
      const result = await dataProvider.updateSubcatFilterOverwriteOrder({
        id: rows[oldIndex].id,
        data: {
          order: newIndex + 1
        },
      });

      console.log('FilteringOverwriteEdit2 . onSortEnd : result = ');
      console.log(result);

      refreshRows();
    };

    const refreshRows = useCallback(async()=>{

      const result2 = await dataProvider.getOneSubcatFilterOverwriteList('', {id: record.id});
      console.log('FilteringOverwriteEdit2 . refreshRows : result2 = ');
      console.log(result2);
      setRows(result2.data.data);
    });

    useEffect(()=>{
      refreshRows();
    }, []);

    return (<>
      <CustomToolbar rows={rows} refresh={refreshRows} />
      <PopupboxContainer />
      <SimpleForm toolbar={null}>
      <FormDataConsumer>{(props) => {
        console.log('FormDataConsumer : props');
        console.log(props);        
        
        return <>
          <MySortableContainer {...props} rows={rows} setRows={setRows} refreshRows={refreshRows} onSortEnd={onSortEnd} useDragHandle={true} lockAxis="y" />
        </>;
      }}</FormDataConsumer>
      </SimpleForm>

    </>);
}

const CustomToolbar = (props) => {
    const redirectTo = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const record = useRecordContext();


    const [loading, setLoading] = useState(false);

    const onDeleteAllClick = useCallback(()=>{
      console.log('CustomToolbar . onDeleteAllClick');
    
      // popup content
      const content = (
        <div style={{textAlign:"center"}}>
          <Grid item sm={12} md={12}>
            <h2>Delete all overwrite sub_cat filtering</h2>
          </Grid>
          <Grid item sm={12} md={12}>
            <br/>
            <p>{"Are you sure?"}</p>
            <br/>
          </Grid>
          <Grid item sm={12} md={12}>
  
            <Grid sm={12} md={12} container justifyContent="space-around">
            
              <EditButton 
                label="Confirm" 
                icon={<Done />}
                disabled={loading}
                onClick={async(event) => {
                  event.preventDefault();
                  setLoading(true);

                  try{
                    // call api
                    await dataProvider.deleteAllSubcatFilterOverwrite({
                      category_tag_id: record.id,
                    });

                    PopupboxManager.close();
                    setLoading(false);
                    notify('Delete all sub_cat filtering success', 'success');

                    // setTimeout(()=>{
                      // window.location = '/#/subcat-filter-overwrite-list/' + record.id;
                      // redirectTo('/subcat-filter-overwrite-list/' + record.id);
                      props.refresh();
                      
                    // }, 10000);

                  }catch(error){
                    notify(error.message.toString(), 'warning');
                    PopupboxManager.close();
                    setLoading(false);
                  }finally{
                    
                  }

                }}
                />
            
              <EditButton
                label="Cancel"
                icon={<Close />}
                disabled={loading}
                onClick={(event) => {
                  event.preventDefault();
                  PopupboxManager.close();
                }}
              />
  
            </Grid>
          
          </Grid>
        </div>
      );

      // open popup
      PopupboxManager.open({ content, config: {overlayClose: false, escClose: false} });

    });

    return (
      <SimpleForm toolbar={null} >
      <Toolbar {...props} style={{justifyContent:"space-between"}} fullWidth > 
          <div style={{display:'flex'}} >
            <TextInput disabled label="tag ID" source="id" style={{minWidth:'200px'}}/>&emsp;
            <TextInput disabled label="Name in EN" source="tag.name_en" style={{minWidth:'200px'}} />
          </div>
          <div>
            <EditButton variant="outlined" icon={<Delete />} label="Delete all sub_cat filtering" basePath="#" record={null} onClick={onDeleteAllClick} disabled={props.rows.length==0} />
            <EditButton icon={<Add />} label="Create" basePath="/subcat-filter-overwrite-create" record={record} />
          </div>
        </Toolbar>
      </SimpleForm>
    );
  };

  const MySortableContainer = SortableContainer(({formData, rows, setRows, refreshRows}) => {
        
    useEffect(()=>{
      setRows(formData.data);
    }, []);

    return (
      <Datagrid 
      style={{ "tableLayout": "fixed" }} 
      header={<DatagridHeader />} 
      body={
        <MyDatagridBody
        items={rows}
        refreshRows={refreshRows}
        />
      }      
      />      
    );
  });

  const DatagridHeader = ({ children }) => (
    <TableHead>
      <TableRow>
        <TableCell style={{}}></TableCell>
        <TableCell style={{}}>Tag ID</TableCell>
        <TableCell style={{}}>Type</TableCell>
        {/* <TableCell style={{}}>Subcat group</TableCell> */}
        <TableCell style={{}}>Name in TC</TableCell>
        <TableCell style={{}}>Name in EN</TableCell>
        <TableCell style={{}}></TableCell>
        <TableCell style={{}}></TableCell>
      </TableRow>
    </TableHead>
  );

  const MyDatagridBody = (({ items, refreshRows }) => {

    console.log('MyDatagridBody : items = ');
    console.log(items);

    return (items.map((item, index) => {
        return (
          <SortableItem item={item} key={JSON.stringify(item)} index={index} value={item} refreshRows={refreshRows} />
        );
      }));
  });

  const SortableItem = SortableElement(({item, refreshRows}) => {

    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const onDeleteClick = useCallback(()=>{
      
      // popup content
      const content = (
        <div style={{textAlign:"center"}}>
          <Grid item sm={12} md={12}>
            <h2>Delete sub_cat filtering</h2>
          </Grid>
          <Grid item sm={12} md={12}>
            <br/>
            <p>{"Are you sure?"}</p>
            <br/>
          </Grid>
          <Grid item sm={12} md={12}>
  
            <Grid sm={12} md={12} container justifyContent="space-around">
            
              <EditButton 
                label="Confirm" 
                icon={<Done />}
                disabled={loading}
                onClick={async(event) => {
                  event.preventDefault();
                  setLoading(true);

                  try{
                    // call api
                    await dataProvider.deleteSubcatFilterOverwrite({
                      id: item.id,
                    });
                    refreshRows();
                    notify('Delete sub_cat filtering success', 'success');

                  }catch(error){
                    notify(error.message.toString(), 'warning');
                  }finally{
                    PopupboxManager.close();
                    setLoading(false);
                  }

                }}
                />
            
              <EditButton
                label="Cancel"
                icon={<Close />}
                disabled={loading}
                onClick={(event) => {
                  event.preventDefault();
                  PopupboxManager.close();
                }}
              />
  
            </Grid>
          
          </Grid>
        </div>
      );

      // open popup
      PopupboxManager.open({ content, config: {overlayClose: false, escClose: false} });
    });

        
    return (
      <TableRow style={{zIndex: 9999}} >      
        <DragHandle />
        <TableCell style={{'overflowWrap': 'break-word'}}>{item.sub_category_tag_id}</TableCell>
        <TableCell style={{'overflowWrap': 'break-word'}}>{item.type}</TableCell>
        <TableCell style={{'overflowWrap': 'break-word'}}>{item.name_tc}</TableCell>
        <TableCell style={{'overflowWrap': 'break-word'}}>{item.name_en}</TableCell>
        <TableCell>
          <EditButton basePath="/subcat-filter-overwrite-edit" record={{id:item.id}} />
        </TableCell>
        <TableCell>
          <EditButton label="Delete" icon={<Delete />} style={{color:'red'}} onClick={(event)=>{
            event.preventDefault();
            onDeleteClick();
          }}  />
        </TableCell>
      </TableRow>
      
    )
  });

  const DragHandle = SortableHandle(() => (
    <TableCell style={{cursor: "pointer"}}>
      <ListItemIcon>
        <DragHandleIcon />
      </ListItemIcon>
    </TableCell>
  ));
  