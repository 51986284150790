var moment = require("moment-timezone");

export const getJsonFromQuery = (href) => {
  const result = {};

  const array = href.split("?");
  // console.log('array = ');
  // console.log(array);

  if (array.length > 1) {
    const array2 = array[1].split("&");
    // console.log('array2 = ');
    // console.log(array2);

    for (const item of array2) {
      const array3 = item.split("=");
      if (array3.length > 1) {
        result[array3[0]] = array3[1];
      }
    }
  }

  return result;
};

export const convert_IdToId = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(convert_IdToId);
  } else if (typeof obj === "object" && obj !== null) {
    if (obj instanceof Date) {
      return obj;
    }

    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key === "_id" ? "id" : key;
      acc[newKey] = convert_IdToId(obj[key]);
      return acc;
    }, {});
  } else {
    return obj;
  }
};

export const convertIdTo_Id = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(convertIdTo_Id);
  } else if (typeof obj === "object" && obj !== null) {
    if (obj instanceof Date) {
      return obj;
    }

    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key === "id" ? "_id" : key;
      acc[newKey] = convertIdTo_Id(obj[key]);
      return acc;
    }, {});
  } else {
    return obj;
  }
};

export const getIMStatus = (start_time, end_time) => {
  const now = moment.utc();

  const startTime = moment.utc(start_time);
  const endTime = moment.utc(end_time);

  if (now < startTime) {
    return "preview";
  } else if (now >= startTime && now < endTime) {
    return "live";
  } else if (now >= endTime) {
    return "end";
  }
};
