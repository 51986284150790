import * as React from "react";
import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  TopToolbar,
  Link,
  ExportButton,
  ShowButton,
  Labeled,
  EditButton,
  TextField,
  NumberField,
  BooleanField,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  DeleteButton,
  SaveButton,
  SimpleList,
  SimpleShowLayout,
  useNotify,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  RadioButtonGroupInput,
  SelectField,
  FormDataConsumer,
  useCreate,
  useRedirect,
} from "react-admin";
import { Grid, useMediaQuery, Button } from "@material-ui/core";
import { AssetBulkActionButtons } from "./components";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { useDataProvider, Loading } from "react-admin";
import { useState, useEffect, useCallback } from "react";
import { useForm, Form, Field } from "react-final-form";
import { MyDateField } from "../MyDateField";
// popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Add } from "@material-ui/icons";
var moment = require("moment-timezone");

const customButtonStyle = {
  backgroundColor: "transparent",
  border: "1px solid grey",
  margin: "10px",
};

const ChannelFilter = ({ showAllOnChange, previewTimeOnChange, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label="OTT Ch Code" source="network_code" alwaysOn />
      <TextInput label="Ch No" source="channel_no" alwaysOn />
      <MyDateTimeInput label="Preview Date" source="preview_time" onChange={previewTimeOnChange} alwaysOn required={true} />
      <BooleanInput label="Show All Channels" source="show_all" defaultValue={false} alwaysOn onChange={showAllOnChange} />
    </Filter>
  );
};

const ListActions = (props) => {
  const ChannelCreatePopup = ({ close, ...props }) => {
    const CustomToolbar = (props) => {
      const redirectTo = useRedirect();
      const notify = useNotify();
      const dataProvider = useDataProvider();
      const [create] = useCreate("channel-no-list");

      const handleSave = useCallback(
        async (values, redirect) => {
          console.log("onSave");
          console.log(values);
          create(
            {
              payload: {
                data: {
                  channel_no: parseInt(values.channel_no),
                },
              },
            },
            {
              onSuccess: ({ data: newRecord }) => {
                console.log("onSuccess");
                redirectTo(`/channels?filter=${JSON.stringify({ channel_no: newRecord.channel_no })}`);
              },
              onFailure: (error) => {
                console.log("onFailure");
                notify(error.message.toString(), "warning");
              },
            }
          );
        },
        [create, notify, redirectTo]
      );

      return (
        <Toolbar {...props}>
          <Button onClick={close}>Cancel</Button>
          <SaveButton onSave={handleSave} label="Create" />
        </Toolbar>
      );
    };

    return (
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput label="Channel No" source="channel_no" validate={required()} fullWidth />
      </SimpleForm>
    );
  };

  return (
    <TopToolbar>
      <Popup
        trigger={
          <Button color="primary" startIcon={<Add />}>
            Create
          </Button>
        }
        modal
        closeOnDocumentClick={false}
      >
        {(close) => <ChannelCreatePopup close={close} {...props} />}
      </Popup>
      {/* <ExportButton /> */}
    </TopToolbar>
  );
};

export const ChannelNoList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const MyEditButton = ({ record }) => {
    return <Link to={`/channels?filter=${JSON.stringify({ channel_no: record.channel_no })}`}> Edit</Link>;
  };

  const [previewDate, setPreviewDate] = useState(moment.tz("Asia/Hong_Kong")); //ALWAYS Store in UTC+8 timezone
  // const [datestring, setDatestring] = useState(moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]", false));
  const [showAll, setShowAll] = useState(false);

  const previewTimeOnChange = (e, val) => {
    //Note: This is HK time, create moment object in HK time
    var x = moment.tz(e.target.value, "Asia/Hong_Kong");
    setPreviewDate(x);
    // setDatestring(moment(e.target.value).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]", false));
  };

  const showAllOnChange = (e, val) => {
    setShowAll(e);
  };

  const refreshData = () => {
    //NOTE: This doesn't work, because preview_time is set by filterDefaultValues
    setPreviewDate(moment.tz("Asia/Hong_Kong"));

    // setDatestring(moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]", false));
    // console.log("ChannelNoList . refreshData : datestring = ");
    // console.log(datestring);
    // console.log(moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]", false));
  };

  // listen to refresh button on App Bar
  useEffect(() => {
    window.addEventListener("appBarRefreshButtonOnClick", function (event) {
      if (refreshData) {
        console.log("ChannelNoList . refreshData");
        refreshData();
      }
    });
  }, []);

  return (
    <List
      {...props} //
      sort={{ field: "channel_no", order: "ASC" }}
      actions={<ListActions />}
      bulkActionButtons={<AssetBulkActionButtons />}
      filters={<ChannelFilter showAllOnChange={showAllOnChange} previewTimeOnChange={previewTimeOnChange} />}
      filterDefaultValues={{ preview_time: previewDate.format("YYYY-MM-DDTHH:mm"), show_all: showAll }}
    >
      {isSmall ? (
        <Datagrid rowClick="edit">
          <NumberField label="Ch No" source="channel_no" />
          <TextField label="Channel Name in TC" source="name_tc" />
          <BooleanField source="enabled" />
        </Datagrid>
      ) : (
        <Datagrid>
          <NumberField label="Ch No" source="channel_no" sortable={false} />
          <TextField label="OTT Ch Code" source="channel.network_code" sortable={false} />
          <TextField label="Channel Name in TC" source="channel.name_tc" sortable={false} />
          <TextField label="Channel Name in EN" source="channel.name_en" sortable={false} />
          <MyDateField label="Start Time" source="channel.start_time" sortable={false} showTime />
          <MyDateField label="End Time" source="channel.end_time" sortable={false} showTime />
          <ImageField label="Image (Landscape)" source="channel.landscape_poster" sortable={false} />
          <ImageField label="Image (Portrait)" source="channel.portrait_poster" sortable={false} />
          <MyEditButton />
        </Datagrid>
      )}
    </List>
  );
};
