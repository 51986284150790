import {
  List, //
  Create,
  Edit,
  Button,
  CloneButton,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  TopToolbar,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Pagination,
  Labeled,
  ListButton,
  DeleteWithConfirmButton,
  useRecordContext,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import "./custom.css";

// custom delete button with confirm
import DeleteWithCustomConfirmButton from "ra-delete-with-custom-confirm-button";
import Delete from "@material-ui/icons/Delete";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { MyDateField } from "../../MyDateField";

// Define your custom title of confirm dialog
const DeleteConfirmTitle = "Delete Menu Tree Set";

// Define your custom contents of confirm dialog
const DeleteConfirmContent = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <span>Are you sure to delete the menu tree of </span>
      <MyDateField source="start_time" showTime label="" />
      <span>?</span>
    </SimpleShowLayout>
  );
};

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name tc" source="name_tc" alwaysOn />
  </Filter>
);

const CategoryTopLevelEditButton = (props) => {
  if (!props.record.categories || props.record.categories.length == 0) {
    return <EditButton basePath="/categories" record={{ id: props.record.id }} />;
  }

  return <></>;
};

export const MenuTreeList = (props) => {
  console.log("MenuTreeList : props");
  console.log(props);
  return (
    <List {...props} actions={<ListActions />} sort={{ field: "start_time", order: "DESC" }} exporter={false} pagination={<CustomPagination />} perPage={10} bulkActionButtons={false}>
      <Datagrid className="menu-tree-list">
        <EditButton />

        {/* custom delete */}
        <DeleteWithCustomConfirmButton
          title={DeleteConfirmTitle} // your custom title of delete confirm dialog
          content={DeleteConfirmContent} // your custom contents of delete confirm dialog
          label="Delete" // label of delete button (default: 'Delete')
          confirmColor="warning" // color of delete button ('warning' or 'primary', default: 'warning')
          ConfirmIcon={Delete} // icon of delete button (default: 'Delete')
          cancel="Cancel" // label of cancel button (default: 'Cancel')
          CancelIcon={ErrorOutline} // icon of cancel button (default: 'ErrorOutline')
          undoable={false} // undoable (default: true)
        />

        <CustomCloneButton record={props.record} />
        <MyDateField label="Effective Time" source="start_time" showTime sortable={false} />
        <TextField label="Description" source="description" sortable={false} />
        <TextField label="Current Active" source="status" sortable={false} />
      </Datagrid>
    </List>
  );
};

const ListActions = (props) => (
  <TopToolbar>
    <ListButton label="Preview" basePath="/menu-tree/preview" />
  </TopToolbar>
);

const CustomPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 50, 100, 200]} {...props} />;
};

const CustomCloneButton = (props) => {
  // console.log("CustomCloneButton : props = ");
  // console.log(props);

  const onClick = (event) => {
    console.log("CustomCloneButton . onClick");
    event.preventDefault();
    event.stopPropagation();

    var url = "/#/menu-tree/create?source=";
    const model = JSON.parse(JSON.stringify(props.record));
    delete model.start_time;
    delete model.description;
    url += encodeURIComponent(JSON.stringify(model));

    window.location.href = url;
  };

  return <CloneButton onClick={onClick} />;
};
