import {
  List, //
  Show,
  Datagrid,
  ShowButton,
  TextField,
  TextInput,
  SimpleList,
  SimpleShowLayout,
  Labeled,
  Filter,
  downloadCSV,
  useListFilterContext,
  useListSortContext,
} from "react-admin";

import { Grid } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { useState } from "react";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import dataProvider from "../dataProvider";

const TagsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
    <TextInput label="Type" source="type" alwaysOn />
    <TextInput label="Tag ID" source="tag_id" alwaysOn />
  </Filter>
);

export const TagsList = (props) => {

  const customParam = {filter: null, sort: null};

  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;

  const exporter = async(data) => {

    // popup content
    const content = (
      <Grid container style={{ justifyContent: "center" }} >
        <Grid item sm={12} md={12} >
          <h3 style={{ textAlign: "center" }} >Downloading ... </h3>
        </Grid>
      </Grid>
    );

    // open popup for loading
    PopupboxManager.open({ content, config: {overlayClose: false, escClose: false} });

    // call api
    const BOM = "\uFEFF";
    const {data: data2} = await dataProvider.getAllTagsList({
      filter: customParam.filter,
      sort: customParam.sort,
    });
    console.log('exporter data.length = ' + data2.length);

    // close popup for loading
    PopupboxManager.close();

    // make file  
    jsonExport(data2, (err, csv) => {
      downloadCSV(`${BOM} ${csv}`, "tags");
      if (err) {
        console.log("Error trying to export list");
      }
    });
    
  };

  return (
    <List {...props} sort={{ field: "tag_id", order: "DESC" }} filters={<TagsFilter />} exporter={exporter}>
      {isSmall ? (
        <CustomSimpleList customParam={customParam} primaryText={(record) => record.name_tc} />
      ) : (
        <CustomListDatagrid customParam={customParam} />        
      )}
    </List>
  );
};

const CustomSimpleList = (props)=>{

  const filter = useListFilterContext();
  const sort = useListSortContext();

  props.customParam.filter = filter.filterValues;
  props.customParam.sort = sort.currentSort;

  return (<>
    <PopupboxContainer />
    <SimpleList primaryText={props.primaryText} />
  </>);
};

const CustomListDatagrid = (props)=>{

  const filter = useListFilterContext();
  const sort = useListSortContext();

  props.customParam.filter = filter.filterValues;
  props.customParam.sort = sort.currentSort;

  return (<>
    <PopupboxContainer />
    <Datagrid>
    <TextField label="Tag ID" source="tag_id" />
      <TextField source="type" />
      <TextField label="Sub-cat group" source="group" />
      <TextField label="Name in TC" source="name_tc" />
      <TextField label="Name in EN" source="name_en" />
      <ShowButton />
    </Datagrid>
  </>);
};

const TagsTitle = ({ record }) => {
  return <span>Tag {record ? `${record.name_tc}` : ""}</span>;
};

export const TagsShow = (props) => (
  <Show {...props} title={<TagsTitle />}>
    <SimpleShowLayout>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Tag ID">
            <TextField source="tag_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Type">
            <TextField source="type" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Name in TC">
            <TextField source="name_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Name in EN">
            <TextField source="name_en" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Sub-cat group">
            <TextField source="group" />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);
