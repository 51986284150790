import React, { useState, useEffect, useCallback } from "react";
import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  ShowButton,
  Labeled,
  EditButton,
  TextField,
  NumberField,
  SaveButton,
  BooleanField,
  useRecordContext,
  Link,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  SimpleList,
  SimpleShowLayout,
  useNotify,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DeleteWithConfirmButton,
  TopToolbar,
  DatagridBody,
  CreateButton,
  FormDataConsumer,
  CardActions,
} from "react-admin";
import { Grid, useMediaQuery, ListItem, Divider, ListItemText, makeStyles } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { AssetBulkActionButtons } from "../components";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { useForm, Form, Field } from "react-final-form";
import { useDataProvider, Loading, Error } from "react-admin";
import arrayMove from "array-move";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { TableHead, TableRow, TableCell, Button } from "@material-ui/core";
import { ImageDropzone } from "./components/image-dropzone";
import { Close, Delete, Done } from "@material-ui/icons";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
var moment = require("moment-timezone");

const PageTitle = ({ record }) => {
  return <span>Page {record ? `${record.name}` : ""}</span>;
};

const PageFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="_id" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Menu ID" source="category_id" alwaysOn />
  </Filter>
);

const PageEditToolbar = (props) => {
  const data = useRecordContext();

  return (
    <Toolbar alwaysEnableSaveButton={true} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const EmarsysTitleHomepageList = (props) => {
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getMpmHomepage("general")
      .then(({ data }) => {
        window.location.href = "/admin/#/emarsys-title/" + data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <></>;
};

const ListAction = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  );
};

// const PageList = (props) => {
//   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
//   return (
//     <List {...props} sort={{ field: "_id", order: "DESC" }} bulkActionButtons={false} filters={<PageFilter />} actions={<ListAction />}>
//       <Datagrid>
//         <TextField source="id" />
//         <TextField source="name" />
//         <TextField label="Menu ID" source="category_id" />
//         <EditButton />
//       </Datagrid>
//     </List>
//   );
// };

const SortableList = ({ ...props }) => {
  const data = useRecordContext();
  const form = useForm();

  const dataProvider = useDataProvider();
  const [rows, setRows] = useState();
  const [templates, setTemplates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [dummy, setDummy] = useState(1);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log("onSortEnd = " + oldIndex + " " + newIndex);
    var oldItem = rows[oldIndex];
    var newItem = rows[newIndex];

    setRows((rows) => {
      var updatedRows = arrayMove(rows, oldIndex, newIndex); //Reorder Row
      var updatedRowIds = updatedRows.map((r) => r.id);
      form.change("rows", updatedRowIds); //Also change form::rows
      return updatedRows;
    });
  };

  const onPopupConfirm = () => {
    console.log("onPopupConfirm");
    setDummy(dummy + 1);
  };

  useEffect(() => {
    console.log("GET mpm-row-templates");
    dataProvider
      .getList("mpm-row-templates", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setTemplates(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    console.log("get mpm-rows " + data.rows);

    var rowIds = data.rows;
    dataProvider
      .getMany("mpm-rows", { ids: rowIds })
      .then(({ data }) => {
        //Result is not in correct order. Reorder result according to data.rows order
        var arr = [];
        for (var rowId of rowIds) {
          var x = data.find((row) => row.id == rowId);
          if (x != null) {
            //Maybe row does not exist anymore
            arr.push(x);
          }
        }

        setRows(arr);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [data.rows.length, dummy]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!rows) return null;
  if (!templates) return null;

  return (
    <>
      <SortableListContainer {...props} isHomepage={data.is_homepage} page_id={data.id} templates={templates} items={rows} onSortEnd={onSortEnd} onPopupConfirm={onPopupConfirm} useDragHandle={true} lockAxis="y" />
    </>
  );
};

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const SortableItem2 = SortableElement(({ isHomepage, item, layoutName, onPopupConfirm }) => {
  return <MyDatagridRow key={item.id} isHomepage={isHomepage} item={item} layoutName={layoutName} showDeleteButton={true} showReorderButton={true} onPopupConfirm={onPopupConfirm} />;
});

const DatagridHeader = ({ children, isHomepage }) => (
  <TableHead>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Row Name</TableCell>
      <TableCell>Title in TC</TableCell>
      <TableCell>Content</TableCell>
      <TableCell>Layout</TableCell>
      <TableCell>Emarsys Title</TableCell>
      <TableCell>Onshelf Datetime</TableCell>
      <TableCell>Offshelf Datetime</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
);

const MyDatagridBody = ({ isHomepage, templates, items, onPopupConfirm }) => {
  return items.map((item, index) => {
    var layoutName = "";
    for (const template of templates) {
      if (template.id == item.template) {
        layoutName = template.name;
      }
    }

    return <SortableItem2 isHomepage={isHomepage} key={item.id} index={index} item={item} layoutName={layoutName} onPopupConfirm={() => onPopupConfirm(item.id)} />;
  });
};

const MyDatagridRow = ({ isHomepage, item, layoutName, showDeleteButton, showReorderButton, onPopupConfirm }) => {
  var content = "";
  if (item.type == "editorial_pick") {
    content = "Editorial";
  } else if (item.type == "event") {
    content = "Event";
  } else if (item.type == "programme_list") {
    content = "ProgrammeList";
  } else if (item.type == "history") {
    content = "WatchingHistory";
  } else if (item.type == "recom_personalised") {
    content = "Recommendation (Personalised)";
  } else if (item.type == "recom_trendy") {
    content = "Recommendation (Trending)";
  } else if (item.type == "recom_universal") {
    content = "Recommendation (Universal list)";
  } else if (item.type == "recom_free_preview") {
    content = "Recommendation (Free Episode)";
  } else if (item.type == "latest") {
    content = "Latest";
  } else if (item.type == "top_sv") {
    content = "Top SV";
  } else if (item.type == "episode_list") {
    content = "Episode";
  } else if (item.type == "clip_list") {
    content = "Clips";
  } else if (item.type == "category_list") {
    content = "Category List";
  }

  var onshelfTime = "";
  var offshelfTime = "";
  if (item.start_time) {
    onshelfTime = moment.tz(item.start_time, "Asia/Hong_Kong").format("YYYY-MM-DD HH:mm");
  }
  if (item.end_time) {
    offshelfTime = moment.tz(item.end_time, "Asia/Hong_Kong").format("YYYY-MM-DD HH:mm");
  }

  // edit popup
  const rowEditOnClick = useCallback(() => {
    PopupboxManager.open({ content: rowEditPopupContent, config: { overlayClose: false, escClose: false } });
  });

  const EmarsysTitlePopupFormContent = () => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      form.change("path", item.path);
    }, []);

    return (
      <>
        <Grid item sm={12} md={12}>
          <TextInput label="Emarsys Title" source="path" />
        </Grid>
        <Grid item sm={12} md={12}>
          <Grid sm={12} md={12} container justifyContent="space-around">
            <EditButton
              disabled={isLoading}
              label="Confirm"
              icon={<Done />}
              onClick={async () => {
                setIsLoading(true);

                try {
                  await dataProvider.updateMpmRowEmarsysTitle({ data: { path: form.getState().values.path } }, item);
                  onPopupConfirm();
                  PopupboxManager.close();
                } catch (e) {
                  notify(e.message.toString(), "warning");
                } finally {
                  setIsLoading(false);
                }
              }}
            />

            <EditButton
              disabled={isLoading}
              label="Cancel"
              icon={<Close />}
              onClick={() => {
                PopupboxManager.close();
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const rowEditPopupContent = (
    <div style={{ textAlign: "center" }}>
      <Grid item sm={12} md={12}>
        <h2>Emarsys Title</h2>
      </Grid>
      <SimpleForm toolbar={null}>
        <EmarsysTitlePopupFormContent />
      </SimpleForm>
    </div>
  );

  return (
    <TableRow>
      <TableCell style={{ overflowWrap: "break-word" }}>{item.id}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{item.name}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{item.title_tc}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{content}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{layoutName}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{item.path}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{onshelfTime}</TableCell>
      <TableCell style={{ overflowWrap: "break-word" }}>{offshelfTime}</TableCell>
      <TableCell>
        {["editorial_pick", "latest", "top_sv"].includes(item.type) ? (
          <EditButton
            label="Edit"
            onClick={(e) => {
              console.log(e);
              e.preventDefault();
              rowEditOnClick(item);
            }}
          />
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
};

const SortableListContainer = SortableContainer(({ ...props }) => {
  const { page_id, isHomepage, templates, items, onPopupConfirm } = props;

  return <Datagrid style={{ "table-layout": "fixed" }} header={<DatagridHeader isHomepage={isHomepage} />} body={<MyDatagridBody isHomepage={isHomepage} templates={templates} items={items} onPopupConfirm={onPopupConfirm} />}></Datagrid>;
});

const EditX = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  const onSuccess = () => {
    window.location.reload(false);
  };

  return (
    <Edit onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" {...props} title={<PageTitle />}>
      <SimpleForm toolbar={null}>
        <PopupboxContainer />
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <SortableList {...props} />
          </Grid>
        </Grid>
        <Field name="is_homepage" component="input" type="hidden" />
      </SimpleForm>
    </Edit>
  );
};

export const EmarsysTitlePageEdit = (props) => {
  return <EditX {...props} />;
};
