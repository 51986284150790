import jsonExport from "jsonexport/dist";
import React from "react";
import { Datagrid, useNotify, downloadCSV, ExportButton, List, TextField, TopToolbar } from "react-admin";
import { ImportButton } from "react-admin-import-csv";
import { MyDateField } from "../../MyDateField";

var moment = require("moment-timezone");

export const EpisodePreviewScheduleList = (props) => {
  const exporter = (programmes) => {
    console.log("EpisodePreviewScheduleList : rows =");
    console.log(programmes);
    const BOM = "\uFEFF";

    const forExport = programmes.map((programme) => {
      return {
        platform: programme.platform,
        programme_id: programme.programme_id,
        episode_list: "" + programme.episode_list,
        start_time: moment.tz(programme.start_time, "Asia/Hong_Kong").format("YYYYMMDDHHmm"),
        end_time: moment.tz(programme.end_time, "Asia/Hong_Kong").format("YYYYMMDDHHmm"),
        name_en: programme.name_en,
        name_tc: programme.name_tc,
      };
    });
    jsonExport(
      forExport,
      {
        headers: ["platform", "programme_id", "episode_list", "start_time", "end_time", "name_en", "name_tc"], // order fields in the export
        rename: ["Platform", "Programme ID*", "Episode*", "Free Viewing Start (GMT+8)*", "Free Viewing End (GMT+8)*", "Full Title (English)", "Full Title (Chinese)"],
      },
      (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, "preview_schedules"); // download as 'posts.csv` file
      }
    );
  };

  const ListActions = (props) => {
    const notify = useNotify();

    const config = {
      // // Enable logging
      // logging?: boolean;
      // // Disable the attempt to use "createMany", will instead just use "create" calls
      // disableCreateMany?: boolean,
      // // Disable the attempt to use "updateMany", will instead just use "update" calls
      // disableUpdateMany?: boolean,
      // // Disable the attempt to use "getMany", will instead just use "getSingle" calls
      // disableGetMany?: boolean,
      // // Disable "import new" button
      // disableImportNew?: boolean;
      // // Disable "import overwrite" button
      // disableImportOverwrite?: boolean;
      // // A function to translate the CSV rows on import
      // preCommitCallback?: (action: "create" | "overwrite", values: any[]) => Promise<any[]>;
      // // A function to handle row errors after import
      postCommitCallback: (error) => {
        if (error && error[0]) {
          console.log(error);
          if (!error[0].success) {
            notify(error[0].err.message.toString(), "error");
            throw error; //Note: throw to avoid another imported successful notify
          }
        }
      },
      // // Transform rows before anything is sent to dataprovider
      // transformRows?: (csvRows: any[]) => Promise<any[]>;
      // // Async function to Validate a row, reject the promise if it's not valid
      // validateRow?: (csvRowItem: any) => Promise<void>;
      // Any option from the "papaparse" library
      parseConfig: {
        // For all options see: https://www.papaparse.com/docs#config
        //   delimiter: "",	// auto-detect
        //   newline: "",	// auto-detect
        //   quoteChar: '"',
        //   escapeChar: '"',
        header: true,
        transformHeader: (header, index) => {
          return index;
        },
        //   dynamicTyping: false,
        //   preview: 0,
        //   encoding: "",
        //   worker: false,
        //   comments: false,
        //   step: undefined,
        complete: (results, file) => {
          console.log("import complete:", results, file);
        },
        error: (error, file) => {
          console.log("import error:", error, file);
        },
        //   download: false,
        //   downloadRequestHeaders: undefined,
        //   downloadRequestBody: undefined,
        skipEmptyLines: true,
        //   chunk: undefined,
        //   chunkSize: undefined,
        //   fastMode: undefined,
        //   beforeFirstChunk: undefined,
        //   withCredentials: undefined,
        //   transform: undefined,
        //   delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
      },
    };

    return (
      <TopToolbar>
        <ExportButton />
        <ImportButton {...props} {...config} />
      </TopToolbar>
    );
  };

  const ListNotice = (props) => {
    return (
      <p>
        *The database will <span style={{ color: "red" }}>CRASH</span> if you set episode = all for a programme with more than 100 episodes, such as 愛．回家之開心速遞.
      </p>
    );
  };

  return (
    <List {...props} resource="episode-preview-schedules" perPage={10} filters={<ListNotice />} empty={false} actions={<ListActions />} exporter={exporter} bulkActionButtons={null}>
      <Datagrid>
        <TextField source="programme_id" label="Programme ID" />
        <TextField source="episode_list" label="Episode" />
        <MyDateField source="start_time" label="Preview Start Time" showTime />
        <MyDateField source="end_time" label="Preview End Time" showTime />
        <TextField source="name_tc" label="Name in TC" />
        <TextField source="name_en" label="Name in EN" />
        <TextField source="platform" />
      </Datagrid>
    </List>
  );
};
