import React, { useState, useEffect, useCallback } from "react";
import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  ShowButton,
  Labeled,
  EditButton,
  TextField,
  NumberField,
  SaveButton,
  BooleanField,
  useRecordContext,
  Link,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  SimpleList,
  SimpleShowLayout,
  useNotify,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DeleteWithConfirmButton,
  TopToolbar,
  DatagridBody,
  CreateButton,
  FormDataConsumer,
  CardActions,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Grid, useMediaQuery, ListItem, Divider, ListItemText, makeStyles } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { AssetBulkActionButtons } from "../components";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { useForm, Form, Field, useFormState } from "react-final-form";
import { useDataProvider, Loading, Error } from "react-admin";
import arrayMove from "array-move";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { TableHead, TableRow, TableCell, Button } from "@material-ui/core";
import { Download } from "@mui/icons-material";
import dataProvider from "../../dataProvider";
import { showFormContent } from "../mpm-rows/components/show-form-content";
import { useRecursiveTimeout } from "./components/use-recursive-timeout";
import { MyDateField } from "../../MyDateField";

export const ImportRequestsList = (props) => {
  // console.log('ImportRequestsList : props = ');
  // console.log(props);

  const notify = useNotify();
  const [defaultId, setDefaultId] = useState("");
  const [dummy, setDummy] = useState(1);
  const [dummy2, setDummy2] = useState(0);

  useEffect(() => {
    // console.log('ImportRequestsList . useEffect : ');
    const p = props.location.pathname;
    if (!p) return;

    const arr = p.split("/import-requests/");
    const part1 = arr[arr.length - 1];

    const arr2 = part1.split("?");
    const last = arr2[0];
    if (!last) return;

    const editId = Number.isInteger(Number(last)) ? last : null;
    if (!editId) return;

    // console.log('ImportRequestsList . useEffect : editId = ' + editId);
    setDefaultId(editId);
  });

  // callback
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const onSuccess = (resp) => {
    notify("import programme success", "success");
    setDummy(dummy + 1);
  };

  // periodic refresh
  useRecursiveTimeout(() => {
    // console.log('ImportRequestsList . useRecursiveTimeout : ');
    // refresh();
    setDummy2(dummy2 + 1);
  }, 200);

  return (
    <>
      <h2>Import Programme from MCM</h2>
      <Create {...props} onFailure={onFailure} onSuccess={onSuccess} title="Import Requests  ">
        <SimpleForm toolbar={<CustomToolbar />}>
          <MyFormSet defaultId={defaultId} />
          <MyFormReset dummy={dummy} />
          <Grid container spacing={1} fullWidth>
            <Grid item xs={12} sm={12} md={6}>
              <span>Search By</span>
              <TextInput source="from" defaultValue="mcm" style={{ display: "none" }} />
              <TextInput source="resource" defaultValue="programme" style={{ display: "none" }} />
              <TextInput label="Programme ID" source="resource_id" fullWidth />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>

      <Grid container spacing={1} fullWidth>
        <Grid item xs={12} sm={12} md={6}>
          <h3>Import History</h3>
        </Grid>
      </Grid>

      {dummy2 % 100 == 0 ? <></> : <MyList {...props} dummy2={dummy2} />}
    </>
  );
};

const ProgrammeName = (props) => {
  return (
    <>
      <ReferenceField label="Edit By" source="resource_id" reference="programmes-for-import-requests" link={false}>
        <TextField source="name_tc" />
      </ReferenceField>
    </>
  );
};

const CustomToolbar = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  // const [create] = useCreate("ads-adgroups");

  const handleSave = useCallback(
    async (values, redirect) => {
      //   console.log("onSave");
      //   console.log(values);
    }
    // [notify, redirectTo]
  );

  // return false;
  return (
    <Toolbar {...props}>
      <SaveButton label="Import this Programme from MCM" icon={<Download />} variant="outlined" />
    </Toolbar>
  );
};

const MyFormSet = ({ defaultId }) => {
  const form = useForm();
  const redirectTo = useRedirect();
  // console.log('MyFormSet : defaultId = ' + defaultId);

  useEffect(async() => {
    // console.log('MyFormSet . useEffect : defaultId = ' + defaultId);

    if (!defaultId) return;
    if (!Number.isInteger(Number(defaultId))) return;

    form.change("resource_id", defaultId);
    // console.log('MyFormSet . useEffect : form = ');
    // console.log(showFormContent(form));

    await form.submit();
    redirectTo('/import-requests');
  }, [defaultId]);

  return <></>;
};

const MyFormReset = (props) => {
  const form = useForm();
  // console.log('MyFormReset :');

  useEffect(() => {
    form.change("resource_id", null);
    // console.log('MyFormReset . useEffect : form = ');
    // console.log(showFormContent(form));
  }, [props.dummy]);

  return <></>;
};

const MyList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} exporter={false} sort={{ field: "created_at", order: "DESC" }} title="  ">
      <Datagrid>
        <MyDateField source="created_at" label="Import Datetime" showTime />
        {/* <TextField source="id" /> */}
        {/* <TextField source="from" /> */}
        {/* <TextField source="resource" /> */}
        <TextField source="resource_id" label="Programme ID" />
        {/* <TextField source="__v" /> */}
        {/* <TextField source="modified_at" /> */}
        <ProgrammeName {...props} label="Programme Name" />
        <TextField source="status" label="Status" />
        <TextField source="message" label="Message" />
      </Datagrid>
    </List>
  );
};
