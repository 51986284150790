import { Grid } from "@mui/material";
import { required } from "react-admin";
import { MyDateTimeInput } from "../../../MyDateTimeInput";

export const ShelfDateTimeInput = () => {
    return (
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="On shelf datetime" source="start_time" fullWidth validate={required()}  />
        </Grid>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="Off shelf datetime" source="end_time" fullWidth validate={required()}  />
        </Grid>
      </Grid>
    );
};
