import { Grid, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AutocompleteInput, BooleanInput, ExportButton, CreateButton, Button, TopToolbar, CheckboxGroupInput, Confirm, Create, Datagrid, Edit, EditButton, Filter, FormDataConsumer, FunctionField, ImageField, Labeled, List, NumberInput, RadioButtonGroupInput, RecordContextProvider, ReferenceInput, SaveButton, SelectInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, minValue, required, useDataProvider, useNotify, usePermissions, useRecordContext, useRedirect } from "react-admin";
import { useForm } from "react-final-form";
import { MyDateField } from "../../MyDateField";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { AssetBulkActionButtons } from "../components";
import { ChannelSearch } from "./components/channel-search";
import { EpisodeSearch } from "./components/episode-search";
import { ImageDropzone } from "./components/image-dropzone";
import { ProgrammeSearch } from "./components/programme-search";
import { pnEditorRoleIds, pnApproverRoleIds, pnMocRoleIds } from "../../constant";
// import { belongToRoleIds } from "../../authUtils";

const getNotificationDisplayValue = (status) => {
  const s = notificationStatuses.find((s) => s.id == status);

  if (s) {
    return s.name;
  }

  return status;
};

const notificationStatuses = [
  { id: "saved", name: "Saved" },
  { id: "submitted", name: "Pending Approval" },
  { id: "approved", name: "Pending Verification" },
  { id: "reject", name: "Rejected" },
  { id: "success", name: "Success" },
  { id: "failure", name: "Failure" },
  { id: "cancelled", name: "Cancelled" },
  { id: "schedule", name: "Scheduled" },
];

const NotyFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Platform"
      source="platform"
      choices={[
        { id: "App", name: "App" },
        { id: "Web", name: "Web" },
      ]}
      alwaysOn
    />
    <SelectInput
      label="Push Type"
      source="push_type"
      choices={[
        { id: "homepage", name: "Homepage" },
        { id: "library", name: "Library" },
        { id: "top_attribute", name: "Top Attribute" },
        { id: "programme", name: "Programme" },
        { id: "episode", name: "Episode" },
        { id: "live_channel", name: "Live Channel" },
        { id: "clip", name: "Clip" },
        { id: "url", name: "URL" },
      ]}
      alwaysOn
    />
    <SelectInput
      label="Notification Type"
      source="notification_type"
      choices={[
        { id: "global_push", name: "Global Push" },
        { id: "specific_cids", name: "Specific Cids" },
      ]}
      alwaysOn
    />
    <SelectInput source="status" choices={notificationStatuses} alwaysOn />
    <MyDateTimeInput label="Start Time" source="start_time" alwaysOn />
    <MyDateTimeInput label="End Time" source="end_time" alwaysOn />
  </Filter>
);

export const NotyList = (props) => {
  const notify = useNotify();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();

  const MyEditButton = (props) => {
    var showButton = false;

    // if (belongToRoleIds(pnEditorRoleIds)) {
    if (permissions && permissions.includes("edit_push_notification")) {
      const status = props.record.status;

      if (!status.includes("success") && !status.includes("failure")) {
        showButton = true;
      }
    }
    // const sendTime = props.record.scheduled_push_start_date;
    // if (sendTime && moment(sendTime) >= moment.utc()) {
    //   showEditButton = false;
    // }

    if (showButton) {
      return <EditButton {...props} />;
    }
    return null;
  };

  const MyApproverApproveButton = (props) => {
    const [showConfirm, setShowConfirm] = React.useState(false);

    var showButton = false;

    // if (belongToRoleIds(pnApproverRoleIds)) {
    if (permissions && permissions.includes("approve_push_notification")) {
      const status = props.record.status;

      if (status.includes("submitted")) {
        showButton = true;
      }
    }

    if (showButton) {
      return (
        <React.Fragment>
          <Button
            label="Approve"
            onClick={() => {
              setShowConfirm(true);
            }}
          />
          <Confirm
            isOpen={showConfirm}
            title="Are you sure you want to approve the push notification?"
            onConfirm={async () => {
              try {
                await dataProvider.approverApproveNotification(props.record.id);
                window.location.reload(true);
              } catch (error) {
                notify(error.message.toString(), "warning");
              }

              setShowConfirm(false);
            }}
            onClose={() => {
              setShowConfirm(false);
            }}
          />
        </React.Fragment>
      );
    }
    return null;
  };

  const MyApproverRejectButton = (props) => {
    const [showConfirm, setShowConfirm] = React.useState(false);

    var showButton = false;

    // if (belongToRoleIds(pnApproverRoleIds)) {
    if (permissions && permissions.includes("approve_push_notification")) {
      const status = props.record.status;

      if (status.includes("submitted")) {
        showButton = true;
      }
    }

    if (showButton) {
      return (
        <React.Fragment>
          <Button
            label="Reject"
            onClick={() => {
              setShowConfirm(true);
            }}
          />
          <Confirm
            isOpen={showConfirm}
            title="Are you sure you want to reject the push notification?"
            onConfirm={async () => {
              try {
                await dataProvider.rejectNotification(props.record.id);
                window.location.reload(true);
              } catch (error) {
                notify(error.message.toString(), "warning");
              }

              setShowConfirm(false);
            }}
            onClose={() => {
              setShowConfirm(false);
            }}
          />
        </React.Fragment>
      );
    }
    return null;
  };

  // const MyMOCApproveButton = (props) => {
  //   const [showConfirm, setShowConfirm] = React.useState(false);

  //   var showButton = false;

  // if (belongToRoleIds(pnMocRoleIds)) {
  // if (permissions && permissions.includes("verifield_push_notification")) {
  //     const status = props.record.status;

  //     if (status.includes("approved")) {
  //       showButton = true;
  //     }
  //   }

  //   if (showButton) {
  //     return (
  //       <React.Fragment>
  //         <Button
  //           label="Approve"
  //           onClick={() => {
  //             setShowConfirm(true);
  //           }}
  //         />
  //         <Confirm
  //           isOpen={showConfirm}
  //           title="Are you sure you want to approve the push notification?"
  //           onConfirm={async () => {
  //             try {
  //               await dataProvider.mocApproveNotification(props.record.id);
  //               window.location.reload(true);
  //             } catch (error) {
  //               notify(error.message.toString(), "warning");
  //             }

  //             setShowConfirm(false);
  //           }}
  //           onClose={() => {
  //             setShowConfirm(false);
  //           }}
  //         />
  //       </React.Fragment>
  //     );
  //   }
  //   return null;
  // };

  // const MyMOCRejectButton = (props) => {
  //   const [showConfirm, setShowConfirm] = React.useState(false);

  //   var showButton = false;

  //   if (belongToRoleIds(pnMocRoleIds)) {
  // if (permissions && permissions.includes("verifield_push_notification")) {
  //     const status = props.record.status;

  //     if (status.includes("approved")) {
  //       showButton = true;
  //     }
  //   }

  //   if (showButton) {
  //     return (
  //       <React.Fragment>
  //         <Button
  //           label="Reject"
  //           onClick={() => {
  //             setShowConfirm(true);
  //           }}
  //         />
  //         <Confirm
  //           isOpen={showConfirm}
  //           title="Are you sure you want to reject the push notification?"
  //           onConfirm={async () => {
  //             try {
  //               await dataProvider.rejectNotification(props.record.id);
  //               window.location.reload(true);
  //             } catch (error) {
  //               notify(error.message.toString(), "warning");
  //             }

  //             setShowConfirm(false);
  //           }}
  //           onClose={() => {
  //             setShowConfirm(false);
  //           }}
  //         />
  //       </React.Fragment>
  //     );
  //   }
  //   return <></>;
  // };

  const MyCancelButton = (props) => {
    const [showConfirm, setShowConfirm] = React.useState(false);

    var showButton = false;

    // if (belongToRoleIds(pnEditorRoleIds) || belongToRoleIds(pnApproverRoleIds) || belongToRoleIds(pnMocRoleIds)) {
    if (permissions && permissions.includes("cancel_push_notification")) {
      const status = props.record.status;

      if (!status.includes("success") && !status.includes("failure") && !status.includes("cancelled")) {
        showButton = true;
      }
    }

    if (showButton) {
      return (
        <React.Fragment>
          <Button
            label="Cancel"
            onClick={() => {
              setShowConfirm(true);
            }}
          />
          <Confirm
            isOpen={showConfirm}
            title="Are you sure you want to cancel the push notification?"
            onConfirm={async () => {
              try {
                await dataProvider.cancelNotification(props.record.id);
                window.location.reload(true);
              } catch (error) {
                notify(error.message.toString(), "warning");
              }

              setShowConfirm(false);
            }}
            onClose={() => {
              setShowConfirm(false);
            }}
          />
        </React.Fragment>
      );
    }
    return null;
  };

  const renderField = (record, fieldName, lengthLimit) => {
    if (!(fieldName in record)) {
      return "";
    }

    // max show 'fieldName' with 'lengthLimit' chars
    var result = record[fieldName].substring(0, lengthLimit);
    if (record[fieldName].length > lengthLimit) result += "...";
    return result;
  };

  const NotfyListActions = (props) => {
    if (permissions && permissions.includes("edit_push_notification")) {
      return (
        <TopToolbar>
          <CreateButton />
          <ExportButton />
        </TopToolbar>
      );
    } else {
      return (
        <TopToolbar>
          <ExportButton />
        </TopToolbar>
      );
    }
  };

  return (
    <List {...props} sort={{ field: "created_at", order: "DESC" }} filters={<NotyFilter />} bulkActionButtons={<AssetBulkActionButtons />} actions={<NotfyListActions />}>
      <Datagrid>
        <TextField label="msgID" source="master_message_id" />
        <TextField label="Push Type" source="push_type" />
        <TextField label="Notification type" source="notification_type" />
        <FunctionField label="Subject" render={(record) => renderField(record, "subject", 15)} />
        <FunctionField label="Content" render={(record) => renderField(record, "content", 15)} />
        <TextField label="Platform" source="platform" />
        <FunctionField label="Status" render={(record) => getNotificationDisplayValue(record.status)} />
        <FunctionField
          label="Last updated by"
          render={(record) => {
            if (record.action_log && record.action_log.length > 0) {
              return record.action_log[record.action_log.length - 1].username;
            }
            return "";
          }}
        />
        <MyDateField label="Schedule Push Start Date" source="scheduled_push_start_date" showTime />
        <MyDateField label="Create Time" source="created_at" showTime />
        <ShowButton />
        <MyEditButton />
        <MyApproverApproveButton />
        <MyApproverRejectButton />
        {/* <MyMOCApproveButton /> */}
        {/* <MyMOCRejectButton /> */}
        <MyCancelButton />
      </Datagrid>
    </List>
  );
};

const AutoCompleteProgramme = (props) => {
  return (
    <ReferenceInput
      label="Search Programme Name"
      source="programme"
      reference="programmes-autocomplete"
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="programme_id" fullWidth />
    </ReferenceInput>
  );
};

const AutoCompleteChannel = (props) => {
  return (
    <ReferenceInput
      label="Search Channel Name"
      source="channel_id"
      reference="channels-autocomplete"
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

const ProgrammeInputs = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!props.programme_id) {
      return;
    }

    dataProvider
      // .getOne("programmes", { id: props.programme_id })
      .getList("programmes", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: { programme_id: props.programme_id.toString() },
      })
      .then(({ data }) => {
        var programme = data[0];
        form.change("programme_id", programme.programme_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.programme_id]);

  return <TextInput label="Programme ID" disabled={true} source="programme_id" clearAlwaysVisible={true} fullWidth />;
};

const EpisodeInput = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!props.episode_id) {
      return;
    }
    dataProvider
      .getOne("episodes", { id: props.episode_id })
      .then(({ data }) => {
        console.log(data);
        form.change("video_id", data.episode_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.episode_id]);

  return <TextInput label="Video ID" disabled={true} source="video_id" clearAlwaysVisible={true} fullWidth />;
};

const ChannelInputs = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!props.channel_id) {
      return;
    }

    dataProvider
      // .getOne("channels", { id: props.channel_id })
      .getList("channels", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: { network_code: props.channel_id.toString() },
      })
      .then(({ data }) => {
        var channel = data[0];
        form.change("network_code", channel.network_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.channel_id]);

  return <TextInput disabled={true} label="Network Code" source="network_code" fullWidth validate={required()} />;
};

const EpisodeSelect = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const [episodes, setEpisodes] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    if (!props.programme_id) {
      return;
    }

    dataProvider
      .getList("episodes", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: { programme_id: `${props.programme_id}` },
      })
      .then(({ data }) => {
        setEpisodes(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [props.programme_id]);

  if (!episodes) return null;

  const ep_choices = episodes.map((episode) => ({
    id: episode.id,
    name: episode.nameWithEpisodeNo,
  }));

  return <SelectInput source="episode_id" choices={ep_choices} fullWidth allowEmpty={true} />;
};

export const NotyEdit = (props) => {
  const notify = useNotify();
  const { permissions } = usePermissions();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const MyCancelButton = (props) => {
    const [showConfirm, setShowConfirm] = React.useState(false);

    var showButton = false;

    if (permissions && permissions.includes("cancel_push_notification")) {
      const status = props.record.status;

      if (!status.includes("success") && !status.includes("failure") && !status.includes("cancelled")) {
        showButton = true;
      }
    }

    if (showButton) {
      return (
        <React.Fragment>
          <Button
            label="Cancel this Push Notification"
            style={{ color: "red" }}
            onClick={() => {
              setShowConfirm(true);
            }}
          />
          <Confirm
            isOpen={showConfirm}
            title="Are you sure you want to cancel the push notification?"
            onConfirm={async () => {
              try {
                await dataProvider.cancelNotification(props.record.id);
                redirectTo("/push-notifications");
              } catch (error) {
                notify(error.message.toString(), "warning");
              }

              setShowConfirm(false);
            }}
            onClose={() => {
              setShowConfirm(false);
            }}
          />
        </React.Fragment>
      );
    }
    return null;
  };

  const NotyEditToolbar = (props) => {
    const form = useForm();
    return (
      <Toolbar {...props}>
        <SaveButton
          style={{ marginRight: 10 }}
          transform={(data) => {
            data.status = "saved";
            return data;
          }}
        />
        <SaveButton
          style={{ marginRight: 10 }}
          label="SAVE AND SUBMIT"
          transform={(data) => {
            data.status = "submitted";
            return data;
          }}
        />

        <MyCancelButton />
        {/* <DeleteWithConfirmButton
          mutationMode="pessimistic"
          label="Cancel this Push Notification"
          confirmTitle="Cancel Push Notification"
          confirmContent="Are you sure you want to cancel this item?"
          onSuccess={(data) => {
            notify("Push Notification Cancelled", "info");
            redirectTo("/push-notifications");
          }}
        /> */}
      </Toolbar>
    );
  };

  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<NotyTitle />}>
      <SimpleForm toolbar={<NotyEditToolbar />}>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <SelectInput
              source="push_type"
              choices={[
                // { id: "homepage", name: "Homepage" },
                // { id: "library", name: "Library" },
                // { id: "top_attribute", name: "Top Attribute" },
                // { id: "programme", name: "Programme" },
                // { id: "episode", name: "Episode" },
                // { id: "live_channel", name: "Live Channel" },
                // { id: "clip", name: "Clip" },
                { id: "url", name: "URL" },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <SelectInput
              source="notification_type"
              choices={[
                { id: "global_push_all", name: "Global Push (Guest + Registered User)" },
                { id: "global_push_login", name: "Global Push (Registered User Only)" },
                { id: "specific_cids", name: "Specific Cids" },
              ]}
              fullWidth
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <RadioButtonGroupInput
              label="Prefill Url content"
              source="prefill_url_content"
              optionText="name"
              optionValue="id"
              choices={[
                { id: "programme", name: "Programme" },
                { id: "episode", name: "Episode" },
                { id: "live_channel", name: "Live Channel" },
              ]}
              translateChoice={false}
            />
          </Grid>

          {/* Auto complete "destination_url" */}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.prefill_url_content == "programme" && (
                <>
                  <ProgrammeSearch formData={formData} />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.prefill_url_content == "episode" && (
                <>
                  <EpisodeSearch formData={formData} />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.prefill_url_content == "live_channel" && (
                <>
                  <ChannelSearch formData={formData} />
                </>
              )
            }
          </FormDataConsumer>

          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "programme" && <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "live_channel" && <AutoCompleteChannel clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "episode" && <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "programme" && <ProgrammeInputs programme_id={formData.programme} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "episode" && <ProgrammeInputs programme_id={formData.programme} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "live_channel" && <ChannelInputs channel_id={formData.channel_id} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.programme_id && formData.push_type == "episode" && <EpisodeSelect programme_id={formData.programme_id} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.episode_id && <EpisodeInput episode_id={formData.episode_id} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={12}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.notification_type == "specific_cids" && <TextInput label="CIDs (Maximum 20 CIDs allowed)" source="cids" {...rest} fullWidth />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={12}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "url" && <TextInput label="URL Destination" source="destination_url" multiline={true} fullWidth {...rest} />}</FormDataConsumer>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="platform" disabled={true} fullWidth />
          </Grid>

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.platform && formData.platform.includes("Web")) {
                return (
                  <Grid item sm={12} md={12}>
                    <NumberInput label="Valid time for web push (hours)" source="valid_time" fullWidth {...rest} validate={[required(), minValue(1)]} min={1} defaultValue={24} />
                  </Grid>
                );
              }
              return <></>;
            }}
          </FormDataConsumer>

          <Grid item sm={12} md={12}>
            <TextInput label="Subject" source="subject" multiline={true} fullWidth />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput source="content" multiline={true} fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <SelectInput
            class="hidden"
            source="status"
            defaultValue="schedule"
            choices={[
              { id: "success", name: "Success" },
              { id: "failure", name: "Failure" },
              { id: "cancelled", name: "Cancelled" },
              { id: "schedule", name: "Schedule" },
            ]}
          />
          <Grid item sm={12} md={12}>
            <ImageDropzone source="image_url" sourceBase="image_base64" label="Photo" />
          </Grid>

          <Grid item sm={12} md={4}>
            <BooleanInput label="Push Now" source="push_now_toggle" />
          </Grid>
          <Grid item sm={12} md={4}>
            <FormDataConsumer>
              {({ formData }) => {
                console.log("FormDataConsumer : push_now_toggle = ");
                console.log(formData.push_now_toggle);
                if (!formData.push_now_toggle) {
                  return <MyDateTimeInput source="scheduled_push_start_date" fullWidth validate={required()} />;
                } else {
                  return <MyDateTimeInput source="scheduled_push_start_date" fullWidth disabled />;
                }
              }}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const NotyTitle = ({ record }) => {
  return <span>Notification {record ? `${record.push_type}` : ""}</span>;
};

export const NotyCreate = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const onSuccess = (data) => {
    redirectTo("/push-notifications");
  };

  const CustomToolbar = (props) => {
    const form = useForm();

    return (
      <Toolbar alwaysEnableSaveButton={true} {...props}>
        <SaveButton
          style={{ marginRight: 10 }}
          transform={(data) => {
            data.status = "saved";
            return data;
          }}
        />
        <SaveButton
          label="SAVE AND SUBMIT"
          transform={(data) => {
            data.status = "submitted";
            return data;
          }}
        />
      </Toolbar>
    );
  };

  let now = new Date();
  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <SelectInput
              source="push_type"
              choices={[
                // { id: "homepage", name: "Homepage" },
                // { id: "library", name: "Library" },
                // { id: "top_attribute", name: "Top Attribute" },
                // { id: "programme", name: "Programme" },
                // { id: "episode", name: "Episode" },
                // { id: "live_channel", name: "Live Channel" },
                // { id: "clip", name: "Clip" },
                { id: "url", name: "URL" },
              ]}
              defaultValue="url"
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <SelectInput
              source="notification_type"
              choices={[
                { id: "global_push_all", name: "Global Push (Guest + Registered User)" },
                { id: "global_push_login", name: "Global Push (Registered User Only)" },
                { id: "specific_cids", name: "Specific Cids" },
              ]}
              fullWidth
              validate={[required()]}
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <RadioButtonGroupInput
              label="Prefill Url content"
              source="prefill_url_content"
              optionText="name"
              optionValue="id"
              choices={[
                { id: "programme", name: "Programme" },
                { id: "episode", name: "Episode" },
                { id: "live_channel", name: "Live Channel" },
              ]}
              translateChoice={false}
            />
          </Grid>

          {/* Auto complete "destination_url" */}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.prefill_url_content == "programme" && (
                <>
                  <ProgrammeSearch formData={formData} />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.prefill_url_content == "episode" && (
                <>
                  <EpisodeSearch formData={formData} />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.prefill_url_content == "live_channel" && (
                <>
                  <ChannelSearch formData={formData} />
                </>
              )
            }
          </FormDataConsumer>

          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "programme" && <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "live_channel" && <AutoCompleteChannel clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "episode" && <AutoCompleteProgramme clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "programme" && <ProgrammeInputs programme_id={formData.programme} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "episode" && <ProgrammeInputs programme_id={formData.programme} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
            <FormDataConsumer>{({ formData, ...rest }) => formData.push_type == "live_channel" && <ChannelInputs channel_id={formData.channel_id} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.programme_id && formData.push_type == "episode" && <EpisodeSelect programme_id={formData.programme_id} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.episode_id && <EpisodeInput episode_id={formData.episode_id} clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={12}>
            <FormDataConsumer>{({ formData, ...rest }) => formData.notification_type == "specific_cids" && <TextInput label="CIDs (Maximum 20 CIDs allowed)" source="cids" {...rest} fullWidth />}</FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={12}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.push_type == "url" && (
                  <>
                    <TextInput label="URL Destination" source="destination_url" fullWidth {...rest} validate={[required()]} />
                    <span style={{ fontStyle: "italic" }}>Remarks: add utm after URL if needed, e.g. https://mytvsuper.com/tc/live/81/?utm_source=aaa&utm_medium=bbb&utm_campaign=ccc </span>
                  </>
                )
              }
            </FormDataConsumer>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <CheckboxGroupInput
              label="Platform"
              source="pla"
              optionText="name"
              optionValue="id"
              choices={[
                { id: "App", name: "App" },
                { id: "Web", name: "Web" },
              ]}
              validate={[required()]}
            />
          </Grid>

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.pla && formData.pla.includes("Web")) {
                return (
                  <Grid item sm={12} md={12}>
                    <NumberInput label="Valid time for web push (hours)" source="valid_time" fullWidth {...rest} validate={[required(), minValue(1)]} min={1} defaultValue={24} />
                  </Grid>
                );
              }
              return <></>;
            }}
          </FormDataConsumer>

          <Grid item sm={12} md={12}>
            <TextInput label="Subject" source="subject" fullWidth validate={[required()]} />
          </Grid>
          <Grid item sm={12} md={12}>
            <TextInput source="content" multiline={true} fullWidth validate={[required()]} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <SelectInput
            class="hidden"
            defaultValue="schedule"
            source="status"
            choices={[
              { id: "success", name: "Success" },
              { id: "failure", name: "Failure" },
              { id: "cancelled", name: "Cancelled" },
              { id: "schedule", name: "Schedule" },
            ]}
          />
          <Grid item sm={12} md={12}>
            <ImageDropzone source="image_url" sourceBase="image_base64" label="Photo" />
          </Grid>
          <Grid item sm={12} md={4}>
            <BooleanInput label="Push Now" source="push_now_toggle" />
          </Grid>
          <Grid item sm={12} md={4}>
            <FormDataConsumer>
              {({ formData }) => {
                console.log("FormDataConsumer : push_now_toggle = ");
                console.log(formData.push_now_toggle);
                if (!formData.push_now_toggle) {
                  return <MyDateTimeInput source="scheduled_push_start_date" fullWidth validate={required()} />;
                } else {
                  return <MyDateTimeInput source="scheduled_push_start_date" fullWidth disabled />;
                }
              }}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const LogDataGrid = (props) => {
  const record = useRecordContext();

  const DatagridHeader = ({ children }) => (
    <TableHead>
      <TableRow>
        <TableCell style={{}}>Update by</TableCell>
        <TableCell style={{}}>Status</TableCell>
        <TableCell style={{}}>Update time</TableCell>
      </TableRow>
    </TableHead>
  );

  const MyDatagridRow = ({ item, index, ...props }) => {
    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <TableCell style={{ overflowWrap: "break-word" }}>{item.username}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>{getNotificationDisplayValue(item.status)}</TableCell>
          <TableCell style={{ overflowWrap: "break-word" }}>
            <MyDateField source="modified_at" showTime />
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  };

  const MyDatagridBody = ({ items, ...props }) => {
    return items.map((item, index) => {
      console.log("index =" + index);
      return <MyDatagridRow item={item} index={index} {...props} />;
    });
  };

  return <Datagrid header={<DatagridHeader />} body={<MyDatagridBody items={record.action_log} {...props} />} />;
};

export const NotyShow = (props) => (
  <Show {...props} title={<NotyTitle />} actions={<></>}>
    <SimpleShowLayout>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="msgIDe">
            <TextField source="master_message_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Push type">
            <TextField source="push_type" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={12}>
          <Labeled label="Notification type">
            <FunctionField
              render={(record) => {
                var txt = record.notification_type;

                if (record.cids) {
                  return (
                    <>
                      {txt}
                      <br />
                      {record.cids}
                    </>
                  );
                }

                return txt;
              }}
            />
          </Labeled>
        </Grid>

        <Grid item sm={12} md={12}>
          <Labeled label="URL Destination">
            <TextField source="destination_url" className="textFieldWrapText" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Programme ID">
            <TextField source="programme_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Video ID">
            <TextField source="video_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Network Code">
            <TextField source="network_code" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={12}>
          <Labeled label="Subject">
            <TextField source="subject" className="textFieldWrapText" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={12}>
          <Labeled label="Content">
            <TextField source="content" className="textFieldWrapText" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Photo">
            <ImageField source="image_url" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Platform">
            <TextField source="platform" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Valid time for web push (hours)">
            <TextField source="valid_time" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Status">
            <FunctionField render={(record) => getNotificationDisplayValue(record.status)} />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Create Time">
            <MyDateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Schedule Push Start Date">
            <MyDateField source="scheduled_push_start_date" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={12}>
          <Labeled label="Log" fullWidth>
            <LogDataGrid />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);
