import { FileUpload, FilterAltOff } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import {
  Datagrid, Edit, EditButton, Filter, List, NumberInput, ShowButton, SimpleForm, TextField, TextInput, Toolbar, useAuthenticated, useAuthProvider, useAuthState, useLogin, useNotify, usePermissions, useRecordContext
} from "react-admin";
import dataProvider from "../../dataProvider";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { Grid } from "@mui/material";

export const VodLivesEdit = (props) => {
  const notify = useNotify();

  const validate = useCallback(()=>{
    return true;
  });
  
  const onFailure = useCallback((error) => {
    console.log("VodLivesEdit . onFailure");
    notify(error.message.toString(), "warning");
  });

  return (<>
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" >
      <SimpleForm validate={validate} >
        <EditVodLivesFormContent {...props} />
      </SimpleForm>  
    </Edit>
  </>);
};

const EditVodLivesFormContent = (props) => {
  return (<>
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={4}>
        <NumberInput label="ID" disabled source="event_id" fullWidth />
      </Grid>
      <Grid item sm={12} md={4}>
        <TextInput label="Name in TC" source="name_tc" fullWidth />
      </Grid>
      <Grid item sm={12} md={4}>
        <TextInput label="Name in EN" source="name_en" fullWidth />
      </Grid>
    </Grid>

    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={6}>
        <TextInput label="Channel Number" source="channel_no" fullWidth />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput source="network_code" fullWidth />
      </Grid>
    </Grid>

    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={6}>
        <TextInput source="sports_topic_code" fullWidth />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput source="sports_event_code" fullWidth />
      </Grid>
    </Grid>

    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={6}>
        <MyDateTimeInput source="start_time" fullWidth />
      </Grid>
      <Grid item sm={12} md={6}>
        <MyDateTimeInput source="end_time" fullWidth />
      </Grid>
    </Grid>

    <div style={{display: 'none'}}>
      <TextInput source="language_1" />
      <TextInput source="language_2" />
      <TextInput source="synopsis_tc" />
      <TextInput source="synopsis_en" />
    </div>
    
  </>);
}
