import React from "react";
import { Button, Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, ImageField, ImageInput, Link, List, required, SaveButton, SimpleForm, TextField, TextInput, Toolbar, TopToolbar, useNotify, useRecordContext } from "react-admin";

import { AssetBulkActionButtons } from "../components";

const CustomEdit = () => {
  const record = useRecordContext();
  if (record.is_used) {
    return <></>;
  }

  return <EditButton record={record} />;
};

const CustomDelete = () => {
  const record = useRecordContext();
  if (record.is_used) {
    return <></>;
  }

  return (
    <DeleteWithConfirmButton
      record={record}
      undoable={false}
      confirmContent={
        <div>
          <center>
            <img src={record.image_url} style={{ width: "50px", height: "50px" }} />
          </center>
          <br></br>
          <span>Are you sure you want to delete this item?</span>
        </div>
      }
      confirmTitle={"Delete this icon?"}
    />
  );
};

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/im-icons/create`,
      }}
      label="+ CREATE"
    />
  </TopToolbar>
);

export const IMIconList = (props) => (
  <List {...props} sort={{ field: "created_at", order: "DESC" }} bulkActionButtons={false} actions={<ListActions />}>
    <Datagrid>
      <TextField source="id" />
      <ImageField source="image_url" label="Icon" className="chatroom-icon" />
      <CustomEdit />
      <CustomDelete />
    </Datagrid>
  </List>
);

const IMIconTitle = ({ record }) => {
  return <span>Icon {record ? `${record.id}` : ""}</span>;
};

const EditToolbar = (props) => (
  <Toolbar
    {...props}
    style={{
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <CustomDelete />
  </Toolbar>
);

export const IMIconEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<IMIconTitle />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ImageField source="image_url" label="Icon" />
        <ImageInput label="Icon" source="icon_base64" accept="image/svg+xml,image/png" validate={required()}>
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

const redirectIMIcon = () => "/im-icons";

export const IMIconCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirectIMIcon}>
        <ImageInput label="Icon" source="icon_base64" accept="image/svg+xml,image/png" multiple={true} validate={required()} className="chatroom-icon">
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
