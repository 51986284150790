import { Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import {
    AutocompleteInput,
    Create, ImageField,
    ImageInput, NumberInput, ReferenceInput, required, SimpleForm, TextInput, useDataProvider, useNotify, useRedirect, useRecordContext
} from "react-admin";
import { useForm } from "react-final-form";
import { useDebouncedCallback } from 'use-debounce';
import dataProvider from "../../dataProvider";
import { MyDateTimeInput } from "../../MyDateTimeInput";


export const OverwritePosterModuleCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  const onSuccess = () => {
    console.log('OverwritePosterModuleCreate . onSuccess');
    redirect('/programme-overwrite-poster-module?sort=order');
  }

  let programme_str = document.location;
  let programme_id = programme_str.href.split("programme_id=")[1];

  
  const FormContent = ()=>{

    const form = useForm();
    
    const [state, setState] = useState('auto');

    const programmeIdOnChange = useDebouncedCallback(async(value) => {
      console.log('programmeIdOnChange : value = ' + value);
      // form.change("api_timestamp", moment().valueOf());
      form.change("programme_field_dummy", undefined);
      setState('show');

      const programme = await dataProvider.getProgrammeByProgrammeId(value);
      console.log('programme =  ');
      console.log(programme);
      if(programme && programme.data){
        // form.change("api_timestamp", moment().valueOf());
        form.change("programme_field_dummy", programme.data.name_tc + ' - ' + programme.data.programme_id);
      }
      
    }, 1000);  

    const onClickProgrammeNameField = ()=>{
      console.log('onClickProgrammeNameField');
      setState('auto');
    }

    return (<>
      <FetchProgrammeData programme_id={programme_id} />
      <Grid item sm={12} md={6}>
        {
          state == 'auto' ? 
          <AutoCompleteProgramme {...props} clearAlwaysVisible={true} allowEmpty={true} fullWidth /> : 
          <TextInput label="Programme Name" source="programme_field_dummy" onClick={onClickProgrammeNameField} disabled fullWidth />
        }
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Programme ID" source="programme_id" fullWidth onChange={(e) => { programmeIdOnChange(e.target.value) }} />
      </Grid>
    </>);
  }

  const shadeStyle = {position: "absolute", width:"100%" ,height:"100%", top: "0", backgroundColor: "rgba(255, 255, 255, 0.4)"};
  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" title="Create Poster">
      <SimpleForm method="POST">
        
        <Grid container spacing={1} fullWidth>
          
          <FormContent/>

          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="start_time" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="end_time" fullWidth validate={required()} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4} style={{position: 'relative'}} >
            <ImageInput source="portrait_small_base64" label="Portrait Small (128 x 186)" accept="image/*" placeholder={<p>Auto resize from “Portrait Large”</p>} onClick={(event)=>{event.preventDefault()}} >
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle} ></div>
          </Grid>
          <Grid item sm={12} md={4} style={{position: 'relative'}} >
            <ImageInput source="portrait_medium_base64" label="Portrait Medium (200 x 290)" accept="image/*" placeholder={<p>Auto resize from “Portrait Large”</p>} >
              <ImageField source="src" title="title" />              
            </ImageInput>
            <div style={shadeStyle} ></div>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="portrait_large_base64" label="Portrait Large (743 x 1080)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4} style={{position: 'relative'}} >
            <ImageInput source="landscape_small_base64" label="Landscape Small (324 x 182)" accept="image/*" placeholder={<p>Auto resize from Landscape Large”</p>} >
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle} ></div>
          </Grid>
          <Grid item sm={12} md={4} style={{position: 'relative'}} >
            <ImageInput source="landscape_medium_base64" label="Landscape Medium (615 x 346)" accept="image/*" placeholder={<p>Auto resize from Landscape Large”</p>} >
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle} ></div>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="landscape_large_base64" label="Landscape Large (1920 x 1080)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const FetchProgrammeData = (props) => {
    const dataProvider = useDataProvider();
    const form = useForm();
  
    useEffect(async () => {
      if (!props.programme_id) {
        return;
      }
  
      console.log(props.programme_id);
      const res = await dataProvider.getProgrammeByProgrammeId(props.programme_id);
      const programme = res.data;
      
      if (!programme) {
        console.log("Programme Not Found");
        return;
      }
  
      // const res = await dataProvider.getList("programmes", {
      //   pagination: { page: 1, perPage: 100 },
      //   sort: { field: "id", order: "ASC" },
      //   filter: { programme_id: props.programme_id.toString() },
      // });
  
      // if (res.data.length == 0) {
      //   return;
      // }
  
      // const programme = res.data[0];
  
      form.change("programme_id", programme.programme_id);
  
      form.change("programme_name_tc", programme.name_tc);
      form.change("programme_name_en", programme.name_en);
  
      //Use caption_tc/en if exists, use programme name otherwise
    //   if (!props.caption_tc) {
    //     form.change("caption_tc", programme.name_tc);
    //   }
    //   if (!props.caption_en) {
    //     form.change("caption_en", programme.name_en);
    //   }
  
    //   form.change("landscape_poster", programme.image.landscape_large);
    //   form.change("portrait_poster", programme.image.portrait_large);
    //   if (!props.landscape_poster) {
    //     if (document.getElementById("pg-image")) {
    //       document.getElementById("pg-image").src = programme.image.landscape_large;
    //     } else {
    //       var lands = document.getElementById("landscape_poster");
    //       var parent = lands.parentElement;
    //       parent.innerHTML = '<img id="pg-image" style="margin-top:30px" width="50%" src="" />';
    //       parent.getElementsByTagName("img")[0].src = programme.image.landscape_large;
    //       lands.remove();
    //     }
    //   }
  
    //   if (!props.portrait_poster) {
    //     if (document.getElementById("lg-image")) {
    //       document.getElementById("lg-image").src = programme.image.portrait_large;
    //     } else {
    //       var port = document.getElementById("portrait_poster");
    //       var parentP = port.parentElement;
    //       parentP.innerHTML = '<img id="lg-image" style="margin-top:30px" width="30%" src="" />';
    //       parentP.getElementsByTagName("img")[0].src = programme.image.portrait_large;
    //       port.remove();
    //     }
    //   }
  
      // show default posters
    //   form.change('showDefaultPortraitPoster', true);
    //   form.change('showDefaultLandscapePoster', true);
  
    }, [props.programme_id, props.api_timestamp]);
  
    return null;
};

const AutoCompleteProgramme = (props) => {
    console.log('AutoCompleteProgramme : props = ');
    console.log(props);
    const form = useForm();
    const [temp, setTemp] = useState();
    return (
      <ReferenceInput
        label="Search Programme Name"
        source="programme_id"
        reference="programmes-autocomplete"
        onChange={(val) => {
          //Reset all params
        //   form.change("episode_id", undefined);
        //   form.change("content_id", undefined);
        //   form.change("caption_tc", undefined);
        //   form.change("caption_en", undefined);
          form.change("programme_name_tc", undefined);
          form.change("programme_name_en", undefined);
        //   form.change("landscape_poster", undefined);
        //   form.change("portrait_poster", undefined);
  
          form.change("programme_id", val);
          form.change("api_timestamp", moment().valueOf());
          setTemp(val);
  
        }}
        filterToQuery={(searchText) => {
          if (searchText) {
            return { name_tc: searchText };
          }
          return {};
        }}
      >
        <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
        
      </ReferenceInput>
    );
};