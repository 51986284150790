import { Grid } from "@material-ui/core";
import { result } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ArrayInput, AutocompleteInput, FormDataConsumer, maxValue, minValue, NumberInput, ReferenceInput, required, SelectInput, SimpleFormIterator, TextField, TextInput, useDataProvider, useRecordContext } from "react-admin";
import { useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { MyDateTimeInput } from "../../../MyDateTimeInput";
import { EpisodeForm } from "./episode-form";
import { ShelfDateTimeInput } from "./shelf-date-time-input";

export const TemplateSelectInput = ({ layouts, multilineNumOfColumns, defaultValue, isRequired }) => {
  const dataProvider = useDataProvider();
  const [allTemplatesX, setAllTemplatesX] = useState([]);
  const form = useForm();
  var allTemplates = [];

  useEffect(() => {
    console.log("useEffect");
    dataProvider.getMpmRowTemplatesByLayouts().then(({ data }) => {
      setAllTemplatesX(data);
    });
  }, []);

  const getActiveTemplates = () => {
    if (!allTemplatesX) {
      return [];
    }

    var arr = [];
    for (var t of allTemplatesX) {
      if (layouts.includes(t.layout)) {
        arr.push(t);
      }
    }

    return arr;
  };

  const getTemplateByTemplateId = (templateId) => {
    for (var d of allTemplates) {
      if (d.id == templateId) {
        return d;
      }
    }

    return null;
  };

  const getTemplate1IdByTemplateId = (templateId) => {
    if (!templateId) {
      return getTemplate1InitialValue();
    }

    const template = getTemplateByTemplateId(templateId);

    if (template && template.layout) {
      if (template.layout == "loop" || template.layout == "loop_med") {
        for (const d of allTemplates) {
          if (d.is_default && d.layout == "loop") {
            return d.id;
          }
        }
      } else if (template.layout == "multiline") {
        for (const d of allTemplates) {
          if (d.is_default && d.layout == "multiline") {
            return d.id;
          }
        }
      }
    }

    return templateId;
  };

  const getTemplate2IdByTemplateId = (contentType, templateId, template1Id) => {
    if (!templateId) {
      return getTemplate2InitialValue(template1Id);
    }

    const template = getTemplateByTemplateId(templateId);

    if (template && template.layout) {
      if (template.layout == "loop" || template.layout == "loop_med") {
        if (contentType == "editorial_pick") {
          //There will be 3rd level if loop/loop_med
          for (const d of allTemplates) {
            if (d.is_default && d.layout == template.layout) {
              return d.id;
            }
          }
        }
      }
    }

    return templateId;
  };

  const getTemplate3IdByTemplateId = (templateId, template2Id) => {
    if (!templateId) {
      return getTemplate3InitialValue(template2Id);
    }
    return templateId;
  };

  const getTemplate1Choices = () => {
    if (!allTemplates) {
      return [];
    }

    var arr = [];
    for (var d of allTemplates) {
      //Special Handling for layout=loop and loop_med
      if (d.layout == "loop_med") {
        continue; //SKIP loop_med, use loop is enough
      }

      if (d.is_default) {
        if (d.layout == "loop") {
          arr.push({
            id: d.id,
            name: "LoopRow",
          });
        } else {
          arr.push({
            id: d.id,
            name: d.name,
          });
        }
      }
    }
    return arr;
  };

  const getTemplate1InitialValue = () => {
    if (!allTemplates) {
      return null;
    }

    for (var d of allTemplates) {
      if (defaultValue && defaultValue == d.layout && d.is_default) {
        return d.id;
      }
    }
  };

  //Either multiline OR loopRowLarge loopRowMed
  const getTemplate2Choices = (template1Id) => {
    if (!template1Id) {
      return [];
    }

    //Depends on template1 choice to decide template2
    if (template1Id) {
      const template1 = getTemplateByTemplateId(template1Id);

      var arr = [];
      if (template1.layout == "loop") {
        for (var d of allTemplates) {
          if (d.is_default && (d.layout == "loop" || d.layout == "loop_med")) {
            arr.push({
              id: d.id,
              name: d.name,
            });
          }
        }
      } else if (template1.layout == "multiline") {
        for (var d of allTemplates) {
          if (d.layout == "multiline") {
            if (multilineNumOfColumns.includes(d.num_of_columns)) {
              arr.push({
                id: d.id,
                name: d.option_name,
              });
            }
          }
        }
      }
      return arr;
    }
  };

  const getTemplate2InitialValue = (template1Id) => {
    if (!template1Id) {
      return null;
    }

    return template1Id;
  };

  //Must be Portrait/Landscape
  const getTemplate3Choices = (template2Id) => {
    if (!template2Id) {
      return [];
    }

    //Depends on template1 choice to decide template2
    if (template2Id) {
      const template2 = getTemplateByTemplateId(template2Id);

      var arr = [];
      for (var d of allTemplates) {
        if (d.layout == template2.layout) {
          arr.push({
            id: d.id,
            name: d.option_name,
          });
        }
      }

      return arr;
    }
  };

  const getTemplate3InitialValue = (template2Id) => {
    if (!template2Id) {
      return null;
    }

    return template2Id;
  };

  const isTemplate2Visible = (template1Id) => {
    if (!template1Id) {
      return false;
    }

    const template1 = getTemplateByTemplateId(template1Id);
    if (template1 && template1.layout) {
      if (template1.layout == "loop" || template1.layout == "multiline") {
        return true;
      }
    }
    return false;
  };

  const isTemplate3Visible = (contentType, template2Id) => {
    if (!contentType || !template2Id) {
      return false;
    }

    const template2 = getTemplateByTemplateId(template2Id);
    if (contentType == "editorial_pick" && (template2.layout == "loop" || template2.layout == "loop_med")) {
      return true;
    }
    return false;
  };

  const getTemplate2Label = (template1Id) => {
    if (!template1Id) {
      return "";
    }

    const template1 = getTemplateByTemplateId(template1Id);
    if (template1.layout == "loop") {
      return "LoopRow size";
    } else if (template1.layout == "multiline") {
      return "Layout Option Name";
    }
    return "";
  };

  const getTemplate3Label = (template2Id) => {
    return "Poster Orientation for mobile view";
  };

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        allTemplates = getActiveTemplates();

        if (allTemplates.length == 0) {
          return <></>;
        }

        console.log(formData);
        console.log("formData.template = " + formData.template);
        formData.template_1 = getTemplate1IdByTemplateId(formData.template);
        formData.template_2 = getTemplate2IdByTemplateId(formData.type, formData.template, formData.template_1);
        formData.template_3 = getTemplate3IdByTemplateId(formData.template, formData.template_2);

        return (
          <>
            <SelectInput
              fullWidth
              source="template_1"
              choices={getTemplate1Choices()}
              label="Layout"
              initialValue={getTemplate1InitialValue()}
              validate={isRequired ? required() : null}
              onChange={(e) => {
                const template2Id = getTemplate2InitialValue(e.target.value);
                const template3Id = getTemplate3InitialValue(template2Id);

                form.change("template", e.target.value);
                form.change("template_2", template2Id);
                form.change("template_3", template3Id);
              }}
              translateChoice={false}
            />
            {isTemplate2Visible(formData.template_1) ? (
              <SelectInput
                fullWidth
                source="template_2"
                choices={getTemplate2Choices(formData.template_1)}
                label={getTemplate2Label(formData.template_1)}
                initialValue={getTemplate2InitialValue(formData.template_1)}
                validate={isRequired ? required() : null}
                onChange={(e) => {
                  const template3Id = getTemplate3InitialValue(e.target.value);

                  form.change("template", e.target.value);
                  form.change("template_3", getTemplate3InitialValue(template3Id));
                }}
                translateChoice={false}
              />
            ) : (
              <></>
            )}
            {isTemplate3Visible(formData.type, formData.template_2) ? (
              <SelectInput
                fullWidth
                source="template_3"
                choices={getTemplate3Choices(formData.template_2)}
                label={getTemplate3Label(formData.template_2)}
                initialValue={getTemplate3InitialValue(formData.template_2)}
                validate={isRequired ? required() : null}
                onChange={(e) => {
                  form.change("template", e.target.value);
                }}
                translateChoice={false}
              />
            ) : (
              <></>
            )}
          </>
        );
      }}
    </FormDataConsumer>
  );
};
