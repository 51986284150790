import * as React from 'react';
import { AppBar, UserMenu,  MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { Typography } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to="/password/create"
        primaryText="Change Password"
        leftIcon={<SettingsIcon />}
        onClick={onClick} // close the menu on click
    />
));

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

export const MyAppBar = props => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const onClick = (event)=>{

        if(!event.target) return;

        const k = event.target.firstElementChild;

        if(!k) return;
        
        const j = k.getAttribute("d");

        if(j == 'M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z'){
            console.log('refreshButtonOnClick');

            let event = new Event("appBarRefreshButtonOnClick");
            window.dispatchEvent(event);

        }

    };

    return (
        <AppBar {...props} userMenu={<MyUserMenu />}  onClick={onClick}>
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {!isSmall ? (
            <div>
                <span style={{fontSize:"12px"}}>Date Time Input Template</span>
                <br />
                <input type="datetime-local" disabled/> 
            </div>
            ): <></>}
        </AppBar>
    );
    
}
