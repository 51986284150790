import { CheckboxGroupInput, useRecordContext, ChipField } from "react-admin";

const qrCodeEntryCheckboxChoices = [
  { id: "stb", name: "STB" },
  { id: "android_tv", name: "Android TV" },
  { id: "samsung_tv", name: "Samsung TV" },
  { id: "lg_tv", name: "LG TV" },
];

export const QrCodeEntryCheckboxGroupInput = (props) => {
  return <CheckboxGroupInput label="QR Code Entry" source="qr_code_entry" choices={qrCodeEntryCheckboxChoices} {...props} />;
};

export const QrCodeEntryChipField = (props) => {
  const record = useRecordContext();

  return (
    <>
      {record.qr_code_entry.map((item) => {
        const choice = qrCodeEntryCheckboxChoices.find((i) => i.id == item);
        return <ChipField record={{ key: choice.id, name: choice.name }} source="name" />;
      })}
    </>
  );
};
