import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  ShowButton,
  RecordContextProvider,
  EditButton,
  TextField,
  SimpleForm,
  TextInput,
  SimpleList,
  SimpleShowLayout,
  BooleanInput,
  NumberInput,
  BooleanField,
  useNotify,
  required,
  Link,
  linkToRecord,
  DeleteButton,
  DeleteWithConfirmButton,
  Filter,
  SelectInput,
  useRedirect,
  usePermissions,
  downloadCSV,
} from "react-admin";
import React, { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { AssetBulkActionButtons } from "../components";
import { Field } from "react-final-form";
import { ListItem, Divider, ListItemText } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import { CustomDeleteHotSearchButton } from "./components/custom-delete-hot-search-button";
import jsonExport from "jsonexport/dist";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

const SearchFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        onChange={() => {
          // setTimeout(function () {
          //   window.location.reload(true);
          // }, 2500);
        }}
        label="Keyword"
        source="search"
        alwaysOn
      />
      <SelectInput
        onChange={() => {
          // setTimeout(function () {
          //   window.location.reload(true);
          // }, 1000);
        }}
        label="Status"
        source="enabled"
        choices={[
          { id: "true", name: "Enabled" },
          { id: "false", name: "Disabled" },
        ]}
        alwaysOn
      />
    </Filter>
  );
};

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

const SearchFields = (record) => {
  const searchShowPage = linkToRecord("/hot-searchs", record.record.id, "show");
  return (
    <div>
      return <Link to={searchShowPage}>{record.record.search},&nbsp;</Link>;
    </div>
  );
};

const SortableItem = SortableElement(({ ...props }) => {
  const { data, setItems } = props;
  useEffect(() => {
    const list = [];
    for (const id of props.ids) {
      list.push(data[id]);
    }
    // Object.keys(data).map((key) => {
    // list.push(data[id]);
    // list.push({ id: data[key].id, text: data[key].search, enabled: data[key].enabled, order: data[key].order });
    // });
    setItems(list);
  }, [data]);

  return (
    <div style={{ margin: "1em" }}>
      {props.ids.map((id) => (
        <ListItem>
          <SearchFields record={props.data[id]} />
          <ShowButton />
          <EditButton />
          <DragHandle />
        </ListItem>
      ))}
    </div>
  );
});

// const SortableItem2 = SortableElement(({ item, ...props }) => {
//   const notify = useNotify();
//   const redirect = useRedirect();
//   return (
//     <div style={{ margin: "1em" }}>
//       <ListItem>
//         <div class="record-search-name">
//           <span>{item.search}</span>
//         </div>
//         <div class="record-search-order">
//           <span>{item.order}</span>
//         </div>
//         <div class="record-search-status">
//           <span>
//             {item.enabled ? (
//               <span class="" title="Yes">
//                 <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
//                   <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
//                 </svg>
//               </span>
//             ) : (
//               <span title="No" class="">
//                 <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
//                   <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
//                 </svg>
//               </span>
//             )}
//           </span>
//         </div>
//         <div class="record-search-action">
//           <ShowButton label="Show" record={item} />
//         </div>
//         <div class="record-search-action">
//           <EditButton label="Edit" record={item} />
//         </div>
//         <div class="record-search-action">
//           <CustomDeleteHotSearchButton record={item} />
//         </div>
//         <DragHandle />
//       </ListItem>
//       <Divider />
//     </div>
//   );
// });

const DataGridEmptyView = () => {
  return (
    <div style={{ margin: "16px" }}>
      <p class="MuiTypography-root MuiTypography-body2">No results found</p>
    </div>
  );
};

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{}}>Name</TableCell>
      <TableCell style={{}}>Order</TableCell>
      <TableCell style={{}}>Status</TableCell>
      <TableCell style={{}}></TableCell> {/* Show */}
      <TableCell style={{}}></TableCell> {/* Edit */}
      <TableCell style={{}}></TableCell> {/* Remove */}
      <TableCell style={{}}></TableCell> {/* Drag */}
    </TableRow>
  </TableHead>
);

const MyDatagridBody = ({ profileClass, items, setItems, data, permissions, ...props }) => {
  useEffect(() => {
    const list = [];
    for (const id of props.ids) {
      list.push(data[id]);
    }
    setItems(list);
  }, [data]);

  return items.map((item, index) => {
    return <SortableItem2 item={item} index={index} pos={index} {...props} />;
  });
};

const SortableItem2 = SortableElement(({ item, pos, ...props }) => {
  return <MyDatagridRow item={item} pos={pos} {...props} />;
});

const MyDatagridRow = ({ item, pos, ...props }) => {
  return (
    <RecordContextProvider value={item}>
      <TableRow>
        <TableCell>{item.search}</TableCell>
        <TableCell>{item.order}</TableCell>

        <TableCell>
          {item.enabled ? (
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
            </svg>
          ) : (
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          )}
        </TableCell>

        <TableCell>
          <ShowButton record={item} />
        </TableCell>

        <TableCell>
          <EditButton record={item} />
        </TableCell>

        <TableCell>
          <CustomDeleteHotSearchButton record={item} />
        </TableCell>
        <TableCell>
          <DragHandle />
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const SortableListContainer = SortableContainer(({ profileClass, ...props }) => {
  const { setItems, items } = props;

  const exporter = (programmes) => {
    console.log(programmes);
    const BOM = "\uFEFF";

    jsonExport(programmes, {}, (err, csv) => {
      downloadCSV(`${BOM} ${csv}`, "hot-searches"); // download as 'posts.csv` file
    });
  };

  return (
    <List {...props} filter={{ profile_class: profileClass }} sort={{ field: "order", order: "ASC" }} pagination={false} filters={<SearchFilter />} exporter={exporter}>
      <Datagrid header={<DatagridHeader />} body={<MyDatagridBody profileClass={profileClass} items={items} setItems={setItems} {...props} />} empty={<DataGridEmptyView />} />
    </List>
  );

  // return (
  //   <>
  //     {items.length ? (
  //       <List {...props} filter={{ profile_class: profileClass }} sort={{ field: "order", order: "ASC" }} pagination={false} filters={<SearchFilter />} exporter={exporter}>
  //         <>
  //           <div class="header-list">
  //             <div class="header-column 1">
  //               <span>Name</span>
  //             </div>
  //             <div class="header-column order">
  //               <span>Order</span>
  //             </div>
  //             <div class="header-column status">
  //               <span>Status</span>
  //             </div>
  //           </div>
  //           {items.map((item, index) => (
  //             <SortableItem2 key={item.id} index={index} item={item} />
  //           ))}
  //         </>
  //       </List>
  //     ) : (
  //       <List {...props} filter={{ profile_class: profileClass }} filters={<SearchFilter />}>
  //         <SortableItem {...props} />
  //       </List>
  //     )}
  //   </>
  // );
});

const SortableList = ({ profileClass, ...props }) => {
  const [items, setItems] = useState([]);

  const dataProvider = useDataProvider();
  // const [artists, setArtists] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    var oldItem = items[oldIndex];
    var newItem = items[newIndex];
    setItems((items) => arrayMove(items, oldIndex, newIndex));

    var data = {
      hotSearchId: oldItem.id,
      newOrder: newItem.order,
    };
    dataProvider
      .update("hot-searchs-order", data)
      .then(({ data }) => {
        // setArtists(data);
        setLoading(false);
        window.location.reload(true);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  return <SortableListContainer {...props} profileClass={profileClass} items={items} setItems={setItems} onSortEnd={onSortEnd} useDragHandle={true} lockAxis="y" />;
};

const HotSearchList = ({ profileClass, ...props }) => {
  return <SortableList profileClass={profileClass} {...props} />;
};

const HotSearchTitle = ({ record }) => {
  return <span>HotSearch {record ? `${record.search}` : ""}</span>;
};

const HotSearchShow = (props) => (
  <Show {...props} title={<HotSearchTitle />}>
    <SimpleShowLayout>
      <TextField source="search" />
      <TextField label="Order Number" source="order" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);

const HotSearchEdit = ({ profileClass, ...props }) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<HotSearchTitle />}>
      <SimpleForm>
        <Field name="pc" component="input" type="hidden" defaultValue={profileClass} />
        <TextInput source="search" validate={required()} />
        <BooleanInput source="enabled" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

const HotSearchCreate = ({ profileClass, ...props }) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const onSuccess = (result) => {
    console.log("HotSearchCreate::onSuccess");
    if (profileClass == "general") {
      window.location.href = "/admin/#/hot-searchs";
    } else if (profileClass == "kids") {
      window.location.href = "/admin/#/kids-hot-searchs";
    }
    // window.location.reload(true);
  };

  return (
    <Create {...props} onFailure={onFailure} onSuccess={onSuccess} mutationMode="pessimistic">
      <SimpleForm
        redirect={false}
        // redirect={() => (profileClass == "kids" ? "/kids-hot-searchs" : "/hot-searchs")}
      >
        <Field name="pc" component="input" type="hidden" defaultValue={profileClass} />
        <TextInput source="search" validate={required()} />
        {/* <NumberInput source="order" validate={required()} /> */}
        <BooleanInput source="enabled" defaultValue={true} validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export const GeneralHotSearchList = (props) => <HotSearchList profileClass="general" {...props} />;
export const KidsHotSearchList = (props) => <HotSearchList profileClass="kids" {...props} />;

export const GeneralHotSearchShow = (props) => <HotSearchShow profileClass="general" {...props} />;
export const KidsHotSearchShow = (props) => <HotSearchShow profileClass="kids" {...props} />;

export const GeneralHotSearchCreate = (props) => <HotSearchCreate profileClass="general" {...props} />;
export const KidsHotSearchCreate = (props) => <HotSearchCreate profileClass="kids" {...props} />;

export const GeneralHotSearchEdit = (props) => <HotSearchEdit profileClass="general" {...props} />;
export const KidsHotSearchEdit = (props) => <HotSearchEdit profileClass="kids" {...props} />;
