import React, { useState, useEffect } from "react";
import {
  List, //
  Show,
  Edit,
  Create,
  Datagrid,
  ShowButton,
  Labeled,
  EditButton,
  TextField,
  NumberField,
  SaveButton,
  BooleanField,
  useRecordContext,
  Link,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  SimpleList,
  SimpleShowLayout,
  useNotify,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  ImageInput,
  ImageField,
  Toolbar,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DeleteWithConfirmButton,
  TopToolbar,
  DatagridBody,
  CreateButton,
  FormDataConsumer,
  CardActions,
  Button,
} from "react-admin";
import { Grid, useMediaQuery, ListItem, Divider, ListItemText, makeStyles } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useForm, Form, Field } from "react-final-form";
import { useDataProvider, Loading, Error } from "react-admin";
import arrayMove from "array-move";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { showFormContent } from "../../mpm-rows/components/show-form-content";
import { OnChange } from "react-final-form-listeners";

/*
    props : 
    source = "zoneimage_app_tc" 
    sourceBase = "zoneimage_app_tc_base64" 
    label = "ZoneImage"
*/
export const ImageDropzone = (props) => {
  const [show, setShow] = useState(true);

  const form = useForm();
  // console.log("ImageDropzone : form = ");
  // console.log(showFormContent(form));

  const record = useRecordContext();
  // console.log("ImageDropzone : record = ");
  // console.log(record);

  return (
    <>
      <h3>{props.label}</h3>

      {show ? (
        <ImageField source={props.source} />
      ) : record[props.source] ? (
        <>
          <span style={{ color: "red", fontStyle: "italic" }}>Existing Image will be removed</span>
        </>
      ) : (
        <></>
      )}

      <ImageInput source={props.sourceBase} label="" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <Button
        startIcon={<Delete />}
        label="Remove"
        onClick={() => {
          form.change(props.sourceBase, "remove");
          setShow(false);
        }}
      />

      <OnChange name={props.sourceBase}>
        {() => {
          setShow(false);
        }}
      </OnChange>
    </>
  );
};
