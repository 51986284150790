import {
  required, //
  Labeled,
  ChipField,
  TextInput,
  useRecordContext,
  FunctionField,
  CheckboxGroupInput,
} from "react-admin";

export const convertRegionToJson = (region) => {
  return {
    locations: {
      allowlist: {
        countries: region,
      },
    },
  };
};

export const convertJsonToRegion = (json) => {
  if (json.locations) {
    return json.locations.allowlist.countries;
  }
  return undefined;
};

export const RegionTextField = (props) => {
  return (
    <FunctionField
      label="Region"
      source="locations"
      render={(record) => {
        const countries = record.locations.allowlist.countries;
        return countries.join(", ");
      }}
      {...props}
    />
  );
};

export const RegionTextInput = (props) => {
  return <TextInput label="Region" source="region" {...props} />;
};

export const regionDefaultValue = ["HK", "MO"];

const regionChoices = [
  { id: "HK", name: "HK" },
  { id: "MO", name: "MO" },
];
//NOTE: Add noDefaultValue props because
//In mpm-rows (Refer to mpm-rows-form-content.js), don't know why Input with defaultValues crash the page, so, set the value when the page is loaded instead.
export const RegionCheckboxGroupInput = (props) => {
  var defaultValue;
  if (!props.noDefaultValue) {
    defaultValue = regionDefaultValue;
  }

  return <CheckboxGroupInput label="Region" source="region" choices={regionChoices} defaultValue={defaultValue} validate={required()} />;
};

export const RegionChipField = (props) => {
  const record = useRecordContext();

  return (
    <>
      {record.region.map((item) => {
        const choice = regionChoices.find((i) => i.id == item);
        return <ChipField record={{ key: choice.id, name: choice.name }} source="name" />;
      })}
    </>
  );
};
