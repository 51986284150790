import React, { useCallback, useEffect, useState } from "react";
import { Create, Edit, SaveButton, SimpleForm, Toolbar, useNotify } from "react-admin";
import { Field, useForm } from "react-final-form";
import { useHistory } from "react-router-dom";
import dataProvider from "../../dataProvider";
import { MpmRowFormContent } from "./components/mpm-rows-form-content";
import { showFormContent } from "./components/show-form-content";

const queryString = require("query-string");

export const MpmRowCreate = (props) => {
  const notify = useNotify();

  const [page, setPage] = useState(null);

  const parsed = queryString.parse(props.location.search);

  var page_id;
  var pc;
  var is_homepage;
  if (parsed.page_id) {
    page_id = parsed.page_id;
  }
  if (parsed.profile_class) {
    pc = parsed.profile_class;
  }
  if (parsed.is_homepage) {
    is_homepage = parsed.is_homepage == "true";
  }

  useEffect(() => {
    if (page) return;

    const parsed = queryString.parse(props.location.search);
    console.log("PageCreate . useEffect : parsed = ");
    console.log(parsed);
    dataProvider.getOne("mpm-pages", { id: parsed.page_id }).then((result) => {
      console.log("PageCreate . useEffect : result = ");
      console.log(result);

      setPage(result.data);
    });
  }, []);

  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const onRedirect = () => {
    if (pc == "kids") {
      if (is_homepage) {
        return "/kids-mpm-home-page";
      } else {
        return "/kids-mpm-pages/" + page_id;
      }
    } else {
      if (is_homepage) {
        return "/mpm-home-page";
      } else {
        return "/mpm-pages/" + page_id;
      }
    }
  };

  // let str = props.location.search.split("page_id=");
  // let page_id = str[1];

  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={onRedirect} toolbar={<MpmRowEditToolbar />}>
        <MpmRowFormContent {...props} page={page} mode="create" />
        <Field name="page_id" component="input" type="hidden" defaultValue={page_id} />
      </SimpleForm>
    </Create>
  );
};

const MpmRowEditToolbar = (props) => {
  const form = useForm();
  const notify = useNotify();

  const handleSubmitWithRedirect = useCallback((formData) => {
    console.log("MpmRowEditToolbar . handleSubmitWithRedirect : form = ");
    console.log(showFormContent(form));
    const formState = form.getState();

    console.log("MpmRowEditToolbar . handleSubmitWithRedirect : formState = ");
    console.log(formState);

    // check form valid
    if (formState.valid) {
      // form submit
      form.submit();
    }
  }, []);

  return (
    <Toolbar {...props}>
      <SaveButton
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        onClick={(e) => {
          if (!form.getState().values.start_time || !form.getState().values.end_time) {
            notify("Onshelf / offshelf date are mandatory, please select a datetime.", "error");
          }
        }}
      />
    </Toolbar>
  );
};

export const MpmRowEdit = (props) => {
  const history = useHistory();

  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  let str = props.location.search.split("page_id=");
  let page_id = str[1];

  const onSuccess = () => {
    history.goBack();
  };

  return (
    <Edit {...props} onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic">
      {/* <SimpleForm redirect={() => "/mpm-pages/" + page_id}> */}
      <SimpleForm toolbar={<MpmRowEditToolbar />}>
        <MpmRowFormContent {...props} mode="edit" />
      </SimpleForm>
    </Edit>
  );
};
