import React, { useEffect, useState } from "react";
import { ArrayInput, BooleanField, BooleanInput, Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, Filter, FormDataConsumer, ImageField, ImageInput, Labeled, List, RecordContextProvider, SelectInput, Show, ShowButton, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, downloadCSV, required, useDataProvider, useNotify, useRecordContext } from "react-admin";
import { AssetBulkActionButtons } from "./components";

import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { Field } from "react-final-form";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import { Grid } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import arrayMove from "array-move";
import jsonExport from "jsonexport/dist";

const ProfileFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Series in TC" source="series_tc" alwaysOn />
    <TextInput label="Series in EN" source="series_en" alwaysOn />
    <SelectInput
      source="enabled"
      alwaysOn
      lable="Enabled"
      choices={[
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ]}
    />

    <SelectInput
      source="show_in_frontend"
      alwaysOn
      lable="Show in frontend"
      choices={[
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ]}
    />
  </Filter>
);

const exporter = (data) => {
  const BOM = "\uFEFF";

  jsonExport(data, (err, csv) => {
    downloadCSV(`${BOM} ${csv}`, "profile-icons");
    if (err) {
      console.log("Error trying to export list");
    }
  });
};

const SortableItem2 = SortableElement(({ item, pos, ...props }) => {
  return <MyDatagridRow item={item} pos={pos} {...props} />;
});

const MyDatagridRow = ({ item, pos, ...props }) => {
  return (
    <RecordContextProvider value={item}>
      <TableRow>
        <TableCell>
          <DragHandle />
        </TableCell>
        <TableCell style={{ overflowWrap: "anywhere" }}>{item.id}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.series_tc}</TableCell>
        <TableCell style={{ overflowWrap: "break-word" }}>{item.series_en}</TableCell>
        <TableCell style={{ overflowWrap: "break-word", width: "100%" }}>
          <img src={item.series_icon} style={{ width: "auto", height: "100px" }} />
        </TableCell>
        <TableCell>
          {item.enabled ? (
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
            </svg>
          ) : (
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          )}
        </TableCell>
        <TableCell>
          {item.show_in_frontend ? (
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
            </svg>
          ) : (
            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          )}
        </TableCell>
        <TableCell>
          <ShowButton record={item} />
        </TableCell>

        <TableCell>
          <EditButton record={item} />
        </TableCell>

        <TableCell>
          <DeleteWithConfirmButton record={item} mutationMode="pessimistic" />{" "}
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const SortableListContainer = SortableContainer(({ profileClass, items, setItems, ...props }) => {
  return (
    <List filter={{ profile_class: profileClass }} {...props} pagination={false} bulkActionButtons={<AssetBulkActionButtons />} filters={<ProfileFilter />} exporter={exporter}>
      <Datagrid header={<DatagridHeader />} body={<MyDatagridBody profileClass={profileClass} items={items} setItems={setItems} {...props} />} empty={<DataGridEmptyView />} />
    </List>
  );
});

const DataGridEmptyView = () => {
  return (
    <div style={{ margin: "16px" }}>
      <p class="MuiTypography-root MuiTypography-body2">No results found</p>
    </div>
  );
};

const MyDatagridBody = ({ profileClass, items, setItems, data, permissions, ...props }) => {
  useEffect(() => {
    const list = [];
    for (const id of props.ids) {
      list.push(data[id]);
    }
    setItems(list);
  }, [data]);

  return items.map((item, index) => {
    return <SortableItem2 item={item} index={index} pos={index} {...props} />;
  });
};

const DatagridHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: "50px" }}></TableCell> {/* Drag */}
      <TableCell style={{}}>Series ID</TableCell>
      <TableCell style={{}}>Series in TC</TableCell>
      <TableCell style={{}}>Series in EN</TableCell>
      <TableCell style={{}}>Series image</TableCell>
      <TableCell style={{}}>Enabled</TableCell>
      <TableCell style={{}}>Show in frontend</TableCell>
      <TableCell style={{}}></TableCell> {/* Show */}
      <TableCell style={{}}></TableCell> {/* Edit */}
      <TableCell style={{}}></TableCell> {/* Remove */}
    </TableRow>
  </TableHead>
);

const DragHandle = SortableHandle(() => (
  <TableCell>
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  </TableCell>
));

const ProfileIconList = ({ profileClass, ...props }) => {
  const [items, setItems] = useState([]);

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    var oldItem = items[oldIndex];
    var newItem = items[newIndex];
    setItems((items) => arrayMove(items, oldIndex, newIndex));

    var data = {
      profileIconId: oldItem.id,
      newOrder: newItem.order,
    };
    dataProvider
      .update("profile-icon-order", data)
      .then(({ data }) => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  return <SortableListContainer {...props} profileClass={profileClass} items={items} setItems={setItems} onSortEnd={onSortEnd} useDragHandle={true} lockAxis="y" />;
};

const PreviewImage = ({ record }) => {
  if (record && record.image && record.image.large) {
    return (
      <div>
        <p>Icon</p>
        <img label="Icon" src={record.image.large} alt="Image Preview" />
      </div>
    );
  } else {
    return "";
  }
};

const ProfileIconEdit = ({ ...props }) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<ProfileTitle />}>
      <SimpleForm>
        {/* <Field name="pc" component="input" type="hidden" defaultValue={profileClass} /> */}
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="series_tc" label="Series in TC" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput source="series_en" label="Series in EN" validate={required()} fullWidth disabled={true} />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <ImageField id="series_icon" source="series_icon" fullWidth />
            <ImageInput source="series_icon_base64" label="Series Icon" accept="image/*" fullWidth>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            {/* https://github.com/marmelab/react-admin/pull/4477 */}
            <ArrayInput label="Profile Icons (Best resolution: 160px x 160px)" source="profile_icons">
              <SimpleFormIterator>
                {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return (
                      <Grid container spacing={1} fullWidth>
                        <Grid item sm={12} md={8}>
                          <PreviewImage label="Icon" record={scopedFormData} />
                        </Grid>
                        <Grid item sm={12} md={8}>
                          <ImageInput label="Icon Base64" source={getSource("icon_base64")} record={scopedFormData}>
                            <ImageField source="src" />
                          </ImageInput>
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <BooleanInput label="Enabled" source={getSource("enabled")} record={scopedFormData} defaultValue={true} />
                        </Grid>
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid item sm={12} md={6}>
            <BooleanInput source="enabled" label="Enabled" validate={required()} defaultValue={true} />
          </Grid>

          <Grid item sm={12} md={6}>
            <BooleanInput source="show_in_frontend" label="Show in frontend" validate={required()} defaultValue={true} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const ProfileTitle = ({ record }) => {
  return <span>Profile Icon {record ? `${record.series_tc}` : ""}</span>;
};

const ProfileIconCreate = ({ profileClass, ...props }) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={() => (profileClass == "kids" ? "/kids-profile-icons" : "/profile-icons")}>
        <Field name="pc" component="input" type="hidden" defaultValue={profileClass} />
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="series_tc" label="Series in TC" validate={required()} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput source="series_en" label="Series in EN" validate={required()} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <ImageInput source="series_icon_base64" label="Series Icon" accept="image/*" validate={required()} fullWidth>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <ArrayInput label="Profile Icons (Best resolution: 160px x 160px)" source="profile_icons" validate={required()}>
              <SimpleFormIterator>
                {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return (
                      <Grid container spacing={1} fullWidth>
                        <Grid item sm={12} md={8}>
                          <ImageInput label="Icon Base64" source={getSource("icon_base64")} record={scopedFormData}>
                            <ImageField source="src" />
                          </ImageInput>
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <BooleanInput label="Enabled" source={getSource("enabled")} record={scopedFormData} defaultValue={true} />
                        </Grid>
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>

          <Grid item sm={12} md={6}>
            <BooleanInput source="enabled" label="Enabled" validate={required()} defaultValue={true} />
          </Grid>

          <Grid item sm={12} md={6}>
            <BooleanInput source="show_in_frontend" label="Show in frontend" validate={required()} defaultValue={true} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const ProfileIconShow = (props) => (
  <Show {...props} title={<ProfileTitle />}>
    <SimpleShowLayout>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={4}>
          <Labeled label="ID">
            <TextField source="id" fullWidth />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Series in TC">
            <TextField source="series_tc" fullWidth />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Series in EN">
            <TextField source="series_en" fullWidth />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={4}>
          <Labeled label="Enabled">
            <BooleanField source="enabled" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Show in frontend">
            <BooleanField source="show_in_frontend" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <Labeled label="Series Icon">
            <ImageField source="series_icon" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <ProfileIconListImpl {...props} />
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export const ProfileIconListImpl = ({ ...props }) => {
  const icons = useRecordContext();

  const DatagridHeader = ({ children }) => (
    <TableHead>
      <TableRow>
        <TableCell colSpan={1}></TableCell> {/* Index */}
        <TableCell colSpan={2}>Icon ID</TableCell>
        <TableCell colSpan={2}>ID</TableCell>
        <TableCell colSpan={2}>Small</TableCell>
        <TableCell colSpan={2}>Medium</TableCell>
        <TableCell colSpan={2}>Large</TableCell>
        <TableCell colSpan={1}>Enabled</TableCell>
      </TableRow>
    </TableHead>
  );

  const MyDatagridRow = ({ item, index, ...props }) => {
    return (
      <RecordContextProvider value={item}>
        <TableRow>
          <TableCell colSpan={1} style={{ overflowWrap: "break-word" }}>
            {index + 1}
          </TableCell>
          <TableCell colSpan={2} style={{ overflowWrap: "break-word" }}>
            {item.id}
          </TableCell>
          <TableCell colSpan={2} style={{ overflowWrap: "break-word" }}>
            {item.icon_id}
          </TableCell>
          <TableCell colSpan={2} style={{ overflowWrap: "break-word" }}>
            <img style={{ maxWidth: "100%" }} src={item.image.small} />
          </TableCell>
          <TableCell colSpan={2} style={{ overflowWrap: "break-word" }}>
            <img style={{ maxWidth: "100%" }} src={item.image.medium} />
          </TableCell>
          <TableCell colSpan={2} style={{ overflowWrap: "break-word" }}>
            <img style={{ maxWidth: "100%" }} src={item.image.large} />
          </TableCell>
          <TableCell colSpan={1} style={{ overflowWrap: "break-word" }}>
            {item.enabled ? (
              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            ) : (
              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            )}
          </TableCell>
        </TableRow>
      </RecordContextProvider>
    );
  };

  const MyDatagridBody = ({ items, ...props }) => {
    return items.map((item, index) => {
      console.log("index =" + index);
      return <MyDatagridRow item={item} index={index} {...props} />;
    });
  };

  return (
    <>
      <Labeled label="Icons"></Labeled>
      <Datagrid style={{ tableLayout: "fixed" }} header={<DatagridHeader />} body={<MyDatagridBody items={icons.profile_icons ?? []} />} />
    </>
  );
};

export const GeneralProfileIconList = (props) => <ProfileIconList profileClass="general" {...props} />;
export const GeneralProfileIconCreate = (props) => <ProfileIconCreate profileClass="general" {...props} />;
export const GeneralProfileIconEdit = (props) => <ProfileIconEdit {...props} />;
export const GeneralProfileIconShow = (props) => <ProfileIconShow {...props} />;

export const KidsProfileIconList = (props) => <ProfileIconList profileClass="kids" {...props} />;
export const KidsProfileIconCreate = (props) => <ProfileIconCreate profileClass="kids" {...props} />;
export const KidsProfileIconEdit = (props) => <ProfileIconEdit {...props} />;
export const KidsProfileIconShow = (props) => <ProfileIconShow {...props} />;
