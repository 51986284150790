import { convertRegionToJson } from "../../component/region-components";
var moment = require("moment-timezone");

export const MenuTreeMassage = (rows) => {
  console.log("MenuTreeMassage : rows = ");
  console.log(rows);

  // massage rows
  const menuTree = rows.map((row) => ({
    category_id: row.category_id ? row.category_id : -1,
    name_tc: row.name_tc,
    name_en: row.name_en,
    path: row.path,
    start_time: row.start_time ? moment(row.start_time).toISOString() : "",
    end_time: row.end_time ? moment(row.end_time).toISOString() : "",
    tags: row.tags,
    ...convertRegionToJson(row.region),
    categories: row.categories
      ? row.categories.map((row2) => ({
          category_id: row2.category_id ? row2.category_id : -1,
          name_tc: row2.name_tc,
          name_en: row2.name_en,
          path: row2.path,
          start_time: row2.start_time ? moment(row2.start_time).toISOString() : "",
          end_time: row2.end_time ? moment(row2.end_time).toISOString() : "",
          tags: row2.tags,
          ...convertRegionToJson(row2.region),
          categories: row2.categories ? row2.categories : [],
        }))
      : [],
  }));

  return menuTree;
};
