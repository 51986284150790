import {
  List, //
  Show,
  Edit,
  Datagrid,
  ShowButton,
  EditButton,
  TextField,
  SimpleForm,
  Link,
  TextInput,
  DateTimeInput,
  SimpleList,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  BooleanInput,
  BooleanField,
  ImageField,
  Labeled,
  useNotify,
  required,
  useRecordContext,
  ReferenceArrayField,
  linkToRecord,
  Filter,
  SelectInput,
  Button,
  NumberInput,
  AutocompleteInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRedirect,
  ExportButton,
  useListContext,
  Error,
} from "react-admin";
import { useState, useEffect, useCallback } from "react";
import { useDataProvider, Loading } from "react-admin";

import { Grid } from "@material-ui/core";
import { StringArrayChipField } from "../components";
import { MyDateTimeInput } from "../../MyDateTimeInput";
import { Download, FileUpload } from "@mui/icons-material";
import { MyDateField } from "../../MyDateField";
import { Close, Done } from "@material-ui/icons";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { ListItemIcon, TableCell } from "@mui/material";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { SortableListContainer } from "./component/sortable-list";
import { useForm } from "react-final-form";
import DragHandle from "@material-ui/icons/DragHandle";
import { SortableComponent } from "./component/subcat-filters-edit-sortable-list";





const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
  </Filter>
);




// edit page = reorder page

export const FilteringEdit = (props) => {
  
  const notify = useNotify();

  console.log('FilteringEdit : props = ');
  console.log(props);

  const dataProvider = useDataProvider();
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [dummy, setDummy] = useState(1);

  
  const onSortEnd = async({oldIndex, newIndex}) => {
    console.log('onSortEnd : oldIndex = ' + oldIndex + ' , newIndex = ' + newIndex);
    
    const tempRows = (JSON.parse(JSON.stringify(rows)));

    const promise = apiReorder(rows[oldIndex], rows[newIndex], oldIndex, newIndex);
    const r  = arrayMove(rows, oldIndex, newIndex);
    setRows(r);
    const result = await promise;

    // if error , revert the rows
    if(result.error){
      setRows(tempRows);
    }

    setDummy(dummy + 1);

    console.log('onSortEnd : result = ');
    console.log(result);
  };

  const apiReorder = useCallback(async(oldRow, newRow, oldIndex, newIndex)=>{
    console.log('apiReorder : oldIndex = ' + oldIndex + ' , newIndex = ' + newIndex);
    console.log(oldRow);
    console.log(newRow);

    const diff = newRow.order - oldRow.order;

    try{
      const promise = dataProvider.updateSubcatFilters('subcat-filters', {
        id: oldRow._id,
        order: oldRow.order + diff,
      });

      const result = await promise;

      return {
        data: result,
      }
  
    }catch(error){
      notify(error.message.toString(), "warning");
      return {
        error: error.message.toString(),
      }
    }

    

  });

  useEffect(() => {

    dataProvider.getManySubcatFilters("subcat-filters", props.id)
    .then(({ data }) => {

      console.log('FilteringEdit . useEffect : data = ');
      console.log(data);

      setRows(data);
      setLoading(false);
    })
    .catch((error) => {
      setError(error);
      setLoading(false);
    });

  }, [dummy]);

  // if (loading) return <Loading />;
  if (loading) return <><div>loading</div></>;
  if (error) return <><div>error</div></>;
  if (!rows) return <><div>no data</div></>;
  
  console.log('FilteringEdit : rows');
  console.log(rows);

  return (<>
    <Edit {...props} >
      {rows ? <SortableComponent rows={rows} onSortEnd={onSortEnd} /> : <><div>sorry i have no data</div></>}
    </Edit>
  </>);
};





const CustomListActions = function (props) {
  console.log("CustomListActions : props = ");
  console.log(props);

  return (
    <Toolbar {...props} style={{padding: 0, margin: 0, backgroundColor:"transparent" }} >
      {/* button link to Import Request List page  */}
      <SimpleForm toolbar={false} basePath="/import-requests" record={{ id: "" }} >
        <EditButton label="Update filtering" icon={<FileUpload />} style={{ width: "unset" }} />
      </SimpleForm>

      {/* default Export Button  */}
      {/* <ExportButton /> */}
    </Toolbar>
  );
};
