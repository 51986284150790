import { apiUrl } from "./constant";
import { fetchUtils } from "react-admin";

const httpClient = fetchUtils.fetchJson;

const localStorageKeys = {
  accessToken: "access_token",
  refreshToken: "refresh_token",
};
const refreshEndpoint = `${apiUrl}/auth/refresh`;

const delay = 5000; //ms
export const getAuthHeaders = async () => {
  const headers = {};
  const accessToken = await getAccessToken();
  console.log("accessToken = ", accessToken);
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return new Headers(headers);
};

export const setTokens = (accessToken, accessTokenExpiresIn, refreshToken) => {
  const now = new Date();
  const item = {
    value: accessToken,
    expiry: now.getTime() + accessTokenExpiresIn * 1000 - delay,
  };
  localStorage.setItem(localStorageKeys.accessToken, JSON.stringify(item));
  localStorage.setItem(localStorageKeys.refreshToken, refreshToken);
};

export const ereaseTokens = () => {
  localStorage.removeItem(localStorageKeys.accessToken);
  localStorage.removeItem(localStorageKeys.refreshToken);
};

export const getAccessToken = async () => {
  const accessTokenWithExpiry = localStorage.getItem(localStorageKeys.accessToken);
  // if the item doesn't exist, return null
  if (!accessTokenWithExpiry) {
    return null;
  }
  const item = JSON.parse(accessTokenWithExpiry);
  const now = new Date();
  // compare the expiry time of the access token with the current time
  if (now.getTime() > item.expiry) {
    // If the access token is expired, refresh it
    // If fail to refreesh, delete it in the storage and return null
    const refreshToken = localStorage.getItem(localStorageKeys.refreshToken);
    const res = await getRefreshedToken(refreshToken);
    if (!res) {
      ereaseTokens();
      return null;
    }
    setTokens(res.access_token, res.access_token_expires_in, res.refresh_token);
    return res.access_token;
  }
  return item.value;
};

const getRefreshedToken = async (refreshToken) => {
  console.log("refresh");
  const { status, json } = await httpClient(refreshEndpoint, {
    method: "POST",
    body: JSON.stringify({ token: refreshToken }),
  });
  if (status !== 200) {
    return null;
  }
  return json;
};

// export const belongToRoleIds = (roleIdCsv) => {
//   const u = JSON.parse(localStorage.getItem("user"));

//   const roleIds = roleIdCsv.split(",");

//   for (const r of u.roles) {
//     if (roleIds.includes(r)) {
//       return true;
//     }
//   }
//   return false;
// };
