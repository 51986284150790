import { useRecordContext, CheckboxGroupInput, required, ChipField } from "react-admin";

const platformChoices = [
  { id: "ios", name: "iOS" },
  { id: "android", name: "Android" },
  { id: "web", name: "Web" },
  { id: "stb", name: "STB" },
  { id: "android_tv", name: "Android TV" },
  { id: "samsung_tv", name: "Samsung TV" },
  { id: "lg_tv", name: "LG TV" },
];

const platformDefaultValue = ["ios", "android", "web", "stb", "android_tv", "samsung_tv", "lg_tv"];

//NOTE: Add useDefaultValue props because
//In mpm-rows (Refer to mpm-rows-form-content.js), don't know why Input with defaultValues crash the page, so, set the value when the page is loaded instead.
export const PlatformCheckboxGroupInput = ({ disabled, ...props }) => {
  var defaultValue;
  if (props.useDefaultValue) {
    defaultValue = platformDefaultValue;
  }

  return (
    <CheckboxGroupInput //
      label="Platform"
      source="platform"
      optionText="name"
      optionValue="id"
      choices={platformChoices}
      defaultValue={defaultValue}
      validate={[required()]}
      disabled={disabled ?? false}
    />
  );
};

export const PlatformChipField = (props) => {
  const record = useRecordContext();

  return (
    <>
      {record.platform.map((item) => {
        const choice = platformChoices.find((i) => i.id == item);
        return <ChipField record={{ key: choice.id, name: choice.name }} source="name" />;
      })}
    </>
  );
};
