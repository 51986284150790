import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { ArrayInput, AutocompleteInput, BooleanInput, FormDataConsumer, maxValue, minValue, NumberInput, ReferenceInput, required, SelectInput, SimpleFormIterator, TextField, TextInput, useDataProvider, useRecordContext } from "react-admin";
import { useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { MyDateTimeInput } from "../../../MyDateTimeInput";
import { ScoopPlusForm } from "./scoop-plus-form";
import { CategoryListForm } from "./category-list-form";
import { EpisodeForm } from "./episode-form";
import { ShelfDateTimeInput } from "./shelf-date-time-input";
import { showFormContent } from "./show-form-content";
import { TemplateSelectInput } from "./template-select-input";
import { PagePathInput } from "../../page-row-paths/components/page-path-input";
import { RowPathInput } from "../../page-row-paths/components/row-path-input";
import { PlatformCheckboxGroupInput } from "../../component/platform-components";
import { RegionCheckboxGroupInput, regionDefaultValue } from "../../component/region-components";

export const MpmRowFormContent = (props) => {
  const form = useForm();
  const [type, setType] = useState(props.record.type);
  console.log("MpmRowFormContent : props = ");
  console.log(props);
  console.log("MpmRowFormContent : form = ");
  console.log(showFormContent(form));
  // console.log(form.getFieldState('type'));
  // console.log(form.getFieldState('type').value);
  // if(form.getFieldState('type').value == "episode_list")

  const page = props.page ? props.page : props.record.page ? props.record.page : null;

  useEffect(() => {
    console.log("MpmRowFormContent . useEffect :");
    form.change("programme_id", props.record.ref_x);
    // form.change('title_tc', props.record.ref_x);
    // form.change('programme_id', props.record.ref_x);

    if (props.mode == "create") {
      //set default value
      form.change("platform", ["ios", "android", "web", "stb", "android_tv", "samsung_tv", "lg_tv"]);
      form.change("region", regionDefaultValue);
    }
  }, []);

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <TextInput label="Name" source="name" validate={required()} fullWidth />
      </Grid>
      <Grid item sm={12} md={12}>
        <SelectInput
          label="Content"
          source="type"
          choices={[
            { id: "category_list", name: "Category List" },
            { id: "editorial_pick", name: "Editorial" },
            { id: "episode_list", name: "Episode" },
            { id: "event", name: "Event" },
            { id: "history", name: "Watching History" },
            { id: "latest", name: "Latest" },
            { id: "programme_list", name: "Programme List" },
            { id: "recommendation", name: "Recommendation" },
            { id: "top_sv", name: "Top SV" },
            { id: "scoopplus", name: "Scoop Plus" },
          ]}
          defaultValue=""
          fullWidth
          onChange={(e, val) => {
            console.log("DW");
            console.log(val);
          }}
        />
      </Grid>

      <OnChange name="type">
        {(value, previous) => {
          setType(value);

          console.log("MpmRowFormContent . OnChange : value = " + value + " :  previous = " + previous);
          if (!["editorial_pick", "recommendation", "programme_list", "history", "latest", "top_sv", "episode_list", "event", "clip_list", "scoopplus"].includes(previous)) {
            return; //If previous is not one of the type, don't execute anything
          }

          // form.resetField("template_1");
          // form.resetField("template_2");
          // form.resetField("template_3");
          form.change("ref_x", undefined);
          form.change("template", undefined);
          form.change("template_1", undefined);
          form.change("template_2", undefined);
          form.change("template_3", undefined);
        }}
      </OnChange>

      {type == "editorial_pick" ? <FormDataConsumer>{({ formData, ...rest }) => <EditorialForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "recommendation" ? <FormDataConsumer>{({ formData, ...rest }) => <RecommendationForm formData={formData} page={props.page} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "programme_list" ? <FormDataConsumer>{({ formData, ...rest }) => <ProgrammeListForm formData={formData} rest={rest} mode={props.mode} page={page} />}</FormDataConsumer> : <></>}
      {type == "history" ? <FormDataConsumer>{({ formData, ...rest }) => <WatchingHistoryForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "latest" ? <FormDataConsumer>{({ formData, ...rest }) => <LatestForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "top_sv" ? <FormDataConsumer>{({ formData, ...rest }) => <TopSVForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "episode_list" ? <FormDataConsumer>{({ formData, ...rest }) => <EpisodeForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "event" ? <FormDataConsumer>{({ formData, ...rest }) => <EventForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "category_list" ? <FormDataConsumer>{({ formData, ...rest }) => <CategoryListForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
      {type == "scoopplus" ? <FormDataConsumer>{({ formData, ...rest }) => <ScoopPlusForm formData={formData} rest={rest} />}</FormDataConsumer> : <></>}
    </Grid>
  );
};

const AutoCompleteEditorial = (props) => {
  const form = useForm();
  return (
    <ReferenceInput
      label="Search Editorial Name"
      source="editorial_type_autocomplete"
      reference="editorials-autocomplete"
      onChange={(val) => {
        console.log("AutoCompleteEditorial . onChange : val = ");
        console.log(val);
        //Reset all params
        // form.change("episode_id", undefined);
        // form.change("content_id", undefined);
        // form.change("caption_tc", undefined);
        // form.change("caption_en", undefined);
        // form.change("programme_name_tc", undefined);
        // form.change("programme_name_en", undefined);
        // form.change("landscape_poster", undefined);
        // form.change("portrait_poster", undefined);
        form.change("programme_id", val);
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="name" optionValue="id" fullWidth />
    </ReferenceInput>
  );
};

export const EditorialForm = ({ formData, rest }) => {
  return (
    <Grid container spacing={1} fullWidth>
      {/* <FetchEditorialData {...formData} editorial_type={formData.editorial_type_autocomplete ?? formData.ref_x.split("/")[1]} /> */}
      <FetchEditorialData {...formData} />

      <Grid item sm={12} md={12}>
        <AutoCompleteEditorial clearAlwaysVisible={true} fullWidth {...rest} allowEmpty={true} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Name" source="editorial_name" disabled fullWidth />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Editorial ID" source="editorial_type" disabled validate={required()} fullWidth />
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <TemplateSelectInput layouts={["loop", "loop_med", "loop_small", "multiline", "top_banner"]} multilineNumOfColumns={[4, 5]} defaultValue={"loop"} isRequired={true} />
      </Grid>

      <Grid item sm={12} md={12}>
        <PlatformCheckboxGroupInput fullWidth {...rest} />
      </Grid>

      <Grid item sm={12} md={12}>
        <RegionCheckboxGroupInput fullWidth />
      </Grid>

      <ShelfDateTimeInput />
    </Grid>
  );
};

const FetchEditorialData = (props) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  console.log("FetchEditorialData : props = ");
  console.log(props);

  // this is only for edit page first time load
  useEffect(async () => {
    console.log("FetchEditorialData . useEffect 2:");

    if (!props.ref_x || props.ref_x.length == 0) {
      return;
    }

    var filterType = "";
    if (props.editorial_type_autocomplete) {
      filterType = props.editorial_type_autocomplete;
    } else if (props.ref_x && props.ref_x.split && props.ref_x.split("/") && props.ref_x.split("/")[1]) {
      filterType = props.ref_x.split("/")[1];
    }

    if (filterType) {
      const result = await dataProvider.getMpmRowScheduleTypeByType(filterType);

      console.log("FetchEditorialData . useEffect 2 : result = ");
      console.log(result);

      if (result.data != null) {
        const editorial = result.data;
        form.change("editorial_name", editorial.name);
        form.change("editorial_type", editorial.type);
      }
    }
  }, []);

  useEffect(async () => {
    console.log("FetchEditorialData . useEffect:");

    if (!props.editorial_type_autocomplete) {
      return;
    }

    // if (!props.ref_x || props.ref_x.length == 0) {
    //   return;
    // }

    var filterType = "";
    if (props.editorial_type_autocomplete) {
      filterType = props.editorial_type_autocomplete;
    } else {
      filterType = props.ref_x.split("/")[1];
    }

    const result = await dataProvider.getMpmRowScheduleTypeByType(filterType);

    console.log("FetchEditorialData . useEffect : result = ");
    console.log(result);

    if (result.data != null) {
      const editorial = result.data;
      form.change("editorial_name", editorial.name);
      form.change("editorial_type", editorial.type);
    }
  }, [props.editorial_type_autocomplete, props.ref_x]);

  return null;
};

export const RecommendationForm = ({ formData, page, rest }) => {
  const form = useForm();

  const record = useRecordContext();
  console.log("RecommendationForm : record = ");
  console.log(record);

  console.log("RecommendationForm : page = ");
  console.log(page);

  const isHomepage = (page && page.is_homepage) || (record.page && record.page.is_homepage);
  console.log("RecommendationForm : isHomepage = ");
  console.log(isHomepage);

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <SelectInput
          source="recommendation_type"
          choices={[
            { id: "recom_personalised", name: "Personalised" },
            { id: "recom_trendy", name: "Trending" },
            { id: "recom_universal", name: "Universal List" },
            { id: "recom_free_preview", name: "Free Episode" },
          ]}
          defaultValue=""
          fullWidth
          onChange={(e) => {
            console.log(e.target.value);
            if (e.target.value == "recom_trendy") {
              form.change("title_tc", "熱門節目");
              form.change("title_en", "Trending");
            } else if (e.target.value == "recom_personalised") {
              form.change("title_tc", "為你推薦");
              form.change("title_en", "Recommendation");
            } else if (e.target.value == "recom_free_preview") {
              form.change("title_tc", "為你推薦免費劇集");
              form.change("title_en", "Free Episode Recommendation");
            }
            // else if(e.target.value == 'universal_list') {
            //   form.change("title_tc", "為你推薦");
            //   form.change("title_en", "Recommendation");
            // }
          }}
        />
      </Grid>

      <FormDataConsumer label="Value" fullWidth>
        {({ formData, ...rest }) => {
          if (!formData.recommendation_type) {
            return <></>;
          }

          if (formData.recommendation_type == "recom_universal") {
            return (
              <Grid container spacing={1} fullWidth>
                <Grid item sm={12} md={12}>
                  {isHomepage ? <NumberInput fullWidth label="No. of list to show (Enter from 1-40)" source="num_of_rows" validate={[required(), minValue(1), maxValue(40)]} max={40} min={1} /> : <NumberInput fullWidth label="No. of list to show (Enter from 1-30)" source="num_of_rows" validate={[required(), minValue(1), maxValue(30)]} max={30} min={1} />}
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextInput label="Row title in TC" source="title_tc" fullWidth disabled defaultValue={"Follow the universal list"} />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextInput label="Row title in EN" source="title_en" fullWidth disabled defaultValue={"Follow the universal list"} />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TemplateSelectInput layouts={["loop"]} defaultValue={"loop"} />
                </Grid>

                <Grid item sm={12} md={12}>
                  <PlatformCheckboxGroupInput fullWidth {...rest} />
                </Grid>

                <Grid item sm={12} md={12}>
                  <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
                </Grid>

                <ShelfDateTimeInput />
              </Grid>
            );
          }

          return (
            <Grid container spacing={1} fullWidth>
              <Grid item sm={12} md={6}>
                <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
              </Grid>
              <Grid item sm={12} md={6}>
                <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
              </Grid>
              <Grid item sm={12} md={12}>
                <TemplateSelectInput layouts={["loop", "ranking"]} defaultValue={"loop"} />
              </Grid>

              <Grid item sm={12} md={12}>
                <PlatformCheckboxGroupInput fullWidth {...rest} />
              </Grid>

              <ShelfDateTimeInput />
            </Grid>
          );
        }}
      </FormDataConsumer>
    </Grid>
  );
};

const listenerEN = (event) => {
  var maxlength = 170;

  document.getElementById("start-en").innerHTML = document.getElementById("seo_desc_en").value.length + 1;
};
const listenerTC = (event) => {
  var maxlength = 85;
  document.getElementById("start-tc").innerHTML = document.getElementById("seo_desc_tc").value.length + 1;
};

const DefaultValueEn = (props) => {
  const page = useRecordContext();
  let count = 0;
  if (page.seo_desc_en && page.seo_desc_en.length) {
    count = page.seo_desc_en.length;
  }
  return (
    <div id="count-en">
      <span id="start-en">{count}</span>/170
    </div>
  );
};
const DefaultValuetc = (props) => {
  const page = useRecordContext();
  let count = 0;
  if (page.seo_desc_tc && page.seo_desc_tc.length) {
    count = page.seo_desc_tc.length;
  }
  return (
    <div id="count-tc">
      <span id="start-tc">{count}</span>/85
    </div>
  );
};

export const ProgrammeListForm = ({ formData, rest, mode, page }) => {
  const dataProvider = useDataProvider();

  const [tagTypes, setTagTypes] = useState([]);

  useEffect(() => {
    dataProvider.getTagTypes().then((types) => {
      setTagTypes(types.data);
    });
  }, []);

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <ArrayInput source="tags">
          {/* Add TransitionProps because of bug: https://github.com/marmelab/react-admin/issues/6900 */}
          <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
            {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
            <SelectInput fullWidth source="tag_type" choices={tagTypes} label="Type" />
            <FormDataConsumer label="Value" fullWidth>
              {({ formData, scopedFormData, getSource, ...rest }) => {
                if (!scopedFormData) {
                  return <></>;
                }

                return (
                  <ReferenceInput
                    fullWidth
                    label="Value"
                    source={getSource("tag_value")}
                    reference="mpm-row-tag-autocomplete"
                    filter={{ tag_type: scopedFormData.tag_type }}
                    filterToQuery={(searchText) => {
                      if (searchText) {
                        return {
                          name_tc: searchText,
                        };
                      }
                      return {};
                    }}
                  >
                    <AutocompleteInput
                      fullWidth
                      optionText={(record) => {
                        if (record == null) {
                          return null;
                        }
                        return record.name_tc + " " + record.name_en;
                      }}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <BooleanInput label="Programmes with free episode only" source="free_episodes_only" defaultValue={false} fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>

        <RowPathInput disabled={mode == "edit"} page={page ? page : null} />

        {/* <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>

        <Grid item sm={12} md={12}>
          <RowPathInput disabled={mode == 'edit'} page={page ? page : null} />
        </Grid> */}

        <Grid item sm={12} md={12}>
          <TextInput onKeyDown={listenerTC} label="SEO Description in TC (within 85 char)" source="seo_desc_tc" fullWidth defaultValue={""} />
          <DefaultValuetc />
        </Grid>
        <Grid item sm={12} md={12}>
          <TextInput onKeyDown={listenerEN} label="SEO Description in EN (within 170 char)" source="seo_desc_en" fullWidth defaultValue={""} />
          <DefaultValueEn />
        </Grid>
        <Grid item sm={12} md={12}>
          <TemplateSelectInput layouts={["subcat", "multiline"]} multilineNumOfColumns={[4, 5]} />
        </Grid>

        {/* <FormDataConsumer>
            {({ formData, ...rest }) => {
              var subcatTemplate = templates.find((element) => element.layout == "subcat");
              if (subcatTemplate && formData.template == subcatTemplate.id) {
                return <BooleanInput label="Show it as item in menu tree" source="show_in_menu" />;
              }
              return <></>;
            }}
          </FormDataConsumer> */}

        <Grid item sm={12} md={12}>
          <PlatformCheckboxGroupInput fullWidth {...rest} />
        </Grid>

        <Grid item sm={12} md={12}>
          <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
        </Grid>

        <ShelfDateTimeInput />
      </Grid>
    </Grid>
  );
};

export const LatestForm = ({ formData, rest }) => {
  const dataProvider = useDataProvider();

  const [tagTypes, setTagTypes] = useState([]);

  useEffect(() => {
    dataProvider.getTagTypes().then((types) => {
      setTagTypes(types.data);
    });
  }, []);

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <ArrayInput source="tags">
          {/* Add TransitionProps because of bug: https://github.com/marmelab/react-admin/issues/6900 */}
          <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
            {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
            <SelectInput fullWidth source="tag_type" choices={tagTypes} label="Type" />
            <FormDataConsumer label="Value" fullWidth>
              {({ formData, scopedFormData, getSource, ...rest }) => {
                if (!scopedFormData) {
                  return <></>;
                }

                return (
                  <ReferenceInput
                    fullWidth
                    label="Value"
                    source={getSource("tag_value")}
                    reference="mpm-row-tag-autocomplete"
                    filter={{ tag_type: scopedFormData.tag_type }}
                    filterToQuery={(searchText) => {
                      if (searchText) {
                        return {
                          name_tc: searchText,
                        };
                      }
                      return {};
                    }}
                  >
                    <AutocompleteInput
                      fullWidth
                      optionText={(record) => {
                        if (record == null) {
                          return null;
                        }
                        return record.name_tc + " " + record.name_en;
                      }}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={12}>
          <TemplateSelectInput layouts={["loop"]} defaultValue={"loop"} />
        </Grid>

        <Grid item sm={12} md={12}>
          <PlatformCheckboxGroupInput fullWidth {...rest} />
        </Grid>

        <Grid item sm={12} md={12}>
          <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
        </Grid>

        <ShelfDateTimeInput />
      </Grid>
    </Grid>
  );
};

export const TopSVForm = ({ formData, rest }) => {
  const dataProvider = useDataProvider();

  const [tagTypes, setTagTypes] = useState([]);

  useEffect(() => {
    dataProvider.getTagTypes().then((types) => {
      setTagTypes(types.data);
    });
  }, []);

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <ArrayInput source="tags">
          {/* Add TransitionProps because of bug: https://github.com/marmelab/react-admin/issues/6900 */}
          <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
            {/* If want custom layout: https://stackoverflow.com/questions/60175609/arrayinput-with-simpleformiterator-of-react-admin-generate-wrong-output */}
            <SelectInput fullWidth source="tag_type" choices={tagTypes} label="Type" />
            <FormDataConsumer label="Value" fullWidth>
              {({ formData, scopedFormData, getSource, ...rest }) => {
                if (!scopedFormData) {
                  return <></>;
                }

                return (
                  <ReferenceInput
                    fullWidth
                    label="Value"
                    source={getSource("tag_value")}
                    reference="mpm-row-tag-autocomplete"
                    filter={{ tag_type: scopedFormData.tag_type }}
                    filterToQuery={(searchText) => {
                      if (searchText) {
                        return {
                          name_tc: searchText,
                        };
                      }
                      return {};
                    }}
                  >
                    <AutocompleteInput
                      fullWidth
                      optionText={(record) => {
                        if (record == null) {
                          return null;
                        }
                        return record.name_tc + " " + record.name_en;
                      }}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={12}>
          <TemplateSelectInput layouts={["loop", "ranking"]} defaultValue={"loop"} />
        </Grid>

        <Grid item sm={12} md={12}>
          <PlatformCheckboxGroupInput fullWidth {...rest} />
        </Grid>

        <Grid item sm={12} md={12}>
          <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
        </Grid>

        <ShelfDateTimeInput />
      </Grid>
    </Grid>
  );
};

export const WatchingHistoryForm = ({ formData, rest }) => {
  const form = useForm();

  return (
    <Grid container spacing={1} fullWidth>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth defaultValue="觀看紀錄" />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth defaultValue="Watching History" />
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <TemplateSelectInput layouts={["history"]} defaultValue="history" />
      </Grid>

      <Grid item sm={12} md={12}>
        <PlatformCheckboxGroupInput fullWidth {...rest} />
      </Grid>

      <Grid item sm={12} md={12}>
        <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
      </Grid>

      <ShelfDateTimeInput />
    </Grid>
  );
};

export const EventForm = ({ formData, rest }) => {
  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={12}>
        <SelectEventType {...formData} />
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <TemplateSelectInput layouts={["loop", "multiline"]} multilineNumOfColumns={[4, 5]} isRequired={true} />
      </Grid>

      <Grid item sm={12} md={12}>
        <PlatformCheckboxGroupInput fullWidth {...rest} />
      </Grid>

      <Grid item sm={12} md={12}>
        <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
      </Grid>

      <ShelfDateTimeInput />
    </Grid>
  );
};

const SelectEventType = (props) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState();

  console.log("SelectEventType : props = ");
  console.log(props);

  var initialValue = "";
  if (props.ref_x && props.ref_x.split) {
    var event_type_value = props.ref_x.split("/")[1];
    initialValue = event_type_value;
  } else if (props.programme_id && props.programme_id.split) {
    var event_type_value = props.programme_id.split("/")[1];
    initialValue = event_type_value;
  }

  useEffect(() => {
    dataProvider
      .getListEventType()
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!data) return null;

  const keys = data[0].values.map((value) => ({
    id: value,
    name: value,
  }));
  return <SelectInput fullWidth source="event_type_value" initialValue={initialValue} choices={keys} allowEmpty={true} />;
};
