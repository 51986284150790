import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider, useNotify, useRedirect } from "react-admin";
import { MenuTreeEdit2 } from "./components/menu-tree-edit-2";
import { MenuTreeMassage } from "./components/menu-tree-massage";

import "./custom.css";

// popup
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
const queryString = require("query-string");

// import dataProvider from "../../dataProvider";

// var moment = require("moment-timezone");

// const customButtonStyle = {
//   backgroundColor: "transparent",
//   border: "1px solid grey",
//   margin: "10px",
// };

export const MenuTreeCreate = (props) => {
  // console.log("MenuTreeCreate : props = ");
  // console.log(props);
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const notify = useNotify();

  // constructor
  const [resp, setResp] = useState(false);
  // const [tagTypes, setTagTypes] = useState([]);

  // const setStartTime = useCallback((value) => {
  //   console.log("MenuTreeCreate . setStartTime : value = ");
  //   console.log(value);
  //   resp.start_time = value;
  //   setResp(resp);
  // });

  // const setDescription = useCallback((value) => {
  //   console.log("MenuTreeCreate . setDescription : value = ");
  //   console.log(value);
  //   resp.description = value;
  //   setResp(resp);
  // });

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);

    // const source = (function () {
    //   const s = props.location.search;
    //   if (s.length <= 1) return null;

    //   const s2 = s.substring(1);
    //   const o1 = JSON.parse('{"' + decodeURI(s2).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    //   const o2 = decodeURIComponent(o1.source);
    //   // console.log('o2')
    //   // console.log(o2)
    //   const o3 = o2.replace(/\//g, "\\/").replace(/\\/g, ""); //      / is escaped , \ is removed
    //   // console.log("o3");
    //   // console.log(o3);
    //   const o4 = JSON.parse(o3);

    //   return o4;
    // })();

    if (parsed && parsed.source) {
      // if (source) {
      const j = JSON.parse(parsed.source);
      setResp(j);
    } else {
      setResp({
        menu_tree: [],
        start_time: "",
        description: "description here",
      });
    }
  }, []);

  // call POST API
  const saveMenuTreeCallback = useCallback((params) => {
    // console.log("MenuTreeCreate . saveMenuTreeCallback : param1 = ");
    // console.log(params);
    const rows = params.rows;
    const startTime = params.startTime;
    const description = params.description;
    const profileClass = resp.profile_class;

    // massage rows
    const menuTree = MenuTreeMassage(rows);

    const param = {
      menu_tree: menuTree,
      start_time: startTime,
      description: description,
      profile_class: profileClass,
    };
    // console.log("MenuTreeCreate . saveMenuTreeCallback : param2 = ");
    // console.log(param);

    dataProvider
      .postMenuTree(param)
      .then((result) => {
        // console.log("MenuTreeCreate . saveMenuTree . postMenuTree : result");
        // console.log(result);
        notify("Create Menu tree is Successful", "success");
        redirectTo("/menu-tree");
      })
      .catch((e) => {
        // console.log("MenuTreeCreate . saveMenuTree . postMenuTree : error");
        // console.log(e);
        notify(e.message.toString(), "warning");
      });
  });

  return resp.menu_tree ? <MenuTreeEdit2 {...props} mode="create" rows={JSON.parse(JSON.stringify(resp.menu_tree))} start_time={resp.start_time} description={resp.description} saveMenuTreeCallback={saveMenuTreeCallback} /> : <></>;
};
