import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from "react-admin";
import { ChannelList, ChannelShow, ChannelEdit, ChannelCreate } from "./resources/channels/channels";
import { ChannelOrderingList } from "./resources/channels/channels-ordering";
import { UserList, UserShow, UserEdit, UserCreate } from "./resources/users/users";
import { RoleList, RoleShow, RoleEdit, RoleCreate } from "./resources/roles/roles";
import { KidsMenuTreePreview, GeneralMenuTreePreview } from "./resources/menu-tree/menu-tree-preview";
import { MenuTreeList } from "./resources/menu-tree/menu-tree-list";
import { MenuTreeCreate } from "./resources/menu-tree/menu-tree-create";
import { MenuTreeEdit } from "./resources/menu-tree/menu-tree-edit";
import { BitrateProfileList, BitrateProfileShow, BitrateProfileEdit, BitrateProfileCreate } from "./resources/bitrate-profiles";
import { DrmProfileList, DrmProfileShow, DrmProfileEdit, DrmProfileCreate } from "./resources/drm-profiles";
import { CodingProfileList, CodingProfileShow, CodingProfileEdit, CodingProfileCreate } from "./resources/coding-profiles";
import { ArtistList, ArtistShow, ArtistEdit, ArtistCreate } from "./resources/artists";
import { ProgrammeList, ProgrammeShow, ProgrammeEdit } from "./resources/programmes";
import { EpisodeList, EpisodeShow, EpisodeEdit } from "./resources/episodes";
// import { FilteringList, FilteringShow, FilteringEdit } from "./resources/subcat-filters/subcat-filters";
import { FilteringList } from "./resources/subcat-filters/subcat-filters-list";
import { FilteringEdit } from "./resources/subcat-filters/subcat-filters-edit";
import { VipsList, VipEdit, VipCreate, VipShow } from "./resources/vips";
import { LandingList } from "./resources/landing";
import { NotyList, NotyEdit, NotyCreate, NotyShow } from "./resources/notification/notification";
import { TagsList, TagsShow } from "./resources/tags";
import { PosterList, PosterShow, PosterEdit, PosterCreate } from "./resources/overide-poster";
import { PasswordCreate } from "./resources/password";
import { MPMList } from "./resources/mpm";
import { SlotList, SlotEdit, SlotCreate } from "./resources/slots";
import { KidsProfileIconList, KidsProfileIconEdit, KidsProfileIconCreate, KidsProfileIconShow, GeneralProfileIconList, GeneralProfileIconEdit, GeneralProfileIconCreate, GeneralProfileIconShow } from "./resources/profile-icons";
import { KidsProfilesPreferenceList, KidsProfilesPreferenceCreate, GeneralProfilesPreferenceList, GeneralProfilesPreferenceCreate } from "./resources/profiles-preferences";
import { KidsHotSearchList, KidsHotSearchShow, KidsHotSearchEdit, KidsHotSearchCreate, GeneralHotSearchList, GeneralHotSearchShow, GeneralHotSearchEdit, GeneralHotSearchCreate } from "./resources/hot-searches/hot-searches";
import { EventList, EventEdit, EventCreate, EventShow } from "./resources/events";
import { HorseRaceList, HorseRaceEdit, HorseRaceCreate } from "./resources/horserace";
import { HorseRaceDataList, HorseRaceDataEdit, HorseRaceDataCreate } from "./resources/horserace-data";
import { KeyValueList, KeyValueEdit, KeyValueCreate, KeyValueShow } from "./resources/key-values";
import { EventTypeList, EventTypeEdit } from "./resources/event-type";
import { EditorialList, EditorialEdit, EditorialCreate, EditorialShow } from "./resources/editorial";
import { MpmPageDeleteList } from "./resources/mpm-page-delete";
import { ImagesList } from "./resources/images";
import { AdGroupingList, AdGroupingCreate, AdGroupingEdit } from "./resources/ad-groupings";

import { IMChatroomList, IMChatroomEdit, IMChatroomCreate, IMChatroomShow } from "./resources/im-chatrooms/im-chatrooms";
import { IMCannedMessageList, IMCannedMessageEdit, IMCannedMessageCreate } from "./resources/im-canned-messages/im-canned-messages";
import { IMIconList, IMIconEdit, IMIconCreate } from "./resources/im-icons/im-icons";

import { GeneralHomepageList, GeneralHomepageEdit, KidsHomepageList, PageList, PageCreate, GeneralPageEdit, KidsHomepageEdit } from "./resources/pages/pages";
import { MpmRowCreate, MpmRowEdit } from "./resources/mpm-rows/mpm-rows";

import { CategoriesList, CategoriesEdit, CategoriesCreate, CategoriesShow } from "./resources/categories";
import { SpecialProgramList } from "./resources/special-programs";
import { ChannelNoList } from "./resources/channel-nos";
import { VipoBlacklistList } from "./resources/vipo-blacklist";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { customRoutes } from "./customRoutes";
import { MyLayout } from "./MyLayout";
import PeopleIcon from "@material-ui/icons/People";
import TvIcon from "@material-ui/icons/Tv";
import Dashboard from "./resources/Dashboard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { Customtheme } from "./customTheme";
import { ImportRequestsList } from "./resources/import-requests/import-requests";
import { TagsForShowEdit } from "./resources/tags-for-show";

import { EmarsysTitleHomepageList, EmarsysTitlePageEdit } from "./resources/emarsys-title/emarsys-title";

import { PageRowPathList } from "./resources/page-row-paths/page-row-paths-list";
import { PageRowPathEdit } from "./resources/page-row-paths/page-row-paths-edit";
import { McdnAppNameEdit, McdnAppNameList } from "./resources/mcdn-appname";
import { FilteringOverwriteList } from "./resources/subcat-filter-overwrite/subcat-filter-overwrite-list";
import { FilteringOverwriteCreate } from "./resources/subcat-filter-overwrite/subcat-filter-overwrite-create";
import { FilteringOverwriteEdit } from "./resources/subcat-filter-overwrite/subcat-filter-overwrite-edit";
import { EpisodePreviewScheduleList } from "./resources/episode-preview-schedules/episode-preview-schedules-list";
import { OverwritePosterModuleList } from "./resources/overwrite-poster-module/list";
import { OverwritePosterModuleCreate } from "./resources/overwrite-poster-module/create";
import { VipsAdFreeCreate, VipsAdFreeEdit, VipsAdFreeList, VipsAdFreeShow } from "./resources/vips-ad-free";
import { VodLivesList } from "./resources/vod-lives/vod-lives-list";
import { VodLivesEdit } from "./resources/vod-lives/vod-lives-edit";
import { VodLivesShow } from "./resources/vod-lives/vod-lives-show";

const App = () => (
  <Admin
    // theme={Customtheme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
    layout={MyLayout}
    disableTelemetry

    // dashboard={Dashboard}
  >
    <Resource name="landing" list={LandingList} />

    <Resource name="ads-adgroups" list={AdGroupingList} create={AdGroupingCreate} edit={AdGroupingEdit} options={{ label: "Ad Grouping" }} />

    <Resource name="admin" icon={NarrowIcon} options={{ label: "Admin", isMenuParent: true }} />
    <Resource name="roles" list={RoleList} show={RoleShow} edit={RoleEdit} create={RoleCreate} options={{ label: "Roles", menuParent: "admin" }} />
    <Resource name="users" list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={PeopleIcon} options={{ label: "Users", menuParent: "admin" }} />

    <Resource name="mpm-group" icon={NarrowIcon} options={{ label: "Editorial Pick", isMenuParent: true }} />
    <Resource options={{ label: "Editorial Listing", menuParent: "mpm-group" }} name="editorial-types" list={EditorialList} edit={EditorialEdit} create={EditorialCreate} show={EditorialShow} />
    <Resource name="events" list={EventList} edit={EventEdit} create={EventCreate} show={EventShow} />

    <Resource name="frontend-ui" icon={NarrowIcon} options={{ label: "Frontend UI", isMenuParent: true }} />
    <Resource name="emarsys-title" options={{ label: "Emarsys Title", menuParent: "frontend-ui" }} list={EmarsysTitleHomepageList} edit={EmarsysTitlePageEdit} />
    <Resource name="mpm-home-page" options={{ label: "Homepage", menuParent: "frontend-ui" }} list={GeneralHomepageList} edit={GeneralHomepageEdit} />
    <Resource name="menu-tree/preview" options={{ label: "Menu Tree", menuParent: "frontend-ui" }} list={GeneralMenuTreePreview} />
    <Resource name="mpm-pages" options={{ label: "Page", menuParent: "frontend-ui" }} list={PageList} create={PageCreate} edit={GeneralPageEdit} />
    <Resource name="page-row-paths" options={{ label: "Page / Row Path", menuParent: "frontend-ui" }} list={PageRowPathList} edit={PageRowPathEdit} />

    <Resource name="horserace" list={HorseRaceList} edit={HorseRaceEdit} create={HorseRaceCreate} />

    <Resource name="hot-searchs" options={{ label: "Hot Searches" }} list={GeneralHotSearchList} show={GeneralHotSearchShow} edit={GeneralHotSearchEdit} create={GeneralHotSearchCreate} />

    <Resource name="im" icon={NarrowIcon} options={{ label: "Instant Message", isMenuParent: true }} />
    <Resource name="im-canned-messages" options={{ label: "Canned Message", menuParent: "im" }} list={IMCannedMessageList} edit={IMCannedMessageEdit} create={IMCannedMessageCreate} />
    <Resource name="im-rooms" options={{ label: "Chat Room", menuParent: "im" }} list={IMChatroomList} edit={IMChatroomEdit} create={IMChatroomCreate} show={IMChatroomShow} />
    <Resource name="im-icons" options={{ label: "Icon", menuParent: "im" }} list={IMIconList} edit={IMIconEdit} create={IMIconCreate} />

    <Resource name="kids" icon={NarrowIcon} options={{ label: "Kids mode", isMenuParent: true }} />
    <Resource name="kids-mpm-home-page" options={{ label: "Homepage", menuParent: "kids" }} list={KidsHomepageList} edit={KidsHomepageEdit} />
    <Resource name="kids-hot-searchs" options={{ label: "Hot Searches", menuParent: "kids" }} list={KidsHotSearchList} show={KidsHotSearchShow} edit={KidsHotSearchEdit} create={KidsHotSearchCreate} />
    <Resource name="kids-menu-tree/preview" options={{ label: "Menu Tree", menuParent: "kids" }} list={KidsMenuTreePreview} />
    <Resource name="kids-profile-icons" options={{ label: "Profile Icons", menuParent: "kids" }} list={KidsProfileIconList} edit={KidsProfileIconEdit} create={KidsProfileIconCreate} show={KidsProfileIconShow} />
    <Resource name="kids-profiles-preferences" options={{ label: "Profiles Preference", menuParent: "kids" }} list={KidsProfilesPreferenceList} create={KidsProfilesPreferenceCreate} />

    <Resource name="master-table" icon={NarrowIcon} options={{ label: "Master Table", isMenuParent: true }} />
    <Resource name="artists" options={{ label: "Artists", menuParent: "master-table" }} list={ArtistList} show={ArtistShow} />
    <Resource name="event-type" options={{ label: "Event Type", menuParent: "master-table" }} list={EventTypeList} edit={EventTypeEdit} />
    <Resource name="mcdn-appname" options={{ label: "MCDN Appname", menuParent: "master-table" }} list={McdnAppNameList} edit={McdnAppNameEdit} />
    <Resource name="profile-icons" list={GeneralProfileIconList} edit={GeneralProfileIconEdit} create={GeneralProfileIconCreate} show={GeneralProfileIconShow} options={{ label: "Profile Icons", menuParent: "master-table" }} />
    <Resource name="tags" list={TagsList} show={TagsShow} options={{ label: "Tags", menuParent: "master-table" }} />

    <Resource name="pools" icon={NarrowIcon} options={{ label: "Pools", isMenuParent: true }} />
    <Resource name="channel-nos" list={ChannelNoList} icon={TvIcon} options={{ label: "Channels", menuParent: "pools" }} />
    <Resource name="channel-ordering" list={ChannelOrderingList} options={{ label: "Channels Ordering", menuParent: "pools" }} />
    <Resource name="subcat-filters" list={FilteringList} edit={FilteringEdit} options={{ label: "Filtering", menuParent: "pools" }} />
    <Resource name="images" list={ImagesList} options={{ label: "Image", menuParent: "pools" }} />
    <Resource name="programme-overwrite-poster-module" list={OverwritePosterModuleList} create={OverwritePosterModuleCreate} options={{ label: "Overwrite Programme Poster", menuParent: "pools" }} />
    <Resource name="programmes" list={ProgrammeList} show={ProgrammeShow} edit={ProgrammeEdit} options={{ label: "Programmes", menuParent: "pools" }} />

    <Resource name="episode-preview-schedules" list={EpisodePreviewScheduleList} options={{ label: "Preview Schedule" }} />

    <Resource name="profiles" icon={NarrowIcon} options={{ label: "Profiles", isMenuParent: true }} />
    <Resource name="bitrate-profiles" list={BitrateProfileList} show={BitrateProfileShow} edit={BitrateProfileEdit} create={BitrateProfileCreate} options={{ label: "Bitrate Profile", menuParent: "profiles" }} />
    <Resource name="coding-profiles" list={CodingProfileList} show={CodingProfileShow} edit={CodingProfileEdit} create={CodingProfileCreate} options={{ label: "Coding Profile", menuParent: "profiles" }} />
    <Resource name="drm-profiles" list={DrmProfileList} show={DrmProfileShow} edit={DrmProfileEdit} create={DrmProfileCreate} options={{ label: "DRM Profile", menuParent: "profiles" }} />

    <Resource name="profiles-preferences" options={{ label: "Profiles Preference" }} list={GeneralProfilesPreferenceList} create={GeneralProfilesPreferenceCreate} />

    <Resource name="push-notifications" options={{ label: "Push Notifications" }} list={NotyList} edit={NotyEdit} create={NotyCreate} show={NotyShow} />

    <Resource name="special-program" list={SpecialProgramList} options={{ label: "Special Program" }} />

    <Resource name="vipo-blacklist" list={VipoBlacklistList} edit={VipEdit} create={VipCreate} show={VipShow} options={{ label: "VIPO Blacklist" }} />

    <Resource name="vips" list={VipsList} edit={VipEdit} create={VipCreate} show={VipShow} />

    <Resource name="vips-ad-free" list={VipsAdFreeList} edit={VipsAdFreeEdit} create={VipsAdFreeCreate} show={VipsAdFreeShow} />

    {/* this is only for QA */}
    <Resource name="vod-live-testing-data" list={VodLivesList} show={VodLivesShow} edit={VodLivesEdit} options={{ label: "VOD live testing data" }} />

    {/* Pages not shown in Menu */}
    <Resource name="key-values" list={KeyValueList} edit={KeyValueEdit} create={KeyValueCreate} show={KeyValueShow} />
    <Resource name="categories" list={CategoriesList} edit={CategoriesEdit} show={CategoriesShow} options={{ label: "Categories", menuParent: "hide" }} />
    <Resource name="import-requests" list={ImportRequestsList} edit={ImportRequestsList} options={{ label: "", menuParent: "hide" }} />
    <Resource name="slots" list={SlotList} edit={SlotEdit} create={SlotCreate} />
    <Resource name="episodes" list={EpisodeList} show={EpisodeShow} edit={EpisodeEdit} />
    <Resource name="password" create={PasswordCreate} />
    <Resource name="channels" list={ChannelList} show={ChannelShow} edit={ChannelEdit} create={ChannelCreate} icon={TvIcon} options={{ label: "Channels", menuParent: "pools" }} />
    <Resource name="subcat-filter-overwrite-list" list={null} edit={FilteringOverwriteList} />
    <Resource name="subcat-filter-overwrite-create" list={null} edit={FilteringOverwriteCreate} />
    <Resource name="subcat-filter-overwrite-edit" list={null} edit={FilteringOverwriteEdit} />
    <Resource name="programme-overwrite-posters" list={PosterList} show={PosterShow} edit={PosterEdit} create={PosterCreate} />
    <Resource name="horserace-data" list={HorseRaceDataList} edit={HorseRaceDataEdit} create={HorseRaceDataCreate} />
    <Resource name="mpm-rows" create={MpmRowCreate} edit={MpmRowEdit} />
    <Resource name="menu-tree" list={MenuTreeList} create={MenuTreeCreate} edit={MenuTreeEdit} options={{ label: "Menu Tree", menuParent: "hide" }} />
    <Resource name="mpm" list={MPMList} options={{ label: "myTV SUPER", menuParent: "mpm-group" }} />

    {/* API only */}
    <Resource name="mpm-row-templates" />
    <Resource name="programmes-autocomplete" />
    <Resource name="im-canned-messages-autocomplete" />
    <Resource name="episodes-autocomplete" />
    <Resource name="editorials-autocomplete" />
    <Resource name="clips-autocomplete" />
    <Resource name="channels-autocomplete" />
    <Resource name="channels-autocomplete-2" />
    <Resource name="mpm-page-autocomplete" />
    <Resource name="progammes-by-prorgamme_id" />
    <Resource name="clip-by-clip_id" />
    <Resource name="channels-by-network_code" />
    <Resource name="key-value-by-quality" />
    <Resource name="key-value-by-image-type" />
    <Resource name="key-value-by-image-platform" />
    <Resource name="key-value-by-platform" />
    <Resource name="key-value-by-drm" />
    <Resource name="key-value-by-coding" />
    <Resource name="key-value-by-event" />
    <Resource name="clips-type" />
    <Resource name="tags-autocomplete" />
    <Resource name="tags-checkbox" />
    <Resource name="tags-programme-show" />
    <Resource name="vod-lives" />
    <Resource name="mpm-row-schedules/type" />
    <Resource name="vodlives-autocomplete" />
    <Resource name="tags/type" />
    <Resource name="tags-for-show" />
    <Resource name="profiles-mapping" />
    <Resource name="mpm-row-page" />
    <Resource name="mpm-row-tag-autocomplete" />
    <Resource name="ads-adgroups-programs-search" />
    <Resource name="ads-adgroups-programs" />
    <Resource name="special-program-programs" />
    <Resource name="programmes-for-import-requests" />
    <Resource name="permissions" />
  </Admin>
);

export default App;

const NarrowIcon = () => {
  return <ArrowForwardIosIcon fontSize="small" />;
};
