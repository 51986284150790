import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput, required, TextInput, useDataProvider } from "react-admin";
import { useForm } from "react-final-form";
import { ShelfDateTimeInput } from "./shelf-date-time-input";
import { TemplateSelectInput } from "./template-select-input";
import { showFormContent } from "./show-form-content";
import { PlatformCheckboxGroupInput } from "../../component/platform-components";
import { RegionCheckboxGroupInput } from "../../component/region-components";

export const CategoryListForm = (props) => {
  // const formData = props.formData;
  // const rest = props.rest;

  // reset the form into initial values
  // const form = useForm();
  // const dataProvider = useDataProvider();

  // console.log("CategoryListForm : props = ");
  // console.log(props);
  // console.log("CategoryListForm : form = ");
  // console.log(showFormContent(form));

  // useEffect(() => {
  //   console.log("CategoryListForm . useEffect :");
  // }, []);

  //   const fetchProgramme = useCallback(async(filterType)=>{

  //     const result = await dataProvider.getProgrammeByProgrammeId(filterType);

  //     console.log('CategoryListForm . fetchProgramme : result');
  //     console.log(result);

  //     form.change('programme_name', result.data.name_tc);
  //     form.change('title_tc', result.data.name_tc);
  //     form.change('title_en', result.data.name_en);

  //   });

  return (
    <Grid container spacing={1} fullWidth>
      {/* <Grid item sm={12} md={12}>
        <AutoCompleteEpisode
          clearAlwaysVisible={true}
          fullWidth
          formData={formData}
          {...rest}
          allowEmpty={true}
          fetchProgramme={fetchProgramme}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput
          label="Programme Name"
          source="programme_name"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput
          label="Programme ID"
          source="programme_id"
          disabled
          validate={required()}
          fullWidth
        />
      </Grid> */}

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in TC" source="title_tc" validate={required()} fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Row title in EN" source="title_en" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid item sm={12} md={12}>
        <TemplateSelectInput layouts={["loop_text"]} defaultValue={"loop_text"} isRequired={true} />
      </Grid>

      <Grid item sm={12} md={12}>
        <PlatformCheckboxGroupInput fullWidth />
      </Grid>

      <Grid item sm={12} md={12}>
        <RegionCheckboxGroupInput noDefaultValue={true} fullWidth />
      </Grid>

      <ShelfDateTimeInput />
    </Grid>
  );
};

// const AutoCompleteEpisode = (props) => {
//   console.log("AutoCompleteEpisode : props = ");
//   console.log(props);

//   const form = useForm();

//   useEffect(() => {});

//   return (
//     <ReferenceInput
//       label="Search Programme Name"
//       source="episode_autocomplete"
//       reference="programmes-autocomplete"
//       onChange={(val) => {
//         console.log("AutoCompleteEpisode . onChange : val = ");
//         console.log(val);

//         props.fetchProgramme(val);

//         form.change("programme_id", val);
//       }}
//       filterToQuery={(searchText) => {
//         if (searchText) {
//           return { name_tc: searchText };
//         }
//         return {};
//       }}
//     >
//       <AutocompleteInput optionText="name_tc" optionValue="programme_id" fullWidth />
//     </ReferenceInput>
//   );
// };
