import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput, required, TextInput, useDataProvider } from "react-admin";
import { useForm } from "react-final-form";
import { mytvsuperUrl } from "../../../constant";
import { showFormContent } from "../../mpm-rows/components/show-form-content";

export const ChannelSearch = (props) => {
  const formData = props.formData;
  const rest = props.rest;

  // reset the form into initial values
  const form = useForm();
  const dataProvider = useDataProvider();

  console.log("ChannelSearch : props = ");
  console.log(props);
  console.log("ChannelSearch : form = ");
  console.log(showFormContent(form));

  // check change of programme_id , fetch programme name , fill the field
  //   useEffect(async() => {
  //     console.log("ChannelSearch . useEffect :");

  //     if(formData.channel_id){
  //     //   const result = await dataProvider.getProgrammeByProgrammeId(parseInt(formData.channel_id));

  //     //   console.log('ChannelSearch . useEffect : fetchChannel result');
  //     //   console.log(result);

  //     //   if(result && result.data && result.data.name_tc){
  //     //     form.change('programme_name', result.data.name_tc);

  //     //     // Auto complete "destination_url"
  //     //     form.change('destination_url', 'https://www.mytvsuper.com/programme/' + result.data.path + '/');
  //     //   }

  //     }
  //   }, [formData.channel_id]);

  const fetchChannel = useCallback(async (param) => {
    console.log("ChannelSearch . fetchChannel : param = ");
    console.log(param);

    const result = await dataProvider.getManyChannelsAutocomplete2({
      ids: [param],
    });

    console.log("ChannelSearch . fetchChannel : result");
    console.log(result);

    form.change("network_code_display", result.data[0].network_code);
    form.change("title_tc", result.data[0].name_tc);
    form.change("title_en", result.data[0].name_en);

    // Auto complete "destination_url"
    form.change("destination_url", mytvsuperUrl + "/tc/live/" + result.data[0].channel_no + "/");
  });

  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={6}>
        <AutoCompleteChannel clearAlwaysVisible={true} fullWidth formData={formData} {...rest} allowEmpty={true} fetchChannel={fetchChannel} />
      </Grid>
      <Grid item sm={12} md={6}>
        <TextInput label="Content ID" source="network_code_display" disabled fullWidth />
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Caption in TC *" source="title_tc" disabled fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Caption in EN *" source="title_en" disabled fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
};

const AutoCompleteChannel = (props) => {
  console.log("AutoCompleteChannel : props = ");
  console.log(props);

  const form = useForm();

  useEffect(() => {});

  return (
    <ReferenceInput
      label="Search Channel Name"
      source="channel_autocomplete"
      reference="channels-autocomplete-2"
      onChange={(val) => {
        console.log("AutoCompleteChannel . onChange : val = ");
        console.log(val);

        props.fetchChannel(val);

        form.change("channel_no", val);
      }}
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
    >
      <AutocompleteInput optionText="channel_text" optionValue="channel_no" fullWidth />
    </ReferenceInput>
  );
};
