import React from "react";
import { AutocompleteInput, Button, Confirm, CreateButton, Datagrid, List, ReferenceInput, SimpleForm, TextField, useDataProvider, useNotify, useRefresh, useUnselectAll } from "react-admin";

import { Grid } from "@material-ui/core";
import { useForm } from "react-final-form";
import { MyDateField } from "../MyDateField";

const RemoveVipoDataButton = (props) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <React.Fragment>
      <Button
        label="Remove VIPO Data"
        onClick={() => {
          setShowConfirm(true);
        }}
      />
      <Confirm
        isOpen={showConfirm}
        title={"Are you sure you want to remove the VIPO Data of " + props.record.name_tc + "?"}
        onConfirm={async () => {
          try {
            await dataProvider.removeVipoData(props.record.id);

            refresh();
          } catch (error) {
            notify(error.message.toString(), "warning");
          }

          setShowConfirm(false);
        }}
        onClose={() => {
          setShowConfirm(false);
        }}
      />
    </React.Fragment>
  );
};

const AddVipoBlacklistButton = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  return (
    <CreateButton
      label="Add"
      onClick={async (e) => {
        e.preventDefault();

        const formData = form.getState().values;
        if (formData.programme_id) {
          await dataProvider.addVipoBlacklist(formData.programme_id);

          refresh();
          form.change("programme_id", undefined);
        } else {
          console.log("NO PROGRAMME ID");
        }
      }}
    />
  );
};

const AutoCompleteProgramme = (props) => {
  return (
    <Grid container spacing={1} fullWidth>
      <Grid item sm={12} md={8}>
        <ReferenceInput
          label="Search Programme Name or ID"
          source="programme_id"
          reference="programmes-autocomplete"
          onChange={(val) => {}}
          filterToQuery={(searchText) => {
            if (searchText) {
              return { name_tc: searchText };
            }
            return {};
          }}
        >
          <AutocompleteInput optionText="name_tc" optionValue="id" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item sm={12} md={4}>
        <AddVipoBlacklistButton />
      </Grid>
    </Grid>
  );
};

const MyBulkActionButtons = (props) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();

  return (
    <React.Fragment>
      <Button
        label="Delete"
        style={{ color: "red" }}
        onClick={() => {
          setShowConfirm(true);
        }}
      />
      <Confirm
        isOpen={showConfirm}
        title={"Are you sure you want to delete the programme(s) from the list?"}
        onConfirm={async () => {
          try {
            await dataProvider.deleteManyVipoBlacklist(props.selectedIds);

            refresh();
            unselectAll("vipo-blacklist");
          } catch (error) {
            notify(error.message.toString(), "warning");
          }

          setShowConfirm(false);
        }}
        onClose={() => {
          setShowConfirm(false);
        }}
      />
    </React.Fragment>
  );
};

export const VipoBlacklistList = (props) => {
  const isSmall = false;
  return (
    <>
      <SimpleForm toolbar={false}>
        <AutoCompleteProgramme />
      </SimpleForm>
      <List {...props} filter={{ enabled: true }} sort={{ field: "modified_at", order: "DESC" }} filters={false} actions={false} bulkActionButtons={<MyBulkActionButtons />}>
        <Datagrid>
          <TextField source="programme_id" />
          <TextField label="Name in TC" source="name_tc" />
          <TextField label="Name in EN" source="name_en" />
          <MyDateField source="modified_at" label="Last update time in VIPO Blacklist" showTime />
          <RemoveVipoDataButton />
        </Datagrid>
      </List>
      <h1 style={{ textAlign: "center" }}>Record of removing VIPO data</h1>
      <List {...props} filter={{ enabled: true, requested_to_vipo: true }} sort={{ field: "modified_at", order: "DESC" }} filters={false} actions={false} bulkActionButtons={false}>
        <Datagrid>
          <TextField source="programme_id" />
          <TextField label="Name in TC" source="name_tc" />
          <TextField label="Name in EN" source="name_en" />
          <MyDateField source="requested_to_vipo_at" label="Time" showTime />
        </Datagrid>
      </List>
    </>
  );
};
