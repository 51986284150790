import {
  List, //
  Show,
  Edit,
  Create,
  DeleteButton,
  Datagrid,
  ShowButton,
  EditButton,
  TextField,
  SimpleForm,
  Link,
  TextInput,
  DateTimeInput,
  SimpleList,
  SimpleShowLayout,
  ImageField,
  ImageInput,
  Labeled,
  useNotify,
  required,
  Filter,
  NumberInput,
  ExportButton,
  TopToolbar,
  Button,
  DeleteWithConfirmButton,
} from "react-admin";

import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { MyDateField } from "../MyDateField";

const PosterFilter = (props) => (
  <Filter {...props}>
    <TextInput disabled label="Programme ID" source="programme_id" alwaysOn />
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/programme-overwrite-posters/create`,
        search: `programme_id=${props.filterValues.programme_id}`,
      }}
      label="+ CREATE"
    />
    <ExportButton />
  </TopToolbar>
);

export const PosterList = (props) => {
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;
  return (
    <List {...props} sort={{ field: "order", order: "ASC" }} filters={<PosterFilter />} actions={<ListActions />}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField source="programme_id" />
          <ImageField source="portrait_large" />
          <ImageField source="landscape_large" />
          <MyDateField source="start_time" showTime />
          <MyDateField source="end_time" showTime />
          <ShowButton />
          <EditButton />
          <DeleteWithConfirmButton />
        </Datagrid>
      )}
    </List>
  );
};

const EpisodeTitle = ({ record }) => {
  return <span>Poster of {record ? `${record.programme_id}` : ""}</span>;
};

export const PosterShow = (props) => (
  <Show {...props} title={<EpisodeTitle />}>
    <SimpleShowLayout>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Start Time">
            <MyDateField source="start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="End Time">
            <MyDateField source="end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Small (128 x 186)">
            <ImageField source="portrait_small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Medium(200 x 290)">
            <ImageField source="portrait_medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Large (743 x 1080)">
            <ImageField source="portrait_large" title="" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Small (324 x 182)">
            <ImageField source="landscape_small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Medium (615 x 346)">
            <ImageField source="landscape_medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Large (1920 x 1080)">
            <ImageField source="landscape_large" title="" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Programme ID">
            <TextField source="programme_id" />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export const PosterEdit = (props) => {
  const notify = useNotify();
  const history = useHistory();

  const onSuccess = (result) => {
    console.log("onSuccess : result = ");
    console.log(result);
    history.goBack();
    notify("Edit Programme overwrite poster success", "success");
  };
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const shadeStyle = { position: "absolute", width: "100%", height: "100%", top: "0", backgroundColor: "rgba(255, 255, 255, 0.4)" };

  return (
    <Edit {...props} onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" title={<EpisodeTitle />}>
      <SimpleForm>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <NumberInput source="programme_id" disabled="disabled" />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="start_time" validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="end_time" validate={required()} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4}>
            <ImageField source="portrait_small" title="title" label="Current Portrait Small (128 x 186)" />
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageField source="portrait_medium" title="title" label="Current Portrait Medium(200 x 290)" />
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageField source="portrait_large" title="title" label="Current Portrait Large (743 x 1080)" />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4} style={{ position: "relative" }}>
            <ImageInput source="portrait_small_base64" label="Portrait Small (128 x 186)" accept="image/*" placeholder={<p>Auto resize from “Portrait Large”</p>}>
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle}></div>
          </Grid>
          <Grid item sm={12} md={4} style={{ position: "relative" }}>
            <ImageInput source="portrait_medium_base64" label="Portrait Medium(200 x 290)" accept="image/*" placeholder={<p>Auto resize from “Portrait Large”</p>}>
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle}></div>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="portrait_large_base64" label="Portrait Large (743 x 1080)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4}>
            <ImageField source="landscape_small" title="title" label="Current Landscape Small (324 x 182)" />
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageField source="landscape_medium" title="title" label="Current Landscape Medium (615 x 346)" />
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageField source="landscape_large" title="title" label="Current Landscape Large (1920 x 1080)" />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4} style={{ position: "relative" }}>
            <ImageInput source="landscape_small_base64" label="Landscape Small (324 x 182)" accept="image/*" placeholder={<p>Auto resize from “Portrait Large”</p>}>
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle}></div>
          </Grid>
          <Grid item sm={12} md={4} style={{ position: "relative" }}>
            <ImageInput source="landscape_medium_base64" label="Landscape Medium (615 x 346)" accept="image/*" placeholder={<p>Auto resize from “Portrait Large”</p>}>
              <ImageField source="src" title="title" />
            </ImageInput>
            <div style={shadeStyle}></div>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="landscape_large_base64" label="Landscape Large (1920 x 1080)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const PosterCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  let programme_str = document.location;
  let programme_id = programme_str.href.split("programme_id=")[1];
  return (
    <Create {...props} onFailure={onFailure} mutationMode="pessimistic" title="Create Poster">
      <SimpleForm method="POST">
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <NumberInput disabled type="number" source="programme_id" defaultValue={programme_id} />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="start_time" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <MyDateTimeInput source="end_time" fullWidth validate={required()} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4}>
            <ImageInput source="portrait_small_base64" label="Portrait Small (128 x 186)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="portrait_medium_base64" label="Portrait Medium(200 x 290)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="portrait_large_base64" label="Portrait Large (743 x 1080)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4}>
            <ImageInput source="landscape_small_base64" label="Landscape Small (324 x 182)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="landscape_medium_base64" label="Landscape Medium (615 x 346)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item sm={12} md={4}>
            <ImageInput source="landscape_large_base64" label="Landscape Large (1920 x 1080)" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
