import React from "react";
import { Button, Create, Datagrid, DeleteWithConfirmButton, Edit, EditButton, Filter, Link, List, required, SaveButton, SimpleForm, TextField, TextInput, Toolbar, TopToolbar, useNotify, useRecordContext } from "react-admin";

import { AssetBulkActionButtons } from "../components";

const CustomEdit = () => {
  const record = useRecordContext();
  if (record.is_used) {
    return <></>;
  }

  return <EditButton record={record} />;
};

const CustomDelete = () => {
  const record = useRecordContext();
  if (record.is_used) {
    return <></>;
  }

  return <DeleteWithConfirmButton record={record} undoable={false} confirmTitle={"Delete " + record.text} />;
};

const IMCannedMessgaeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Canned message" source="text" alwaysOn />
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/im-canned-messages/create`,
      }}
      label="+ CREATE"
    />
  </TopToolbar>
);

export const IMCannedMessageList = (props) => (
  <List {...props} sort={{ field: "created_at", order: "DESC" }} bulkActionButtons={false} filters={<IMCannedMessgaeFilter />} actions={<ListActions />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="text" />
      <CustomEdit />
      <CustomDelete />
    </Datagrid>
  </List>
);

const IMCannedMessageTitle = ({ record }) => {
  return <span>Canned Message {record ? `${record.id}` : ""}</span>;
};

const EditToolbar = (props) => (
  <Toolbar
    {...props}
    style={{
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <SaveButton />
    <CustomDelete />
  </Toolbar>
);

export const IMCannedMessageEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<IMCannedMessageTitle />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="text" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

const redirectIMCannedMessage = () => "/im-canned-messages";

export const IMCannedMessageCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect={redirectIMCannedMessage}>
        <TextInput source="text" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
