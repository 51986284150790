import {
  List, //
  Show,
  Edit,
  Datagrid,
  ShowButton,
  EditButton,
  TextField,
  SimpleForm,
  Link,
  TextInput,
  DateTimeInput,
  SimpleList,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  BooleanInput,
  BooleanField,
  ImageField,
  Labeled,
  useNotify,
  required,
  useRecordContext,
  ReferenceArrayField,
  linkToRecord,
  Filter,
  SelectInput,
  Button,
  NumberInput,
  AutocompleteInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRedirect,
  ExportButton,
} from "react-admin";
import { useState, useEffect, useCallback } from "react";
import { useDataProvider, Loading } from "react-admin";

import { Grid } from "@material-ui/core";
import { StringArrayChipField } from "./components";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { Download, FileUpload } from "@mui/icons-material";
import { MyDateField } from "../MyDateField";
import { Close, Done, Restore } from "@material-ui/icons";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import { useForm } from "react-final-form";
import { BooleanInputWithConfirm } from "./component/boolean-input-with-confirm";
import { RegionTextField, RegionTextInput } from "./component/region-components";

const AutoCompleteTags = (props) => {
  const dataProvider = useDataProvider();
  const [tags, setTags] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getList("tags", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setTags(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return "";
  }
  if (error) {
    return "";
  }
  if (!tags) return null;

  const tag_choices = tags.map((tag) => ({
    id: tag.tag_id,
    name: tag.name_tc,
  }));

  return <AutocompleteInput source="tags" choices={tag_choices} allowEmpty={true} />;
};

const AutoCompleteTadsFilter = (props) => {
  const filter = props.props.filterValues.tags;

  return (
    <ReferenceInput
      label="Tags"
      source="tags"
      reference="tags"
      filterToQuery={(searchText) => {
        if (searchText) {
          return { name_tc: searchText };
        }
        return {};
      }}
      allowEmpty={true}
    >
      <AutocompleteInput optionText="name_tc" optionValue="tag_id" allowEmpty={true} fullWidth />
    </ReferenceInput>
  );
};
const ProgrammeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="programme_id" alwaysOn />
    <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
    <SelectInput
      source="enabled"
      alwaysOn
      choices={[
        { id: "true", name: "Yes" },
        { id: "false", name: "No" },
      ]}
    />
    <TextInput label="Path" source="path" alwaysOn />
    <AutoCompleteTadsFilter props={props} alwaysOn />
  </Filter>
);

const LinkToRelatedEpisode = ({ record }) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/episodes",
        search: `filter=${JSON.stringify({
          programme_id: record.programme_id.toString(),
        })}`,
      }}
      label="Episode"
    />
  ) : null;
};

const LinkToRelatedOveridePoster = ({ record }) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/programme-overwrite-posters",
        search: `filter=${JSON.stringify({
          programme_id: record.programme_id.toString(),
        })}`,
      }}
      label="Overwrite Posters"
    />
  ) : null;
};

export const ProgrammeList = (props) => {
  //   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = false;
  return (
    <List {...props} sort={{ field: "modified_at", order: "DESC" }} filters={<ProgrammeFilter />} actions={<ProgrammeListActions {...props} />}>
      {isSmall ? (
        <SimpleList primaryText={(record) => record.name} />
      ) : (
        <Datagrid>
          <TextField source="programme_id" />
          <TextField label="Name in TC" source="name_tc" />
          <TextField label="Name in EN" source="name_en" />
          <BooleanField source="call_ad" />
          <ShowOffshellList label="Show 觀賞期限" />
          <ImageField label="Image" source="image.portrait_large" title="" />
          <BooleanField source="enabled" />
          <LinkToRelatedEpisode />
          <LinkToRelatedOveridePoster />
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

const ProgrammeTitle = ({ record }) => {
  return <span>Programme {record ? `${record.name_tc}` : ""}</span>;
};

// const TagField = (props) => {
//   const tag = useRecordContext();
//   if (tag) {
//     const tagShowPage = linkToRecord("/tags", tag._id, "show");
//     return <Link to={tagShowPage}>{tag.name_tc},&nbsp;</Link>;
//   }
//   return "";
// };

const ArtistField = () => {
  const programme = useRecordContext();

  const dataProvider = useDataProvider();
  const [artists, setArtists] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getMany("artists", { ids: programme.artists })
      .then(({ data }) => {
        setArtists(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  if (!artists) return null;

  return (
    <div>
      {artists.map((artist) => {
        const artistShowPage = linkToRecord("/artists", artist.id, "show");
        return <Link to={artistShowPage}>{artist.name_tc},&nbsp;</Link>;
      })}
    </div>
  );
};

const TagField = () => {
  const programme = useRecordContext();

  const dataProvider = useDataProvider();
  const [tags, setTags] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getMany("tags-programme-show", { ids: programme.tags })
      .then(({ data }) => {
        setTags(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  if (!tags) return null;

  var data = {};
  for (const tag of tags) {
    if (!data[tag.type]) {
      data[tag.type] = {};
    }
    data[tag.type]["type"] = tag.type;
    if (!data[tag.type]["tags"]) {
      data[tag.type]["tags"] = [];
    }

    data[tag.type]["tags"].push(tag);
  }

  var arr = Object.values(data);

  return (
    <Grid container>
      {arr.map((tagType) => (
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid item xs={4} sm={2} md={1}>
            <Labeled label="Tag Type"></Labeled>
          </Grid>
          <Grid item xs={8} sm={4} md={5}>
            <span style={{ wordBreak: "break-all" }}>{tagType.type}</span>
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <Labeled label="Tags"></Labeled>
          </Grid>
          <Grid item xs={8} sm={4} md={5}>
            <div>
              {tagType.tags.map((tag) => {
                const tagShowPage = linkToRecord("/tags", tag._id, "show");
                return (
                  <Link to={tagShowPage} style={{ wordBreak: "break-all" }}>
                    {tag.name_tc},&nbsp;
                  </Link>
                );
              })}
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export const ProgrammeShow = (props) => (
  <Show {...props} title={<ProgrammeTitle />}>
    <SimpleShowLayout>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Programme ID">
            <TextField source="programme_id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Name in TC">
            <TextField source="name_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Name in EN">
            <TextField source="name_en" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Long Desc in TC">
            <TextField source="long_desc_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Long Desc in EN">
            <TextField source="long_desc_en" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Short Desc in TC">
            <TextField source="short_desc_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Short Desc in EN">
            <TextField source="short_desc_en" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Version Type">
            <TextField source="version_type" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Path">
            <TextField source="path" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Gold 搶先看">
            <BooleanField source="is_early_release_for_gold_user" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Gold 搶先看 End Time">
            <MyDateField source="early_release_for_gold_user_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Mobile 搶先看">
            <BooleanField source="mobile_early_release" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Mobile 搶先看 End Time">
            <MyDateField source="mobile_early_release_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={12}>
          <Labeled label="Limited platform">
            <TextField source="limited_platform" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Start Time">
            <MyDateField source="default_start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="End Time">
            <MyDateField source="default_end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Overwrite Start Time">
            <MyDateField source="start_time" showTime />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Overwrite End Time">
            <MyDateField source="end_time" showTime />
          </Labeled>
        </Grid>
      </Grid>
      <MyDateField source="first_onair_time" showTime />
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Region">
            <RegionTextField />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Small">
            <ImageField source="image.portrait_small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Medium">
            <ImageField source="image.portrait_medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Large">
            <ImageField source="image.portrait_large" title="" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Small">
            <ImageField source="image.landscape_small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Medium">
            <ImageField source="image.landscape_medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Large">
            <ImageField source="image.landscape_large" title="" />
          </Labeled>
        </Grid>
      </Grid>
      {/* <ReferenceArrayField
        label="Tags"
        source="tags"
        reference="tags-programme-show"
        perPage={100}
      >
        <SingleFieldList>
          <TagField />
        </SingleFieldList>
      </ReferenceArrayField> */}

      <Labeled label="Tags"></Labeled>
      <TagField />

      <Labeled label="Artists">
        <ArtistField />
      </Labeled>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Labeled label="Directors in TC">
            <ArrayField source="directors_tc">
              <SingleFieldList>
                <StringArrayChipField />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Directors in EN">
            <ArrayField source="directors_en">
              <SingleFieldList>
                <StringArrayChipField />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={2}>
          <Labeled label="Enabled">
            <BooleanField source="enabled" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Recommend Other Title">
            <BooleanField source="recommend_other_title" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Allow Recommendation">
            <BooleanField source="allow_recommendation" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Parental Lock">
            <BooleanField source="parental_lock" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Display EPI title only">
            <BooleanField disabled source="display_epi_title_only" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={3}>
          <Labeled label="Call Ad">
            <BooleanField source="call_ad" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={3}>
          <Labeled label="Gold Content">
            <BooleanField source="gold_content" />
          </Labeled>
        </Grid>

        <Grid item sm={12} md={3}>
          <Labeled label="Show 觀賞期限">
            <ShowOffshell {...props} />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={3}>
          <Labeled label="SEO Desc in TC">
            <TextField source="seo_desc_tc" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={3}>
          <Labeled label="SEO Desc in EN">
            <TextField source="seo_desc_en" />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export const ProgrammeEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  const [form, setForm] = useState(null);

  const programmeEditValidation = () => {
    const errors = {};

    if (form) {
      const formData = form.getState().values;

      if ((formData.start_time && !formData.end_time) || (!formData.start_time && formData.end_time)) {
        errors.start_time = "Please input both overwrite start time and end time";
        errors.end_time = "Please input both overwrite start time and end time";
      }

      return errors;
    }
  };

  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<ProgrammeTitle />}>
      <SimpleForm toolbar={<PostEditToolbar />} validate={programmeEditValidation}>
        <EditProgrammeFormContent setForm={setForm} {...props} />
      </SimpleForm>
    </Edit>
  );
};

const EditProgrammeFormContent = (props) => {
  const form = useForm();

  useEffect(() => {
    props.setForm(form);
  }, []);

  return (
    <>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={4}>
          <NumberInput label="Programme ID" disabled source="programme_id" fullWidth />
        </Grid>
        <Grid item sm={12} md={4}>
          <TextInput label="Name in TC" disabled source="name_tc" fullWidth />
        </Grid>
        <Grid item sm={12} md={4}>
          <TextInput label="Name in EN" disabled source="name_en" fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Long Desc in TC" disabled source="long_desc_tc" fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Long Desc in EN" disabled source="long_desc_en" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Short Desc in TC" disabled source="short_desc_tc" fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Short Desc in EN" disabled source="short_desc_en" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <TextInput label="Version Type" disabled source="version_type" fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput label="Path" disabled source="path" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <Labeled label="Gold 搶先看">
            <BooleanInput source="is_early_release_for_gold_user" label="" defaultValue={false} disabled />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="Gold 搶先看 End Time" source="early_release_for_gold_user_end_time" fullWidth disabled />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <Labeled label="Mobile 搶先看">
            <BooleanInput source="mobile_early_release" label="" defaultValue={false} disabled />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="Mobile 搶先看 End Time" source="mobile_early_release_end_time" fullWidth disabled />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={12}>
          <TextInput label="Limited platform" source="limited_platform" disabled fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="Start Time" source="default_start_time" disabled fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="End Time" source="default_end_time" disabled fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="Overwrite Start Time" source="start_time" fullWidth />
        </Grid>
        <Grid item sm={12} md={6}>
          <MyDateTimeInput label="Overwrite End Time" source="end_time" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <EditButton
          label="Reset Overwrite Start / End Time"
          icon={<Restore />}
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            form.change("start_time", null);
            form.change("end_time", null);
          }}
        />
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <MyDateTimeInput label="First Onair Time" source="first_onair_time" disabled fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <RegionTextInput disabled fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Small">
            <ImageField source="image.portrait_small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Medium">
            <ImageField source="image.portrait_medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Portrait Large">
            <ImageField source="image.portrait_large" title="" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Small">
            <ImageField source="image.landscape_small" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Medium">
            <ImageField source="image.landscape_medium" title="" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Landscape Large">
            <ImageField source="image.landscape_large" title="" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <Labeled label="Tags"></Labeled>
          <TagField />

          {/* <Labeled label="Tags">
          <ReferenceArrayField label="Tags" source="tags" reference="tags">
            <SingleFieldList>
              <TagField />
            </SingleFieldList>
          </ReferenceArrayField>
        </Labeled> */}
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12}>
          <Labeled label="Artists">
            <ArtistField />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={6}>
          <Labeled label="Directors in TC">
            <ArrayField source="directors_tc">
              <SingleFieldList>
                <StringArrayChipField />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Directors in EN">
            <ArrayField source="directors_en">
              <SingleFieldList>
                <StringArrayChipField />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={2}>
          <Labeled label="Enabled">
            <BooleanInputWithConfirm source="enabled" popupTitle="Disable programme?" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Recommend Other Title">
            <BooleanInput disabled source="recommend_other_title" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Allow Recommendation">
            <BooleanInput disabled source="allow_recommendation" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Parental Lock">
            <BooleanInput disabled source="parental_lock" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={2}>
          <Labeled label="Display EPI title only">
            <BooleanInput disabled source="display_epi_title_only" />
          </Labeled>
        </Grid>
      </Grid>

      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={4}>
          <Labeled label="Call Ad">
            <BooleanInput disabled source="call_ad" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Gold Content">
            <BooleanInput source="gold_content" defaultValue={false} />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Show 觀賞期限">
            <BooleanInput disabled label="Show 觀賞期限" source="show_offshelf" />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container spacing={1} fullWidth>
        <Grid item sm={12} md={12} class="full-width">
          <Labeled label="SEO Desc in TC">
            <TextInput onKeyDown={props.listenerTC} id="seo_desc_tc" source="seo_desc_tc" fullWidth multiline />
          </Labeled>
          <DefaultValuetc />
        </Grid>
        <Grid item sm={12} md={12} class="full-width">
          <Labeled label="SEO Desc in EN">
            <TextInput onKeyDown={props.listenerEN} id="seo_desc_en" source="seo_desc_en" fullWidth multiline />
          </Labeled>
          <DefaultValueEn />
        </Grid>
      </Grid>
    </>
  );
};

const ShowOffshell = (props) => {
  const programme = useRecordContext();

  if (programme.show_offshelf) {
    return (
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
      </svg>
    );
  } else {
    return (
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      </svg>
    );
  }
};

const ShowOffshellList = (props) => {
  if (props.record.show_offshelf) {
    return (
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="true">
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
      </svg>
    );
  } else {
    return (
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="false">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      </svg>
    );
  }
};

const listenerEN = (event) => {
  var maxlength = 170;

  document.getElementById("start-en").innerHTML = document.getElementById("seo_desc_en").value.length + 1;
};
const listenerTC = (event) => {
  var maxlength = 85;
  document.getElementById("start-tc").innerHTML = document.getElementById("seo_desc_tc").value.length + 1;
};

const DefaultValueEn = (props) => {
  const programme = useRecordContext();
  let count = 0;
  if (programme.seo_desc_en && programme.seo_desc_en.length) {
    count = programme.seo_desc_en.length;
  }
  return (
    <div id="count-en">
      <span id="start-en">{count}</span>/170
    </div>
  );
};
const DefaultValuetc = (props) => {
  const programme = useRecordContext();
  let count = 0;
  if (programme.seo_desc_tc && programme.seo_desc_tc.length) {
    count = programme.seo_desc_tc.length;
  }
  return (
    <div id="count-tc">
      <span id="start-tc">{count}</span>/85
    </div>
  );
};

// This Toolbar leave a SaveButton the same as default Toolbar but remove Delete
const PostEditToolbar = function (props) {
  console.log("PostEditToolbar : props = ");
  console.log(props);

  const importRequestOnClick = useCallback(() => {
    PopupboxManager.open({ content });
  });

  const content = (
    <div style={{ textAlign: "center" }}>
      <Grid item sm={12} md={12}>
        <h2>Update Programme Info from MCM</h2>
      </Grid>
      <Grid item sm={12} md={12}>
        <br />
        <p>{"Are you sure?"}</p>
        <br />
      </Grid>
      <Grid item sm={12} md={12}>
        <Grid sm={12} md={12} container justifyContent="space-around">
          <SimpleForm toolbar={false} basePath="/import-requests" record={{ id: props.record.programme_id }}>
            <EditButton label="Confirm" icon={<Done />} style={{ width: "unset" }} />
          </SimpleForm>

          <EditButton
            label="Cancel"
            icon={<Close />}
            onClick={() => {
              PopupboxManager.close();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Toolbar {...props}>
      {/* default Save Button  */}
      <SaveButton disabled={props.pristine} />

      {/* button link to Import Request Edit page  */}
      <SimpleForm toolbar={false}>
        <EditButton label="Update Programme Info from MCM" icon={<FileUpload />} variant="outlined" style={{ width: "unset" }} onClick={importRequestOnClick} />
        <PopupboxContainer />
      </SimpleForm>
    </Toolbar>
  );
};

const ProgrammeListActions = function (props) {
  console.log("ProgrammeListActions : props = ");
  console.log(props);

  return (
    <Toolbar {...props}>
      {/* button link to Import Request List page  */}
      <SimpleForm toolbar={false} basePath="/import-requests" record={{ id: "" }}>
        <EditButton label="Import this Programme from MCM" icon={<FileUpload />} style={{ width: "unset" }} />
      </SimpleForm>

      {/* default Export Button  */}
      <ExportButton />
    </Toolbar>
  );
};
