import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ImageField,
  ReferenceField,
  ChipField,
  ReferenceArrayField,
  useRecordContext,
  SelectInput,
  ImageInput,
  required,
  Button,
  Link,
  DeleteWithConfirmButton,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import { Grid } from "@material-ui/core";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import { MyDateField } from "../MyDateField";

const LinkToRaceCard = ({ record }) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/horserace-data",
        search: `filter=${JSON.stringify({ match_day: record.id.toString(), content_type: "horse_racecard" })}`,
      }}
      label="排位表"
    />
  ) : null;
};

const LinkToTips = ({ record }) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/horserace-data",
        search: `filter=${JSON.stringify({ match_day: record.id.toString(), content_type: "horse_tips" })}`,
      }}
      label="貼士"
    />
  ) : null;
};

export const HorseRaceList = (props) => (
  <List {...props} sort={{ field: "start_time", order: "DESC" }} bulkActionButtons={<AssetBulkActionButtons />}>
    <Datagrid>
      <TextField source="match_day" />
      <MyDateField source="start_time" showTime />
      <MyDateField source="end_time" showTime />
      <LinkToRaceCard label="Race Card" />
      <LinkToTips label="Tips" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export const HorseRaceEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<HorseRaceTitle />}>
      <SimpleForm>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4}>
            <TextInput source="match_day" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={4}>
            <MyDateTimeInput source="start_time" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={4}>
            <MyDateTimeInput source="end_time" fullWidth validate={required()} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const HorseRaceTitle = ({ record }) => {
  return <span>Horserace {record ? `${record.user_id}` : ""}</span>;
};

export const HorseRaceCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={4}>
            <TextInput source="match_day" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={4}>
            <MyDateTimeInput source="start_time" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={4}>
            <MyDateTimeInput source="end_time" fullWidth validate={required()} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
