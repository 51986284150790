import { CheckboxGroupInput, RadioButtonGroupInput, useRecordContext, ChipField } from "react-admin";

const shoppingCartModeCheckboxChoices = [
  { id: "purchase", name: "Purchase" },
  { id: "vote", name: "Vote" },
  { id: "url", name: "Other" },
];

export const ShoppingCartModeCheckboxGroupInput = (props) => {
  // return (
  //   <RadioButtonGroupInput
  //     label="Shopping Cart Mode"
  //     source="mode"
  //     choices={shoppingCartModeCheckboxChoices}
  //     validate={(values, allValues) => {
  //       //Prompt error if user do not select Purchase/ vote when there are product in product list
  //       if (allValues.products && allValues.products.length > 0) {
  //         if (!values) {
  //           return "required";
  //         }

  //         if (values != "vote" && values != "purchase") {
  //           return "Must be Purchase or Vote";
  //         }
  //       }

  //       return undefined;
  //     }}
  //     {...props}
  //   />
  // );

  return (
    <CheckboxGroupInput //
      label="Shopping Cart Mode"
      source="mode"
      choices={shoppingCartModeCheckboxChoices}
      validate={(values, allValues) => {
        if (values && values.length >= 2) {
          return "Max 1 selection";
        }

        // Prompt error if user do not select Purchase/ vote when there are product in product list
        if (allValues.products && allValues.products.length > 0) {
          if (!values || values.length == 0) {
            return "required";
          }

          if (!values.includes("vote") && !values.includes("purchase")) {
            return "Must be Purchase or Vote";
          }
        }

        return undefined;
      }}
      {...props}
    />
  );
};

export const ShoppingCartModeChipField = (props) => {
  const record = useRecordContext();

  return (
    <>
      {record.qr_code_entry.map((item) => {
        const choice = shoppingCartModeCheckboxChoices.find((i) => i.id == item);
        return <ChipField record={{ key: choice.id, name: choice.name }} source="name" />;
      })}
    </>
  );
};
