export const showFormContent = (form)=>{
    const result = {};
    if(form){
      for(const key of form.getRegisteredFields()){
        result[key] = form.getFieldState(key).value;
      }  
  
    }
    return result;
  }
