import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ImageField,
  ReferenceField,
  ChipField,
  ReferenceArrayField,
  useRecordContext,
  SelectInput,
  ImageInput,
  required,
  DeleteWithConfirmButton,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  downloadCSV,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";

import { Grid } from "@material-ui/core";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";
import jsonExport from "jsonexport/dist";
 
const EventFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="Name in TC" source="name_tc" alwaysOn />
    <TextInput label="Name in EN" source="name_en" alwaysOn />
    <MyDateTimeInput source="start_time" alwaysOn />
    <MyDateTimeInput source="end_time" alwaysOn /> */}
  </Filter>
);

export const EventList = (props) => {

  const exporter = (programmes) => {
    console.log(programmes);
    const BOM = "\uFEFF";

    jsonExport(
      programmes,
      {},
      (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, "events"); // download as 'posts.csv` file
      }
    );
  };

  return (
  <List {...props} sort={{ field: "start_time", order: "ASC" }} bulkActionButtons={<AssetBulkActionButtons />} filters={<EventFilter />} exporter={exporter} >
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="type" />

      <ArrayField source="codes">
        <Datagrid>
          <TextField source="sports_topic_code" />
          <SportEventCode label="Spots Event Code" />
        </Datagrid>
      </ArrayField>
      <ImageField source="landscape_poster" />
      <ShowButton />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>)
};

export const EventEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };

  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<EventTitle />}>
      <SimpleForm>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="title" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <EventTypeEdit label="Type" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <ArrayInput source="codes">
            <SimpleFormIterator>
              <TextInput label="Sports Topic Code" source="sports_topic_code" fullWidth />

              <TextInput label='Sports Event Code (seperate by comma ",")' source="sports_event_code" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <ImageField source="portrait_poster" />
            <ImageInput source="portrait_poster_base64" label="Portrait" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item sm={12} md={6}>
            <ImageField source="landscape_poster" />
            <ImageInput source="landscape_poster_base64" label="Landscape" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const EventTitle = ({ record }) => {
  return <span>Event {record ? `${record.user_id}` : ""}</span>;
};

const CategoriesList = (props) => {
  const dataProvider = useDataProvider();
  const [categories, setCategories] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getList("categories/list", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name_tc", order: "ASC" },
        filter: { level: 1 },
      })
      .then(({ data }) => {
        setCategories(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (!categories) return null;

  const cat_choices = categories.map((cate) => ({
    id: cate.category_id,
    name: cate.name_tc,
  }));

  return <SelectInput validate={required()} label="Category" source="category_id" choices={cat_choices} allowEmpty={true} fullWidth />;
};

export const EventCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="title" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <EventType label="Type" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <ArrayInput source="codes">
            <SimpleFormIterator>
              <TextInput label="Sports Topic Code" source="sports_topic_code" fullWidth />
              <TextInput label='Sports Event Code (seperate by comma ",")' source="sports_event_code" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <ImageField source="portrait_poster" />
            <ImageInput source="portrait_poster_base64" label="Portrait" accept="image/*" validate={required()}>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item sm={12} md={6}>
            <ImageField source="landscape_poster" />
            <ImageInput source="landscape_poster_base64" label="Landscape" accept="image/*" validate={required()}>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const SportTopicCode = (props) => {
  const event = useRecordContext();
  if (event.sports_topic_code instanceof Array) {
    return <div>{event.sports_topic_code.join(", ")}</div>;
  } else {
    return <div>{event.sports_topic_code}</div>;
  }
};
const SportEventCode = (props) => {
  const event = useRecordContext();
  if (event.sports_event_code instanceof Array) {
    return <div>{event.sports_event_code.join(", ")}</div>;
  } else {
    return <div>{event.sports_event_code}</div>;
  }
};

export const EventShow = (props) => (
  <Show {...props} title={<EventTitle />}>
    <SimpleShowLayout>
      <Grid container>
        <Grid item sm={12} md={4}>
          <Labeled label="ID">
            <TextField source="id" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Title">
            <TextField source="title" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={4}>
          <Labeled label="Type">
            <TextField source="type" />
          </Labeled>
        </Grid>
        <ArrayField source="codes">
          <Datagrid>
            <TextField source="sports_topic_code" />
            <SportEventCode label="Spots Event Code" />
          </Datagrid>
        </ArrayField>

        <Grid item sm={12} md={6}>
          <Labeled label="Portrait Poster">
            <ImageField source="portrait_poster" />
          </Labeled>
        </Grid>
        <Grid item sm={12} md={6}>
          <Labeled label="Landscape Poster">
            <ImageField source="landscape_poster" />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

const EventType = (props) => {
  const dataProvider = useDataProvider();
  const [vas, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getListKeyValues("key-values", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "id", order: "ASC" },
        filter: { key: "event_type" },
      })
      .then(({ data }) => {
        setValues(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return "";
  }
  if (error) {
    return "";
  }
  if (!vas) return null;
  const value_choices = vas[0].values.map((val) => ({
    id: val,
    name: val,
  }));

  return <SelectInput label="Type" source="key" choices={value_choices} allowEmpty={true} fullWidth validate={required()} />;
};

const EventTypeEdit = (props) => {
  const event = useRecordContext();
  const dataProvider = useDataProvider();
  const [vas, setValues] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getListKeyValues("key-values", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "id", order: "ASC" },
        filter: { key: "event_type" },
      })
      .then(({ data }) => {
        setValues(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return "";
  }
  if (error) {
    return "";
  }
  if (!vas) return null;
  const value_choices = vas[0].values.map((val) => ({
    id: val,
    name: val,
  }));

  return <SelectInput defaultValue={event.type} fullWidth label="Type" source="key" choices={value_choices} allowEmpty={true} validate={required()} />;
};
