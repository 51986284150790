import {
  List, //
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  useNotify,
  Filter,
  DeleteButton,
  SimpleShowLayout,
  Labeled,
  ImageField,
  ReferenceField,
  ChipField,
  ReferenceArrayField,
  useRecordContext,
  SelectInput,
  ImageInput,
  required,
  ReferenceInput,
  TopToolbar,
  minValue,
  maxValue,
  Button,
  Link,
  ExportButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { AssetBulkActionButtons } from "./components";
import { Grid } from "@material-ui/core";
import { MyDateTimeInput } from "../MyDateTimeInput";
import { useState, useEffect } from "react";
import { useDataProvider, Loading } from "react-admin";

const HorseRaceDataFilter = (props) => (
  <Filter {...props}>
    <TextInput class="hidden" disabled label="Match Day" source="match_day" alwaysOn />
    <SelectInput
      class="hidden"
      disabled
      source="content_type"
      alwaysOn
      choices={[
        { id: "horse_tips", name: "horse_tips" },
        { id: "horse_racecard", name: "horse_racecard" },
      ]}
    />
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/horserace-data/create`,
        search: `content_type=${props.filterValues.content_type}&match_day=${props.filterValues.match_day}`,
      }}
      label="+ CREATE"
    />
    <ExportButton />
  </TopToolbar>
);

export const HorseRaceDataList = (props) => (
  <List {...props} sort={{ field: "start_time", order: "ASC" }} bulkActionButtons={<AssetBulkActionButtons />} filters={<HorseRaceDataFilter />} actions={<ListActions />}>
    <Datagrid>
      <TextField source="name_tc" />
      <TextField source="name_en" />
      <TextField source="race_no" />
      <TextField source="platform" />
      <ImageField source="image_url" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export const HorseRaceDataEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  return (
    <Edit {...props} onFailure={onFailure} mutationMode="pessimistic" title={<HorseRaceDataTitle />}>
      <SimpleForm>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="name_tc" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput source="name_en" fullWidth validate={required()} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput type="number" label="Race No" source="race_no" validate={[minValue(1)]} min={1} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <SelectInput
              source="platform"
              choices={[
                { id: "Web", name: "Web" },
                { id: "STB", name: "STB" },
                { id: "TV", name: "TV" },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <ImageField source="image_url" />
            <ImageInput source="image_base64" label="Image" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const HorseRaceDataTitle = ({ record }) => {
  return <span>Horserace {record ? `${record.user_id}` : ""}</span>;
};

export const HorseRaceDataCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(error.message.toString(), "warning");
  };
  let params = props.location.search;
  let data = params.split("&");
  let type = data[0].split("content_type=")[1];
  let match = data[1].split("match_day=")[1];
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <TextInput class="hidden" source="content_type" defaultValue={type} />
        <ReferenceInput class="hidden" defaultValue={match} source="match_day" reference="horserace" fullWidth>
          <SelectInput optionText="match_day" optionValue="id" />
        </ReferenceInput>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput source="name_tc" fullWidth validate={required()} />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextInput source="name_en" fullWidth validate={required()} />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={6}>
            <TextInput type="number" label="Race No" source="race_no" validate={[minValue(1)]} min={1} fullWidth />
          </Grid>
          <Grid item sm={12} md={6}>
            <SelectInput
              source="platform"
              choices={[
                { id: "Web", name: "Web" },
                { id: "STB", name: "STB" },
                { id: "TV", name: "TV" },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} fullWidth></Grid>
        <Grid container spacing={1} fullWidth>
          <Grid item sm={12} md={12}>
            <ImageInput source="image_base64" label="Image" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
